<template>
    <div class="gift">
      <leftNav></leftNav>
      <div class="giftcontent" v-loading="loading">
          <div class="gift_top">
              <p class="title">留学进度</p>
              <el-form :inline="true" :model="form" ref="form" label-width="120px" >
                    <el-form-item label="合同名称:" label-width="72px">
                        <el-select v-model="form.contractId"  style="width: 140px;" @change="search">
                            <el-option v-for="item in ContractList" :key="item.contractNumber"  :label="item.contractName" :value="item.contractNumber"></el-option>
                        </el-select>
                    </el-form-item>
                </el-form>
          </div>
          <div class="bigBox">
            <div class="box" :class="orderStatusClass(item.status)" v-for="(item,index) in dataList" :key="index">
                <div class="boxNumber">
                    <p>{{ index+1 }}</p>
                </div>
                <span></span>
                <div class="box_content" >
                    <p><img src="@/assets/studyIcon1.png" alt="">阶段名称：{{ item.processName }}</p>
                    <p><img src="@/assets/studyIcon2.png" alt="">角色：{{ item.roleCode }}</p>
                    <p><img src="@/assets/studyIcon3.png" alt="">执行人：{{ item.teacherName != 'null' ? item.teacherName : '暂无' }}</p>
                </div>
            </div>
            <el-empty v-if="dataList.length == 0" :image="require('../../assets/noData.png')"></el-empty>
          </div>
      </div>

     </div>
  </template>

  <script>
  import leftNav from "./leftNav";
  import { workTaskList, getcustomContractsList } from "api/application.js";
  export default {
      components: {
          leftNav
      },
      data() {
          return {
              dataList: [],
              ContractList: [],
              form: {
                  contractId: ''
              },
              loading: true,
          };
      },
      mounted() {
        this.getContractsList();
      },
      methods: {
          getOrder() {
            this.loading = true;
            const data = {
                contractId: this.form.contractId,
            };
            workTaskList(data).then(res => {
              this.dataList = res.data;
              this.loading = false;
            });
          },
          orderStatusClass(orderStatus) {
            const statusMap = {
                1: 'status1',
                0: 'status2',
                2: 'status4'
            };
                return statusMap[orderStatus];
        },
        getContractsList() {
            const data = {};

            getcustomContractsList(data).then(res => {
                if (res.code == '2000') {
                    this.ContractList = res.data.list;
                    if (this.ContractList.length > 0) {
                        if (!this.form.contractId) {
                            this.contractId = this.form.contractId || this.ContractList[0].id;
                            this.form.contractId = this.ContractList[0].contractNumber;
                        }

                        if (this.ContractList[0].contractNumber) {
                            this.getOrder();
                        }
                    }
                } else {
                this.$message({
                    type: 'error',
                    message: res.msg
                });
                this.loading = false;
                }
            });
        },
        search() {
            this.getOrder();
        }
      }
  };
  </script>

  <style  lang='stylus' scoped>
  @import '~css/stylus.styl';
  .gift{
      width: 1200px;
      height: 100%;
      display: flex;
      justify-content: space-between;
      background-color:#F5F7FA;
      padding-bottom: 60px;
      margin: 0 auto;
  }
  .giftcontent{
      width 940px;
      background-color: #fff;
      margin: 27px 2% 27px;
      box-sizing: border-box;
      border-radius: 12px 12px 12px 12px;
      .gift_top{
          padding:20px;
          border-bottom: 1px solid #EEEEEE;
          display: flex;
          align-items: center;
          .title{
              font-size: 20px;
              color: #333333;
              height:26px;
              position relative;
              padding-left: 14px;
              font-weight: bold;
              margin-right: 34px;
          }
          .title::after{
            content: '';
            position: absolute;
            left: 0;
            top: 2px;
            width :4px;
            height :20px;
            display: block;
            background-color: $themeColor;
            border-radius: 2px;
        }

      }
  }
  .bigBox{
    padding: 30px 17px 80px 20px;
  }
    .box{
        margin-bottom: 20px;
        position: relative;
        &>span{
            display: block;
            width:3px;
            height: 100%;
            position:absolute;
            left: 10px;
            top:22px;
            background: #CCCCCC;
        }
        .boxNumber{
            float: left;
            width: 40px;
            p{
                width: 22px;
                height: 22px;
                border-radius: 50%;
                font-weight: bold;
                font-size: 12px;
                color: #FFFFFF;
                background: #CCCCCC;
                text-align: center;
                line-height: 22px;
            }
        }
        .box_content{
            display: flex;
            float:right;
            width: 840px;
            border-radius: 6px 6px 6px 6px;
            border-top-width: 4px;
            border: 1px solid #EEEEEE;
            padding: 14px;
            box-sizing: border-box;
            p{
                margin-right: 50px;
                display: flex;
                align-items: center;
                font-size: 12px;
                color: #666666;
                img{
                    width 16px;
                    margin-right: 4px;
                }
            }
        }
    }
    .box:after{
        content: '';
        clear: both;
        display: block;
    }
    .status1{
        .box_content{
            border-top:4px solid #62D8A7 !important;
        }
        .boxNumber{
            p{
                background: #62D8A7 !important;
            }
        }
        &>span{
            background: #62D8A7 !important;
        }
    }
    .status2{
        .box_content{
            border-top:4px solid #FF5948 !important;
        }
        .boxNumber{
            p{
                background: #FF5948 !important;
            }
        }
        &>span{
            background: #FF5948 !important;
        }
    }
    .status3{
        .box_content{
            border-top:4px solid #FFAB24 !important;
        }
        .boxNumber{
            p{
                background: #FFAB24 !important;
            }
        }
        &>span{
            background: #FFAB24 !important;
        }
    }
    .status4{
        .box_content{
            border-top:4px solid #ccc !important;
        }
        .boxNumber{
            p{
                background: #ccc !important;
            }
        }
        &>span{
            background: #ccc !important;
        }
    }
    >>>.el-form-item{
        margin-bottom: 0 !important;
    }
    >>>.el-input__inner{
        height: 35px !important;
        line-height: 35px !important;
    }
  </style>
  <style>
   #app{

    }
  </style>