<template>
  <div>
    <!-- 历史记录详情 -->
    <el-dialog
      title=""
      :visible.sync="dialogDetailVisible"
      width="800px"
      center
      :before-close="handleClose"
      :close-on-click-modal="false"
      class="historyDetailDialog"
    >
      <p slot="title" class="dialog-header">
        <span @click="handleClose">材料记录 </span> > 材料记录详情
      </p>
      <div class="historyDetailBox">
        <p class="versionNum">版本{{ historyInfo.versionId }}</p>
        <div>
          <iframe
            id="my-iframe"
            :src="previewUrl"
            frameborder="0"
            width="430px"
            height="440px"
            allowfullscreen
          ></iframe>
          <div class="demo-form-inline" v-if="historyInfo.wfSaiSchoolVo">
            <p>
              操作时间：{{
                formatDate(
                  historyInfo.wfSaiSchoolVo.createTime,
                  'yyyy-MM-dd hh:mm:ss'
                )
              }}
            </p>
            <p>操作人：{{ historyInfo.wfSaiSchoolVo.createName }}</p>
            <!-- <p>材料名称：选校择校表</p> -->
            <p>客户名称：{{ historyInfo.wfSaiSchoolVo.customerName }}</p>
            <p>客户手机号：{{ historyInfo.wfSaiSchoolVo.customerPhone }}</p>
            <p>
              客户是否签字：{{
                historyInfo.wfSaiSchoolVo.isSignatory == 1 ? '已签字' : '未签字'
              }}
            </p>
            <p>
              是否定稿：{{
                historyInfo.wfSaiSchoolVo.isFinalized == 1 ? '已定稿' : '未定稿'
              }}
            </p>
            <div class="info-file-box">
              <span>材料:</span>
              <div
                class="info-file"
                v-if="historyInfo.wfSaiSchoolVo.materialExcelJson"
              >
                <img class="info-file-img" src="@/assets/pdfIcon.png" alt="" />
                <p class="info-file-name">{{ item.name }}</p>
                <div class="info-file-preview">
                  <span
                    class="info-file-preview-btn"
                    @click="handlePreview(item)"
                  >
                    预览
                  </span>
                  |
                  <span
                    class="info-file-preview-dowload"
                    @click="downLoadFileClick(item)"
                  >
                    下载
                  </span>
                </div>
              </div>
              <div
                class="info-file"
                v-if="
                  historyInfo.wfSaiSchoolVo &&
                  historyInfo.wfSaiSchoolVo.materialPdfJson
                "
              >
                <img class="info-file-img" src="@/assets/pdfIcon.png" alt="" />
                <p class="info-file-name">{{ item.name }}</p>
                <div class="info-file-preview">
                  <span
                    class="info-file-preview-btn"
                    @click="handlePreview(item)"
                  >
                    预览
                  </span>
                  |
                  <span
                    class="info-file-preview-dowload"
                    @click="downLoadFileClick(item)"
                  >
                    下载
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { formatDate } from '@utils/smallTools/index.js';
import { downloadFile } from '@/assets/js/index.js';

export default {
  props: {
    dataObj: Object,
    historyInfo: Object,
    dialogDetailVisible: Boolean
  },
  data() {
    return {
      previewUrl: ''
    };
  },
  methods: {
    formatDate,
    downLoadFileClick(item) {
      downloadFile(item.url, item.name);
    },
    handlePreview(file) {
      this.previewUrl = file.url;
    },
    handleClose() {
      this.$emit('handleClose');
    }
  }
};
</script>

<style lang="stylus" scoped>
>>> .el-dialog__header {
  border-bottom: 1px solid #EEEEEE;
}

.historyDetailBox {
  .versionNum {
    font-weight: bold;
    font-size: 16px;
    color: #333333;
    margin-bottom: 20px;
  }

  &>div {
    display: flex;
  }

  .demo-form-inline {
    padding: 0 20px;
    font-weight: 400;
    font-size: 14px;
    color: #333333;

    p {
      margin-bottom: 10px;
    }
  }

  .versionTitle {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 15px;
  }

  .versionFile {
    padding-top: 20px;
  }
}

#my-iframe {
  background: #F7F7F7;
  border-radius: 18px 18px 18px 18px;
  border: 1px solid #EEEEEE;

  img {
    max-width: 100%;
    height: auto;
  }
}

.info-file-box {
  margin-top: 16px;

  .info-file {
    font-size: 12px;
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    .info-file-preview {
      color: #FF8240;
    }

    .info-file-name {
      padding: 0 13px 0 10px;
      margin: 0;
    }

    .info-file-preview {
      cursor: pointer;
    }

    .info-file-img {
      width: 24px;
      height: 26px;
    }
  }
}

.historyDetailDialog {
  .dialog-header {
    font-weight: 400;
    font-size: 18px;
    color: #333333;
    text-align: left;
    margin: 0;

    span {
      cursor: pointer;
    }
  }
}
</style>