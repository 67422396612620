import request from "@utils/request";
// 获取选校择校表列表
export function getSchoolList(data) {
  return request({
    url: "/mv/wfSaiSchool/list",
    method: "post",
    data,
  });
}
// 选校择校表详情
export function getSchoolDetail(data) {
  return request({
    url: "/mv/wfSaiSchool/detail",
    method: "post",
    data,
  });
}
// 获取老师有选校任务的客户
export function getCustomInfoList(data) {
  return request({
    url: "/mv/wfSaiSchool/getCustomInfoList",
    method: "post",
    data,
  });
}
// 查询历史版本
export function getHistorical(data) {
  return request({
    url: "/mv/wfSaiSchool/getHistorical",
    method: "post",
    data,
  });
}
// 发送给用户签字
export function sendCustomSign(data) {
  return request({
    url: "/mv/wfSaiSchool/sendCustomSign",
    method: "post",
    data,
  });
}
// 删除选校择校表
export function toDelete(data) {
  return request({
    url: "/mv/wfSaiSchool/delete",
    method: "post",
    data,
  });
}
// 新增选校择校表
export function addForm(data) {
  return request({
    url: "/mv/wfSaiSchool/add",
    method: "post",
    data,
  });
}
// 获取项目名称
export function programList(data) {
  return request({
    url: "/mv/cmsProgram/list",
    method: "post",
    data,
  });
}
// 批量新增选校择校表
export function batchInsertSaiSchool(data) {
  return request({
    url: "/mv/wfSaiSchool/batchInsertSaiSchool",
    method: "post",
    data,
  });
}
// 客户列表
export function batchContracts(data) {
  return request({
    url: "/mv/customContracts/batchContracts",
    method: "post",
    data,
  });
}

