<template>
    <div>
      <div class="title">第七部分：文书信息表
        <div class="btnBox">
            <!-- <el-button class="saveBtn" plain round @click="goBack">返回上一页</el-button> -->
            <template v-if="isEdit && type!='detail' && finalDraft!=1">
            <el-button type="primary" class="ml-10" round @click="startEdit">开始修改</el-button>
            <!-- <el-button type="primary" class="ml-10" round @click="navigateNext">跳转下一页</el-button> -->
            </template>
            <template  v-else-if="type!='detail' && !isEdit">
            <p class="saveBtn ml-10" v-show="!isEdit" @click="stopEdit">退出修改</p>
            <el-button type="primary" class="ml-10" round @click="saveAndNavigate">确认修改</el-button>
            </template>
        </div>
      </div>
      <div class="contentBox">
        <p class="tipss">说明：为避免“文书模式化”，我们希望每个申请人的文书都有自己的特色，所以对于每个问题，希望你能够不加修饰的提供最真实的想法，也同时通过你提供的信息了解 你，站在你的角度为学校创作出最具特色的文书。</p>
        <h3>1、为什么要选择此学校？</h3>
        <el-form :model="formData" inline class="flexs" label-position="left">
        <el-row>
            <el-col :span="24">
              <p class="smallTitle">A.影响你申请此院校的人物或事迹，怎样影响的你？（家人或者某位名人。）</p>
              <el-form-item label=""  :class="whetherReturnEmpty(formData.question1a)">
                <el-input
                  v-model="formData.question1a"
                  placeholder="请输入"
                  size="medium"
                  class="matching-input"
                  :disabled="isEdit"
                />
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <p class="smallTitle">B.你对这所学校的了解程度以及这所学校的哪位老师、毕业名人、或者理念影响你申请此校？</p>
              <el-form-item label=""  :class="whetherReturnEmpty(formData.question1b)">
                <el-input
                  v-model="formData.question1b"
                  placeholder="请输入"
                  size="medium"
                  class="matching-input"
                  :disabled="isEdit"
                />
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <p class="smallTitle">C.这所学校的教学质量、地理位置或者学校排名是怎样影响你的选择的？</p>
              <el-form-item label=""  :class="whetherReturnEmpty(formData.question1c)">
                <el-input
                  v-model="formData.question1c"
                  placeholder="请输入"
                  size="medium"
                  class="matching-input"
                  :disabled="isEdit"
                />
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <p class="smallTitle">D.其他影响你申请此院校的因素。</p>
              <el-form-item label=""  :class="whetherReturnEmpty(formData.question1d)">
                <el-input
                  v-model="formData.question1d"
                  placeholder="请输入"
                  size="medium"
                  class="matching-input"
                  :disabled="isEdit"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <h3>2、为什么想申请此专业？ </h3>
          <el-row>
            <el-col :span="24">
              <p class="smallTitle">A.你最倾向选择的专业方向和研究领域都有哪些，请详细阐述选择它们的原因。如果是跨专业申请，为什么要放弃原来的专业？请阐明选择现在这 个专业的兴趣所在。</p>
              <el-form-item label=""  :class="whetherReturnEmpty(formData.question2a)"  >
                <el-input
                  v-model="formData.question2a"
                  placeholder="请输入"
                  size="medium"
                  class="matching-input"
                  :disabled="isEdit"
                />
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <p class="smallTitle">B.家人学习的专业或者从事的职业，是否对于你选择此专业有 <font>特殊</font>的影响？如有，请阐述。</p>
              <el-form-item label=""  :class="whetherReturnEmpty(formData.question2b)"  >
                <el-input
                  v-model="formData.question2b"
                  placeholder="请输入"
                  size="medium"
                  class="matching-input"
                  :disabled="isEdit"
                />
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <p class="smallTitle">C.是否某位名人的故事，或者看过的某本书，某个电影等对你选择此专业有<font>特殊</font>的影响？如有，请阐述。</p>
              <el-form-item label=""  :class="whetherReturnEmpty(formData.question2c)"  >
                <el-input
                  v-model="formData.question2c"
                  placeholder="请输入"
                  size="medium"
                  class="matching-input"
                  :disabled="isEdit"
                />
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <p class="smallTitle">D.你个人的特殊成长经历，兴趣爱好，性格特点等因素对你选择此专业的影响？ </p>
                <el-form-item label=""  :class="whetherReturnEmpty(formData.question2d)"  >
                <el-input
                  v-model="formData.question2d"
                  placeholder="请输入"
                  size="medium"
                  class="matching-input"
                  :disabled="isEdit"
                />
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <p class="smallTitle">E.你对此专业的理解，对这个专业的未来展望，以及其他影响你选择此专业的因素。(经济形势或者你的理想)</p>
              <el-form-item label=""  :class="whetherReturnEmpty(formData.question2e)"  >
                <el-input
                  v-model="formData.question2e"
                  placeholder="请输入"
                  size="medium"
                  class="matching-input"
                  :disabled="isEdit"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <h3>3、你申请本专业的优势是什么？劣势又是什么？你认为你能在众多申请者中脱颖而出的最大优势是什么？ </h3>
          <el-row>
            <el-col :span="24">
              <p class="smallTitle">学过哪些与申请专业相关的课程，参加过哪些与申请专业相关的科研项目，实习，课外活动，在这些课程，科研，实习，课外活动中，你分别获得 了哪些专业知识和技能来帮助你未来的学习？</p>
              <el-form-item label=""  :class="whetherReturnEmpty(formData.question3)" >
                <el-input
                  v-model="formData.question3"
                  placeholder="请输入"
                  size="medium"
                  class="matching-input"
                  :disabled="isEdit"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <h3>4、请简述一下你的长短期职业目标分别是什么？怎样去实现？ </h3>
          <el-row>
            <el-col :span="24">
              <p class="smallTitle">A.你觉得XX国XX项目的留学经历，会从哪几方面帮助你成长（专业、思想认识、社交能力、语言能力等），将怎样帮助你实现目标？可以列出进入 该项目后具体要学习的课程和想要参加的项目，或者想要跟随的导师（如有，有的学校会要求列出导师）。</p>
              <el-form-item label=""  :class="whetherReturnEmpty(formData.question4a)" >
                <el-input
                  v-model="formData.question4a"
                  placeholder="请输入"
                  size="medium"
                  class="matching-input"
                  :disabled="isEdit"
                />
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <p class="smallTitle">B.请阐述你拿到学位之后的人生规划与目标，（3-5年内的短期目标：从国外大学拿到学位后想找什么样的公司和谋取什么样的职位；10年以后的长 期目标：以后想建立什么事业，比如创立公司，升为某公司的CEO等。）</p>
              <el-form-item label=""  >
                <el-input
                  v-model="formData.question4b"
                  placeholder="请输入"
                  size="medium"
                  class="matching-input"
                  :disabled="isEdit"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <h3>5、你在人生道路上曾遇到过哪些重大的困难与严峻的挑战？你是如何克服的，以及从中受到了什么启迪？ </h3>
          <el-row>
            <el-col  :span="24">

              <el-form-item label=""  :class="whetherReturnEmpty(formData.question5)" >
                <el-input
                  v-model="formData.question5"
                  placeholder="请输入"
                  size="medium"
                  class="matching-input"
                  :disabled="isEdit"
                />
              </el-form-item>
            </el-col>
        </el-row>
        <h3>6、你觉得有<font>挑战性</font>或<font>印象深刻</font>的特殊经历（如国外学习经历、社会活动、 文体项目、自主科研等）？你从这些经历中学到了什 么？</h3>
          <el-row>
            <el-col  :span="24">

              <el-form-item label=""  :class="whetherReturnEmpty(formData.question6)" >
                <el-input
                  v-model="formData.question6"
                  placeholder="请输入"
                  size="medium"
                  class="matching-input"
                  :disabled="isEdit"
                />
              </el-form-item>
            </el-col>
        </el-row>
        <h3>7、如果你能被你所申请的学校录取，你认为你能给这所学校、这个项目做出什么贡献。</h3>
          <el-row>
            <el-col  :span="24">

              <el-form-item label=""  :class="whetherReturnEmpty(formData.question7)" >
                <el-input
                  v-model="formData.question7"
                  placeholder="请输入"
                  size="medium"
                  class="matching-input"
                  :disabled="isEdit"
                />
              </el-form-item>
            </el-col>
        </el-row>
        <h3>8、你将坚持怎样的人生价值观和信念，追求怎样的人生道路？它们如何帮助你在此项目和专业的学习中获得成功？</h3>
          <el-row>
            <el-col  :span="24">

              <el-form-item label=""  :class="whetherReturnEmpty(formData.question8)" >
                <el-input
                  v-model="formData.question8"
                  placeholder="请输入"
                  size="medium"
                  class="matching-input"
                  :disabled="isEdit"
                />
              </el-form-item>
            </el-col>
        </el-row>
        <h3>9、其他你需要补充的个人信息。 <br/>（如果你有闪光点，在我们的信息表中没有体现出来，那么这里是你自由发挥的地方）</h3>
          <el-row>
            <el-col  :span="24">
              <el-form-item label=""  :class="whetherReturnEmpty(formData.question9)" >
                <el-input
                  v-model="formData.question9"
                  placeholder="请输入"
                  size="medium"
                  class="matching-input"
                  :disabled="isEdit"
                />
              </el-form-item>
            </el-col>
        </el-row>
        </el-form>
      </div>
    </div>
  </template>

  <script>
import { wfSaiEssaysGetDetailByMaterialId, wfSaiEssaysAdd, wfSaiEssaysUpdate } from '@/assets/api/application';
  export default {
    name: "Partseven",
    props: {
      type: {
        type: String,
        default: ''
      },
      finalDraft: {
        type: Number,
        default: 0
      },
      id: {
      type: [String, Number],
      default: ''
    },
    },
    data() {
      return {
        formData: {
          editDto: {}
        },
        name: '',
        isEdit: true,
        isNewEdit: null

      };
    },
    mounted() {},
    created() {
      this.getDetail();
    },
    methods: {
      getDetail() {
          this.loading = true;
          wfSaiEssaysGetDetailByMaterialId({ materialId: this.id }).then(res => {
            if (res.code == '2000') {
              this.formData = res.data;
              this.isNewEdit = false;
            } else {
              this.isNewEdit = true;
              this.formData = {
                ...res.data,
                editDto: {},
              };
            }
          });
      },
      saveAndNavigate() {
        this.$emit('showLoading');
      const url = this.isNewEdit ? wfSaiEssaysAdd : wfSaiEssaysUpdate;
          try {
            const data = {
              ...this.formData,
              materialId: this.id,
              userId: localStorage.getItem('userInfo').id
            };
            if (this.isNewEdit == false) {
              data.id = this.formData.id;
            };
            url(data).then(res => {
              if (res.code === '2000') {
                this.$message.success('保存成功');
                this.isEdit = true;
                this.getDetail();
                setTimeout(() => {
                  this.$emit('getDetail');
                }, 2000);
                this.$emit('hideLoading');
              } else {
                this.$message.error(res.message || '保存失败');
                this.$emit('hideLoading');
              }
            });
          } catch (error) {
            this.$message.error('保存失败');
            this.$emit('hideLoading');
          }
    },
      goBack() {
        this.$emit('backPage');
      },
      startEdit() {
        this.isEdit = !this.isEdit;
      },
      stopEdit() {
        this.isEdit = !this.isEdit;
      },
      whetherReturnEmpty(value) {
      if (value && value == 1) {
        return 'changeValue';
      }
    }
    },
  };
  </script>

  <style  lang='stylus' scoped>
  @import '~css/stylus.styl';

  .title {
    font-weight: bold;
    font-size: 18px;
    color: #333333;
    line-height: 32px;
    margin: 21px 0;
    text-align: left;
    justify-content: space-between;
    display: flex;
  }

  .flexs {
    .el-form-item {
      display: flex;
      margin-bottom: 16px;
      align-items: end;
    }
  }

  .contentBox {
    width: 900px;
    margin: 0 auto;

    h3 {
      font-size: 16px;
      color: #333333;
      margin-bottom: 15px;
      line-height: 24px;
      font{
        color: rgba(255, 130, 64, 1);
      }
    }
  }

  >>>.el-form-item__content{
      width:100%
  }
  .delClass{
    cursor pointer;
    color: rgba(255, 130, 64, 1);
    font-size: 20px;
  }
  .container:after{
    content: '';
    display: block;
    clear: both;
  }
  .addBtnClass{
    width: 90px;
    height: 28px;
    background: #FFEFE6;
    border-radius: 17px 17px 17px 17px;
    border: 1px solid #FF8240;
    font-size: 14px;
    color: #FF8240;
    margin: 15px 0 18px;
    float: right;
    cursor pointer;
    text-align: center;
    line-height: 28px
  }
  .tipss{
    font-size: 12px;
    color: #FF8240;
    margin-bottom: 19px;
    line-height: 18px;
    text-align: left;
  }
  .line{
    width: 100%;
    height: 1px;
    background-color: #E5E5E5;
    margin-bottom: 16px;
  }
  .smallTitle{
    font-weight: 400;
    font-size: 14px;
    color: #333333;
    line-height: 18px;
    margin-bottom: 10px
    font{
        color: rgba(255, 130, 64, 1)
    }
  }
.tips{
    font-size: 12px;
    color: #FF8240;
    line-height: 16px;
    margin-bottom: 15px;
}
.btnBox{
  display: flex;
  justify-content: center;
}
>>>.el-button {
  width: 92px;
  padding: 0 !important;
  height: 30px;
  line-height: 30px;
  border-radius: 15px;
  font-size: 14px;
  font-weight: 400;
}

.saveBtn {
  width: 92px;
  height: 30px;
  background: #FFFFFF;
  border-radius: 15px;
  border: 1px solid #FF8240;
  box-sizing: border-box;
  font-size: 14px;
  color: #FF8240;
  text-align: center;
  line-height: 30px;
  cursor: pointer;
  transition: all 0.3s;
  font-weight: 400;

  &:hover {
    background: #fff5f0;
  }
}
.ml-10 {
  margin-left: 10px;
}
.changeValue{
  >>>.el-form-item__label{
    color: #FF8240 !important;
  }
  >>>.el-input__inner{
    border-color: #FF8240 !important;
  }
}
  </style>