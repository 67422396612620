<template>
    <div class="gift">
      <leftNav></leftNav>
      <div class="giftcontent">
          <div class="gift_top">
              <p class="title">材料管理</p>
          </div>
          <div class="infoBox">
            <div class="searchBox">
                <el-form :inline="true" :model="form" ref="form" label-width="120px" >
                    <el-form-item label="合同名称:" label-width="72px">
                        <el-select v-model="form.contractId"  style="width: 140px;" @change="search">
                            <el-option v-for="item in ContractList" :key="item.contractNumber"  :label="item.contractName" :value="item.contractNumber"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="材料类型:" label-width="72px">
                        <el-select v-model="form.materialType" style="width: 120px;" size="medium" clearable @change="search">
                            <el-option v-for="item in materialtypeList" :key="item.code"  :label="item.name" :value="item.code"></el-option>
                        </el-select>
                    </el-form-item>
                    <!-- <el-form-item label="签字状态:" label-width="72px" >
                        <el-select v-model="form.customerSigned" style="width: 120px;" size="medium" @change="search" clearable>
                            <el-option v-for="item in statusList2" :key="item.code"  :label="item.name" :value="item.code"></el-option>
                        </el-select>
                    </el-form-item>
                     <el-form-item label="是否定稿:" label-width="72px">
                        <el-select v-model="form.finalDraft" style="width: 120px;" size="medium" @change="search" clearable>
                            <el-option v-for="item in statusList" :key="item.code"  :label="item.name" :value="item.code"></el-option>
                        </el-select>
                    </el-form-item> -->
                </el-form>
                <p>
                    <el-button round plain type="primary" size="small" @click="search">查询</el-button>
                </p>
            </div>
            <el-table :data="tableData" style="width: 100%" border v-loading="loading">
                <el-table-column  label="序号" align="center" width="60">
                    <template slot-scope="scope">
                        {{ (pagenum - 1) * pageSize + scope.$index + 1 }}
                    </template>
                </el-table-column>
                <!-- <el-table-column  label="签字提醒" align="center" width="80">
                    <template slot-scope="scope">
                        <i class="el-icon-message-solid" :class="[scope.row.signNotice==1?'activeimg':'img']"></i>
                    </template>
                </el-table-column> -->
                <el-table-column prop="relatedDocument" label="材料类型" align="center"></el-table-column>
                <!-- <el-table-column prop="relatedDocument" label="任务状态" align="center">
                    <template slot-scope="scope">
                        <span :class="getStatusClass(scope.row.taskStatus)"> {{ gettaskStatusText(scope.row.taskStatus) }}</span>
                       </template>
                </el-table-column>
                <el-table-column prop="customerSigned" label="签字状态" align="center">
                    <template slot-scope="scope">
                        <span :class="getStatusClass(scope.row.customerSigned)">
                            {{ getStatusText(scope.row.customerSigned) }}
                        </span>
                    </template>
                </el-table-column>
                <el-table-column prop="finalDraft" label="是否定稿" align="center">
                    <template slot-scope="scope">
                        <span v-if="scope.row.finalDraft==0" style="color:rgba(255, 130, 64, 1)">未定稿</span>
                        <span v-else style="color:rgba(29, 184, 40, 1)">已定稿</span>
                    </template>
                </el-table-column> -->
                <el-table-column prop="teachers" label="负责人" align="center" ></el-table-column>
                <el-table-column prop="updateTime" label="操作时间" width="175" align="center"></el-table-column>
                <el-table-column prop="" label="操作" width="120" align="center">
                    <template slot-scope="scope">
                        <el-button type="text" v-if="canShowDetail(scope.row)" @click="toDetail(scope.row, 'detail')">查看详情</el-button>
                        <el-button type="text" v-if="canEdit(scope.row)" @click="toDetail(scope.row, 'edit')">编辑</el-button>
                        <el-button type="text" v-if="isSharedDocument(scope.row)" @click="toLink(scope.row)">查看共享文档</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <paging :pageall="pageall" :pagenum="pagenum" @pageclick="pageclick" style="margin-top: 20px"></paging>
          </div>
      </div>

     </div>
  </template>
  <script>
import paging from '@/components/paging.vue';
import { getworkTask, getcustomContractsList } from '@/assets/api/application.js';
import { dicListByCode } from 'api/index.js';
import leftNav from "./leftNav";
  export default {
      components: {
        paging,
        leftNav
      },
      data() {
          return {
            active: 0,
            form: {},
            statusList: [
                {
                    code: 0,
                    name: '未定稿'
                },
                {
                    code: 1,
                    name: '已定稿'
                }
            ],
            statusList2: [
                {
                    code: 0,
                    name: '未签字'
                },
                {
                    code: 1,
                    name: '已签字'
                }
            ],
            materialtypeList: [],
            tableData: [],
            pageall: 0,
            pagenum: this.$route.query.pagenum || 1,
            pageSize: 10,
            ContractList: [],
            loading: false,
            sharedDocumentTypes: ['WENSHU', 'ESSAY', 'TUIJIANXIN', 'JIANLI'],
            editableMaterialTypes: ['XSSQXXB1Y', 'XSSQXXB1H', 'XSSQXXB2', 'XSSQXXB3', 'XSSQXXB4', 'XSSQXXB5', 'XSSQXXB6', 'XSSQXXB7', 'XXZXB']

          };
      },
      mounted() {
          this.getCodeList();
          this.getContractsList();
          if (this.$route.query.form && typeof this.$route.query.form === 'object') {
            this.form = {
                ...this.$route.query.form
            };
            this.contractId = this.form.contractId;
        }
      },
      methods: {
        getContractsList() {
            this.loading = true;
            const data = {};
            getcustomContractsList(data).then(res => {
                if (res.code == '2000') {
                    this.loading = false;
                    this.ContractList = res.data.list;
                    if (this.ContractList.length > 0) {
                        if (!this.form.contractId) {
                            this.contractId = this.form.contractId || this.ContractList[0].id;
                            this.form.contractId = this.ContractList[0].contractNumber;
                        }

                        if (this.ContractList[0].contractNumber) {
                            this.getList();
                        }
                    }
                } else {
                this.$message({
                    type: 'error',
                    message: res.msg
                });
                }
            });
        },
        getList() {
            this.loading = true;
            const data = {
                pageNum: this.pagenum,
                pageSize: this.pageSize,
                validContract: 1,
                ...this.form
            };

            getworkTask(data).then(res => {
                if (res.code == '2000') {
                    this.tableData = res.data.list;
                    this.pageall = res.data.total;
                    this.loading = false;
                } else {
                this.$message({
                    type: 'error',
                    message: res.msg
                });
                this.loading = false;
                }
            }).catch(() => {
                this.$message({
                    type: 'error',
                    message: '网络异常'
                });
            });
        },
        getCodeList() {
                dicListByCode({ code: 'CLLX' })
                .then(res => {
                    if (res.code == '2000') {
                        this.materialtypeList = res.data;
                    } else {
                    this.$message({
                        type: 'error',
                        message: res.msg
                    });
                    }
                })
                .catch(error => {
                    console.error('An error occurred:', error);
                    this.$message({
                    type: 'error',
                    message: '请求过程中发生错误，请稍后再试。'
                    });
                });
            },
        pageclick(num) {
            this.pagenum = num;
            this.getList();
        },
        search() {
            this.pagenum = 1;
            this.getList();
        },
        toDetail(row, type) {
            this.$router.push({
                path: '/applicationFormdetail',
                query: { id: row.taskId, type: type, materialType: row.materialType, form: this.form, pagenum: this.pagenum }
            });
        },
        getStatusClass(customerSigned) {
            if (customerSigned === 0) return 'pending';
            if (customerSigned === 1) return 'signed';
        },
        getStatusText(customerSigned) {
            if (customerSigned === 0) return '待签字';
            if (customerSigned === 1) return '已签字';
        },
        gettaskStatusText(taskStatus) {
            if (taskStatus === 0) return '进行中';
            if (taskStatus === 1) return '已完成';
            if (taskStatus === 2) return '已延期';
            if (taskStatus === 3) return '无效任务';
            if (taskStatus === 4) return '未开始';
            if (taskStatus === 5) return '已终止';
            return '';
        },
        toLink(row) {
            if (row.documentUrl && row.documentUrl != null && row.documentUrl != "" ) {
                window.open(row.documentUrl);
            } else {
                this.$message.error('负责老师还未为您创建文档，如需查看请联系相关老师~');
            }
        },
        // 判断是否显示“查看详情”按钮
        canShowDetail(row) {
            return row && row.materialType && !this.sharedDocumentTypes.includes(row.materialType);
        },
        // 判断是否显示“编辑”按钮
        canEdit(row) {
            return (
                row &&
                row.taskStatus !== 5 &&
                !this.sharedDocumentTypes.includes(row.materialType) &&
                this.editableMaterialTypes.includes(row.materialType)
            );
        },
        // 判断是否显示“查看共享文档”按钮
        isSharedDocument(row) {
            return row && row.materialType && this.sharedDocumentTypes.includes(row.materialType);
        }
      }
  };
  </script>

  <style  lang='stylus' scoped>
  @import '~css/stylus.styl';
  .gift{
      width: 1200px;
      height: 100%;
      display: flex;
      justify-content: space-between;
      background-color:#F5F7FA;
      padding-bottom: 60px;
      margin: 0 auto;
  }
  .giftcontent{
      width 940px;
      background-color: #fff;
      margin: 27px 2% 27px;
      box-sizing: border-box;
      border-radius: 12px 12px 12px 12px;
      .gift_top{
          padding:20px;
          border-bottom: 1px solid #EEE;
          .title{
              font-size: 20px;
              color: #333333;
              height:26px;
              position relative;
              padding-left: 14px;
              font-weight: bold;
          }
          .title::after{
            content: '';
            position: absolute;
            left: 0;
            top: 2px;
            width :4px;
            height :20px;
            display: block;
            background-color: $themeColor;
            border-radius: 2px;
        }

      }

  }

  .infoBox{
    padding: 19px 20px 0;
    margin: 0 auto 19px;
    box-sizing: border-box;
    .searchBox{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px
      }
      .el-form-item{
        margin-bottom: 0px;
      }
   }
   .activeimg{
    color: rgba(255, 119, 119, 1);
    font-size: 18px;
}
.img{
    color: rgba(127, 156, 184, 1);
    font-size: 18px;
}
.pending {
    color: rgba(246, 196, 32, 1);
}
.signed {
    color: rgba(29, 184, 40, 1);
}
.revising {
    color: rgba(84, 107, 255, 1);
}
  </style>
  <style>
   #app{
     background-color:#F5F7FA;
    }
    .main{
        background-color:#F5F7FA;
    }
  </style>