import Vue from 'vue';
import Vuex from 'vuex';
import aside from './modules/aside';
import verification from './verification';
import request from '@utils/request';
import { removeToken } from '@/utils/auth';
Vue.use(Vuex);

// const user = { // 重命名模块以避免与state属性冲突
//   state: {
//     info: {} // 重命名属性以避免与模块名冲突
//   },
//   mutations: {
//     setUserInfo(state, value) {
//       state.info = value; // 更新属性名以匹配重命名
//     }
//   },
//   actions: {
//     setUserInfo({ commit }, value) {
//       commit('setUserInfo', value); // 更新属性名以匹配重命名
//     }
//   }
// };
export default new Vuex.Store({
  state: {
    token: localStorage.getItem('token') || '',
    userInfo: localStorage.getItem('userInfo') ? localStorage.getItem('userInfo') : {}
  },
  getters: {
    token: state => state.token,
    userInfo: state => state.userInfo
  },
  mutations: {
    SET_TOKEN(state, token) {
      state.token = token;
    },
    SET_USER_INFO(state, userInfo) {
      state.userInfo = userInfo;
    },
    CLEAR_USER_STATE(state) {
      state.token = '';
      state.userInfo = {};
    },
    TRACK_EVENT(state, payload) {
      // 处理埋点事件
      // 这里可以添加具体的埋点逻辑
    }
  },
  modules: {
    aside,
    verification
  },
  actions: {
    // 退出登录
    async logout({ commit }) {
      try {
        // 清除本地存储
        localStorage.removeItem('token');
        localStorage.removeItem('userInfo');
        // 清除 Vuex 状态
        commit('CLEAR_USER_STATE');
        // 移除 token
        removeToken();
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(error);
      }
    },
    trackEvent({ commit }, payload) {
      commit('TRACK_EVENT', payload);
      console.log('埋点事件', payload.action);
      request({
        url: '/mv/cmsBuryingPoint/add',
        method: 'post',
        data: {
          name: payload.action
        }
      });
    }
  }
});
