import request from "@utils/request";
// 历史记录
export function getworkTaskHistory(data) {
  return request({
    url: "/mv/workTaskHistory/list",
    method: "post",
    data,
  });
}
// 申请材料列表
export function getworkTask(data) {
  return request({
    url: "/mv/workTask/queryTaskFile",
    method: "post",
    data,
  });
}
// 材料详情
export function getworkTaskdetail(data) {
  return request({
    url: "/mv/workTask/detail",
    method: "post",
    data,
  });
}

// 合同列表
export function getcustomContractsList(data) {
  return request({
    url: "/mv/customContracts/list",
    method: "post",
    data,
  });
}
// 合同列表
export function getSchoolCustomContractsList(data) {
  return request({
    url: "/mv/customContracts/batchContracts",
    method: "post",
    data,
  });
}
// 第一部分详情
export function getDetailByMaterialId(data) {
  return request({
    url: "/mv/wfSaiApplicant/getDetailByMaterialId",
    method: "post",
    data,
  });
}
// 第一部分新增
export function wfSaiApplicantAdd(data) {
  return request({
    url: "/mv/wfSaiApplicant/add",
    method: "post",
    data,
  });
}
// 第一部分更新
export function wfSaiApplicantUpdate(data) {
  return request({
    url: "/mv/wfSaiApplicant/update",
    method: "post",
    data,
  });
}
// 第二部分新增
export function wfSaiLanguageAdd(data) {
  return request({
    url: "/mv/wfSaiLanguage/addList",
    method: "post",
    data,
  });
}
// 第二部分更新
export function wfSaiLanguageupdate(data) {
  return request({
    url: "/mv/wfSaiLanguage/updateList",
    method: "post",
    data,
  });
}
// 第二部分详情
export function wfSaiLanguageGetDetailByMaterialId(data) {
  return request({
    url: "/mv/wfSaiLanguage/getDetailByMaterialId",
    method: "post",
    data,
  });
}
// 第三部分新增
export function wfSaiEducationAdd(data) {
  return request({
    url: "/mv/wfSaiEducation/add",
    method: "post",
    data,
  });
}
// 第三部分更新
export function wfSaiEducationUpdate(data) {
  return request({
    url: "/mv/wfSaiEducation/update",
    method: "post",
    data,
  });
}
// 第三部分详情
export function wfSaiEducationGetDetailByMaterialId(data) {
  return request({
    url: "/mv/wfSaiEducation/getDetailByMaterialId",
    method: "post",
    data,
  });
}
// 第四部分新增
export function wfSaiExperienceAdd(data) {
  return request({
    url: "/mv/wfSaiExperience/add",
    method: "post",
    data,
  });
}
// 第四部分更新
export function wfSaiExperienceUpdate(data) {
  return request({
    url: "/mv/wfSaiExperience/update",
    method: "post",
    data,
  });
}
// 第四部分详情
export function wfSaiExperienceGetDetailByMaterialId(data) {
  return request({
    url: "/mv/wfSaiExperience/getDetailByMaterialId",
    method: "post",
    data,
  });
}
// 第五部分新增
export function wfSaiFamilyAdd(data) {
  return request({
    url: "/mv/wfSaiFamily/add",
    method: "post",
    data,
  });
}
// 第五部分更新
export function wfSaiFamilyUpdate(data) {
  return request({
    url: "/mv/wfSaiFamily/update",
    method: "post",
    data,
  });
}
// 第五部分详情
export function wfSaiFamilyGetDetailByMaterialId(data) {
  return request({
    url: "/mv/wfSaiFamily/getDetailByMaterialId",
    method: "post",
    data,
  });
}
// 第六部分新增
export function wfSaiRecommenderAdd(data) {
  return request({
    url: "/mv/wfSaiRecommender/add",
    method: "post",
    data,
  });
}
// 第六部分更新
export function wfSaiRecommenderUpdate(data) {
  return request({
    url: "/mv/wfSaiRecommender/update",
    method: "post",
    data,
  });
}
// 第六部分详情
export function wfSaiRecommenderGetDetailByMaterialId(data) {
  return request({
    url: "/mv/wfSaiRecommender/getDetailByMaterialId",
    method: "post",
    data,
  });
}
// 第七部分新增
export function wfSaiEssaysAdd(data) {
  return request({
    url: "/mv/wfSaiEssays/add",
    method: "post",
    data,
  });
}
// 第七部分更新
export function wfSaiEssaysUpdate(data) {
  return request({
    url: "/mv/wfSaiEssays/update",
    method: "post",
    data,
  });
}
// 第七部分详情
export function wfSaiEssaysGetDetailByMaterialId(data) {
  return request({
    url: "/mv/wfSaiEssays/getDetailByMaterialId",
    method: "post",
    data,
  });
}
// 签字接口
export function studentSign(data) {
  return request({
    url: "/mv/workTask/studentSign",
    method: "post",
    data,
  });
}
// 拒绝签字
export function refuseSign(data) {
  return request({
    url: "/mv/workTask/refuseSign",
    method: "post",
    data,
  });
}
// 检查并更新文件
export function checkAndUpdateFiles(data) {
  return request({
    url: "/mv/workTask/checkAndUpdateFiles",
    method: "post",
    data,
  });
}
// 签字列表
export function getCmsSignList(data) {
  return request({
    url: "/mv/cmsSign/list",
    method: "post",
    data,
  });
}
// 签字新增
export function cmsSignAdd(data) {
  return request({
    url: "/mv/cmsSign/add",
    method: "post",
    data,
  });
}
// 签字删除
export function cmsSignDel(data) {
  return request({
    url: "/mv/cmsSign/delete",
    method: "post",
    data,
  });
}
export function workTaskList(data) {
  return request({
    url: "/mv/workTask/list",
    method: "post",
    data,
  });
}
export function getSignImageByName(data) {
  return request({
    url: "/mv/upload/getSignImageByName",
    method: "post",
    data,
  });
}
