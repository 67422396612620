<template>
  <div class="libraryList"  @contextmenu.prevent @copy.prevent  @cut.prevent  @paste.prevent style=" user-select: none;">
    <headerTitle title="首页" :titles="['院校库/项目库']" :showBtn="true"></headerTitle>
    <div class="flex-justify-center">
      <div>
        <div class="library-search">
          <div class="flex-justify-center tabBox">
            <p @click="changeTab(1)" class="toggle" :class="{ active: activeIndex == 1 }">
              <img :src="activeIndex == 1 ? require('@/assets/lefticon-active.png') : require('@/assets/lefticon.png')" alt="" />
              院校库
            </p>
            <p @click="changeTab(2)" class="toggle" :class="{ active: activeIndex == 2 }">
              <img :src="activeIndex == 2 ? require('@/assets/righticon-active.png') : require('@/assets/righticon.png')" alt="" />
              项目库
            </p>
          </div>
          <div>
            <span>搜索：</span>
            <el-input size="medium" placeholder="请输入学校关键字" v-model="name" class="input-with-button">
              <template slot="append">
                <el-button size="medium" class="el-button-blue" @click="onSearch">搜索</el-button>
              </template>
            </el-input>
          </div>
          <!-- <div class="tab-box" v-for="(item, index) in searchList" :key="index">
            <div class="flex-center" v-show="item.title">
              <span>{{ item.title }}：</span>
              <div class="flex-wrap tab-search" v-if="item.list.length!=0">
                <p class="tab-item" v-for="(items, i) in item.list" :key="i" :class="items == tabList[index] ? 'tab-active' : ''" @click="toSearch(items, index)">{{ items.name }}</p>
              </div>
            </div>
          </div> -->
          <div class="project">
            <p>院校排名榜：</p>
            <el-radio-group
              v-model="rankPlatform"
              @change="areasChange"
            >
              <el-radio-button
                v-for="(item, index) in ptlist"
                :key="index"
                :label="item.value"
                >{{ item.name }}</el-radio-button
              >
            </el-radio-group>
          </div>
          <div class="project">
            <p>地区：</p>
            <el-radio-group
              v-model="areaId"
              @change="areasChanges"
            >
              <el-radio-button
                v-for="(item, index) in ZXDQList"
                :key="index"
                :label="item.id"
                >{{ item.name }}</el-radio-button
              >
            </el-radio-group>
          </div>
          <!-- <div class="project">
            <p>排名：</p>
            <el-radio-group
              v-model="rank"
              @change="areasChange"
            >
              <el-radio-button
                v-for="(item, index) in ZXPMList"
                :key="index"
                :label="item.name"
                >{{ item.name }}</el-radio-button
              >
            </el-radio-group>
          </div> -->
          <div class="tab-box" v-show="activeIndex == 2">
            <!-- <div>查看更多学校排名：</div>
            <div class="flex-wrap school-search">
              <el-checkbox-group v-model="chooseList" size="small">
                <el-checkbox-button v-for="(item, index) in searchSchoolList" :key="index" :label="index" border>
                  {{ item.value }}
                </el-checkbox-button>
              </el-checkbox-group>
            </div>
            <div class="flex-justify-center">
              <p @click="onFold" class="fold finger">
                <span>收起</span>
                <img src="@/assets/college.png" alt="" />
              </p>
            </div> -->
            <div class="schoolbox">
            <!-- <p>查看更多具体学校：</p>
            <div v-if="isPanelVisible" class="panel">
              <el-checkbox-group v-model="checkSchool" @change="areasChange" size="medium">
                    <el-checkbox-button v-for="school in schoolList" :label="school.id" :key="school.id">{{school.name}}</el-checkbox-button>
                  </el-checkbox-group>
              </div>
            <el-button round @click="togglePanel"> {{ isPanelVisible ? '收起' : '展开' }} <img :class="{'activeimg':!isPanelVisible}" src="@/assets/icon_arrow.png" alt=""></el-button> -->
          </div>
          </div>
        </div>
        <div class="library-list" v-loading="loading">
          <div class="flex-wrap" v-show="activeIndex == 1">
            <div class="flex school-box finger" @click="toProfessionalList(item)" v-for="(item, index) in schoolList" :key="index">
              <img :src="item.logo" alt="" />
              <div class="flexBetween flexColumn">
                <p class="school-name"><span>{{ item.universityNameCn }}</span><span>{{ item.area }}</span></p>
                <p>{{item.universityNameEn}}</p>

                <div class="flexBetween">
                  <p >
                    <span>Program数量：</span>
                    <span>{{item.countProgram}}</span>
                  </p>
                  <p v-if="rankPlatform==0">
                    QS排名：
                    <span>{{ item.qsRank }}</span>
                  </p>
                  <p v-if="rankPlatform==1">
                    U.S.News排名：
                    <span>{{ item.usnewsRank }}</span>
                  </p>
                  <p v-if="rankPlatform==2">
                    THE排名：
                    <span>{{ item.theRank }}</span>
                  </p>
                  <p v-if="rankPlatform==3">
                    ARWU排名：
                    <span>{{ item.ruankeRank }}</span>
                  </p>

                </div>
                <div v-if="rankPlatform==8">
                  <div class="aligning">
                    <p style="line-height: 20px;">
                      <span>QS排名：</span>
                      <span>{{ item.qsRank }}</span>
                    </p>
                    <p style="line-height: 20px;" v-if="item.areaId==159">
                      <span>U.S.News排名：</span>
                      <span>{{ item.usnewsRank }}</span>
                    </p>
                      <p style="line-height: 20px;">
                        <span>THE排名：</span>
                        <span>{{ item.theRank }}</span>
                     </p>
                    <p style="line-height: 20px;">
                      <span>ARWU排名：</span>
                      <span>{{ item.ruankeRank }}</span>
                    </p>
                  </div>
                  </div>
              </div>
            </div>
            <el-empty v-if="schoolList.length==0"  :image="require('../../assets/noData.png')"></el-empty>
          </div>
          <div class="flex-wrap" v-show="activeIndex == 2" v-loading="loadings">
            <div class="flex major-box" v-for="(item, index) in majorList" :key="index" @click="toDetail(item)">
              <img :src="item.logo" alt="" />
              <div @click.stop="onCollect(item)" class="major-collect finger">
                收藏
                <i style="font-size: 17px; vertical-align: text-bottom; margin-left: 2px" :class="[item.collFalg==true?'el-icon-star-on':'el-icon-star-off']"></i>
              </div>
              <div class="flexColumn">
                <p class="major-name">{{ item.universityNameCn }}|{{ item.programNameCn }}</p>
                <div class="flexBetween">
                  <p style="margin:11px 0 8px">{{item.programNameEn}}</p>
                  <!-- <p>
                    QS排名：
                    <span class="qs-rank">{{ item.schoolQsRank }}名</span>
                  </p> -->
                </div>
                <!-- <p>{{ item.name }}</p> -->
                <div class="flex major-label" style="margin-bottom:8px">

                  <p v-for="(items,indexs) in item.majors" :key="indexs">{{ items.majorRankStr }}</p>
                </div>
                <p>{{ item.area }}</p>
              </div>
            </div>
            <el-empty v-if="majorList.length==0"  :image="require('../../assets/noData.png')"></el-empty>
          </div>
          <paging v-if="activeIndex==1" :pageall="pageall" :pagenum="pagenum" :pagesize="12" @pageclick="pageclick" style="margin-top: 20px"></paging>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import headerTitle from '@/components/title.vue';
import paging from '@/components/paging.vue';
import { getCollegeList, getMajorRankList, doCollection } from 'api/libraryList.js';
import { dicListByCode } from 'api/index.js';
import { mapActions } from 'vuex';

export default {
  name: 'LibraryList',
  components: { headerTitle, paging },
  data() {
    return {
      chooseList: [],
      pageall: 0,
      pagealls: 0,
      pagenum: 1,
      pagenums: 1,
      activeIndex: 1,
      searchSchoolList: [],
      searchList: [
        {
          title: '地区',
          list: []
        },
        {
          title: '排名',
          list: []
        }
      ],
      tabList: ['', ''],
      schoolList: [],
      majorList: [],
      inputValue: '',
      dicCodelist: ['ZXDQ', 'ZXPM'], // ZXZL 主类  ZXDQ地区  ZXSL子类
      ZXDQList: [],
      ZXPMList: [],
      rank: '不限',
      areaId: 6,
      name: "",
      isPanelVisible: false,
      checkSchool: [],
      rankPlatform: 8,
      ptlist: [
          {
              value: 8,
              name: '全部'
          },
            {
              value: 0,
              name: 'QS'
            },
            {
              value: 1,
              name: 'U.S.News'
            },
            {
              value: 2,
              name: 'THE'
            },
            {
              value: 3,
              name: 'ARWU'
            }
          ],
       Width: 102,
      loading: true,
      loadings: true
    };
  },
  created() {
    this.activeIndex = this.$route.query.source;
    this.areaId = Number(this.$route.query.areaId) || 0;
    this.getCodeList();
    this.getMajorList();
  },
  mounted() {

  },
  methods: {
    ...mapActions(['trackEvent']),
    areasChange() {
      this.pagenum = 1;
      if (this.activeIndex == 1) {
        // 地区只保留美国
        if (this.rankPlatform == 1) {
          this.areaId = 159;
          this.ZXDQList = this.ZXDQList.filter((item) => item.id == 159);
        } else {
          this.areaId = 0;
          this.ZXDQList = [];
          this.getCodeList();
        }
        this.getList();
      } else {
        this.getMajorList();
        this.getSchool();
      }
    },
    areasChanges() {
      this.pagenum = 1;
      if (this.activeIndex == 1) {
        this.getList();
      } else {
        this.getMajorList();
        this.getSchool();
      }
    },
    onSearch() {
      this.pagenums = 1;
      if (this.activeIndex == 1) {
        this.loading = true;
        this.getList();
      } else {
        this.loadings = true;
        this.getMajorList();
      }
    },
    getCodeList() {
        // this.ZXDQList.push({ name: '不限', id: 0 });
        this.ZXPMList.push({ name: '不限', id: 0 });
        this.dicCodelist.forEach((item) => {
            dicListByCode({ code: item }).then(res => {
                if (res.code == '2000') {
                    // 添加全部选项到列表中，并放到最前面
                    if (item == 'ZXDQ') {
                      this[item + 'List'].unshift({ name: '全部', id: 0 });
                        this[item + 'List'].push(...res.data);
                        this.getList();
                        // this.changeTab(this.activeIndex);
                    } else {
                      this[item + 'List'].push(...res.data);
                    }
                } else {
                    this.$message({
                        type: 'error',
                        message: res.msg
                    });
                }
            }).catch(error => {
                console.error('An error occurred:', error);
                this.$message({
                    type: 'error',
                    message: '请求过程中发生错误，请稍后再试。'
                });
            });
        });
    },
    getList() {
      this.loading = true;
      const data = {
        ifPage: true,
        pageSize: 12,
        pageNum: this.pagenum,
        areaId: this.areaId,
        universityName: this.name,
        rankPlatform: this.rankPlatform
      };
      if (this.rank == '不限') {
        data.qsRankStart = '';
        data.qsRankEnd = '';
        data.rank = '';
      } else {
        const parts = this.rank.split('-');
        data.qsRankStart = parts[0];
        data.qsRankEnd = parts[1];
      }
      getCollegeList(data).then(res => {
        if (res.code == '2000') {
          this.pageall = res.data.total || 0;
          this.schoolList = res.data.list || [];
          this.loading = false;
        } else {
          this.$message({
            type: 'error',
            message: res.msg
          });
          this.loading = false;
        }
      });
    },
    getMajorList() {
      this.loadings = true;
      const data = {
        ifPage: true,
        pageSize: 99999,
        pageNum: this.pagenums,
        areaId: this.areaId,
        name: this.name,
        checkSchool: this.checkSchool || '',
        rankPlatform: this.rankPlatform
      };
      if (this.rank == '不限') {
        data.qsRankStart = '';
        data.qsRankEnd = '';
        data.rank = '';
      } else {
        const parts = this.rank.split('-');
        data.qsRankStart = parts[0];
        data.qsRankEnd = parts[1];
      }
      getMajorRankList(data).then(res => {
        if (res.code == '2000') {
          this.pagealls = res.data.total || 0;
          this.majorList = res.data.list || [];
          this.loadings = false;
          console.log(this.majorList);
        } else {
          this.$message({
            type: 'error',
            message: res.msg
          });
          this.loadings = false;
        }
      });
    },
    getSchool() {
      const data = {
        ifPage: true,
        pageSize: 10,
        pageNum: this.pagenum,
        areaId: this.areaId,
        name: this.name
      };
      if (this.rank == '不限') {
        data.qsRankStart = '';
        data.qsRankEnd = '';
        data.rank = '';
      } else {
        const parts = this.rank.split('-');
        data.qsRankStart = parts[0];
        data.qsRankEnd = parts[1];
      }
      getCollegeList(data).then(res => {
        if (res.code == '2000') {
          this.schoolList = res.data.list || [];
        } else {
          this.$message({
            type: 'error',
            message: res.msg
          });
        }
      });
    },
    onFold() {},
    onCollect(val) {
      const params = {
        collectionId: val.id,
        collectionType: 1,
      };
      if (val.collFalg == false ) {
        params.isDelete = 0;
      } else {
        params.isDelete = 1;
      }

      doCollection(params).then(res => {
        if (res.code == '2000') {
          this.$message({
            type: 'success',
            message: val.collFalg == true ? '取消收藏成功' : '收藏成功'
          });
          this.getMajorList();
        } else {
          this.$message({
            type: 'error',
            message: res.msg
          });
        }
      });
    },
    changeTab(val) {
      this.activeIndex = val;
      this.areaId = 0;
      this.name = '';
      this.rank = '不限';
      this.checkSchool = [];
      this.isPanelVisible = false;
      if (this.activeIndex == 1) {
        this.getList();
      } else {
        this.getMajorList();
        this.getSchool();
      }
    },
    toProfessionalList(val) {
      this.$router.push({
        path: '/professionalList',
        query: {
          schoolId: val.id
        }
      });
    },
    toSearch(res, index) {
      this.$set(this.tabList, index, res);
      // if (index == 0) {
      //   // 分类
      //   if (res.value == '学校') {
      //     this.searchList[3] = this.cityList;
      //   } else {
      //     this.searchList[3] = '';
      //   }
      // } else if (index == 1) {
      //   // 平台
      //   if (res.value == 'QS') {
      //   } else {
      //   }
      // } else if (index == 2) {
      //   // 年份
      // }
    },
    pageclick(val) {
      this.pagenum = val;
      this.getList();
    },
    convertedDate(datas) {
      const date = new Date(datas);
      const year = date.getFullYear();
      const month = (1 + date.getMonth()).toString().padStart(2, '0'); // 月份从0开始，所以需要加1，并且补零
      const day = date.getDate().toString().padStart(2, '0'); // 日期补零
      return `${year}-${month}-${day}`;
    },
    getMonthFromTimeStamp(ts) {
      const date = new Date(ts);
      const month = 1 + date.getMonth(); // 月份从0开始，加1得到实际月份
      return month;
    },
    padZero(number) {
      return number.toString().padStart(2, '0'); // 补零操作
    },
    togglePanel() {
      this.isPanelVisible = !this.isPanelVisible;
    },
    toDetail(val) {
      this.$router.push({
        path: `/professionalDetails?id=${val.id}&schoolId=${val.universityId}`
      });
      this.trackEvent({ action: '项目详情页' });
    },
    getWidth() {
      // 获取id为program的元素宽度
      if (document.getElementById('program')) {
        this.Width = document.getElementById('program').offsetWidth;
      }
    }
  }
};
</script>
<style  lang='stylus' scoped>
@import '~css/stylus.styl';

.libraryList {
  width: 100%;
  background: #F5F7FA url('../../assets/library.png') no-repeat;
  background-size: contain;
  padding-bottom: $padding-bottom;

  .library-search, .library-list {
    box-sizing: border-box;
    width: $width;
    background: #fff;
    border-radius: 12px 12px 12px 12px;
    margin-bottom: 20px;
    padding: 0 20px;
  }

  .library-search {
    padding: 21px 20px;
    margin-top: 79px;

    .tab-box {
      margin-top: 20px;

      .fold {
        width: 140px;
        line-height: 30px;
        background: #FFFFFF;
        border-radius: 20px 20px 20px 20px;
        border: 1px solid $themeColor;
        text-align: center;
        color: $themeColor;

        img {
          width: 11px;
          height: 11px;
          margin-left: 7px;
        }
      }

      .tab-item {
        padding: 0 10px;
        line-height: 26px;
        background: #EEEEEE;
        border-radius: 4px 4px 4px 4px;
        margin-right: 10px;
        cursor: pointer;
        color: #999999;
      }

      .tab-active {
        background: #FFEFE6;
        color: $themeColor;
      }
    }

    .input-with-button {
      :deep .el-input__inner {
        border-right: 0;
      }

      width: 370px;
    }

    .el-button-blue {
      background: $themeColor;
      color: #fff;
    }

    .tabBox {
      margin-bottom: 19px;

      img {
        width: 20px;
        height: 20px;
        vertical-align: sub;
      }

      .toggle {
        cursor: pointer;
        padding: 10px 62px;
        border: 1px solid #EEEEEE;
        font-size: 16px;
        position: relative;
        overflow: hidden;
        margin-left: -7px;
      }

      .toggle:nth-of-type(1) {
        border-radius: 20px 0px 0px 20px;
        border-right: none;
        background: linear-gradient(-67deg, transparent 13px, #fff 0) top right;
      }

      .toggle:nth-of-type(2) {
        border-radius: 0px 20px 20px 0px;
        background: linear-gradient(110deg, transparent 13px, #fff 0) top right;
        border-left: none;
        margin-left: -11px;
      }

      .toggle:nth-of-type(1).active {
        background: linear-gradient(-71deg, transparent 13px, #FFEFE6 0) top right;
        color: $themeColor;
        border-color: #FFEFE6;
      }

      .toggle:nth-of-type(2).active {
        background: linear-gradient(-70deg, #FFEFE6 185px, transparent 0) left top;
        color: $themeColor;
        border-color: #FFEFE6;
      }
    }
  }

  .library-list {
    padding: 20px;
    align-items: flex-start;

    img {
      width: 50px;
      height: 50px;
      margin-right: 15px;
      margin-top: 15px;
      object-fit: contain;
    }

    .school-box {
      width: 568px;
      height: 176px;
      background: linear-gradient( 180deg, #FDFAF6 0%, #FFFFFF 100%);
      border-radius: 14px 14px 14px 14px;
      padding: 15px;
      box-sizing: border-box;
      margin: 0 23px 23px 0;
      transition: all 0.3s;

      &>div {
        flex: 1;
      }

      &:nth-child(2n) {
        margin-right: 0;
      }

      .school-name {
        line-height: 32px;
        font-weight: bold;
        font-size: 18px;

        span:nth-of-type(1) {
          color: #666666;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1; /* 限制显示的行数为3行 */
          overflow: hidden;
          max-width: 410px; /* 设置最大宽度，超出部分隐藏 */
          float: left;
          font-weight: bold;
        }
        span:nth-of-type(2){
          float: right;
          font-size: 14px;
          font-weight: 400;
          color: #333333;
          line-height: 32px;
        }
      }
      .school-name:after {
        content: " ";
        clear: both;
        display: block;
      }
      span:nth-of-type(2) {
        font-weight: bold;
        color: #FF772E;
      }
    }
    .school-box:hover{
      box-shadow: 0px 5px 30px 1px rgba(0,0,0,0.1);
      .school-name {
        color: #FF772E
       }
    }
    div.major-box:nth-child(2n){
      margin-right: 0
    }
    .major-box {
      width: 570px;
      height: 176px;
      background: linear-gradient( 180deg, #FDFAF6 0%, #FFFFFF 100%);
      border-radius: 14px 14px 14px 14px;
      padding: 15px 15px 20px;
      box-sizing: border-box;
      margin: 0 20px 20px 0;
      position: relative;
      transition: all 0.3s;
      cursor:pointer

      .major-collect {
        position: absolute;
        right: 0;
        top: 0;
        color: #FF8240;
        background: #F9EEE5;
        border-radius: 0 14px 0 14px;
        line-height: 28px;
        width: 79px;
        text-align: center;
      }

      &>div {
        flex: 1;
      }

      .major-name {
        font-weight: bold;
        font-size: 18px;
        line-height: 32px;
      }

      .major-label {
        p {
          padding: 0 9px;
          line-height: 24px;
          border-radius: 4px 4px 4px 4px;
          border: 1px solid #CCCCCC;
          color: #666666;
          margin-left: 10px;
          font-size: 12px;

          &:first-child {

            margin-left: 0;
          }
        }
      }
    }
    .major-box:hover {
      box-shadow: 0px 5px 30px 1px rgba(0, 0, 0, 0.1);
      background: linear-gradient( 180deg, #FDFAF6 0%, #FFFFFF 100%);
      .major-name {
        color: #FF772E
       }
     }

  }
}
.qs-rank{
  color: #FF8240;
}
/deep/ .is-checked .el-checkbox-button__inner, /deep/ .is-focus .el-checkbox-button__inner, /deep/ .el-checkbox-button__inner, /deep/ .el-checkbox-button:first-child .el-checkbox-button__inner {
  border: none;
  border-radius: 6px !important;
  margin-right: 10px;
  margin-bottom: 20x;
  color: #999999;
  background: #EEEEEE;
  line-height: 26px;
  height: 26px;
  padding: 0 7px;
}

/deep/ .el-checkbox-button.is-checked .el-checkbox-button__inner {
  background: #FFEFE6;
  border: none;
  color: $themeColor;
  box-shadow: 0 0 0 0;
  line-height: 26px;
  height: 26px;
  padding: 0 7px;
}

.school-search {
  margin-top: 15px;
}
.project {
  display: flex;
  align-items: center;
  margin-top: 23px;
  p{
    font-size: 14px;
    color: #333333;
  }
  .el-radio-button{
    margin-right: 10px;
    border: none;
  }
  >>>.el-radio-button__inner{
    background: #EEEEEE;
    height: 26px;
    border-radius: 6px 6px 6px 6px;
    color: #999999;
    background-color: #eee;
    font-size: 14px;
    padding: 4px 8px;
    line-height: 17px;
    border: none;
  }
  >>>.el-radio-button__orig-radio:checked+.el-radio-button__inner{
    color: $themeColor;
    box-shadow: none;
    background: #FFEFE6;
  }
}
.schoolbox{
  .el-button{
    width: 140px;
    height: 30px;
    background: #FFFFFF;
    border-radius: 20px 20px 20px 20px;
    border: 1px solid $themeColor;
    padding: 0;
    margin: 0 auto;
    font-size: 14px;
    color: $themeColor;
    display: block;
    transition: all 0.5s;
  }
  span{
    img{
      transition: all 0.3s;
      width : 9px;
      display: inline-block;
      margin-left:7px
    }
  }
  .activeimg{
    transform: rotate(180deg);
  }
}
.panel {
  padding: 20px 0;
  .el-checkbox-button{
    margin: 0 10px 20px 0
  }
  >>>.el-checkbox-button--medium .el-checkbox-button__inner{
    height: 26px;
    background: #EEEEEE;
    border-radius: 6px 6px 6px 6px;
    font-size: 14px;
    color: #999999;
    padding: 4px 8px;
    line-height: inherit;
    border: none;
    box-shadow: none;
  }
  >>>.el-checkbox-button.is-checked .el-checkbox-button__inner{
    background: #FFEFE6;
    color: $themeColor;
  }
}
.el-empty{
  padding:80px 0;
  margin:auto;
}
>>>.el-radio-button:first-child .el-radio-button__inner{
  border-left: none !important;

}
.aligning{
  &>p{
    width: 50%;
    float: left;
  }
  &>p:nth-child(1),&>p:nth-child(2){
    margin-bottom: 9px;
  }
}
.aligning:after{
  content:'';
  display:block;
  clear:both;
}
</style>