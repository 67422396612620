<template>
  <div class="infobigBox">
    <leftNav></leftNav>
    <div class="infoBox">
      <div class="title">
        选校择校详情
        <div class="btnBox">
          <el-button round plain type="primary" size="small" @click="returnTo">
            返回上一页
          </el-button>
          <el-button
            round
            v-if="pageType != 'add' && historyList.length"
            plain
            type="primary"
            size="small"
            @click="showDialog"
            :loading="addLoading"
            >历史记录</el-button
          >
          <el-button
            v-if="form.isSignatory != 1 && pageType == 'details'"
            round
            type="primary"
            size="small"
            @click="regInfo(true)"
            >{{ addLoading ? '请稍后...' : '发送给客户签字' }}</el-button
          >
          <el-button
            v-if="pageType != 'details'"
            :loading="addLoading"
            round
            type="primary"
            @click="regInfo(false)"
            size="small"
            >{{ addLoading ? '请稍后...' : '保存' }}</el-button
          >
        </div>
      </div>
      <div class="infopng">
        <p class="smallTitle">材料基本信息</p>
        <div class="searchBox">
          <el-form
            :inline="true"
            :model="form"
            :disabled="pageType == 'details'"
            ref="form"
            label-width="120px"
          >
            <!-- <el-form-item label="客户名称:" label-width="72px">
              <p style="width: 100px" v-if="pageType != 'add'">
                {{ form.customerName }}
              </p>
              <el-select
                v-else
                style="width: 150px"
                :multiple="false"
                filterable
                remote
                v-model="form.userId"
                placeholder="请输入搜索客户名称"
                :remote-method="customMethod"
                :loading="customLoading"
                @change="(val) => customChange(val)"
              >
                <el-option
                  v-for="item in customList"
                  :key="item.userId"
                  :label="item.customerName"
                  :value="item.userId"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="客户手机号:" label-width="100px">
              <el-input
                :disabled="pageType != 'add'"
                v-model="form.customerPhone"
                style="width: 130px"
                size="medium"
                placeholder="请输入客户手机号"
              ></el-input>
            </el-form-item> -->
            <el-form-item label="合同名称:" label-width="90px">
              <el-select
                value-key="id"
                :disabled="pageType != 'add'"
                v-model="form.contractName"
                style="width: 160px"
                size="medium"
                @change="
                  (val) => {
                    contractChange(val);
                  }
                "
              >
                <el-option
                  v-for="item in contractList"
                  :key="item.id"
                  :label="item.contractName"
                  :value="item"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="是否定稿:" label-width="90px">
              <p
                style="width: 100px"
                v-if="form.isFinalized && pageType == 'details'"
                :class="getStatusClass(form.isFinalized)"
              >
                {{ form.isFinalized == 1 ? '已定稿' : '未定稿' }}
              </p>
              <el-select
                v-else
                v-model="form.isFinalized"
                style="width: 120px"
                size="medium"
              >
                <el-option
                  v-for="item in statusList"
                  :key="item.code"
                  :label="item.name"
                  :value="item.code"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="客户是否已签字:" label-width="115px">
              <span :class="getStatusClass(form.isSignatory)">{{
                form.isSignatory == 1 ? '已签字' : '未签字'
              }}</span>
            </el-form-item>
          </el-form>
          <!-- <span style="margin-left: 48px"> </span> -->
        </div>
        <p class="line"></p>
        <p class="smallTitle small">选校择校表</p>
        <el-table :data="form.selectionDtos" border>
          <el-table-column label="学校名称（英文）" align="center">
            <template slot-scope="scope">
              <el-select
                :disabled="pageType == 'details'"
                v-model="scope.row.schoolId"
                filterable
                remote
                placeholder="请输入学校名称"
                :remote-method="
                  (name) => {
                    schoolRemoteMethod(name);
                  }
                "
                :loading="schoolLoading"
                @change="
                  (id) => {
                    schoolChange(id, scope.$index);
                  }
                "
              >
                <el-option
                  v-for="item in schoolList"
                  :key="item.id"
                  :label="item.universityNameEn"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </template>
          </el-table-column>
          <el-table-column
            prop="address"
            label="项目名称（英文）"
            align="center"
          >
            <template slot-scope="scope">
              <el-select
                :disabled="pageType == 'details'"
                v-model.trim="scope.row.projectId"
                filterable
                remote
                placeholder="请输入项目名称"
                :remote-method="
                  (name) => {
                    projectRemoteMethod(name, scope.row.schoolId);
                  }
                "
                v-el-select-loadmore="loadmore"
                :loading="projectLoading"
                v-if="scope.row.schoolId"
                @change="
                  (id) => {
                    projectChange(id, scope.$index);
                  }
                "
              >
                <el-option
                  v-for="item in projectList"
                  :key="item.id"
                  :label="item.programNameEn"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </template>
          </el-table-column>
          <el-table-column
            v-if="pageType != 'details'"
            prop="operation"
            label="操作"
            align="center"
            width="80"
          >
            <template slot-scope="scope">
              <el-button
                type="text"
                @click="handleDelete(0, '确认删除该条数据吗？', scope.$index)"
                >移除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <p v-if="pageType != 'details'" class="addBtn" @click="addTable">+</p>
      </div>
    </div>
    <el-dialog
      :title="titile"
      :visible.sync="dialogVisible"
      width="417px"
      center
      :close-on-click-modal="false"
      :show-close="false"
    >
      <span class="tips">{{ tipTxt }}</span>
      <div slot="footer" class="dialog-footer">
        <el-button
          style="width: 38%; color: #ff8240; border: 1px solid #ff8240"
          plain
          round
          @click="cancel"
          >取消</el-button
        >
        <el-button
          :loading="addLoading"
          style="width: 38%; color: #fff; background: #ff8240"
          round
          @click="sure"
          >{{ addLoading ? '请稍候...' : '确定' }}</el-button
        >
      </div>
    </el-dialog>
    <historyDialog
      :historyList="historyList"
      :pagenum="pagenum"
      :pageall="pageall"
      :dialogRecordVisible="dialogRecordVisible"
      @pageclick="pageDialogClick"
      @handleClose="showDialog"
      @toDetail="toDetail"
    ></historyDialog>
    <!--  -->
    <historyDialogDetail
      :dialogDetailVisible="dialogDetailVisible"
      :historyInfo="historyInfo"
      @handleClose="closeDialog"
    ></historyDialogDetail>
  </div>
</template>
<script>
import leftNav from './leftNav';
import { getCollegeList } from 'api/libraryList.js';
import {
  getCustomInfoList,
  getHistorical,
  sendCustomSign,
  addForm,
  getSchoolDetail,
  programList
} from 'api/chooseSchool.js';
import historyDialog from './dialogComponents/historyDialog.vue';
import historyDialogDetail from './dialogComponents/historyDialogDetail.vue';
import { getSchoolCustomContractsList } from '@/assets/api/application.js';

export default {
  components: {
    leftNav,
    historyDialog,
    historyDialogDetail
  },
  directives: {
    'el-select-loadmore': {
      bind(el, binding) {
        // 获取element-ui定义好的scroll盒子
        const SELECTWRAP_DOM = el.querySelector(
          '.el-select-dropdown .el-select-dropdown__wrap'
        );
        SELECTWRAP_DOM.addEventListener('scroll', function () {
          /**
           * scrollHeight 获取元素内容高度(只读)
           * scrollTop 获取或者设置元素的偏移值,常用于, 计算滚动条的位置, 当一个元素的容器没有产生垂直方向的滚动条, 那它的scrollTop的值默认为0.
           * clientHeight 读取元素的可见高度(只读)
           * 如果元素滚动到底, 下面等式返回true, 没有则返回false:
           * ele.scrollHeight - ele.scrollTop === ele.clientHeight;
           */
          const condition =
            this.scrollHeight - this.scrollTop <= this.clientHeight + 10;
          if (condition) {
            binding.value();
          }
        });
      }
    }
  },
  data() {
    return {
      customLoading: false,
      pageType: '',
      dialogVisible: false,
      tipTxt: '',
      titile: '',
      form: {
        selectionDtos: []
      },
      statusList: [
        {
          code: 1,
          name: '已定稿'
        },
        {
          code: 0,
          name: '未定稿'
        }
      ],
      historydialogVisible: false,
      customList: [],
      userInfo: '',
      schoolList: [],
      schoolLoading: false,
      projectLoading: false,
      projectList: [],
      checkDelete: '',
      historyList: [],
      historyInfo: {},
      pageall: 0,
      pagenum: 1,
      dialogRecordVisible: false,
      dialogDetailVisible: false,
      num: '',
      detailsId: '',
      addLoading: false,
      pageData: {
        pageNum: 1,
        pageSize: 999
      },
      contractList: ''
    };
  },
  methods: {
    contractChange(val) {
      const data = this.contractList.find(
        (item) => item.contractNumber == val.contractNumber
      );
      this.form.userId = data.customerId;
      this.form.customerPhone = data.customerPhone;
      this.form.customerName = data.customerName;
      this.form.contractName = data.contractName;
      this.form.contractNumber = data.contractNumber;
    },
    getContractsList() {
      console.log(this.userInfo.teacherId);
      if (this.userInfo.teacherId != null) {
        getSchoolCustomContractsList({
        teacherId: this.userInfo.teacherId
      }).then((res) => {
        if (res.code == '2000') {
          this.contractList = res.data.list;
        } else {
          this.$message({
            type: 'error',
            message: res.msg
          });
        }
      });
      }
    },

    // 自定义指令调用
    loadmore() {
      if (
        this.pageData.pageNum >=
        Math.ceil(this.projectList.length / this.pageData.pageSize)
      ) {
        return;
      }
      this.pageData.pageNum++;
    },
    customChange(id) {
      const data = this.customList.find((item) => item.userId === id);
      this.$set(this.form, 'customerName', data.customerName);
      this.$set(this.form, 'customerPhone', data.customerPhone);
      this.$set(this.form, 'taskId', data.taskId);
    },
    closeDialog() {
      this.dialogDetailVisible = false;
    },
    toDetail(item) {
      this.historyInfo = item;
      this.dialogDetailVisible = true;
    },
    showDialog() {
      this.dialogRecordVisible = !this.dialogRecordVisible;
    },
    pageDialogClick(val) {
      this.pagenum = val;
      this.getHistoryListMethod();
    },
    getHistoryListMethod() {
      if (!this.detailsId) return;
      getHistorical({
        id: this.detailsId
      }).then((res) => {
        if (res.code == '2000') {
          this.historyList = res.data || [];
          this.pageall = res.data.total || 0;
        }
      });
    },
    regInfo(flag) {
      console.log(this.form.selectionDtos);
      if (this.pageType != 'details') {
        if (!this.form.customerName) {
          this.$message({
            type: 'error',
            message: '请选择搜索合同名称！'
          });
          return false;
        }
        if (!this.form.isFinalized && this.form.isFinalized != 0) {
          this.$message({
            type: 'error',
            message: '请选择是否定稿！'
          });
          return false;
        }
        if (this.form.selectionDtos && this.form.selectionDtos.length == 0) {
          this.$message({
            type: 'error',
            message: '请填写选校择校详情！'
          });
          return false;
        } else {
          const errors = [];
          for (let i = 0; i < this.form.selectionDtos.length; i++) {
            for (const key in this.form.selectionDtos[i]) {
              if (key != 'schoolSelectionVos') {
                if (
                  typeof this.form.selectionDtos[i][key] === 'undefined' ||
                  this.form.selectionDtos[i][key] === null ||
                  this.form.selectionDtos[i][key] === ''
                ) {
                  errors.push(`请完善第${i + 1}行内容`);
                }
              }
            }
          }

          if (errors.length > 0) {
            this.$message({
              type: 'error',
              message: errors.join('\n')
            });
            return false;
          }
        }
        this.handleDelete(1, '确认保存选校择校详情吗？');
      }
      if (flag) {
        // 发送签名
        if (this.form.isFinalized == 0) {
          this.$message({
            type: 'error',
            message: '请先定稿后发送给客户签字！'
          });
          return false;
        }
        this.handleDelete(2, '确认将文档发送给客户签字吗？');
      }
    },
    addData() {
      this.addLoading = true;
      this.$delete(this.form, 'createTime');
      this.$delete(this.form, 'updateTime');
      addForm({
        id: this.detailsId,
        teacherId: this.userInfo.teacherId,
        createId: this.userInfo.id,
        createName: this.userInfo.name,
        ...this.form
      })
        .then((res) => {
          this.dialogVisible = false;
          this.addLoading = false;
          if (res.code == '2000') {
            this.$message({
              type: 'success',
              message: res.msg || '操作成功'
            });
            this.returnTo();
          } else {
            this.$message({
              type: 'error',
              message: res.msg
            });
          }
        })
        .catch((res) => {
          this.addLoading = false;
        });
    },
    customMethod(query) {
      if (query !== '') {
        this.customLoading = true;
        setTimeout(() => {
          getCustomInfoList({
            createId: this.userInfo.teacherId,
            customerName: query
          }).then((res) => {
            this.customLoading = false;
            if (res.code == '2000') {
              this.customList = res.data || [];
            } else {
              this.$message({
                type: 'error',
                message: res.msg
              });
            }
          });
        }, 200);
      } else {
        this.customList = [];
      }
    },
    getStatusClass(score) {
      return score === 1 ? 'statusA ' : 'statusB';
    },
    // 删除按钮事件
    handleDelete(num, message, index) {
      this.checkDelete = index || '';
      this.dialogVisible = true;
      this.num = num;
      this.tipTxt = message;
      this.titile = '提示';
    },
    cancel() {
      this.dialogVisible = false;
    },
    deleteItem() {
      this.form.selectionDtos.splice(this.checkDelete, 1);
      this.dialogVisible = false;
    },
    sendCustom() {
      this.addLoading = true;
      sendCustomSign({
        id: this.detailsId
      })
        .then((res) => {
          this.dialogVisible = false;
          this.addLoading = false;
          if (res.code == '2000') {
            this.$message({
              type: 'success',
              message: res.msg || '操作成功'
            });
          } else {
            this.$message({
              type: 'error',
              message: res.msg
            });
          }
        })
        .catch((res) => {
          this.addLoading = false;
        });
    },
    sure() {
      switch (this.num) {
        case 0:
          this.deleteItem();
          break;
        case 1:
          this.addData();
          break;
        case 2:
          this.sendCustom();
          break;
      }
    },
    returnTo() {
      this.$router.go(-1);
    },
    addTable() {
      this.form.selectionDtos.push({
        schoolId: '',
        projectName: ''
      });
    },
    // 获取学校
    schoolRemoteMethod(schoolName) {
      this.schoolLoading = true;
      getCollegeList({
        universityName: schoolName || ''
      })
        .then((res) => {
          this.schoolLoading = false;
          if (res.code == 2000) {
            this.schoolList = res.data.list || [];
          } else {
            this.$message({
              type: 'error',
              message: res.msg
            });
          }
        })
        .catch(() => {
          this.schoolLoading = false;
        });
    },
    // 选中学校并获取学校下学院列表
    schoolChange(schoolId, index) {
      this.form.selectionDtos[index].projectName = '';
      this.projectList = [];
      const data = this.schoolList.find((item) => item.id === schoolId);
      this.$set(
        this.form.selectionDtos[index],
        'schoolName',
        data.universityNameEn
      );
    },
    projectChange(id, index) {
      const data = this.projectList.find((item) => item.id === id);
      console.log(this.projectList);
      this.$set(
        this.form.selectionDtos[index],
        'projectName',
        data.programNameEn
      );
    },
    // 搜索项目
    projectRemoteMethod(projectName, collegeId) {
      if (projectName !== '') {
        this.projectLoading = true;
        programList({
          ifPage: true,
          universityId: collegeId,
          programName: projectName,
          ...this.pageData
        })
          .then((res) => {
            this.projectLoading = false;
            if (res.code == 2000 && res.data.list) {
              this.projectList = res.data.list;
            } else {
              this.$message({
                type: 'error',
                message: res.msg
              });
            }
          })
          .catch(() => {
            this.projectLoading = false;
          });
      }
    },
    getDetail() {
      if (!this.detailsId) return;
      getSchoolDetail({
        id: this.detailsId
      }).then((res) => {
        this.projectLoading = false;
        if (res.code == 2000) {
          this.form = res.data;
          if (this.form.selectionDtos && this.form.selectionDtos.length > 0) {
            this.form.selectionDtos.forEach((item, index) => {
              item.projectId = Number(item.projectId);
              setTimeout(() => {
                this.projectRemoteMethod(item.projectName, item.schoolId);
              }, 2000);
            });
          }
        } else {
          this.$message({
            type: 'error',
            message: res.msg
          });
        }
      });
    }
  },
  mounted() {
    this.userInfo = localStorage.getItem('userInfo');
    this.pageType = this.$route.query.pageType;
    this.detailsId = this.$route.query.id || '';
    this.schoolRemoteMethod();
    this.getHistoryListMethod();
    this.getDetail();
    this.getContractsList();
  }
};
</script>

    <style  lang='stylus' scoped>
    @import '~css/stylus.styl';

    .infobigBox {
      width: 1200px;
      height: 100%;
      display: flex;
      justify-content: space-between;
      background-color: #F5F7FA;
      padding-bottom: 60px;
      margin: 0 auto;
    }

    .infoBox {
      width: 940px;
      background-color: #fff;
      margin: 27px 2% 27px;
      box-sizing: border-box;
      border-radius: 12px 12px 12px 12px;
      padding: 23px 0;

      .title {
        font-size: 20px;
        color: #333333;
        height: 23px;
        position: relative;
        padding-left: 14px;
        font-weight: bold;
        padding: 0 20px 21px 34px;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      .title::after {
        content: '';
        position: absolute;
        left: 20px;
        top: 2px;
        width: 4px;
        height: 20px;
        display: block;
        background-color: $themeColor;
        border-radius: 2px;
      }
    }

    .infopng {
      border-top: 1px solid #EEEEEE;
      padding: 19px 20px 0;
      margin: 0 auto 19px;
      box-sizing: border-box;
    }

    .statusA {
      color: #1db828;
    }

    .statusB {
      color: #ff8240;
    }

    .tips {
      font-size: 16px;
      color: #333333;
      line-height: 20px;
      display: block;
      margin: 0px auto 10px;
      text-align: center;
    }

    .el-button--small.is-round {
      padding: 6px 27px;
      font-size: 14px;
    }

    .searchBox {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
    }

    .el-form-item {
      margin-bottom: 0px;
    }

    .smallTitle {
      font-size: 16px;
      color: #333333;
      line-height: 32px;
      margin: 0 0 20px;
      font-weight: bold;
    }

    .small {
      margin: 15px 0;
    }

    .line {
      width: 100%;
      height: 1px;
      background-color: #EEE;
    }

    .addBtn {
      height: 50px;
      border: 1px solid #EEEEEE;
      border-top: none;
      line-height: 50px;
      text-align: center;
      font-weight: 400;
      font-size: 34px;
      color: rgba(255, 130, 64, 1);
      cursor: pointer;
    }

    >>> .is-disabled .el-input__prefix {
      display: none;
    }
</style>
<style>
#app {
  background-color: #f5f7fa;
}
</style>