<template>
  <div class="gift">
    <leftNav></leftNav>
    <div class="giftcontent">
      <div class="gift_top">
        <p class="title">
          材料管理<i @click="returnTo" class="el-icon-arrow-left"></i>
        </p>
        <div class="btnBox">
          <!-- <el-button round plain type="primary" size="small" @click="returnTo">返回上一页</el-button> -->
          <el-button
            round
            plain
            type="primary"
            size="small"
            @click="tohistory"
            >历史记录</el-button
          >
          <el-button
            round
            plain
            type="primary"
            size="small"
            @click="toDownloadFile"
            >下载文件</el-button
          >
          <!-- <el-button round type="primary" size="small" >发送给客户签字</el-button > -->
            <!-- v-if="dataInfo.customerSigned == 0 &&dataInfo.signNotice == 1 && dataInfo.finalDraft==1 && type=='edit'" -->
          <!-- <el-button
            round
            type="primary"
            size="small"
            @click="studentSign"
            v-if="dataInfo.materialType=='XXZXB' && dataInfo.customerSigned == 0 &&dataInfo.signNotice == 1 && dataInfo.finalDraft==1 && type=='edit' && dataInfo.taskStatus!=3"
            >签字</el-button
          >
          <el-button
            round
            type="danger"
            size="small"
            v-if="dataInfo.materialType=='XXZXB' && dataInfo.customerSigned == 0 &&dataInfo.signNotice==1 && dataInfo.finalDraft==1 && type=='edit' && dataInfo.taskStatus!=3"

            @click="refuseSign"
            >拒绝签字</el-button
          > -->
          <el-button
            round
            type="primary"
            size="small"
            @click="studentSign"
            v-if="dataInfo.materialType=='XXZXB' && dataInfo.customerSigned == 0 && dataInfo.finalDraft==1 && type=='edit' && dataInfo.taskStatus!=3"
            >签字</el-button
          >
          <el-button
            round
            type="danger"
            size="small"
            v-if="dataInfo.materialType=='XXZXB' && dataInfo.customerSigned == 0 && dataInfo.finalDraft==1 && type=='edit' && dataInfo.taskStatus!=3"

            @click="refuseSign"
            >拒绝签字</el-button
          >
          <el-button
            round
            type="primary"
            size="small"
            v-if="type=='edit' && isShowUpload && dataInfo.taskStatus!=3"
            @click="save"
            >保存</el-button
          >
        </div>
      </div>
      <div class="infoBox">
        <div class="searchBox">
          <p>材料基本信息</p>
          <div>
            <span>材料名称：{{ dataInfo.taskName }}</span>
            <span>材料类型：{{ dataInfo.relatedDocument }}</span>
            <span v-if="dataInfo.materialType=='XXZXB'">签字状态：
              <span :class="getSignStatusClass(dataInfo.customerSigned)">
                {{ getSignStatusText(dataInfo.customerSigned) }}
              </span>
            </span>
            <span
              >定稿状态：
              <span :class="getSignStatusClass(dataInfo.finalDraft)">
                {{ getSignStatusTexts(dataInfo.finalDraft) }}
              </span>
            </span>
            <span
              >负责人：
              <template
                v-if="dataInfo.teachers && dataInfo.teachers.length > 0"
              >
                <span
                  v-for="(item, index) in dataInfo.teachers"
                  :key="item.id || index"
                  >{{ item.teacherName }}
                </span>
              </template>
              <template v-else> 暂无负责人信息 </template>
            </span>
          </div>
          <span>操作时间： <font v-if="dataInfo.createTime!=null" >{{ dataInfo.createTime}}</font></span>
        </div>
        <p class="smalltitle" v-if="isshowPdf">
          {{ dataInfo.relatedDocument }}
          <!-- <el-select
            v-model="dataInfo.relatedDocument"
            placeholder="请选择材料类型"
          >
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select> -->
        </p>
        <div class="pdfBox" v-if="isshowPdf">
          <div class="pdfBoxL" style="position: relative">
            <!-- <vue-office-pdf  style="height: 100%" /> -->
            <el-empty
              id="my-iframe"
              description="暂无预览"
              v-if="!pdf || pdf==null"
              image="https://file.chaogexitongban.net/icon/empty.png"
            ></el-empty>
            <iframe
              v-else
              :src="pdf"
              id="my-iframe"
              frameborder="0"
              allowfullscreen
            ></iframe>
          </div>
          <div class="pdfBoxr">
            <div class="pdf_top">
              <!-- <span>材料:</span> -->
              <div class="pdf_box">
                <p v-for="(item, index) in dataInfo.files" :key="index">
                  <img src="@/assets/pdfIcon.png" alt="" /><span>{{
                    item.fileName.split('.')[0] + '.pdf'
                  }}</span
                  ><el-button
type="text"
@click="preview(item.fileUrl)"
                    >预览</el-button
                  >
                </p>
              </div>
            </div>
            <div class="pdfBoxupload" v-if="isShowUpload  && finalDraft=='0'">
              <span style="width: 100px">上传材料：</span>
              <div class="uploadBox" style="padding: 0; width: 100%; margin: 0">
                <el-upload
                  class="upload-demo"
                  drag
                  action=""
                  :multiple="false"
                  :show-file-list="false"
                  :http-request="toChannelRequest"
                  accept=".doc,.docx,.pdf"
                >
                  <div>
                    <font>
                      <img src="@/assets/file_icon.png" alt="" />
                      <p>仅支持Word格式</p>
                    </font>
                    <!-- <div v-if="toChannelName" class="el-upload__text">{{ toChannelName }}</div>  -->
                    <el-button
                      class="btnColor"
                      style="font-weight: bold"
                      type="text"
                      >上传文件</el-button
                    >
                    <!-- <el-button v-else style="font-weight: bold;" type="text">重新上传</el-button> -->
                  </div>
                </el-upload>
              </div>
            </div>
            <div class="pdf_top">
              <span v-if="newFileList.length > 0">材料:</span>
              <div class="pdf_box">
                <p v-for="(item, index) in newFileList" :key="index">
                  <img src="@/assets/pdfIcon.png" alt="" /><span>{{
                    item.fileName
                  }}</span
                  ><el-button
                    type="text"
                    @click="previews(item.fileUrl, item.fileName)"
                    >预览</el-button
                  ><el-button
                    type="text"
                    @click="deleteFile(item.fileName)"
                    >删除</el-button
                  >
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="topIcon" @click="toTop"><i class='el-icon-download'></i></div>
        <partone @nextStep="nextStep(1)"  v-if="active=='XSSQXXB1Y'" :finalDraft="finalDraft" :type="type" @getDetail="getDetail" @showLoading="showLoading" @hideLoading="hideLoading" :id="id"></partone>
        <partoneOther
          @nextStep="nextStep(2)"
          @backPage="backPage(2)"
          :type="type"
          @getDetail="getDetail"
          :finalDraft="finalDraft"
          v-if="active=='XSSQXXB1H'"
          @showLoading="showLoading"
          @hideLoading="hideLoading"
          :id="id"
        ></partoneOther>
        <parttwo
          @nextStep="nextStep(3)"
          @backPage="backPage(3)"
          :type="type"
          @getDetail="getDetail"
          :finalDraft="finalDraft"
           v-if="active=='XSSQXXB2'"
           @showLoading="showLoading"
           @hideLoading="hideLoading"
           :id="id"

        ></parttwo>
        <partthree
          @nextStep="nextStep(4)"
          @backPage="backPage(4)"
          :type="type"
          @getDetail="getDetail"
          :finalDraft="finalDraft"
           v-if="active=='XSSQXXB3'"
           @showLoading="showLoading"
           @hideLoading="hideLoading"
           :id="id"
        ></partthree>
        <partfour
          @nextStep="nextStep(5)"
          @backPage="backPage(5)"
          :type="type"
          @getDetail="getDetail"
          :finalDraft="finalDraft"
          v-if="active=='XSSQXXB4'"
          @showLoading="showLoading"
           @hideLoading="hideLoading"
           :id="id"
        ></partfour>
        <partfive
          @nextStep="nextStep(6)"
          @backPage="backPage(6)"
          :type="type"
          @getDetail="getDetail"
          :finalDraft="finalDraft"
           v-if="active=='XSSQXXB5'"
           @showLoading="showLoading"
           @hideLoading="hideLoading"
           :id="id"
        ></partfive>
        <partsix
          @nextStep="nextStep(7)"
          @backPage="backPage(7)"
          :type="type"
          @getDetail="getDetail"
          :finalDraft="finalDraft"
          v-if="active=='XSSQXXB6'"
          @showLoading="showLoading"
           @hideLoading="hideLoading"
           :id="id"
        ></partsix>
        <partseven @backPage="backPage(8)" v-if="active=='XSSQXXB7'" :type="type" @getDetail="getDetail" :finalDraft="finalDraft" @showLoading="showLoading" @hideLoading="hideLoading" :id="id"></partseven>
        <div class="materialBox" v-if="!isshowPdf && dataInfo.files && dataInfo.files.length > 0">
          <span>学生信息表：材料</span>
          <div class="pdf_box">
            <p>材料：</p>
            <div>
                <p v-for="(item, index) in dataInfo.files" :key="index">
                  <img src="@/assets/pdfIcon.png" alt="" /><span>{{
                    item.fileName.split('.')[0] + '.pdf'
                  }}</span
                  ><el-button
                    type="text"
                    @click="downPdf(item.fileUrl,item.fileName)"

                    >预览</el-button
                  ><span style="color:#FF8240;margin:0 5px">|</span><el-button
                      type="text"
                      @click="downPdf(item.fileUrl, item.fileName)"
                      >下载</el-button
                    >
                </p>
              </div>
              </div>
        </div>
        <!-- 历史记录弹窗 -->
        <el-dialog
          title="历史记录"
          :visible.sync="dialogVisible"
          width="417px"
          center
          :close-on-click-modal="false"
        >
          <div class="historyBox">
            <div
              class="historyListbox"
              v-for="(item, index) in historyList"
              :key="index"
            >
              <p>
                <span>版本{{ item.version }}</span>
                <el-button
type="text"
@click="toDetail(item)"
                  >查看详情</el-button
                >
              </p>
              <p>操作时间：{{ item.createTime }}</p>
              <p>操作人：{{ item.createName }}</p>
            </div>
          </div>
        </el-dialog>
        <!-- 历史记录详情 -->
        <el-dialog
          title=""
          :visible.sync="historydialogVisible"
          width="800px"
          center
          :close-on-click-modal="false"
          class="historyDetailDialog"
        >
          <p class="dialogTitle">
            <span @click="backTo">材料记录</span> >材料记录详情
          </p>
          <div class="historyDetailBox">
            <p>版本{{ historyDataInfo.version }}</p>
            <div class="historyDetailBoxL">
              <el-empty
              id="my-iframe"
              description="暂无预览"
              v-if="!historypdf || historypdf==null"
              image="https://file.chaogexitongban.net/icon/empty.png"
            ></el-empty>
              <iframe
              v-else
              :src="historypdf"
              id="my-iframe"
              frameborder="0"
              allowfullscreen
            ></iframe>
            </div>
            <div class="historyDetailBoxR">
              <p>操作时间：{{ formatTimestamp(historyDataInfo.createTime) }}</p>
              <p>操作人：{{ historyDataInfo.createName }}</p>
              <p>材料名称：{{ historyDataInfo.relatedDocument }}</p>
              <p>
                客户是否签字：{{
                  getStatusText(historyDataInfo.customerSigned)
                }}
              </p>
              <p>
                是否定稿：{{
                  historyDataInfo.finalDraft == 1 ? '已定稿' : '未定稿'
                }}
              </p>
              <p>
                负责人：
                <span
                  v-for="(item, index) in historyDataInfo.teachers"
                  :key="item.id || index"
                  >{{ item.teacherName }}
                </span>
              </p>
              <div class="pdf_top">
                <span>材料:</span>
                <div class="pdf_box">
                  <p v-for="(item, index) in historyUrlList" :key="index">
                    <img src="@/assets/pdfIcon.png" alt="" /><span>{{
                      item.fileName
                    }}</span
                    ><el-button
                      type="text"
                      @click="previewDetail(item.fileUrl, item.fileName)"
                      >预览</el-button
                    >
                  </p>
                </div>
              </div>
            </div>
          </div>
        </el-dialog>
        <el-dialog
          title="签名"
          :visible.sync="showSign"
          width="417px"
          center
          :close-on-click-modal="false"
          :show-close="false"
        >
          <div class="uploadBox">
            <p class="signName">
              <font style="width: 87px">选择签名：</font>
              <el-select
                v-model="signNames"
                placeholder="请选择签名名称"
                @change="changeSignName"
              >
                <el-option
                  v-for="item in signList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </p>
            <p class="addSign">没有签名？<span @click="toAddSign">去添加签名</span></p>
            <p class="signName">
              <font style="width: 88px">预览：</font>
              <span class="imgBox">
                <img :src="signLogo" alt="" />
              </span>
            </p>
          </div>
          <div class="footBtn" style="padding: 0 26px 0">
            <p @click="cancel">取消</p>
            <el-button :disabled="isClickable" @click="sure">确认签字并保存</el-button>
          </div>
        </el-dialog>
        <el-dialog
          title="下载"
          :visible.sync="downloadVisible"
          width="417px"
          center
          :close-on-click-modal="false"
          :show-close="false"
          class="downloadDialog"
        >
          <div class="pdf_top" style="padding: 20px">
            <div class="pdf_box">
              <p v-for="(item, index) in dataInfo.files" :key="index">
                <img src="@/assets/pdfIcon.png" alt="" /><span>{{
                  item.fileName
                }}</span
                ><el-button
type="text"
@click="download(item.fileUrl, item.fileName)"
                  >下载</el-button
                >
              </p>
            </div>
          </div>
          <div class="footBtn" style="justify-content: center">
            <p @click="cancel">取消</p>
            <!-- <p @click="sure">确定</p> -->
          </div>
        </el-dialog>
        <!-- 拒绝签字弹窗 -->
        <el-dialog
          title="拒绝签字"
          :visible.sync="refuseVisible"
          width="417px"
          center
          :close-on-click-modal="false"
          :show-close="false"
        >
          <span class="tipss">确认拒绝签字吗？</span>
          <div class="footBtn" style="padding: 0 26px 0">
            <el-button @click="cancel">取消</el-button>
            <el-button  @click="sureRefuse">确定</el-button>
          </div>
        </el-dialog>
      </div>
    </div>
  </div>
</template>

<script>
import leftNav from './leftNav';
import Partone from './components/Partone.vue';
import PartoneOther from './components/PartoneOther.vue';
import Parttwo from './components/Parttwo.vue';
import Partthree from './components/Partthree.vue';
import Partfour from './components/Partfour.vue';
import Partfive from './components/Partfive.vue';
import Partsix from './components/Partsix.vue';
import Partseven from './components/Partseven.vue';
import { workTaskFileuploadFile, sendLog } from 'api/index.js';
import { Loading } from 'element-ui';
import { downloadFile } from '@/assets/js/index.js';
import {
  getworkTaskdetail,
  getworkTaskHistory,
  checkAndUpdateFiles,
  getCmsSignList,
  studentSign,
  refuseSign
} from '@/assets/api/application.js';
import { dec } from '@utils/encryptedFile/des.js';
export default {
  components: {
    leftNav,
    Partone,
    Parttwo,
    Partthree,
    Partfour,
    Partfive,
    Partsix,
    Partseven,
    PartoneOther
  },
  data() {
    return {
      active: this.$route.query.materialType,

      form: {},
      statusList: [
        {
          code: '1',
          name: '已定稿'
        },
        {
          code: '2',
          name: '未定稿'
        }
      ],
      tableData: [],
      urlList: [],
      dataInfo: {},
      historyDataInfo: {},
      pdf: '',
      docx: '',
      newFileList: [],
      toChannelName: '',
      historyList: [],
      dialogVisible: false,
      historydialogVisible: false,
      detailpdf: '',
      id: this.$route.query.id,
      showSign: false,
      downloadVisible: false,
      signNames: '',
      historydocx: '',
      historyUrlList: [],
      signList: [],
      signLogo: '',
      historypdf: '',
      loading: false,
      refuseVisible: false,
      type: this.$route.query.type,
      fileList: [],
      fileList1: [],
      isShowUpload: false,
      finalDraft: 0,
      isClickable: false,
      isshowPdf: true,
      loadingInstance: null
    };
  },
  mounted() {
    if (dec(this.$route.query.id)) {
      this.id = dec(this.$route.query.id);
      console.log(this.id);
    }
    if (dec(this.$route.query.type)) {
      this.type = dec(this.$route.query.type);
    }
    this.getDetail();
  },
  methods: {
    showLoading() {
      this.loadingInstance = Loading.service({
        lock: true, // 是否锁定屏幕，即是否允许用户操作
        text: '加载中...', // 显示在Loading上的文字
        spinner: 'el-icon-loading', // 自定义加载图标
        background: 'rgba(255, 255, 255, 0.8)', // 背景颜色，可以自定义遮罩层的颜色
        customClass: 'fontSize'
      });
    },
    hideLoading() {
      if (this.loadingInstance) {
        this.loadingInstance.close(); // 关闭加载动画
      }
    },
    getDetail() {
      this.showLoading();
      const ALLOWED_MATERIAL_TYPES = ['XSSQXXB1Y', 'XSSQXXB1H', 'XSSQXXB2', 'XSSQXXB3', 'XSSQXXB4', 'XSSQXXB5', 'XSSQXXB6', 'XSSQXXB7'];
      getworkTaskdetail({ id: this.id })
        .then((res) => {
          if (res.code == 2000) {
            this.dataInfo = res.data;
            this.urlList = this.dataInfo.files || [];
            this.finalDraft = res.data.finalDraft;
            console.log(this.finalDraft == 0);
            const materialType = this.dataInfo?.materialType ?? '';
            if (ALLOWED_MATERIAL_TYPES.includes(materialType)) {
              console.log(this.isShowUpload);
              this.isshowPdf = false;
              this.active = res.data.materialType;
              this.isShowUpload = false;
            } else {
              this.isShowUpload = true;
            }
            if (this.urlList.length > 0) {
              if (this.urlList[0].fileUrl != null && this.urlList[0].fileUrl != '') {
                this.pdf = this.urlList[0].fileUrl;
              }
            }
            if (this.loadingInstance) {
              this.loadingInstance.close();
            }
          }
        })
        .finally(() => {
          if (this.loadingInstance) {
              this.loadingInstance.close();
            }
        });
    },
    nextStep(act) {
      this.active = act + 1;
    },
    backPage(act) {
      this.active = act - 1;
    },
    getSignStatusClass(status) {
      switch (status) {
        case 0:
          return 'status1';
        case 1:
          return 'status2';
      }
    },
    getSignStatusText(status) {
      switch (status) {
        case 0:
          return '待签字';
        case 1:
          return '已签字';
        default:
          return ''; // 默认为修订中
      }
    },
    getSignStatusTexts(status) {
      switch (status) {
        case 0:
          return '未定稿';
        case 1:
          return '已定稿';
        default:
          return ''; // 默认为修订中
      }
    },
    returnTo() {
      // this.$router.go(-1);
      this.$router.push({ path: '/applicationForm', query: { form: this.$route.query.form, pagenum: this.$route.query.pagenum }});
    },
    isPdfOrDocx(file) {
      const extension = file.split('.').pop().toLowerCase();
      return extension === 'pdf' || extension === 'docx';
    },
    preview(url, fileName) {
      this.pdf = url;
    },
    previews(url, fileName) {
      this.pdf = url;
    },
    previewDetail(url, fileName) {
      this.historypdf = url;
    },
    toChannelRequest(files) {
      const file = files.file;
      this.toChannelName = file.name;
      const fromData = new FormData();
      fromData.append('file', file);
      this.loading = true;
      workTaskFileuploadFile(fromData)
        .then((res) => {
          if (res.code == 2000) {
            this.$message({
              type: 'success',
              message: '上传成功'
            });
            // 把上传的文件信息后缀改成.pdf
            const fileNames = file.name.split('.')[0] + '.pdf';
            this.newFileList.push({
              fileName: fileNames,
              fileUrl: res.data,
              taskId: this.id
            });
          } else {
            this.$message({
              type: 'error',
              message: res.msg
            });
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    tohistory() {
      getworkTaskHistory({ taskId: this.id }).then((res) => {
        if (res.code == 2000) {
          this.historyList = res.data.list;
          if (this.historyList.length > 0) {
            this.dialogVisible = true;
          } else {
            this.$message({
              type: 'success',
              message: '暂无历史记录'
            });
          }
        }
      });
    },
    cancel() {
      this.dialogVisible = false;
      this.downloadVisible = false;
      this.showSign = false;
      this.refuseVisible = false;
    },
    sure() {
      if (this.signLogo === '' || this.signLogo === null) {
        this.$message({
            type: 'error',
            message: '请先添加签名图片！'
          });
          return;
      };
      this.showLoading();
      this.isClickable = true; // 如果不可点击，则直接返回
      const data = {
        id: this.id,
        signImage: this.signLogo
      };
        studentSign(data)
        .then((res) => {
          if (res.code == 2000) {
            this.$message({
              type: 'success',
              message: res.msg
            });
            this.showSign = false;
            this.isClickable = false;
            if (this.loadingInstance) {
              this.loadingInstance.close();
            }
            this.getDetail();
          } else {
            this.$message({
              type: 'error',
              message: res.msg
            });
            if (this.loadingInstance) {
              this.loadingInstance.close();
            }
          }
        })
        .finally(() => {
          this.isClickable = false;
          if (this.loadingInstance) {
              this.loadingInstance.close();
            }
        });
    },
    toDetail(item) {
      this.historydialogVisible = true;
      this.dialogVisible = false;
      getworkTaskdetail({ id: item.taskId, version: item.version }).then(
        (res) => {
          if (res.code == 2000) {
            this.historyDataInfo = res.data;
            this.historyUrlList = this.historyDataInfo.files;
            if (this.historyUrlList.length > 0) {
              this.historypdf = this.historyUrlList[0].fileUrl;
            }
          }
        }
      );
    },
    backTo() {
      this.historydialogVisible = false;
      this.dialogVisible = true;
    },
    formatTimestamp(timestamp) {
      const date = new Date(timestamp);
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0'); // 月份从0开始，需要加1
      const day = String(date.getDate()).padStart(2, '0');
      const hours = String(date.getHours()).padStart(2, '0');
      const minutes = String(date.getMinutes()).padStart(2, '0');
      return `${year}年${month}月${day}日 ${hours}:${minutes}`;
    },
    studentSign() {
      if (this.dataInfo.files.length && this.dataInfo.files.length > 0 ) {
      this.showSign = true;
      const data = {
        startDate: '',
        endDate: ''
      };
      getCmsSignList(data).then((res) => {
        if (res.code == 2000) {
          this.signList = res.data.list;
          if (this.signList.length > 0) {
            this.signLogo = this.signList[this.signList.length - 1].signUrl;
            this.signNames = this.signList[this.signList.length - 1].name;
          }
        }
      });
    } else {
      this.$message({
        type: 'error',
        message: '请先上传并保存文件后在进行签字操作！'
      });
    }
    },
    changeSignName(row) {
      this.signLogo = this.signList.find((item) => item.id == row).signUrl;
    },
    save() {
      this.loading = true;
      const data = {
        taskId: this.id,
        newFiles: this.newFileList
      };
      checkAndUpdateFiles(data)
        .then((res) => {
          if (res.code == 2000) {
            this.$message({
              type: 'success',
              message: res.msg
            });
            this.newFileList = [];
            setTimeout(() => {
              this.getDetail();
            }, 2000);
          } else {
            this.$message({
              type: 'error',
              message: res.msg
            });
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    deleteFile(fileName) {
      this.newFileList = this.newFileList.filter(
        (item) => item.fileName !== fileName
      );
    },
    getStatusText(customerSigned) {
      if (customerSigned === 0) return '待签字';
      if (customerSigned === 1) return '已签字';
      return '修订中';
    },
    download(url, name) {
      downloadFile(url, name, false, '.pdf');
      sendLog({
        logMethod: "/move/sys/log/downloadLog",
        pageModeName: "学生申请信息表",
        logOperation: "下载文件",
        logType: "7",
        modular: "5",
        fileName: name,
        pageModeUrl: "/applicationFormdetail",
        fileUrl: url
      }).then((res) => {
      });
    },
   downPdf(url, name) {
      this.dialogVisible = false;
      downloadFile(url, name, false, '.pdf');
      sendLog({
        logMethod: "/move/sys/log/downloadLog",
        pageModeName: "学生申请信息表",
        logOperation: "下载文件",
        logType: "7",
        modular: "5",
        fileName: name,
        pageModeUrl: "/applicationFormdetail",
        fileUrl: url
      }).then((res) => {
      });
    },
    adjustIframe() {
      var ifm = document.getElementById('bi_iframe');
      ifm.height = document.documentElement.clientHeight;
      ifm.width = document.documentElement.clientWidth;
    },
    refuseSign() {
      this.refuseVisible = true;
    },
    sureRefuse() {
      this.showLoading();
      refuseSign({ taskId: this.id })
        .then((res) => {
          if (res.code == 2000) {
            this.$message({
              type: 'success',
              message: '操作成功'
            });
            this.refuseVisible = false;
            this.loadingInstance.close();
            this.getDetail();
          } else {
            this.$message({
              type: 'error',
              message: res.msg
            });
            this.loadingInstance.close();
          }
        })
        .finally(() => {
          this.loadingInstance.close();
        });
    },
    toDownloadFile() {
      if (this.dataInfo.files.length > 0) {
        this.downloadVisible = true;
      } else {
        this.$message.error('暂无文件可下载');
      }
    },
    toAddSign() {
      this.$router.push({ path: '/mySignature' });
    },
    toTop() {
      window.scrollTo(0, 0);
    }
  }
};
</script>

<style lang='stylus' scoped>
@import '~css/stylus.styl';

.gift {
  width: 1200px;
  height: 100%;
  display: flex;
  justify-content: space-between;
  background-color: #F5F7FA;
  padding-bottom: 60px;
  margin: 0 auto;
}

.giftcontent {
  width: 940px;
  background-color: #fff;
  margin: 27px 2% 27px;
  box-sizing: border-box;
  border-radius: 12px 12px 12px 12px;

  .gift_top {
    padding: 20px;
    border-bottom: 1px solid #EEE;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .title {
      font-size: 20px;
      color: #333333;
      height: 26px;
      position: relative;
      padding-left: 23px;
      font-weight: bold;
    }

    .title>i {
      position: absolute;
      left: 0;
      top: 2px;
      color: #333333;
      display: block;
      cursor: pointer;
      font-size: 23px;
      font-weight: bold;
    }
  }
}

.infoBox {
  padding: 19px 20px 0;
  margin: 0 auto 19px;
  box-sizing: border-box;

  .searchBox {
    padding-bottom: 21px;
    border-bottom: 1px solid #EEE;
    margin-bottom: 19px;

    p {
      font-weight: bold;
      font-size: 16px;
      color: #333333;
      line-height: 32px;
      margin-bottom: 16px;
    }

    div {
      display: flex;
      align-items: center;
      margin-bottom: 14px;
      justify-content: space-between;
      >span {
        font-size: 14px;
        color: #333333;
      }
    }
  }
}

.activeimg {
  color: rgba(255, 119, 119, 1);
  font-size: 18px;
}

.img {
  color: rgba(127, 156, 184, 1);
  font-size: 18px;
}

.status1 {
  color: rgba(246, 196, 32, 1);
}

.status2 {
  color: rgba(34, 197, 130, 1);
}

.status3 {
  color: rgba(84, 107, 255, 1);
}

.smalltitle {
  font-weight: bold;
  font-size: 16px;
  color: #333333;
  line-height: 32px;
  margin-bottom: 15px;
}

.pdfBox {
  .pdfBoxL {
    width: 430px;
    height: 450px;
    background: #F7F7F7;
    border-radius: 18px 18px 18px 18px;
    border: 1px solid #EEEEEE;
    float: left;
    overflow: hidden;
  }

  .pdfBoxr {
    width: 445px;
    height: 450px;
    background: #fff;
    border-radius: 18px 18px 18px 18px;
    border: 1px solid #EEEEEE;
    float: right;
    padding: 22px 20px;
    box-sizing: border-box;
  }
}

.pdf_top {
  &>span {
    width: 42px;
    display: block;
    float: left;
    font-size: 14px;
    margin-top: 6px;
  }

  .pdf_box {
    p {
      display: flex;
      align-items: center;
      margin-bottom: 15px;
      img {
        width: 24px;
      }

      span {
        font-size: 12px;
        color: #333333;
        margin: 0 10px;
        line-height: 16px;
      }

      .el-button {
        padding: 0 !important;
      }
    }
  }
}
.materialBox{
  padding-top: 20px;
  border-top: 1px solid #eee;
  >span{
    font-weight: bold;
    font-size: 16px;
    color: #333333;
  }
  .pdf_box {
    background: #FAFBFC;
    padding: 15px 20px;
    margin-top: 21px;
    border-radius: 5px 5px 0px 0px;
    >p{
      float: left;
      line-height: 26px;

    }
    div{
      float: left;
      p:last-child{
       margin-bottom: 0;
      }
    }
    p {
      display: flex;
      align-items: center;
      margin-bottom: 15px;
      img {
        width: 24px;
      }

      span {
        font-size: 12px;
        color: #333333;
        margin: 0 10px;
        line-height: 16px;
      }

      .el-button {
        padding: 0 !important;
      }
    }
  }
  .pdf_box:after{
    content: '';
    display: block;
    clear: both;
  }
}
.pdf_top:after {
  content: '';
  display: block;
  clear: both;
}

.pdfBox:after {
  content: '';
  display: block;
  clear: both;
}

>>>.el-upload {
  display: block;
}

>>>.el-upload-dragger {
  width: 100%;
  height: 62px;
  background: #FFFFFF;
  border-radius: 8px 8px 8px 8px;
  border: 1px solid #CCCCCC;
  padding: 14px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;

  div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    font {
      display: flex;
      align-items: center;
    }

    img {
      width: 38px;
      display: block;
      margin-right: 10px;
    }

    p {
      font-size: 13px;
      color: #666666;
    }

    .el-button {
      font-weight: bold;
      font-size: 14px;
    }
  }
}

.pdfBoxupload {
  display: flex;
  align-items: center;
  margin-bottom: 15px;

  &>span {
    font-weight: 400;
    font-size: 14px;
    color: #333333;
    width: 70px;
  }
}

>>>.el-dialog__header {
  padding: 19px 0 17px !important;
  text-align: center;
  font-size: 18px;
  line-height: 20px;
  border-bottom: 1px solid #EEEEEE;
}

>>>.el-dialog--center .el-dialog__body {
  padding: 0px 0 24px !important;
}

.footBtn {
  box-sizing: border-box;
  padding: 0 26px 18px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  p {
    width: 172px;
    height: 51px;
    text-align: center;
    line-height: 51px;
    font-weight: 400;
    font-size: 16px;
    color: #FF8240;
    border-radius: 90px 90px 90px 90px;
    cursor: pointer;
  }

  p:nth-of-type(1) {
    border: 1px solid #FF8240;
  }

  p:nth-of-type(2) {
    background: #FF8240;
    color: #FFFFFF;
  }
}

.historyBox {
  padding: 0 25px;
  max-height: 325px;
  overflow-y: scroll;

  .historyListbox {
    padding: 17px 0 5px;
    border-bottom: 1px solid #EEEEEE;

    p {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 14px;
      color: #333333;
      margin-bottom: 10px;

      .el-button {
        padding: 0 !important;
      }
    }

    p:nth-of-type(1) {
      font-weight: bold;
    }
  }
}

.historyDetailBox {
  padding: 0 0 0 22px;

  >p {
    font-weight: bold;
    font-size: 16px;
    color: #333333;
    margin-bottom: 20px;
  }

  .historyDetailBoxL {
    width: 426px;
    height: 442px;
    float: left;
    background: #F7F7F7;
    border-radius: 18px 18px 18px 18px;
    border: 1px solid #EEEEEE;
    overflow: hidden;
    box-sizing: border-box;
  }

  .historyDetailBoxR {
    float: right;
    width: 314px;
    padding-right: 20px;
    &>p {
      font-weight: 400;
      font-size: 14px;
      color: #333333;
      height: 19px;
      line-height: 19px;
      margin-bottom: 10px;
    }
  }
}

.historyDetailBox:after {
  content: '';
  display: block;
  clear: both;
}

.historyDetailDialog {
  >>>.el-dialog__header {
    padding: 0 !important;
    border: none !important;
  }
}

.dialogTitle {
  padding: 19px 20px;
  border-bottom: 1px solid #EEEEEE;
  margin-bottom: 20px;
  font-size: 18px;
  color: rgba(51, 51, 51, 1);

  span {
    color: rgba(153, 153, 153, 1);
    cursor: pointer;
  }
}
</style>
<style>
#app {
  background-color: #f5f7fa;
}

.main {
  background-color: #f5f7fa;
}
.footBtn {
  box-sizing: border-box;
  padding: 0 26px 18px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  p {
    width: 172px;
    height: 51px;
    text-align: center;
    line-height: 51px;
    font-weight: 400;
    font-size: 16px;
    color: #ff8240;
    border-radius: 90px 90px 90px 90px;
    cursor: pointer;
  }
  .el-button {
    width: 172px;
    height: 51px;
    text-align: center;
    line-height: 51px;
    font-weight: 400;
    font-size: 16px;
    color: #ff8240;
    border-radius: 90px 90px 90px 90px;
    cursor: pointer;
    background: #ff8240;
    color: #ffffff;
    padding: 0 !important;
    border: 1px solid #ff8240;
  }
  p:nth-of-type(1) {
    border: 1px solid #ff8240;
  }

  p:nth-of-type(2) {
    background: #ff8240;
    color: #ffffff;
  }
}
.signName {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  font {
    width: 70px;
    text-align: right;
  }
}
.imgBox {
  width: 149px;
  height: 81px;
  border-radius: 6px;
  border: 1px solid #eeeeee;
  margin-top: 0px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  img {
    width: 100%;
    height: auto;
  }
}
.uploadBox {
  margin: 22px auto 24px;
  box-sizing: border-box;
  padding: 0 26px;
  >>> .el-select {
    width: 100%;
  }
}
.tips {
  font-size: 16px;
  color: #333333;
  line-height: 20px;
  display: block;
  margin: 41px auto;
  text-align: center;
}
.downloadDialog {
  .el-dialog{
    top: 50% !important;
    transform: translateY(-50%) !important;

  }
  .el-dialog--center .el-dialog__body {
    padding: 0px !important;
  }
  .el-dialog__header {
    padding: 19px 0 0 !important;
    border-bottom: none !important;
  }
}
.tipss {
  font-size: 16px;
  color: #333333;
  line-height: 20px;
  display: block;
  margin: 41px auto;
  text-align: center;
}
.addSign{
  padding-left:89px;
  margin-bottom: 15px;
  span{
    cursor: pointer;
    color: #ff8240;
  }
}
#my-iframe {
  width: 424px;
  height: 427px;
  img {
    max-width: 100%;
    height: auto;
  }
}
.topIcon{
  width: 45px;
  height: 45px;
  background: #FFFFFF;
  box-shadow: 0px 8px 15px 1px rgba(0,0,0,0.1);
  border-radius: 50%;
  right: 8%;
  bottom: 15%;
  position: fixed;
  z-index:55 ;
  line-height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  i{
    font-size: 20px;
    transform: rotate(180deg);

  }
}
.el-loading-mask{
  z-index: 999999 !important;
}
.fontSize{
  .el-icon-loading{
    font-size:30px;
  }
  .el-loading-text{
    font-size:18px;
  }
}
</style>