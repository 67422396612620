<template>
    <div>
      <div class="title">第三部分：申请人教育背景(仅需填写您的最高学历)
        <div class="btnBox">
          <!-- <el-button class="saveBtn" plain round @click="goBack">返回上一页</el-button> -->
          <template  v-if="isEdit && type!='detail'  && finalDraft!=1">
            <el-button type="primary" class="ml-10" round @click="startEdit">开始修改</el-button>
            <!-- <el-button type="primary" class="ml-10" round @click="navigateNext">跳转下一页</el-button> -->
          </template>
          <template v-else-if="type!='detail' && !isEdit">
            <p class="saveBtn ml-10"  v-show="isEdit==false" @click="stopEdit">退出修改</p>
            <el-button type="primary" class="ml-10" round @click="saveInfo('formData')">确认修改</el-button>
          </template>
      </div>
      </div>
      <div class="contentBox">
        <el-form :model="formData" ref="formData" :rules="rules" inline class="flexs" label-position="left">
          <h3>小学就读学校</h3>
          <el-row>
            <el-col :span="7" style="margin-right: 72px;">
              <el-form-item label="小学就读学校:" label-width="180px" :class="whetherReturnEmpty(formData.editDto.primarySchoolName)">
                <el-input
                  v-model="formData.primarySchoolName"
                  placeholder="请输入"
                  size="medium"
                  :disabled="isEdit"
                />
              </el-form-item>
            </el-col>
            <el-col :span="5" style="margin-right: 72px;">
              <el-form-item label="From:" label-width="65px" :class="whetherReturnEmpty(formData.editDto.primarySchoolFrom)">
                <el-input
                  v-model="formData.primarySchoolFrom"
                  placeholder="请输入"
                  size="medium"
                  :disabled="isEdit"
                />
              </el-form-item>
            </el-col>
            <el-col :span="5">
              <el-form-item label="To:" label-width="50px" :class="whetherReturnEmpty(formData.editDto.primarySchoolTo)">
                <el-input
                  v-model="formData.primarySchoolTo"
                  placeholder="请输入"
                  size="medium"
                  :disabled="isEdit"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="18">
              <el-form-item label="学校地址:" label-width="80px" :class="whetherReturnEmpty(formData.editDto.primarySchoolAddress)">
                <el-input
                  v-model="formData.primarySchoolAddress"
                  placeholder="请输入"
                  size="medium"
                  class="matching-input"
                  :disabled="isEdit"
                />
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="邮政编码:" label-width="110px" :class="whetherReturnEmpty(formData.editDto.primarySchoolPostalCode)">
                <el-input
                  v-model="formData.primarySchoolPostalCode"
                  placeholder="请输入"
                  size="medium"
                  :disabled="isEdit"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <h3>初中就读学校</h3>
          <el-row>
            <el-col :span="7" style="margin-right: 72px;">
              <el-form-item label="初中就读学校:" label-width="180px" :class="whetherReturnEmpty(formData.editDto.juniorHighSchoolName)">
                <el-input
                  v-model="formData.juniorHighSchoolName"
                  placeholder="请输入"
                  size="medium"
                  :disabled="isEdit"
                />
              </el-form-item>
            </el-col>
            <el-col :span="5" style="margin-right: 72px;">
              <el-form-item label="From:" label-width="65px" :class="whetherReturnEmpty(formData.editDto.juniorHighSchoolFrom)">
                <el-input
                  v-model="formData.juniorHighSchoolFrom"
                  placeholder="请输入"
                  size="medium"
                  :disabled="isEdit"
                />
              </el-form-item>
            </el-col>
            <el-col :span="5">
              <el-form-item label="To:" label-width="50px" :class="whetherReturnEmpty(formData.editDto.juniorHighSchoolTo)">
                <el-input
                  v-model="formData.juniorHighSchoolTo"
                  placeholder="请输入"
                  size="medium"
                  :disabled="isEdit"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="18">
              <el-form-item label="学校地址:" label-width="80px" :class="whetherReturnEmpty(formData.editDto.juniorHighSchoolAddress)">
                <el-input
                  v-model="formData.juniorHighSchoolAddress"
                  placeholder="请输入"
                  size="medium"
                  class="matching-input"
                  :disabled="isEdit"
                />
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="邮政编码:" label-width="110px" :class="whetherReturnEmpty(formData.editDto.juniorHighSchoolPostalCode)">
                <el-input
                  v-model="formData.juniorHighSchoolPostalCode"
                  placeholder="请输入"
                  size="medium"
                  :disabled="isEdit"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="18">
              <el-form-item label="学习排名:" label-width="80px" :class="whetherReturnEmpty(formData.editDto.juniorHighClassRank)">
                <!-- <el-input
                  v-model="formData.juniorHighClassSize"
                  placeholder="班上有 人，你排名第 名；年级有 人，你排名第 名"
                  size="medium"
                  class="matching-input"
                  :disabled="isEdit"
                /> -->
                <span>班上有<el-input
                  v-model="formData.juniorHighClassSize"
                  placeholder=""
                  size="medium"
                  style="width: 50px;margin:0 10px"
                  :disabled="isEdit"
                />人，你排名第<el-input
                  v-model="formData.juniorHighClassRank"
                  placeholder=""
                  size="medium"
                   style="width: 50px;margin:0 10px"
                  :disabled="isEdit"
                />名；年级有<el-input
                  v-model="formData.juniorHighGradeSize"
                  placeholder=""
                  size="medium"
                  style="width: 50px;margin:0 10px"
                  :disabled="isEdit"
                />人，你排第<el-input
                  v-model="formData.juniorHighGradeRank"
                  placeholder=""
                  size="medium"
                   style="width: 50px;margin:0 10px"
                  :disabled="isEdit"
                />名</span>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="平均成绩:" label-width="110px" :class="whetherReturnEmpty(formData.editDto.juniorHighAvgScore)">
                <el-input
                  v-model="formData.juniorHighAvgScore"
                  placeholder="请输入"
                  size="medium"
                  :disabled="isEdit"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="校及班级简介：" label-width="130px" :class="whetherReturnEmpty(formData.editDto.juniorHighSchoolDescription)">
                <el-input
                  v-model="formData.juniorHighSchoolDescription"
                  placeholder="请输入"
                  size="medium"
                  class="matching-input"
                  :disabled="isEdit"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <h3>高中就读学校</h3>
          <el-row>
            <el-col :span="7" style="margin-right: 72px;">
              <el-form-item label="高中就读学校:" label-width="180px" :class="whetherReturnEmpty(formData.editDto.seniorHighSchoolName)">
                <el-input
                  v-model="formData.seniorHighSchoolName"
                  placeholder="请输入"
                  size="medium"
                  :disabled="isEdit"
                />
              </el-form-item>
            </el-col>
            <el-col :span="5" style="margin-right: 72px;">
              <el-form-item label="From:" label-width="65px" :class="whetherReturnEmpty(formData.editDto.seniorHighSchoolFrom)">
                <el-input
                  v-model="formData.seniorHighSchoolFrom"
                  placeholder="请输入"
                  size="medium"
                  :disabled="isEdit"
                />
              </el-form-item>
            </el-col>
            <el-col :span="5">
              <el-form-item label="To:" label-width="50px" :class="whetherReturnEmpty(formData.editDto.seniorHighSchoolTo)">
                <el-input
                  v-model="formData.seniorHighSchoolTo"
                  placeholder="请输入"
                  size="medium"
                  :disabled="isEdit"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="18">
              <el-form-item label="学校地址:" label-width="80px" :class="whetherReturnEmpty(formData.editDto.seniorHighSchoolAddress)">
                <el-input
                  v-model="formData.seniorHighSchoolAddress"
                  placeholder="请输入"
                  size="medium"
                  class="matching-input"
                  :disabled="isEdit"
                />
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="邮政编码:" label-width="110px" :class="whetherReturnEmpty(formData.editDto.seniorHighSchoolPostalCode)">
                <el-input
                  v-model="formData.seniorHighSchoolPostalCode"
                  placeholder="请输入"
                  size="medium"
                  :disabled="isEdit"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="18">
              <el-form-item label="学习排名:" label-width="80px" :class="whetherReturnEmpty(formData.editDto.seniorHighSchoolClassRank)">
                <span>班上有<el-input
                  v-model="formData.seniorHighSchoolClassSize"
                  placeholder=""
                  size="medium"
                  style="width: 50px;margin:0 10px"
                  :disabled="isEdit"
                />人，你排名第<el-input
                  v-model="formData.seniorHighSchoolClassRank"
                  placeholder=""
                  size="medium"
                   style="width: 50px;margin:0 10px"
                  :disabled="isEdit"
                />名；年级有<el-input
                  v-model="formData.seniorHighSchoolGradeSize"
                  placeholder=""
                  size="medium"
                  style="width: 50px;margin:0 10px"
                  :disabled="isEdit"
                />人，你排第<el-input
                  v-model="formData.seniorHighSchoolGradeRank"
                  placeholder=""
                  size="medium"
                   style="width: 50px;margin:0 10px"
                  :disabled="isEdit"
                />名</span>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="平均成绩:" label-width="110px" :class="whetherReturnEmpty(formData.editDto.seniorHighSchoolAvgScore)">
                <el-input
                  v-model="formData.seniorHighSchoolAvgScore"
                  placeholder="请输入"
                  size="medium"
                  :disabled="isEdit"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="校及班级简介：" label-width="130px" :class="whetherReturnEmpty(formData.editDto.seniorHighSchoolDescription)">
                <el-input
                  v-model="formData.seniorHighSchoolDescription"
                  placeholder="请输入"
                  size="medium"
                  class="matching-input"
                  :disabled="isEdit"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <div class="tip">
            <p>备注:</p>
            <p>如申请本科，请注明高中学习体系，包括但不限于AP, IB, A-Level, OSSD等，已有实考成绩的写明科目和考试时间，没有实考成绩的写明科目，预计考试时间，和预估分。普高学生注明文理科分科情况<br/>高三在读学生请注明高三上学期在读课程以及高三下学期要读的课程</p>
          </div>
          <h3>本/专科就读学校</h3>
          <el-row>
            <el-col :span="7" style="margin-right: 72px;">
              <el-form-item label="本/专科就读学校:" label-width="275px" prop="universityName" :class="whetherReturnEmpty(formData.editDto.universityName)">
                <el-input
                  v-model="formData.universityName"
                  placeholder="请输入"
                  size="medium"
                  :disabled="isEdit"
                />
              </el-form-item>
            </el-col>
            <el-col :span="5" style="margin-right: 72px;">
              <el-form-item label="From:" label-width="65px" prop="universityFrom" :class="whetherReturnEmpty(formData.editDto.universityFrom)">
                <el-input
                  v-model="formData.universityFrom"
                  placeholder="请输入"
                  size="medium"
                  :disabled="isEdit"
                />
              </el-form-item>
            </el-col>
            <el-col :span="5">
              <el-form-item label="To:" label-width="50px" prop="universityTo" :class="whetherReturnEmpty(formData.editDto.universityTo)">
                <el-input
                  v-model="formData.universityTo"
                  placeholder="请输入"
                  size="medium"
                  :disabled="isEdit"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="18">
              <el-form-item label="学校地址:" label-width="80px" :class="whetherReturnEmpty(formData.editDto.universityAddress)">
                <el-input
                  v-model="formData.universityAddress"
                  placeholder="请输入"
                  size="medium"
                  class="matching-input"
                  :disabled="isEdit"
                />
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="邮政编码:" label-width="110px" :class="whetherReturnEmpty(formData.editDto.universityPostalCode)">
                <el-input
                  v-model="formData.universityPostalCode"
                  placeholder="请输入"
                  size="medium"
                  :disabled="isEdit"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="6">
              <el-form-item label="专业：" label-width="89px" prop="universityMajor" :class="whetherReturnEmpty(formData.editDto.universityMajor)">
                <el-input
                  v-model="formData.universityMajor"
                  placeholder="请输入"
                  size="medium"
                  class="matching-input"
                  :disabled="isEdit"
                />
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="学院：" label-width="89px" prop="universityCollege" :class="whetherReturnEmpty(formData.editDto.universityCollege)">
                <el-input
                  v-model="formData.universityCollege"
                  placeholder="请输入"
                  size="medium"
                  class="matching-input"
                  :disabled="isEdit"
                />
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="获得学位：" label-width="156px" prop="universityDegreeObtained" :class="whetherReturnEmpty(formData.editDto.universityDegreeObtained)">
                <el-input
                  v-model="formData.universityDegreeObtained"
                  placeholder="请输入"
                  size="medium"
                  class="matching-input"
                  :disabled="isEdit"
                />
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="辅修：" label-width="89px" prop="universityMinor" :class="whetherReturnEmpty(formData.editDto.universityMinor)">
                <el-input
                  v-model="formData.universityMinor"
                  placeholder="请输入"
                  size="medium"
                  class="matching-input"
                  :disabled="isEdit"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="其他证书:" label-width="80px" :class="whetherReturnEmpty(formData.editDto.universityOtherCertificates)">
                <el-input
                  v-model="formData.universityOtherCertificates"
                  placeholder="请输入"
                  size="medium"
                  class="matching-input"
                  :disabled="isEdit"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="7" style="margin-right: 72px;">
              <el-form-item label="平均成绩（GPA）:" label-width="320px" prop="universityGpaScore" :class="whetherReturnEmpty(formData.editDto.universityGpaScore)">
                <el-input
                  v-model="formData.universityGpaScore"
                  placeholder="请输入"
                  size="medium"
                  :disabled="isEdit"
                />
              </el-form-item>
            </el-col>
            <el-col :span="6" style="margin-right: 72px;">
              <el-form-item label="加权平均分：" label-width="170px" :class="whetherReturnEmpty(formData.editDto.universityWeightedAvgScore)">
                <el-input
                  v-model="formData.universityWeightedAvgScore"
                  placeholder="请输入"
                  size="medium"
                  :disabled="isEdit"
                />
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="算数平均分：" label-width="170px" :class="whetherReturnEmpty(formData.editDto.universityArithmeticAvgScore)">
                <el-input
                  v-model="formData.universityArithmeticAvgScore"
                  placeholder="请输入"
                  size="medium"
                  :disabled="isEdit"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="本科学籍系统链接及登录名和密码：" class="labeColor" label-width="320px" :class="whetherReturnEmpty(formData.editDto.universityStudentSystem)">
                <el-input
                  v-model="formData.universityStudentSystem"
                  placeholder="请输入"
                  size="medium"
                  class="matching-input"
                  :disabled="isEdit"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="是否是中外合办：" class="labeColor" label-width="157px" prop="universityJointProgram" :class="whetherReturnEmpty(formData.editDto.universityJointProgram)">
                <el-input
                  v-model="formData.universityJointProgram"
                  placeholder="请输入"
                  size="medium"
                  class="matching-input"
                  :disabled="isEdit"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <p class="smallTitle">本（专）科就读学校介绍（211、985学校等）专业介绍（实验室、重点项目、制定课题研究等）</p>
              <el-form-item label="" :class="whetherReturnEmpty(formData.editDto.universityDescription)">
                <el-input
                  v-model="formData.universityDescription"
                  placeholder="如是，注明与哪个国家合办，以及学制，比如4+0, 3+1, 2+2"
                  size="medium"
                  class="matching-input"
                  :disabled="isEdit"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <h3>硕士就读学校</h3>
          <el-row>
            <el-col :span="7" style="margin-right: 72px;">
              <el-form-item label="硕士就读学校:" label-width="220px" prop="masterSchoolName" :class="whetherReturnEmpty(formData.editDto.masterSchoolName)">
                <el-input
                  v-model="formData.masterSchoolName"
                  placeholder="请输入"
                  size="medium"
                  :disabled="isEdit"
                />
              </el-form-item>
            </el-col>
            <el-col :span="5" style="margin-right: 72px;">
              <el-form-item label="From:" label-width="65px" prop="masterSchoolFrom" :class="whetherReturnEmpty(formData.editDto.masterSchoolFrom)">
                <el-input
                  v-model="formData.masterSchoolFrom"
                  placeholder="请输入"
                  size="medium"
                  :disabled="isEdit"
                />
              </el-form-item>
            </el-col>
            <el-col :span="5">
              <el-form-item label="To:" label-width="50px" prop="masterSchoolTo" :class="whetherReturnEmpty(formData.editDto.masterSchoolTo)">
                <el-input
                  v-model="formData.masterSchoolTo"
                  placeholder="请输入"
                  size="medium"
                  :disabled="isEdit"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="18">
              <el-form-item label="学校地址:" label-width="80px" :class="whetherReturnEmpty(formData.editDto.masterSchoolAddress)">
                <el-input
                  v-model="formData.masterSchoolAddress"
                  placeholder="请输入"
                  size="medium"
                  class="matching-input"
                  :disabled="isEdit"
                />
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="邮政编码:" label-width="110px" :class="whetherReturnEmpty(formData.editDto.masterSchoolPostalCode)">
                <el-input
                  v-model="formData.masterSchoolPostalCode"
                  placeholder="请输入"
                  size="medium"
                  :disabled="isEdit"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="6">
              <el-form-item label="专业：" label-width="89px" prop="masterSchoolMajor" :class="whetherReturnEmpty(formData.editDto.masterSchoolMajor)">
                <el-input
                  v-model="formData.masterSchoolMajor"
                  placeholder="请输入"
                  size="medium"
                  class="matching-input"
                  :disabled="isEdit"
                />
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="学院：" label-width="89px" prop="masterSchoolCollege" :class="whetherReturnEmpty(formData.editDto.masterSchoolCollege)">
                <el-input
                  v-model="formData.masterSchoolCollege"
                  placeholder="请输入"
                  size="medium"
                  class="matching-input"
                  :disabled="isEdit"
                />
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="获得学位：" label-width="156px" prop="masterSchoolDegreeObtained" :class="whetherReturnEmpty(formData.editDto.masterSchoolDegreeObtained)">
                <el-input
                  v-model="formData.masterSchoolDegreeObtained"
                  placeholder="请输入"
                  size="medium"
                  class="matching-input"
                  :disabled="isEdit"
                />
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="辅修：" label-width="89px" prop="masterSchoolMinor" :class="whetherReturnEmpty(formData.editDto.masterSchoolMinor)">
                <el-input
                  v-model="formData.masterSchoolMinor"
                  placeholder="请输入"
                  size="medium"
                  class="matching-input"
                  :disabled="isEdit"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="其他证书:" label-width="80px" :class="whetherReturnEmpty(formData.editDto.masterSchoolOtherCertificates)">
                <el-input
                  v-model="formData.masterSchoolOtherCertificates"
                  placeholder="请输入"
                  size="medium"
                  class="matching-input"
                  :disabled="isEdit"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="7" style="margin-right: 72px;">
              <el-form-item label="平均成绩（GPA）:" label-width="290px" prop="masterSchoolGpaScore" :class="whetherReturnEmpty(formData.editDto.masterSchoolGpaScore)">
                <el-input
                  v-model="formData.masterSchoolGpaScore"
                  placeholder="请输入"
                  size="medium"
                  :disabled="isEdit"
                />
              </el-form-item>
            </el-col>
            <el-col :span="6"  style="margin-right: 72px;">
              <el-form-item label="加权平均分：" label-width="170px" :class="whetherReturnEmpty(formData.editDto.masterSchoolWeightedAvgScore)">
                <el-input
                  v-model="formData.masterSchoolWeightedAvgScore"
                  placeholder="请输入"
                  size="medium"
                  :disabled="isEdit"
                />
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="算数平均分：" label-width="170px" :class="whetherReturnEmpty(formData.editDto.masterSchoolArithmeticAvgScore)">
                <el-input
                  v-model="formData.masterSchoolArithmeticAvgScore"
                  placeholder="请输入"
                  size="medium"
                  :disabled="isEdit"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="硕士学籍系统链接及登录名和密码：" class="labeColor"  label-width="320px" :class="whetherReturnEmpty(formData.editDto.masterSchoolStudentSystem)">
                <el-input
                  v-model="formData.masterSchoolStudentSystem"
                  placeholder="请输入"
                  size="medium"
                  class="matching-input"
                  :disabled="isEdit"

                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="是否是中外合办："  class="labeColor"  label-width="150px" :class="whetherReturnEmpty(formData.editDto.masterSchoolJointProgram)">
                <el-input
                  v-model="formData.masterSchoolJointProgram"
                  placeholder="请输入"
                  size="medium"
                  class="matching-input"
                  :disabled="isEdit"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <p class="smallTitle">硕士就读学校介绍（211、985学校等）专业介绍（实验室、重点项目、制定课题研究等）</p>
              <el-form-item label=""  :class="whetherReturnEmpty(formData.editDto.masterSchoolDescription)">
                <el-input
                  v-model="formData.masterSchoolDescription"
                  placeholder="如是，注明与哪个国家合办，以及学制，比如4+0, 3+1, 2+2"
                  size="medium"
                  class="matching-input"
                  :disabled="isEdit"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <p class="smallTitle">学信网账号密码（针对与做学信网认证的学生）</p>
              <el-form-item label=""  :class="whetherReturnEmpty(formData.editDto.chsiAccount)">
                <el-input
                  v-model="formData.chsiAccount"
                  placeholder=""
                  size="medium"
                  class="matching-input"
                  :disabled="isEdit"
                />
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
    </div>
  </template>

  <script>
  import { wfSaiEducationGetDetailByMaterialId, wfSaiEducationUpdate, wfSaiEducationAdd } from '@/assets/api/application';
  export default {
    name: "Partthree",
    props: {
      type: {
        type: String,
        default: ''
      },
      finalDraft: {
        type: Number,
        default: 0
      },
      id: {
      type: [String, Number],
      default: ''
    },
    },
    data() {
      return {
        countries: ["中国", "美国", "英国"],
        formData: {
          editDto: {}
        },
        isEdit: true,
        rules: {
          // universityName: [
          //   { required: true, message: '请输入', trigger: 'blur' }
          // ],
          // universityFrom: [
          //   { required: true, message: '请输入', trigger: 'blur' }
          // ],
          // universityTo: [
          //   { required: true, message: '请输入', trigger: 'blur' }
          // ],
          // universityMajor: [
          //   { required: true, message: '请输入', trigger: 'blur' }
          // ],
          // universityCollege: [
          //   { required: true, message: '请输入', trigger: 'blur' }
          // ],
          // universityDegreeObtained: [
          //   { required: true, message: '请输入', trigger: 'blur' }
          // ],
          // universityMinor: [
          //   { required: true, message: '请输入', trigger: 'blur' }
          // ],
          // universityOtherCertificates: [
          //   { required: true, message: '请输入', trigger: 'blur' }
          // ],
          // universityGpaScore: [
          //   { required: true, message: '请输入', trigger: 'blur' }
          // ],
          // universityWeightedAvgScore: [
          //   { required: true, message: '请输入', trigger: 'blur' }
          // ],
          // universityArithmeticAvgScore: [
          //   { required: true, message: '请输入', trigger: 'blur' }
          // ],
          // universityStudentSystem: [
          //   { required: true, message: '请输入', trigger: 'blur' }
          // ],
          // universityJointProgram: [
          //   { required: true, message: '请输入', trigger: 'blur' }
          // ],
          // universityDescription: [
          //   { required: true, message: '请输入', trigger: 'blur' }
          // ],
          // masterSchoolName: [
          //   { required: true, message: '请输入', trigger: 'blur' }
          // ],
          // masterSchoolFrom: [
          //   { required: true, message: '请输入', trigger: 'blur' }
          // ],
          // masterSchoolTo: [
          //   { required: true, message: '请输入', trigger: 'blur' }
          // ],
          // masterSchoolMajor: [
          //   { required: true, message: '请输入', trigger: 'blur' }
          // ],
          // masterSchoolCollege: [
          //   { required: true, message: '请输入', trigger: 'blur' }
          // ],
          // masterSchoolDegreeObtained: [
          //   { required: true, message: '请输入', trigger: 'blur' }
          // ],
          // masterSchoolMinor: [
          //   { required: true, message: '请输入', trigger: 'blur' }
          // ],
          // masterSchoolOtherCertificates: [
          //   { required: true, message: '请输入', trigger: 'blur' }
          // ],
          // masterSchoolGpaScore: [
          //   { required: true, message: '请输入', trigger: 'blur' }
          // ]
        },
        isNewEdit: null

      };
    },
    mounted() {},
    created() {
      this.getDetail();
    },
    methods: {
      getDetail() {
          this.loading = true;
          wfSaiEducationGetDetailByMaterialId({ materialId: this.id }).then(res => {
            if (res.code == '2000') {
              this.formData = res.data;
              this.isNewEdit = false;
            } else {
              this.isNewEdit = true;
              this.formData = {
                ...res.data,
              };
            }
          });
      },
      saveInfo(formName) {
      this.$emit('showLoading');
      const url = this.isNewEdit ? wfSaiEducationAdd : wfSaiEducationUpdate;
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
            const data = {
              ...this.formData,
              materialId: this.id,
              userId: localStorage.getItem('userInfo').id
            };
            if (this.isNewEdit == false) {
              data.id = this.formData.id;
            };
            url(data).then(res => {
              if (res.code === '2000') {
                this.$message.success('保存成功');
                this.getDetail();
                this.isEdit = true;
                setTimeout(() => {
                  this.$emit('getDetail');
                }, 2000);
                this.$emit('hideLoading');
              } else {
                this.$message.error(res.msg || '保存失败');
                this.$emit('hideLoading');
              }
            });
        } else {
          this.$message.error('请完善必填信息');
          this.$emit('hideLoading');
          return false;
        }
      });
    },
      goBack() {
          this.$emit('backPage');
      },
      navigateNext() {
          this.$emit('nextStep');
      },
      startEdit() {
          this.isEdit = !this.isEdit;
      },
      stopEdit() {
          this.isEdit = !this.isEdit;
      },
      whetherReturnEmpty(value) {
      if (value && value == 1) {
        return 'changeValue';
      }
    }
    },
  };
  </script>

  <style  lang='stylus' scoped>
  @import '~css/stylus.styl';

  .title {
    font-weight: bold;
    font-size: 18px;
    color: #333333;
    line-height: 32px;
    margin: 21px 0;
    text-align: left;
    display: flex;
    justify-content: space-between;
  }

  .flexs {
    .el-form-item {
      display: flex;
      margin-bottom: 16px;
      align-items: end;

    }
  }

  .contentBox {
    width: 900px;
    margin: 0 auto;

    h3 {
      font-size: 16px;
      color: #333333;
      margin-bottom: 15px;
    }
  }

  >>>.el-form-item__content{
      width:100%
  }

  .tip{
    font-weight: 400;
    font-size: 12px;
    color: #FF8240;
    line-height: 17px;
    margin-bottom: 20px;
  }
  .labeColor{
    >>>.el-form-item__label{
      color: #FF8240;
    }
  }
  .smallTitle{
    font-size: 14px;
    color: #606266;
    margin-bottom: 10px;
  }
  .btnBox{
    display: flex;
    justify-content: center;
  }
  >>>.el-button {
    width: 92px;
    padding: 0 !important;
    height: 30px;
    line-height: 30px;
    border-radius: 15px;
    font-size: 14px;
    font-weight: 400;
  }

  .saveBtn {
    width: 92px;
    height: 30px;
    background: #FFFFFF;
    border-radius: 15px;
    border: 1px solid #FF8240;
    box-sizing: border-box;
    font-size: 14px;
    color: #FF8240;
    text-align: center;
    line-height: 30px;
    cursor: pointer;
    transition: all 0.3s;
    font-weight: 400;

    &:hover {
      background: #fff5f0;
    }
  }
  .changeValue{
    >>>.el-form-item__label{
      color: #FF8240 !important;
    }
    >>>.el-input__inner{
      border-color: #FF8240 !important;
    }
  }
  .ml-10 {
    margin-left: 10px;
  }
  .changeValue{
    >>>.el-form-item__label{
      color: #FF8240 !important;
    }
    >>>.el-input__inner{
      border-color: #FF8240 !important;
    }
  }
  .el-input >>>.el-input__inner{
    padding:0 6px !important;
  }
  </style>