<template>
    <div class="professionalList"  @contextmenu.prevent @copy.prevent  @cut.prevent  @paste.prevent style=" user-select: none;">
      <headerTitle title="首页" :titles="titles"  :showBtn="true" :translate="true" :isBack='isBack' @back="back"></headerTitle>
      <div class="flex-center-column">
        <!--  -->
        <boxHead :schoolId="schoolId"  :isChoose="false"></boxHead>
        <!--  -->
        <div class="professional-list" v-if="isProject">
          <div class="professional-box-title">
            <p>
              <span></span>
              项目
              <a class="links" @click="toColleags">{{ collageInfo.collegeNameCn }}&nbsp;&nbsp;&nbsp;{{ collageInfo.collegeNameEn }}</a>
            </p>
            <span @click="changeExhibit" class="finger flex-center">
              {{ changeTxt }}
              <img :src="changeIcon" alt="" />
            </span>
          </div>
          <div>
            <span>项目名称：</span>
              <el-input
                size="medium"
                placeholder="请输入项目名称"
                v-model="programName"
                class="input-with-button"
                clearable
              >
                <template slot="append">
                  <el-button size="medium" class="el-button-blue" @click="search">
                    搜索
                  </el-button>
                </template>
              </el-input>
            </div>
          <!-- <div class="tabBox flex-wrap">
            <p v-for="(item, index) in tablist" :key="index" :class="{ 'tab-active': item == searchTab }" @click="toSearch(item, index)">{{ item.name }}</p>
          </div> -->
          <div class="majorBox flex-wrap" v-show="changeTxt === '图文'">
            <div v-for="(item, index) in majorList" :key="index" @click="handleRowClicks(item)">
              <p class="major-name">{{ item.programNameCn }}</p>
              <p class="major-names">{{ item.programNameEn }}</p>
              <p class="pro"><span v-for="(items,indexs) in item.majors" :key="indexs">{{ items.majorRankStr }}</span></p>
            </div>
          </div>
          <div v-show="changeTxt === '列表'">
            <el-table :data="majorList" @row-click="handleRowClicks">
              <el-table-column type="index" label="序号" align="center">
                <template slot-scope="scope">{{ (pagenum2 - 1) * pageSize + scope.$index + 1 }}</template>
              </el-table-column>
              <el-table-column label="中文项目名称" prop="programNameCn" align="center"></el-table-column>
              <el-table-column label="英文项目名称" prop="programNameEn" align="center"></el-table-column>
              <!-- <el-table-column label="专业排名" prop="Majorinner" align="center">
                <template slot-scope="scope">

                  <span v-for="(items,indexs) in scope.row.majors" :key="indexs">{{ items.majorRankStr }}</span>
                </template>
              </el-table-column> -->
            </el-table>
          </div>
          <paging :pageall="pageall2" :pagenum="pagenum2" @pageclick="pageclick2" style="margin-top: 20px"></paging>
        </div>
        <div class="professional-list" v-if="isDetail">
          <div class="professional-box-title">
            <p>
              <span></span>
              学院详情
            </p>
          </div>
          <div class="collegaBox">
              <p>
                <span @click="toHome">学院名称（中文）：{{ collageInfo.collegeNameCn }}</span>
                <span @click="toHome">学院名称（英文）：{{ collageInfo.collegeNameEn }}</span>
              </p>
              <p>
                <span v-if="collageInfo.qsRank">QS排名：{{ collageInfo.qsRank }}</span>
                <span v-if="collageInfo.usnewsRank">U.S.News排名：{{ collageInfo.usnewsRank }}</span>
                <span v-if="collageInfo.theRank">The排名：{{ collageInfo.theRank }}</span>
                <span v-if="collageInfo.ruankeRank">ARWU排名：{{ collageInfo.ruankeRank }}</span>
              </p>
              <p v-if="collageInfo.introduction">简介（中文）:{{collageInfo.introduction}}</p>
              <p v-if="collageInfo.introductionEn">简介（英文）：{{ collageInfo.introductionEn }}</p>
          </div>
        </div>
      </div>
    </div>
  </template>

  <script>
  import headerTitle from '@/components/title.vue';
  import paging from '@/components/paging.vue';
  import boxHead from './components/boxHead.vue';
  import { getcmsCollegedetail } from 'api/collegeMatching.js';
  import { getMajorRankList } from 'api/libraryList.js';
  import { mapActions } from 'vuex';
  export default {
    name: 'ProfessionalList',
    components: { headerTitle, paging, boxHead },
    data() {
      return {
        schoolId: '',
        pageall: 0,
        pagenum: 1,
        pageall2: 0,
        pagenum2: 1,
        tablist: [],
        pageSize: 12,
        searchTab: '',
        majorList: [],
        changeTxt: '列表',
        iscollage: true,
        collageInfo: {},
        collageId: '',
        collageNameCn: "",
        collageNameEn: "",
        isDetail: false,
        isProject: true,
        isBack: false,
        isContrast: false,
        programName: "",
        titles: [
          { name: "学院列表", path: "/professionalList?schoolId=" + this.$route.query.schoolId }, // 院校详情
          { name: "项目列表", path: "" },
        ]
      };
    },
    mounted() {
      this.schoolId = this.$route.query.schoolId;
      this.ID = this.$route.query.id;
      this.getMajorList();
      this.toColleag();
    },
    methods: {
      ...mapActions(['trackEvent']),
      getMajorList() {
        const data = {
          universityId: this.schoolId,
          collegeId: this.ID,
          ifPage: true,
          pageSize: 12,
          pageNum: this.pagenum2,
          programName: this.programName
        };
        getMajorRankList(data).then(res => {
          if (res.code == '2000') {
            this.pageall2 = res.data.total || 0;
            this.majorList = res.data.list || [];
            this.majorList.map(item => {
              if (item.majors && item.majors.length > 0) {
                item.Majorinner = item.majors.map(items => items.majorRankStr).join('、');
              }
            });
          } else {
            this.$message({
              type: 'error',
              message: res.msg
            });
          }
        });
      },
      handleRowClick(row, event, column) {
        this.toDetail(row);
        this.iscollage = false;
        this.isProject = true;
        this.isBack = true;
        this.pagenum = 1;
      },
      handleRowClicks(row, event, column) {
         this.$router.push({
          path: `/professionalDetails?id=${row.id}&schoolId=${this.schoolId}`
        });
        this.trackEvent({ action: '项目详情页' });
      },
      toDetail(val) {
        // this.$router.push({
        //   path: `/professionalDetails?id=${val.id}&schoolId=${this.schoolId}`
        // });
        this.collageId = val.id;
        this.getMajorList(this.collageId);
        this.collageId = val.id;
        this.collageNameCn = val.collegeNameCn;
        this.collageNameEn = val.collegeNameEn;
        this.isProject = !this.isProject;
      },
      changeExhibit() {
        this.changeTxt = this.changeTxt === '列表' ? '图文' : '列表';
      },
      pageclick(num) {
        this.pagenum = num;
        this.getList();
      },
      pageclick2(num) {
        this.pagenum2 = num;
        this.getMajorList(this.collageId);
      },
      toSearch(val) {
        this.searchTab = val;
      },
      toColleags() {
        this.isDetail = true;
        this.isProject = false;
      },
      toColleag() {
        const data = {
          id: this.$route.query.id || this.collageId,
        };
        getcmsCollegedetail(data).then(res => {
          if (res.code == '2000') {
            this.collageInfo = res.data || [];
            console.log(this.collageInfo);
          } else {
            this.$message({
              type: 'error',
              message: res.msg
            });
          }
        });
        this.trackEvent({ action: '学院详情页' });
       },
       toHome() {
         window.open(this.collageInfo.homepage, '_blank');
       },
       back() {
        if (this.iscollage) {
            this.isBack = false;
            this.$router.go(-1);
          } else {
            if (this.isProject) {
              this.iscollage = true;
              this.isProject = false;
              this.pagenum = 1;
             this.getList();
              this.pagenum2 = 1;
            } else if (this.isDetail) {
              this.isProject = true;
              this.isDetail = false;
            }
          }
      },
      search() {
        this.pagenum2 = 1;
        this.getMajorList(this.collageId);
      },
    },
    computed: {
      changeIcon() {
        return this.changeTxt === '列表' ? require('@/assets/list.png') : require('@/assets/picture.png');
      }
    }
  };
  </script>
  <style  lang='stylus' scoped>
  @import '~css/stylus.styl';

  .professionalList {
    width: 100%;
    background: #F5F7FA url('../../assets/professionalList.png') no-repeat;
    background-size: contain;
    padding-bottom: $padding-bottom;

    .professional-list {
      box-sizing: border-box;
      width: $width;
      background: #fff;
      border-radius: 12px 12px 12px 12px;
      margin-bottom: 20px;
      padding: 0 20px 45px;
    }

    .professional-list {
      .professional-box-title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 20px 0;
        position:relative;
        .links{
          font-weight: bold;
          font-size: 20px;
          color: $themeColor;
          text-decoration: underline;
          cursor: pointer;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          width:700px;
          text-align:center;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis
        }
        img {
          width: 18px;
          display: inline-block;
          margin-left: 10px;
        }

        p {
          font-weight: bold;
          font-size: 20px;
          color: #333333;
          line-height: 32px;
          display: flex;
          align-items: center;
          &>span {
            line-height: 32px;
            display: inline-block;
            color: $themeColor;
            width: 4px;
            height: 20px;
            background: $themeColor;
            border-radius: 90px 90px 90px 90px;
            margin-right: 8px;
          }
        }
      }
    }

    .tabBox {
      background: #FAFBFC;
      border-radius: 12px 12px 12px 12px;
      margin-bottom: 30px;
      padding: 20px 20px 0;

      p {
        padding: 0 23px;
        text-align: center;
        line-height: 30px;
        background: rgba(207, 214, 226, 0);
        border-radius: 15px 15px 15px 15px;
        border: 1px solid #CCCCCC;
        margin: 0 20px 15px 0;
        cursor: pointer;
      }

      .tab-active {
        background: $themeColor;
        color: #fff;
      }
    }

    .majorBox {
      &>div {
        width: 244px;
        background: linear-gradient( 180deg, #FDFAF6 0%, #FFFFFF 100%);
        border-radius: 14px 14px 14px 14px;
        margin: 0 21px 20px 0;
        padding: 15px;
        cursor: pointer;
        transition: all 0.3s;
      }

      &>div:hover {
        box-shadow: 0px 5px 30px 1px rgba(0, 0, 0, 0.1);

        .major-name {
          color: $themeColor;
        }
      }
      &>div:nth-of-type(4n){
        margin-right: 0;
      }
      .major-name {
        font-weight: bold;
        font-size: 18px;
      }

      >span {
        font-weight: bold;
        color: #FF772E;
      }
      .major-names{
        font-weight: 400;
        font-size: 14px;
        color: #333333;
        margin: 8px 0 9px;
        line-height: 22px;
      }
      .ranking {
        padding: 8px 0;
        font-size: 14px;
        font{
          font-weight: bold;
          color: #FF772E;
        }
      }

      .major-date {
        line-height: 22px;
      }
      .rankbox{
        p{
          display: flex;
          justify-content: space-between;

        }
      }
      .pro{
        font-weight: 400;
        font-size: 14px;
        color: #333333;
        span{
          float: left;
          line-height: 26px;
        }
        span:nth-of-type(2n){
          float: right;
        }
      }
      .pro:after{
        content: '';
        display: block;
        clear: both;
      }

    }
  }
  >>>.el-table__body tr .el-table__cell{
    padding:15px 0 !important;
  }
  .collegaBox{
    p{
      font-weight: 400;
      font-size: 14px;
      color: #333333;
      line-height: 26px;
      margin-bottom: 15px;
      display: flex;
    }
    span{
      margin-right: 40px;
    }
    p:nth-of-type(1){
      span:nth-of-type(1){
        font-weight: 400;
        font-size: 14px;
        color: #5574D8;
        text-decoration-line: underline;
        cursor: pointer;
      }
      span:nth-of-type(2){
        font-weight: 400;
        font-size: 14px;
        color: #5574D8;
        text-decoration-line: underline;
        cursor: pointer;
      }
    }
  }
  .input-with-button {
    :deep .el-input__inner {
      border-right: 0;
    }
    width: 235px;
    margin-bottom: 20px;
    .el-button-blue {
      background: $themeColor;
      color: #fff;
    }
  }
  </style>