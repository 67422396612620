<template>
  <div>
    <el-dialog
      title="GPA计算"
      :visible.sync="dialogVisible"
      width="60%"
      :close-on-click-modal="false"
      v-loading="loading"
    >
      <div class="content">
        <div class="content_left">
            <div class="lefttop">
                <p>课程名称</p>
                <p>
                    <span>成绩：</span>
                    <el-select v-model="achievement">
                        <el-option
                        v-for="item in achievementList"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                        ></el-option>
                    </el-select>
                </p>
                <p>课程学分</p>
            </div>
            <div class="leftbottom">
                <div v-for="(item,index) in gpaScore" :key="index">
                    <div class="listbox">
                        <p><el-input v-model="item.course" placeholder="请输入课程名称"></el-input></p>
                        <p><el-input v-model="item.score" placeholder="请输入成绩"></el-input></p>
                        <p><el-input v-model="item.credit" placeholder="请输入课程学分"></el-input></p>
                    </div>
                </div>
                <el-button icon="el-icon-plus" class="addBtn" @click="addList"></el-button>
            </div>
        </div>
        <div class="contetn_right">
            <p><span>上传模板</span><span><a
                class="btnColor"
                @click="downLoadFileClick('https://file.chaogexitongban.net/others/app/18503e749cb3c4359f7f3b546a704e4e.xlsx','GPA计算模板')"

              >
                下载导入模板
              </a></span></p>
            <div class="uploadBox">
                <el-upload
                class="upload-demo"
                drag
                action=""
                :multiple="false"
                :show-file-list="false"
                :http-request="toChannelRequest"
                 accept=".xls, .xlsx"
                >
                <div>
                    <font>
                        <img src="../assets/file_icon.png" alt="">
                        <p v-if="!toChannelName">仅支持Excel格式</p>
                    </font>
                    <div v-if="toChannelName" class="el-upload__text" >{{ toChannelName }}</div>
                    <el-button v-if="!toChannelName" class="btnColor" style="font-weight: bold;" type="text">上传文件</el-button>
                    <el-button v-else style="font-weight: bold;" type="text">重新上传</el-button>

                </div>

                </el-upload>
            </div>
            <el-form :model="form" label-width="70px" label-position="left">
                <el-form-item label="姓名：">
                    <el-input v-model="form.orderUserName" placeholder="请输入姓名"></el-input>
                </el-form-item>
                <el-form-item label="手机号：">
                    <el-input v-model="form.orderUserMobile" placeholder="请输入手机号"></el-input>
                </el-form-item>
            </el-form>
            <p class="tips">支付完成后，会有客服专员联系您并为您进行服务，以保证您的付费体验!</p>
        </div>
      </div>
      <div class="footbtn">
        <p @click="dialogVisible = false">取消</p>
        <p  @click="send">支付10元开始计算</p
        >
      </div>
    </el-dialog>
    <el-dialog
        :visible.sync="showQr"
        width="20%">
        <img :src="Qrcode" alt="" style="width: 80%;margin:0 auto;display: block;">
        <p style="text-align: center;font-size: 16px;padding-bottom: 20px">请使用微信扫码进行支付</p>
    </el-dialog>
  </div>
</template>

<script>
import { submitOrder, Orderdetail, uploadFile } from 'api/index.js';
import { downloadFile } from "../assets/js/index.js";
export default {
    name: 'GPAcalculation',
    components: {},
    data() {
        return {
            dialogVisible: false,
            form: {
                orderUserName: '',
                orderUserMobile: ''
            },
            achievementList: [
                {
                    value: '1',
                    label: '百分制'
                },
                {
                    value: '2',
                    label: '五分制'
                },
                {
                    value: '3',
                    label: '等级制'
                },

            ],
            achievement: '',
            gpaScore: [
                {
                    course: '',
                    score: '',
                    credit: ''
                },
            ],
            fileList: [],
            Qrcode: "https://file.chaogexitongban.net/images/gpa/da271d004be749f96d50f34a284684d0.jpg",
            showQr: false,
            timer1: null,
            loading: false,
            toChannelName: '',
            gpaScoreFile: ''
        };
    },
    methods: {
        openDialog() {
            this.dialogVisible = !this.dialogVisible;
        },
        addList() {
            this.gpaScore.push({
                name: '',
                score: '',
                credit: ''
            });
            console.log(this.listContent);
        },
        send() {
            this.loading = true;
            const data = {
                orderUserName: this.form.orderUserName,
                orderUserMobile: this.form.orderUserMobile,
                gpaScore: this.gpaScore,
                orderType: 1,
                cmsUserId: localStorage.getItem('userInfo').id,
                gpaScoreFile: this.gpaScoreFile
            };
            data.gpaScore = JSON.stringify(data.gpaScore);
            submitOrder(data).then(res => {
                if (res.code == '2000') {
                    this.Qrcode = res.data.qrcode;
                    this.showQr = true;
                    this.loading = false;
                    this.timer1 = setInterval(() => {
                            this.paySuccess(res.data.orderId);
                        }, 1000);
                } else {
                this.$message({
                    type: 'error',
                    message: res.msg
                });
                }
            });
    },
    paySuccess(ids) {
        const data = {
            id: ids
        };
        Orderdetail(data).then(res => {
                if (res.code == '2000' && res.data.orderStatus != 0) {
                    this.$message({
                        type: 'success',
                        message: '支付成功'
                    });
                    this.dialogVisible = false;
                    this.showQr = false;
                    clearInterval(this.timer1);
                } else {

                }
            }).catch(error => {
                // 添加网络请求异常的处理逻辑
                console.error('Orderdetail request failed:', error);
                this.$message({
                    type: 'error',
                    message: '订单查询异常，请检查网络或稍后再试'
                });
            });
    },
    toChannelRequest(files) {
      const file = files.file;
      this.toChannelName = file.name;
      const fromData = new FormData();
      fromData.append('file', file);
      uploadFile(fromData).then(res => {
        if (res.code == 2000) {
          this.$message({
            type: 'success',
            message: res.msg
          });
          this.gpaScoreFile = res.data;
        } else {
          this.$message({
            type: 'error',
            message: res.msg
          });
        }
      });
    },
    downLoadFileClick(url, name) {
            downloadFile(url, name)
                .then((res) => {
                console.log(res);
                })
                .catch((err) => {
                console.log(err);
                });
            },
},
};
</script>

<style lang='stylus' scoped>
@import '~css/stylus.styl';

>>>.el-dialog__body{
    padding: 0
}
.content{
    border-top:1px solid #eee;
    border-bottom:1px solid #eee;
    display: flex;
    .content_left{
        width: 58%;
        padding:16px 20px;
        border-right: 1px solid #eee;
        .lefttop{
            display: flex;
            justify-content: space-between;
            align-items: center;
            box-sizing: border-box;
            p{
                font-weight: bold;
                font-size: 14px;
                color: #666666;
                width:31%;
                display: flex;
                align-items: center;
                span{
                    width:73px;
                    display: block;
                }
            }
        }
    }
    .leftbottom{
        margin-top: 10px;
        .listbox{
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 15px
            p{
                width:31%;
            }
        }
    }
}
.footbtn{
    display: flex;
    justify-content: center;
    padding: 21px;
    box-sizing: border-box;
    align-items: center;
    p{
        padding: 15px 21px;
        color:#fff;
        font-size: 16px;
        border-radius: 90px;
        width:16%;
        text-align: center;
        cursor:pointer;
    }
    p:nth-of-type(1){
        background: #ACACAC;
        margin-right: 21px;
    }
    p:nth-of-type(2){
        background: $themeColor;
    }
}
.addBtn{
    height: 35px;
    border-radius: 6px 6px 6px 6px;
    border: 1px solid $themeColor;
    line-height: 35px;
    padding: 0;
    text-align: center;
    width:100%;
    color:$themeColor;
    >>>.el-icon-plus{
        font-size: 16px;
        font-weight: bold;
    }
}
.el-button:focus, .el-button:hover{
    background-color:#ff824012;
}
.contetn_right{
    width: 42%;
    padding: 20px;
    box-sizing: border-box;
    >p{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 17px;
        span:nth-of-type(1){
            font-weight: bold;
            font-size: 14px;
            color: #666666;
        }
        span:nth-of-type(2){
            font-size: 12px;
            color: #2797FF;
            cursor: pointer;
        }
    }
    >>>.el-upload{
        display: block;
    }
    >>>.el-upload-dragger{
        width: 100%;
        height: 62px;
        background: #FFFFFF;
        border-radius: 8px 8px 8px 8px;
        border: 1px solid #CCCCCC;
        padding:14px;
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
        div{
            display: flex;
            align-items: center;
            justify-content: space-between;
            width:100%;

            font{
                display: flex;
                align-items: center;
            }
            img{
                width: 38px;
                display: block;
                margin-right: 10px;
            }
            p{
                font-size: 13px;
                color: #666666;
            }
            .el-button{
                font-weight: bold;
                font-size: 14px;
            }
        }

    }
    .tips{
        padding: 7px 17px 9px 13px;
        background: #ff824017;
        border-radius: 6px 6px 6px 6px;
        font-size: 12px;
        color: #FF8240;
        line-height: 20px;
    }
    .btnColor{
        color:$themeColor;
    }
}

</style>