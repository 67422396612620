<template>
  <div>
    <el-dialog
      :visible.sync="show"
      width="80%"
      :show-close="false"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <!-- title slot -->
      <div slot="title">
        <div class="headerBigBox">
          <!-- <div class="headerText">项目排名对比</div> -->
          <div class="headerButtonBox">
            <!--  -->
            <div class="headerButton" @click="cancelClick">
              <i class="el-icon-close"></i>
            </div>
          </div>
        </div>
      </div>
      <!-- search -->
      <div class="searchBigBox">
        <el-form :inline="true" :model="searhObj" class="demo-form-inline">
          <el-form-item label="排名年份：">
            <el-select
              v-model="searhObj.rankYear"
              placeholder="请选择排名年份"
              @change="yearChange"
            >
              <el-option
                v-for="(item, index) in yearList"
                :key="index"
                :label="item.name"
                :value="item.name"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <span style="color: #999999">
              提示：如果切换年份将会清空列表数据!
            </span>
          </el-form-item>
        </el-form>
      </div>
      <!-- content -->
      <div class="contentBigBox">
        <el-table
          :data="comparisonList"
          stripe
          style="width: 100%"
          row-key="id"
        >
          <el-table-column type="index" label="序号" width="55" align="center">
          </el-table-column>
          <el-table-column
            prop="name"
            label="学校名称"
            align="center"
            width="220"
          >
            <template slot-scope="scope">
              <el-select
                v-model="scope.row.schoolId"
                filterable
                remote
                placeholder="请输入学校名称"
                :remote-method="
                  (name) => {
                    schoolRemoteMethod(scope.row, name);
                  }
                "
                :loading="scope.row.schoolLoading"
                @change="
                  (id) => {
                    schoolChange(id, scope.$index);
                  }
                "
              >
                <el-option
                  v-for="item in scope.row.schoolList"
                  :key="item.universityId"
                  :label="item.nameCn"
                  :value="item.universityId"
                >
                </el-option>
              </el-select>
            </template>
          </el-table-column>
          <el-table-column
            prop="schoolRankCollect"
            label="学校排名"
            align="center"
            min-width="200"
          >
            <template slot-scope="scope">
              <div class="rankingBox">
                <div
                  class="ranking"
                  v-for="(item, index) in scope.row.schoolRankCollect"
                  :key="index"
                >
                  {{ item }}
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="date"
            label="学院名称"
            align="center"
            width="220"
          >
            <template slot-scope="scope">
              <el-select
                v-model="scope.row.collegeId"
                filterable
                remote
                placeholder="请输入学院名称"
                :remote-method="
                  (name) => {
                    collegeRemoteMethod(scope.row, name);
                  }
                "
                :loading="scope.row.collegeLoading"
                @change="
                  (id) => {
                    collegeChange(id, scope.$index);
                  }
                "
                v-if="scope.row.schoolId"
              >
                <el-option
                  v-for="item in scope.row.collegeList"
                  :key="item.collegeId"
                  :label="item.nameCn"
                  :value="item.collegeId"
                >
                </el-option>
              </el-select>
            </template>
          </el-table-column>
          <el-table-column
            prop="collegeRankCollect"
            label="学院排名"
            align="center"
            min-width="200"
          >
            <template slot-scope="scope">
              <div class="rankingBox">
                <div
                  class="ranking"
                  v-for="(item, index) in scope.row.collegeRankCollect"
                  :key="index"
                >
                  {{ item }}
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="address"
            label="项目名称"
            align="center"
            width="220"
          >
            <template slot-scope="scope">
              <el-select
                v-model="scope.row.projectId"
                filterable
                remote
                placeholder="请输入项目名称"
                :remote-method="
                  (name) => {
                    projectRemoteMethod(scope.row, name);
                  }
                "
                :loading="scope.row.projectLoading"
                @change="
                  (id) => {
                    projectChange(id, scope.$index);
                  }
                "
                v-if="scope.row.collegeId"
              >
                <el-option
                  v-for="item in scope.row.projectList"
                  :key="item.id"
                  :label="item.programNameCn"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </template>
          </el-table-column>
          <el-table-column
            prop="date"
            label="项目排名"
            align="center"
            min-width="200"
          >
            <template slot-scope="scope">
              <div class="rankingBox">
                <div
                  class="ranking"
                  v-for="(item, index) in scope.row.majorsList"
                  :key="index"
                >
                  {{ item.majorRankStr }}
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="90" align="center" fixed="right">
            <template slot-scope="scope">
              <el-button
                type="text"
                style="color: #FF8240"
                @click="deleteOnceList(scope.$index)"
              >
                移除
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <div class="addListButton" @click="addOnceList">
          <i
            class="el-icon-plus"
            style="font-weight: bold; font-size: 18px; color: #FF8240"
            v-if="searhObj.rankYear"
          ></i>
          <span v-else>请先选择排名年份</span>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import {
  getSchoolRankingComparison,
  getCollegeRankingComparison,
  getProjectRankingComparison
} from 'api/rankingComparison.js';
export default {
  name: 'ProjectRankingComparison',
  props: {
    year: [String, Number],
    show: Boolean,
    yearList: Array
  },
  data() {
    return {
      searhObj: {
        rankYear: ''
      },
      comparisonList: []
    };
  },
  methods: {
    cancelClick() {
      this.$emit('update:show', false);
      this.$emit('cancel');
    },
    // 选中年份并清空列表
    yearChange() {
      this.comparisonList = [];
    },
    // 删除一条数据
    deleteOnceList(index) {
      this.$delete(this.comparisonList, index);
    },
    // 增加一条数据
    addOnceList() {
      if (!this.searhObj.rankYear) {
        this.$message({
          type: 'warning',
          message: '请先选择排名年份!!!'
        });
        return;
      }
      this.comparisonList.push({
        schoolList: [],
        schoolLoading: false,
        collegeList: [],
        collegeLoading: false,
        projectList: [],
        projectLoading: false,
        majorsList: [],
        schoolId: '',
        collegeId: '',
        projectId: ''
      });
    },
    // 获取学校
    schoolRemoteMethod(item, schoolName = '') {
      // item-对比列表中的单条数据对象
      // schoolName-搜索输入的学校名称
      // 获取到学校列表后以this.$set(item,'schoolList',list)赋值
      this.$set(item, 'schoolLoading', true);
      getSchoolRankingComparison({
        searchKey: schoolName,
        ...this.searhObj
      })
        .then((res) => {
          this.$set(item, 'schoolLoading', false);
          if (res.code == 2000) {
            this.$set(item, 'schoolList', res.data);
          } else {
            this.$message({
              type: 'error',
              message: res.msg
            });
          }
        })
        .catch(() => {
          this.$set(item, 'schoolLoading', false);
        });
    },
    // 选中学校并获取学校下学院列表
    schoolChange(schoolId, index) {
      // schoolId-学校id
      // index-对比列表下标

      // 清空当前数据已选中学院、项目及其排名信息start
      this.comparisonList[index].collegeList = [];
      this.comparisonList[index].collegeRankCollect = [];
      this.comparisonList[index].collegeId = '';
      this.comparisonList[index].projectList = [];
      this.comparisonList[index].majorsList = [];
      this.comparisonList[index].projectId = '';
      // 清空当前数据已选中学院、项目及其排名信息end
      this.getCollegeList(
        schoolId,
        () => {
          this.$set(this.comparisonList[index], 'collegeLoading', true);
        },
        () => {
          this.$set(this.comparisonList[index], 'collegeLoading', false);
        },
        (list) => {
          this.$set(this.comparisonList[index], 'collegeList', list);
        }
      );
      const schoolList = this.comparisonList[index].schoolList;
      for (let i = 0; i < schoolList.length; i++) {
        if (schoolId == schoolList[i].universityId) {
          this.$set(
            this.comparisonList[index],
            'schoolRankCollect',
            schoolList[i].rankCollect
              ? schoolList[i].rankCollect.split(',')
              : []
          );
          break;
        }
      }
    },
    // 获取学院
    getCollegeList(
      schoolId,
      loadingStart,
      loadingStop,
      callback,
      collegeName = ''
    ) {
      // schoolId-接口入参-学校id
      // loadingStart-loading开始方法
      // loadingStop-loading停止方法
      // callback-用于对指定列表赋值,获取到学院列表后调用并将获取到的列表作为参数传入
      // collegeName-接口入参-搜索输入的学院名称
      loadingStart();
      getCollegeRankingComparison({
        universityId: schoolId,
        searchKey: collegeName,
        ...this.searhObj
      })
        .then((res) => {
          loadingStop();
          if (res.code == 2000) {
            console.log(res.data);
            callback(res.data);
          } else {
            this.$message({
              type: 'error',
              message: res.msg
            });
          }
        })
        .catch(() => {
          loadingStop();
        });
    },
    // 搜索学院
    collegeRemoteMethod(item, collegeName = '') {
      this.getCollegeList(
        item.schoolId,
        () => {
          this.$set(item, 'collegeLoading', true);
        },
        () => {
          this.$set(item, 'collegeLoading', false);
        },
        (list) => {
          this.$set(item, 'collegeList', list);
        },
        collegeName
      );
    },
    // 选中学院并获取项目列表数据
    collegeChange(collegeId, index) {
      // collegeId-学院id
      // index-对比列表下标
      // 清空当前数据已选中项目及其排名信息start
      this.comparisonList[index].projectList = [];
      this.comparisonList[index].majorsList = [];
      this.comparisonList[index].projectId = '';
      // 清空当前数据已选中项目及其排名信息end
      this.getProjectList(
        collegeId,
        () => {
          this.$set(this.comparisonList[index], 'projectLoading', true);
        },
        () => {
          this.$set(this.comparisonList[index], 'projectLoading', false);
        },
        (list) => {
          this.$set(this.comparisonList[index], 'projectList', list);
        }
      );
      const collegeList = this.comparisonList[index].collegeList;
      for (let i = 0; i < collegeList.length; i++) {
        if (collegeId == collegeList[i].collegeId) {
          this.$set(
            this.comparisonList[index],
            'collegeRankCollect',
            collegeList[i].rankCollect
              ? collegeList[i].rankCollect.split(',')
              : []
          );
          break;
        }
      }
    },
    // 获取项目列表
    getProjectList(
      collegeId,
      loadingStart,
      loadingStop,
      callback,
      projectName = ''
    ) {
      // collegeId-接口入参-学院id
      // loadingStart-loading开始方法
      // loadingStop-loading停止方法
      // callback-用于对指定列表赋值,获取到项目列表后调用并将获取到的列表作为参数传入
      // projectName-接口入参-搜索输入的项目名称
      loadingStart();
      getProjectRankingComparison({
        ifPage: false,
        collegeId: collegeId,
        programName: projectName,
        ...this.searhObj
      })
        .then((res) => {
          loadingStop();
          if (res.code == 2000) {
            console.log(res);
            callback(res.data.list || []);
          } else {
            this.$message({
              type: 'error',
              message: res.msg
            });
          }
        })
        .catch(() => {
          loadingStop();
        });
    },
    // 搜索项目
    projectRemoteMethod(item, projectName = '') {
      this.getProjectList(
        item.collegeId,
        () => {
          this.$set(item, 'projectLoading', true);
        },
        () => {
          this.$set(item, 'projectLoading', false);
        },
        (list) => {
          this.$set(item, 'projectList', list);
        },
        projectName
      );
    },
    // 选中项目并对专业排名赋值
    projectChange(projectId, index) {
      // projectId-项目id
      // index-对比列表下标
      // 以this.$set(this.comparisonList[index],'列表展示-专业排名字段名',item.数据对象中的-专业排名字段名)对专业排名赋值赋值
      for (let i = 0; i < this.comparisonList[index].projectList.length; i++) {
        if (projectId == this.comparisonList[index].projectList[i].id) {
          this.$set(
            this.comparisonList[index],
            'majorsList',
            this.comparisonList[index].projectList[i].majors
          );
          break;
        }
      }
      console.log(this.comparisonList[index]);
    }
  },
  created() {
    this.$set(this.searhObj, 'rankYear', this.year);
  }
};
</script>
<style lang="stylus" scoped>
.headerBigBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: bold;
  font-size: 18px;
  color: #666666;

  .headerButton {
    cursor: pointer;
  }
}

.addListButton {
  width: 100%;
  height: 52px;
  border: 1px solid #EEEEEE;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.rankingBox {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;

  .ranking {
    margin-bottom: 12px;
    margin-right: 18px;
  }
}
.el-select-dropdown__item.selected{
  color: #FF8240 !important;
}
</style>