<template>
  <div class="flex-justify-center">
    <div class="box">
      <div class="flex-center logoBox">
        <a @click="toHome"><img src="https://jyt-new.oss-cn-beijing.aliyuncs.com/other/logo.png" alt=""/></a>
        <div class="tab-list finger">
          <div v-for="(item, index) in tabList" :key="index" @click="toPage(item.path, index)" :class="{activeCss:isActive(item.path)}">
            <p>{{ item.name }}</p>
            <span></span>
          </div>
        </div>
      </div>
      <div class="box_r flex-center">
        <div class="remind" @click="showRemind">
          <el-badge :is-dot="isNewMessage">
            <i class="el-icon-bell" ></i>
          </el-badge>
            消息提醒
          </div>
        <p v-if="!token" class="loginText finger" @click="onLogin">登录</p>
        <div class="flex-center info" style="cursor: pointer;" v-else >

          <img :src="userInfo.headLogo" @click="topersonalCenter" alt="" style="border-radius: 50%;" />
          <p>用户：{{ userInfo.name }}</p>
          <div class="menuList">
            <span class="triangle-up"></span>
             <p @click="routerTo('/personalData')">个人资料</p>
             <p @click="routerTo('myCollection')">我的收藏</p>
             <p @click="routerTo('/myOrder')">我的订单</p>
             <p v-if="userInfo.userType == 0" @click="routerTo('/crmArchives')">我的档案</p>
             <p @click="loginOut">退出登录</p>
          </div>
        </div>
      </div>
    </div>
  <Message ref="Message" ></Message>
  </div>
</template>

<script>
// import { api } from "@/api/api";
// import Cookies from 'js-cookie';
import { queryYesNoHaveNoRead } from '@/assets/api';
import Message from './Message.vue';
import { mapActions, mapGetters } from 'vuex';

export default {
  components: { Message },
  name: 'Header',
  data() {
    return {
      tabList: [
        {
          name: '首页',
          path: '/'
        },
        {
          name: '院校匹配',
          path: '/collegeMatching'
        },
        // {
        //   name: '案例分享',
        //   path: '/caseShare'
        // },
        {
          name: '超哥资讯',
          path: '/ininformational'
        },
        {
          name: 'Q&A',
          path: '/readQuestions'
        }
      ],
      tabIndex: 0,
      isShow: false,
      pollingInterval: null,
      isNewMessage: false
    };
  },
  created() {},

  computed: {
    ...mapGetters(['token', 'userInfo'])
  },
  mounted() {
    if (this.token && this.userInfo) {
      this.startPollingForMessages();
    }
  },
  methods: {
    ...mapActions(['trackEvent', 'logout']),
    isActive(routePath) {
      return this.$route.path === routePath;
    },
    onLogin() {
      this.$store.dispatch('callSetIshow', true);
    },
    toPage(path, index) {
      this.toNext(path);
      this.tabIndex = index;
      this.startPollingForMessages();
      console.log(path);
      if (path == '/ininformational') {
        this.trackEvent({ action: '超哥资讯页' });
      }
      // this.$store.dispatch('callSetVerifySendVerification', {
      //   self: this,
      //   fun: this.toNext,
      //   verifyArguments: path
      // });
    },
    toNext(val) {
      this.$router.push({
        path: val
      });
    },
    topersonalCenter() {
      this.$router.push({
        path: '/personalData'
      });
    },
    toHome() {
      this.$router.push({
        path: '/'
      });
    },
    routerTo(url) {
      this.$router
        .push({
          path: url
        })
        .then(() => {})
        .catch(() => {});
    },
    async loginOut() {
      try {
        await this.$confirm('确定退出登录?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        });
        // 调用 Vuex action 进行退出登录
        await this.logout();
        // 清除轮询定时器
        clearInterval(this.pollingInterval);
        // 跳转到首页
        await this.$router.push('/');
        // 刷新页面
        window.location.reload();
      } catch (error) {
        if (error !== 'cancel') {
          console.error('退出登录失败:', error);
          this.$message.error('退出登录失败，请重试');
        }
      }
    },
    showRemind() {
      this.$refs.Message.openDialog();
    },
    startPollingForMessages() {
        this.checkForNewMessages();
    },
    checkForNewMessages() {
        // 发送请求检查新消息
        const data = {
          userId: this.userInfo.id
        };
        queryYesNoHaveNoRead(data).then(res => {
          if (res.code == '2000') {
            this.isNewMessage = true;
          } else {
            this.isNewMessage = false;
          }
        }).catch(error => {
          console.log('请求失败', error);
        });
    },
  }
};
</script>

<style lang="stylus" scoped>
@import '~css/stylus.styl';

.box {
  width: $width;
  background: #fff;
  display: flex;
  justify-content: space-between;
  height: 50px;
  box-sizing: border-box;
}

.el-message-box {
  width: 100% !important;
}

.logoBox {
  img {
    width: 37px;
  }

  .tab-list {
    margin-left: 20px;
    display: flex;
    align-items: center;
    height: 100%;

    &>div {
      color: #303243;
      margin-left: 50px;
      height: 100%;
      display: flex;
      align-items: center;
      position: relative;
    }

    p {
      font-weight: 400;
      color: #303243;
    }

    span {
      width: 100%;
      display: inline-block;
      height: 3px;
      background: #fff;
      border-radius: 50px 50px 50px 50px;
      position: absolute;
      bottom: 0;
    }

    .activeCss {
      p {
        color: $themeColor;
        font-weight: bold;
      }

      span {
        background: $themeColor;
      }
    }
  }
}

.loginText {
  color: $themeColor;
}
.box_r{
  div {
    color: #666666
    img{
      width: 30px;
      height: 30px;
      margin-right: 10px
    }
  }
}
.info{
  position: relative;
  height 100%

}
.info:hover{
  .menuList{
    display: block;
  }
}
.menuList{
  position: absolute;
  top: 52px;
  left: -43px;
  width: 120px;
  padding:11px 0;
  text-align: center;
  background: #fff;
  z-index: 999;
  border-radius: 8px;
  box-shadow: 0px 5px 30px 1px rgba(0,0,0,0.1);
  display: none;
  p{
    height: 45px;
    line-height: 45px;
    transition: all .3s;
    font-size: 14px;
    color: #666666;
  }
  p:hover{
    background: #FFEFE6;
    color: $themeColor
  }
  .triangle-up{
    width: 0;
    height: 0;
    border-left: 11.5px solid transparent; /* 左边框宽度约为底边宽度的一半 */
    border-right: 11.5px solid transparent; /* 右边框宽度也约为底边宽度的一半 */
    border-bottom: 12px solid #fff;
    position: absolute;
    top:-12px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 99999;
  }
}
.remind{
  margin-right: 45px;
  cursor: pointer;
}
.activeimg{

}
</style>