<template>
    <div>
      <div class="title">第二部分：申请人语言测试信息
        <div class="btnBox">
          <!-- <el-button class="saveBtn" plain round @click="goBack">返回上一页</el-button> -->
          <template v-if="isEdit && type!='detail' && finalDraft!=1">
            <el-button type="primary" class="ml-10" round @click="startEdit">开始修改</el-button>
            <!-- <el-button type="primary" class="ml-10" round @click="navigateNext">跳转下一页</el-button> -->
          </template>
          <template v-else-if="type!='detail' && !isEdit">
            <p class="saveBtn ml-10" v-show="!isEdit"  @click="stopEdit">退出修改</p>
            <el-button type="primary" class="ml-10" round @click="saveAndNavigate">确认修改</el-button>
          </template>
      </div>
      </div>
      <div class="contentBox">
        <h3>A: 考试注册信息：(TOEFL, IELTS, GRE, GMAT, SAT, ACT等语言考试和学术考试）</h3>
        <el-table :data="a" border>
            <el-table-column prop="examType" label="考试类型"  align="center">
                <template slot-scope="scope">
                    <el-input  :class="whetherReturnEmpty(scope.row.editDto.examType)" v-model="scope.row.examType" :disabled="isEdit" placeholder="请输入考试类型"></el-input>
                </template>
            </el-table-column>
            <el-table-column prop="username" label="用户名"  align="center">
                <template slot-scope="scope">
                    <el-input :class="whetherReturnEmpty(scope.row.editDto.username)" v-model="scope.row.username" :disabled="isEdit" placeholder="请输入用户名"></el-input>
                </template>
            </el-table-column>
            <el-table-column prop="pwd" label="密码"  align="center">
                <template slot-scope="scope">
                    <el-input :class="whetherReturnEmpty(scope.row.editDto.pwd)" v-model="scope.row.pwd" :disabled="isEdit" placeholder="请输入密码"></el-input>
                </template>
            </el-table-column>
            <el-table-column prop="address" label="备注"  align="center">
                <template slot-scope="scope">
                    <el-input :class="whetherReturnEmpty(scope.row.editDto.remark)" v-model="scope.row.remark" :disabled="isEdit" placeholder="请输入备注"></el-input>
                </template>
            </el-table-column>
            <el-table-column prop="address" label="移除"  align="center" width="70">
                <template slot-scope="scope">
                    <i class="el-icon-remove delClass" v-if="!isEdit" @click="delayRow(scope.row)"></i>
                </template>
            </el-table-column>
        </el-table>
        <div class="container"><el-button class="addBtnClass" round plain @click="addRow" :disabled="isEdit">+添加一行</el-button></div>
        <h3>B：语言成绩</h3>
        <el-table
              :data="b"
              style="width: 100%;margin-top: 10px;">
              <el-table-column
                prop="languageType"
                label="语言成绩类型"
                align="center"
                >
                <template slot-scope="scope">
                  <el-select
                        v-model="scope.row.languageType"
                        placeholder="请选择语言成绩"
                        :disabled="type == 'detail'"
                      >
                        <el-option
                          v-for="item in languageAchievement"
                          :key="item.code"
                          :label="item.name"
                          :value="item.name"
                          :disabled="getLanguageDisabled(item.name)"
                        >
                        </el-option>
                      </el-select>
                </template>
              </el-table-column>
              <el-table-column
                prop="totalPoints"
                label="Total points（总分）"
                align="center"
                width="168px"
                >
                <template slot-scope="scope">
                    <el-input :class="whetherReturnEmpty(scope.row.editDto.totalPoints)" v-model="scope.row.totalPoints" :disabled="isEdit" placeholder="请输入总分"></el-input>
                </template>
              </el-table-column>
              <el-table-column label="细项" align="center">
                <el-table-column
                  prop="points1"
                  align="center"
                  label="Listening（听）">
                  <template slot-scope="scope">
                    <el-input :class="whetherReturnEmpty(scope.row.editDto.points1)" v-model="scope.row.points1" :disabled="isEdit" placeholder="请输入成绩"></el-input>
                </template>
                </el-table-column>
                <el-table-column
                  prop="points1"
                  align="center"
                  label="Speaking（说）">
                  <template slot-scope="scope">
                    <el-input :class="whetherReturnEmpty(scope.row.editDto.points2)" v-model="scope.row.points2" :disabled="isEdit" placeholder="请输入成绩"></el-input>
                </template>
                </el-table-column>
                <el-table-column
                  prop="points3"
                  align="center"
                  label="Reading（读）">
                  <template slot-scope="scope">
                    <el-input :class="whetherReturnEmpty(scope.row.editDto.points3)" v-model="scope.row.points3" :disabled="isEdit" placeholder="请输入成绩"></el-input>
                </template>
                </el-table-column>
                <el-table-column
                  prop="points4"
                  align="center"
                  label="Writing（写）">
                  <template slot-scope="scope">
                    <el-input :class="whetherReturnEmpty(scope.row.editDto.points4)" v-model="scope.row.points4" :disabled="isEdit" placeholder="请输入成绩"></el-input>
                </template>
                </el-table-column>
                <el-table-column prop="address" label="移除"  align="center" width="70">
                <template slot-scope="scope">
                    <i class="el-icon-remove delClass" v-if="!isEdit" @click="delayRow2(scope.row)"></i>
                </template>
            </el-table-column>
              </el-table-column>
            </el-table>
            <div class="container"><el-button class="addBtnClass" round plain @click="addRow2" :disabled="isEdit">+添加一行</el-button></div>
            <h3>C：标准学术考试成绩：</h3>
            <el-table
              :data="c"
              style="width: 100%;margin-top: 10px;"
              border
              >
              <el-table-column
                prop="languageType"
                label="语言成绩类型"
                align="center"
                >
                <template slot-scope="scope">
                  <el-select
                        v-model="scope.row.languageType"
                        placeholder="请选择语言成绩"
                        :disabled="type == 'detail'"
                        @change="changeAcademic(scope.row,scope.$index)"
                      >
                        <el-option
                          v-for="item in academicList"
                          :key="item.code"
                          :label="item.name"
                          :value="item.name"
                          :disabled="getLanguageDisabled2(item.name)"

                        >
                        </el-option>
                      </el-select>
                </template>
              </el-table-column>
              <el-table-column
                prop="totalPoints"
                label="Total points（总分）"
                align="center"
                width="168px"

                >
                <template slot-scope="scope">
                    <el-input :class="whetherReturnEmpty(scope.row.editDto.totalPoints)" v-model="scope.row.totalPoints" :disabled="isEdit" placeholder="请输入总分"></el-input>
                </template>
              </el-table-column>
              <el-table-column
              :show-header="false"
                prop="points1"
                align="center"
                label="细项1">
                <template slot-scope="scope">
                    <el-input :class="whetherReturnEmpty(scope.row.editDto.points1)" v-model="scope.row.points1" :disabled="isEdit || scope.row.languageType=='LSAT'" placeholder="请输入成绩"></el-input>
                </template>
              </el-table-column>
              <el-table-column
                prop="points2"
                align="center"
                :show-header="false"
                label="细项2">
                <template slot-scope="scope">
                    <el-input :class="whetherReturnEmpty(scope.row.editDto.points2)" v-model="scope.row.points2" :disabled="isEdit || scope.row.languageType=='LSAT'" placeholder="请输入成绩"></el-input>
                </template>
              </el-table-column>
              <el-table-column
                prop="points3"
                align="center"
                :show-header="false"
                label="细项3">
                <template slot-scope="scope">
                    <el-input :class="whetherReturnEmpty(scope.row.editDto.points3)" v-model="scope.row.points3" :disabled="isEdit || scope.row.languageType=='LSAT'" placeholder="请输入成绩"></el-input>
                </template>
              </el-table-column>
              <el-table-column prop="address" label="移除"  align="center" width="70">
                <template slot-scope="scope">
                    <i class="el-icon-remove delClass" v-if="!isEdit" @click="delayRow3(scope.row)"></i>
                </template>
            </el-table-column>
            </el-table>
        <!-- <h3>B：考试信息</h3>
        <el-table :data="b" border>
            <el-table-column prop="examType" label="考试类型"  align="center">
                <template slot-scope="scope">
                    <el-input :class="whetherReturnEmpty(scope.row.editDto.examType)" v-model="scope.row.examType" :disabled="isEdit" placeholder="请输入考试类型"></el-input>
                </template>
            </el-table-column>
            <el-table-column prop="examTime" label="考试时间"  align="center">
                <template slot-scope="scope">
                    <el-date-picker
                      :class="whetherReturnEmpty(scope.row.editDto.examTime)"
                      v-model="scope.row.examTime"
                      type="date"
                      style="width:180px"
                      value-format="yyyy-MM-dd"
                      placeholder="选择日期"
                      :disabled="isEdit">
                      </el-date-picker>
                  </template>
            </el-table-column>
            <el-table-column prop="examScore" label="分数细节（各小分）"  align="center">
                <template slot-scope="scope">
                    <el-input :class="whetherReturnEmpty(scope.row.editDto.examScore)" v-model="scope.row.examScore" :disabled="isEdit" placeholder="请输入分数细节"></el-input>
                </template>
            </el-table-column>
            <el-table-column prop="remark" label="备注"  align="center">
                <template slot-scope="scope">
                    <el-input :class="whetherReturnEmpty(scope.row.editDto.remark)" v-model="scope.row.remark" :disabled="isEdit" placeholder="请输入备注"></el-input>
                </template>
            </el-table-column>
            <el-table-column prop="" label="移除"  align="center" width="70">
                <template slot-scope="scope">
                    <i class="el-icon-remove delClass" v-if="!isEdit" @click="delayRow2(scope.row)"></i>
                </template>
            </el-table-column>
        </el-table> -->
        <div class="container"><el-button class="addBtnClass" round plain @click="addRow3" :disabled="isEdit">+添加一行</el-button></div>
      </div>
    </div>
  </template>

  <script>
  import { wfSaiLanguageGetDetailByMaterialId, wfSaiLanguageAdd, wfSaiLanguageupdate } from '@/assets/api/application';
  import { dicListByCode } from 'api/index.js';
  export default {
    name: "Parttwo",
    props: {
      type: {
        type: String,
        default: ''
      },
      finalDraft: {
        type: Number,
        default: 0
      },
      id: {
      type: [String, Number],
      default: ''
    },
    },
    data() {
      return {

        formData: {
          editDto: {}
        },
        a: [],
        b: [],
        c: [],
        isEdit: true,
        isNewEdit: null,
        languageAchievement: [],
        academicList: []
      };
    },
    mounted() {

    },
    created() {
      this.getDetail();
      this.getLanguageList();
    },
    methods: {
      getDetail() {
          this.loading = true;
          wfSaiLanguageGetDetailByMaterialId({ materialId: this.id }).then(res => {
            if (res.code == '2000') {
              this.isNewEdit = false;
              this.a = res.data.a || [];
              this.b = res.data.b || [];
              this.c = res.data.c || [];
            } else {
              this.isNewEdit = true;
              this.a = [];
              this.b = [{
                languageType: '托福',
                totalPoints: '',
                points1: '',
                points2: '',
                points3: '',
                points4: '',
                materialId: this.id,
                userId: localStorage.getItem('userInfo').id,
                editDto: {}
              }];
              this.c = [{
                languageType: 'GRE',
                totalPoints: '',
                points1: '',
                points2: '',
                points3: '',
                points4: '',
                materialId: this.id,
                userId: localStorage.getItem('userInfo').id,
                editDto: {}
              }];
            }
          });
      },
    saveAndNavigate() {
      this.$emit('showLoading');
      const url = this.isNewEdit ? wfSaiLanguageAdd : wfSaiLanguageupdate;
            const data = {
             a: this.a || [],
             b: this.b || [],
             c: this.c || [],
            };
            if (this.isNewEdit == false) {
              data.id = this.formData.id;
            };
            url(data).then(res => {
              if (res.code === '2000') {
                this.$message.success('保存成功');
                this.isEdit = true;
                this.getDetail();
                this.$emit('getDetail');
                this.$emit('hideLoading');
              } else {
                this.$message.error(res.msg || '保存失败');
                this.$emit('hideLoading');
              }
            });
    },
      addRow() {
          this.a.push({
                examType: '',
                username: '',
                pwd: '',
                remark: '',
                materialId: this.id,
                userId: localStorage.getItem('userInfo').id,
                editDto: {}
          });
      },
      delayRow(row) {
        const index = this.a.indexOf(row);
        this.a.splice(index, 1);
      },
      addRow2() {
          // this.b.push({
          //       examType: '',
          //       examTime: '',
          //       examScore: '',
          //       remark: '',
          //       materialId: this.id,
          //       userId: localStorage.getItem('userInfo').id,
          //       editDto: {}
          // });
          this.b.push({
                languageType: '',
                totalPoints: '',
                points1: '',
                points2: '',
                points3: '',
                points4: '',
                materialId: this.id,
                userId: localStorage.getItem('userInfo').id,
                editDto: {}
            });
      },
      addRow3() {
          this.c.push({
                languageType: '',
                totalPoints: '',
                points1: '',
                points2: '',
                points3: '',
                materialId: this.id,
                userId: localStorage.getItem('userInfo').id,
                editDto: {}
            });
      },
      delayRow2(row) {
        const index = this.b.indexOf(row);
        this.b.splice(index, 1);
      },
      delayRow3(row) {
        const index = this.c.indexOf(row);
        this.c.splice(index, 1);
      },
      goBack() {
          this.$emit('backPage');
      },
      navigateNext() {
          this.$emit('nextStep');
      },
      startEdit() {
          this.isEdit = !this.isEdit;
      },
      stopEdit() {
          this.isEdit = true;
      },
      whetherReturnEmpty(value) {
      if (value != 'undefined' & value && value == 1) {
        return 'changeValue';
      }
    },
      async getLanguageList() {
        const fetchData = async (code, property) => {
          try {
            const res = await dicListByCode({ code });
            if (res.code === '2000') {
              this[property] = res.data;
            } else {
              this.$message({ type: 'error', message: res.msg });
            }
          } catch (error) {
            this.$message({ type: 'error', message: '请求失败' });
          }
        };

        await Promise.all([
          fetchData('XSKSLX', 'academicList'),
          fetchData('LANGUAGE', 'languageAchievement')
        ]);
      },

    getLanguageDisabled(code) {
      const list = this.b || [];
      for (const i in list) {
        if (list[i].languageType == code) {
          return true;
        }
      }
      return false;
    },
    getLanguageDisabled2(code) {
      const list = this.c || [];
      for (const i in list) {
        if (list[i].languageType == code) {
          return true;
        }
      }
      return false;
    },
    changeAcademic(row, index) {
      console.log(row, index);
      if (row.languageType == 'LSAT') {
        row.points1 = '';
        row.points2 = '';
        row.points3 = '';
      }
    },
  },
  };
  </script>

  <style  lang='stylus' scoped>
  @import '~css/stylus.styl';

  .title {
    font-weight: bold;
    font-size: 18px;
    color: #333333;
    line-height: 32px;
    margin: 21px 0;
    text-align: left;
    display: flex;
    justify-content: space-between;
  }

  .flexs {
    .el-form-item {
      display: flex;
      margin-bottom: 16px;
      align-items: end;
    }
  }

  .contentBox {
    width: 900px;
    margin: 0 auto;

    h3 {
      font-size: 16px;
      color: #333333;
      margin-bottom: 15px;
    }
  }

  >>>.el-form-item__content{
      width:100%
  }

  .delClass{
    cursor pointer;
    color: rgba(255, 130, 64, 1);
    font-size: 20px;
  }
  .container:after{
    content: '';
    display: block;
    clear: both;
  }
  .addBtnClass{
    width: 90px !important;
    height: 28px !important;
    background: #FFEFE6;
    border-radius: 17px 17px 17px 17px;
    border: 1px solid #FF8240;
    font-size: 14px !important;
    color: #FF8240;
    margin: 15px 0 18px !important;
    float: right;
    cursor pointer;
    text-align: center;
    line-height: 28px !important;
  }
  .btnBox {
    display: flex;
    justify-content: center;
  }

  >>>.el-button {
    width: 92px;
    padding: 0 !important;
    height: 30px;
    line-height: 30px;
    border-radius: 15px;
    font-size: 14px;
    font-weight: 400;
  }

  .saveBtn {
    width: 92px;
    height: 30px;
    background: #FFFFFF;
    border-radius: 15px;
    border: 1px solid #FF8240;
    box-sizing: border-box;
    font-size: 14px;
    color: #FF8240;
    text-align: center;
    line-height: 30px;
    cursor: pointer;
    transition: all 0.3s;
    font-weight: 400;

    &:hover {
      background: #fff5f0;
    }
  }
  .changeValue{
    >>>.el-form-item__label{
      color: #FF8240 !important;
    }
    >>>.el-input__inner{
      border-color: #FF8240 !important;
    }
  }
  .ml-10{
    margin-left: 10px;
  }
  .changeValue {
    >>>.el-form-item__label {
      color: #FF8240 !important;
    }

    >>>.el-input__inner {
      border-color: #FF8240 !important;
      border:1px solid #FF8240 !important;
    }
  }
  </style>