<template>
  <div class="infobigBox">
    <leftNav></leftNav>
    <div class="infoBox">
      <p class="title">基础信息<span class="editClass" @click="editInfo">编辑资料</span></p>
      <div class="infopng">
        <p>
          <!-- <el-upload
            class="avatar-uploader"
            action="https://jsonplaceholder.typicode.com/posts/"
            :show-file-list="false"
            :on-success="handleAvatarSuccess"
            >
            <img v-if="form.headLogo" :src="form.headLogo" class="avatar" style="border-radius: 50%;">
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            <span class="btn">更换头像</span>
          </el-upload> -->
          <img v-if="isEdit" :src="form.headLogo" class="avatar"  />
          <el-upload v-else class="avatar-uploader" action="" :show-file-list="false" :http-request="uploadSectionFile" accept=".jpg,.jpeg" :limit="1" :multiple="false" :on-remove="handleRemove" :file-list="form.imageUrl ? [{ name: '用户头像', url: form.imageUrl }] : []">
            <img v-if="form.headLogo" :src="form.headLogo" class="avatar"  />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            <span class="btn" v-if="!isEdit">更换头像</span>
          </el-upload>
        </p>
      </div>
      <div class="info">
        <el-form :model="form" :rules="rules" label-width="90px" label-position="left" class="fl">
          <el-form-item  label="昵称：">
            <el-input v-model="form.name" placeholder="请输入昵称" :disabled="isEdit"></el-input>
          </el-form-item>
          <el-form-item label="我的身份：">
            <el-select v-model="form.userType" placeholder="请选择身份" :disabled="isEdit">
              <el-option
                v-for="item in identityList"
                :key="item.id"
                :label="item.name"
                :value="item.code">
                </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="我的学校：">
            <el-select
            :disabled="isEdit"
            style="display:block"
            v-model="form.schoolName"
            filterable
            remote
            :remote-method="remoteSearch"
            default-first-option
            placeholder="请输入关键词"
            allow-create
            @change="changeSchool"
            >
            <el-option
              v-for="item in collegeList"
              :key="item.id"
              :label="item.name"
              :value="item.name"
            ></el-option>
          </el-select>
            <!-- <el-input v-model="form.schoolName" placeholder="请输入我的学校"></el-input> -->
             <!-- <p v-if="!isEdit" style="height: 19px;line-height: 19px;">没有我的学校/找不到我的学校<el-button style="margin-left:12px " type="text" @click="addSchool">添加学校</el-button></p> -->
          </el-form-item>
          <!-- <el-form-item label="院校等级：" >
            <el-select v-model="sendObj.schoolType" placeholder="请选择院校等级" :disabled="isEdit">
              <el-option
                 v-for="(item, index) in levelList"
                :key="index"
                :label="item.name"
                :value="item.code">
                </el-option>
            </el-select>
          </el-form-item> -->
          <el-form-item label="手机号：" prop="phone">
            <el-input v-model="form.phone"  placeholder="请输入手机号" :disabled="isEdit"></el-input>
          </el-form-item>
        </el-form>
        <!-- <el-form :model="form" :rules="rules" label-width="90px" label-position="left"  class="fr">
          <el-form-item  label="办学层次：">
            <el-select v-model="form.schoolType" placeholder="请选择" :disabled="isEdit">
              <el-option
                v-for="item in schoolrunnigLevelList"
                :key="item.id"
                :label="item.name"
                :value="item.name">
                </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="双一流：">
            <el-select v-model="sendObj.institutionType" placeholder="请选择" :disabled="isEdit">
              <el-option
                v-for="item in dfciList"
                :key="item.id"
                :label="item.name"
                :value="item.name">
                </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="办学性质：">
            <el-select
            :disabled="isEdit"
            style="display:block"
            v-model="form.natureEducation"
            filterable
            >
            <el-option
              v-for="item in natureEducationList"
              :key="item.id"
              :label="item.name"
              :value="item.name"
            ></el-option>
          </el-select>
          </el-form-item>
          <el-form-item label="手机号：" prop="phone">
            <el-input v-model="form.phone"  placeholder="请输入手机号" :disabled="isEdit"></el-input>
          </el-form-item>
        </el-form> -->

      </div>
      <p class="sendBtn" @click="Save" v-if="!isEdit">保存</p>
    </div>
    <el-dialog
        title="添加大学"
        :visible.sync="dialogVisible"
        width="417px"
        center
        :close-on-click-modal="false"
        :show-close="false"
      >
      <div class="formBox">
        <el-form :model="FormData" :rules="rules" label-width="90px" label-position="left" >
          <el-form-item  label="学校名称：">
            <el-input v-model="FormData.name" placeholder="请输入昵称" :disabled="isEdit"></el-input>
          </el-form-item>
          <el-form-item label="院校等级：">
            <el-select v-model="FormData.schoolType" placeholder="请选择院校等级" :disabled="isEdit">
              <el-option
                 v-for="(item, index) in levelList"
                :key="index"
                :label="item.name"
                :value="item.code">
                </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="办学层次：">
            <el-select v-model="FormData.educationalLevel" placeholder="请选择办学层次" :disabled="isEdit">
              <el-option
                v-for="item in educationalLevelList"
                :key="item.value"
                :label="item.name"
                :value="item.code">
                </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="双一流：">
            <el-select v-model="FormData.institutionType" placeholder="请选择" :disabled="isEdit">
              <el-option
                v-for="item in dfciList"
                :key="item.id"
                :label="item.name"
                :value="item.code">
                </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="办学性质：">
            <el-select v-model="FormData.natureEducation" placeholder="请选择办学性质" :disabled="isEdit">
              <el-option
                v-for="item in natureEducationList"
                :key="item.value"
                :label="item.name"
                :value="item.code">
                </el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
        <div class="footBtn">
          <p @click="cancel">取消</p>
          <p @click="sure">确定</p>
        </div>
      </el-dialog>
  </div>
</template>

<script>
import leftNav from "./leftNav";
import { uploadFile, dicListByCode, cmsBkSchoolAdd } from 'api/index.js';
import { cmsUserUpdate } from 'api/login.js';
import { getBkSchool } from 'api/caseSharing.js';

export default {
  components: {
    leftNav,
  },
  data() {
    const mobileRule = (rule, value, callback) => {
      const regExp = /^1[3-9]\d{9}$/;
      if (regExp.test(value) === false) {
        callback(new Error('请输入正确的手机号'));
      } else {
        callback();
      }
    };
    return {
      imageUrl: require('../../assets/defaultbigIcon.png'),
      form: {},
      identityList: [
        {
          code: 0,
          name: '学生'
        },
        {
          code: 1,
          name: '顾问'
        }
      ],
      rules: {
        phone: [
          { required: true, message: '请输入手机号码', trigger: 'blur' },
          { min: 11, message: '手机号码长度有误', trigger: 'blur' },
          { validator: mobileRule, trigger: ['blur', 'change'] }
        ]
      },
      collegeList: [],
      isEdit: true,
      dialogVisible: false,
      sendObj: {},
      levelList: [],
      educationalLevelList: [], // SCHOOLRUNNINGLEVEL
      institutionTypelList: [], // DFCI
      natureEducationList: [], // SCHOOLNATURE
      schoolrunnigLevelList: [], // SCHOOLRUNNINGLEVEL
      dfciList: [], // DFCI
      FormData: {},
    };
  },
  methods: {

    handleAvatarSuccess(res, file) {
      this.form.headLogo = URL.createObjectURL(file.raw);
    },
    handleRemove(file, fileList) {
      this.$set(this.form, 'imageUrl', '');
    },
    uploadSectionFile(file) {
      const isJPG = file.file.type === 'image/jpeg';
      const isPNG = file.file.type === 'image/png';
      const isLt2M = file.file.size / 1024 / 1024 < 2;
      console.log(isJPG, isPNG);
      if (!isJPG && !isPNG) {
        this.$message.error('上传图片只能是 JPG/JPEG 格式!');
      }
      if (!isLt2M) {
        this.$message.error('上传图片大小不能超过 2MB!');
      }
      const fromData = new FormData();
      fromData.append('file', file.file);
      uploadFile(fromData).then(res => {
        if (res.code == 2000) {
          this.$message({
            type: 'success',
            message: '上传成功'
          });
          this.$set(this.form, 'headLogo', res.data);
        } else {
          this.$message({
            type: 'error',
            message: res.msg
          });
        }
      });
    },
    Save() {
      delete this.form.cmsToken;
      cmsUserUpdate(this.form).then(res => {
            if (res.code == 2000) {
              this.$message({
                type: 'success',
                message: res.msg
              });
              localStorage.setItem('userInfo', this.form);
              this.isEdit = true;
              window.location.reload();
            } else {
              this.$message({
                type: 'error',
                message: res.msg
              });
            }
          });
    },
    remoteSearch(queryString) {
      if (queryString !== '') {
        const data = {
          ifPage: false,
          pageSize: 99999,
          pageNum: 1,
          name: queryString,
        };
        getBkSchool(data).then(res => {
        if (res.code == '2000') {
          this.collegeList = res.data.list || [];
        } else {
          this.$message({
            type: 'error',
            message: res.msg
          });
        }
      });
      } else {
        this.collegeList = []; // 当搜索字符串为空时，清空选项列表
      }
    },
    editInfo() {
      this.isEdit = !this.isEdit;
    },
    addSchool() {
      this.dialogVisible = true;
    },
    cancel() {
      this.dialogVisible = false;
    },
    sure() {
      cmsBkSchoolAdd(this.FormData).then(res => {
        if (res.code == 2000) {
          this.$message({
            type: 'success',
            message: res.msg
          });
          this.dialogVisible = false;
          this.FormData = {};
        } else {
          this.$message({
            type: 'error',
            message: res.msg
          });
        }
      });
    },
    // 获取字典
    getCodeList(code, listKey) {
      dicListByCode({
        code: code,
        status: 1
      })
        .then((res) => {
          if (res.code == '2000') {
            this[listKey] = res.data;
          } else {
            this.$message({
              type: 'error',
              message: res.msg
            });
          }
        })
        .catch(() => {});
    },
    changeSchool(val) {
      this.collegeList.map(item => {
        if (item.name == val) {
          this.form.schoolId = item.id;
        }
      });
    }
  },
  mounted() {
    this.form = localStorage.getItem('userInfo');
  },
  created() {
    this.getCodeList('LEVEL', 'levelList');
    this.getCodeList('SCHOOLRUNNINGLEVEL', 'educationalLevelList');
    this.getCodeList('DFCI', 'institutionTypelList');
    this.getCodeList('SCHOOLNATURE', 'natureEducationList');
    // this.getCodeList('ROLE', 'identityList');
    this.getCodeList('SCHOOLRUNNINGLEVEL', 'schoolrunnigLevelList');
    this.getCodeList('DFCI', 'dfciList');
  }
};
</script>

<style  lang='stylus' scoped>
@import '~css/stylus.styl';
.infobigBox{
    width: 1200px;
    height: 100%;
    display: flex;
    justify-content: space-between;
    background-color:#F5F7FA;
    padding-bottom: 60px;
    margin: 0 auto;
}
.infoBox{
  width 940px;
  background-color: #fff;
  margin: 27px 2% 27px;
  box-sizing: border-box;
  border-radius: 12px 12px 12px 12px;
  padding: 23px 20px;
  .title{
      font-size: 20px;
      color: #333333;
      height:23px;
      position relative;
      padding-left: 14px;
  }
  .title::after{
      content: '';
      position: absolute;
      left: 0;
      top: 2px;
      width :4px;
      height :20px;
      display: block;
      background-color: $themeColor;
      border-radius: 2px;
  }
  .infopng{
    display: flex;
    margin: 30px 0 33px;
    align-items: center;
    p{
      width: 80px;
      height: 80px;
      border-radius: 50%;
      .avatar-uploader{
        position:relative
      }
      .avatar-uploader .el-upload {
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
        .btn{
          width: 92px;
          height: 30px;
          background: #FFFFFF;
          border-radius: 15px 15px 15px 15px;
          border: 1px solid $themeColor;
          font-size: 14px;
          color: $themeColor;
          text-align: center;
          line-height: 30px;
          display: block;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          left: 102px;

        }
      }
      .avatar-uploader .el-upload:hover {
        border-color: #409EFF;
      }
      .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 80px;
        height: 80px;
        line-height: 80px;
        text-align: center;
      }
      .avatar {
        width: 80px;
        height: 80px;
        display: block;
      }

    }
  }
}
.info{
  width: 100%;
  .el-select{
    display: block;
  }

}
.sendBtn{
  width: 250px;
  height: 50px;
  background: $themeColor;
  border-radius: 90px 90px 90px 90px;
  font-size: 16px;
  color: #FFFFFF;
  line-height: 50px
  text-align: center;
  margin-top: 34px;
  cursor:pointer
}
.info:after{
  content: '';
  display: block;
  clear: both;
}
.avatar{
  border-radius: 50%
}
.editClass{
  width: 92px;
  height: 26px;
  background: #FFFFFF;
  border-radius: 15px 15px 15px 15px;
  border: 1px solid #FF8240;
  font-weight: 400;
  font-size: 14px;
  color: #FF8240;
  text-align: center;
  line-height: 26px;
  float: right;
  cursor pointer;
}
.fl{
  float: left;
  width:380px;

}
.fr{
  float: right;
  width:380px;

}
>>>.el-form-item{
  margin-bottom: 19px;
}
>>>.el-dialog__header {
  padding: 19px 0 0 !important;
  text-align: center;
  font-size: 18px;
  line-height: 20px;
}

>>>.el-dialog--center .el-dialog__body {
  padding: 0px !important;
}

.footBtn {
  box-sizing: border-box;
  padding: 0 26px 18px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  p {
    width: 172px;
    height: 51px;
    text-align: center;
    line-height: 51px;
    font-weight: 400;
    font-size: 16px;
    color: #FF8240;
    border-radius: 90px 90px 90px 90px;
    cursor: pointer;
  }

  p:nth-of-type(1) {
    border: 1px solid #FF8240;
  }

  p:nth-of-type(2) {
    background: #FF8240;
    color: #FFFFFF;
  }
}
.formBox{
  padding: 0  26px 0 37px;
  box-sizing: border-box;
  .el-select{
    width: 100%;
  }
}

</style>
<style>
#app{
    background-color: #F5F7FA;
}
</style>