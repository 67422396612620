<template>
    <div>
        <div class="title">第四部分：申请人奖励、课外活动、科研和工作实习情况
            <div class="btnBox">
                <!-- <el-button class="saveBtn" plain round @click="goBack">返回上一页</el-button> -->
                <template v-if="isEdit && type!='detail' && finalDraft!=1">
                    <el-button type="primary" round @click="startEdit">开始修改</el-button>
                    <!-- <el-button type="primary" class="ml-10" round @click="navigateNext">跳转下一页</el-button> -->
                </template>
                <template v-else-if="type!='detail' && !isEdit">
                    <p class="saveBtn ml-10" v-show="isEdit==false" @click="stopEdit">退出修改</p>
                    <el-button type="primary" class="ml-10" round @click="saveInfo('formData')">确认修改</el-button>
                </template>
            </div>
        </div>
        <div class="contentBox">
            <h3>1. 申请人在校期间参加的课题、研究项目</h3>
            <el-table :data="list1" border>
                <el-table-column prop="date" label="起止时间" width="320" align="center">
                    <template slot-scope="scope">
                        <el-date-picker
                            :class="whetherReturnEmpty(scope.row.editDto.date)"
                            v-model="scope.row.date"
                            type="daterange"
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                            style="width:280px"
                            value-format="yyyy-MM-dd"
                            :disabled="isEdit"></el-date-picker>
                    </template>
                </el-table-column>
                <el-table-column prop="projectName" label="项目名称" align="center" width="200">
                    <template slot-scope="scope">
                        <el-input :class="whetherReturnEmpty(scope.row.editDto.projectName)" v-model="scope.row.projectName" placeholder="请输入课题/项目名称" :disabled="isEdit"></el-input>
                    </template>
                </el-table-column>
                <el-table-column prop="projectContent" label="项目目的，内容（项目组的主要工作及申请人参与的具体工作），运用 技术（软件、工具等），困难描述和解决方案，项目成果和个人收获" width="465" align="center">
                    <template slot-scope="scope">
                        <el-input :class="whetherReturnEmpty(scope.row.editDto.projectContent)" v-model="scope.row.projectContent" placeholder="请输入项目目的" :disabled="isEdit"></el-input>
                    </template>
                </el-table-column>
                <el-table-column prop="address" label="移除" align="center" width="56">
                    <template slot-scope="scope">
                        <i class="el-icon-remove delClass" v-show="!isEdit" @click="delayRow(scope.row, list1)"></i>
                    </template>
                </el-table-column>
            </el-table>
            <div class="container">
                <el-button class="addBtnClass" round plain @click="addRow(list1)" :disabled="isEdit">+添加一行</el-button>
            </div>
            <h3>2. 申请人工作/实习经历</h3>
            <div class="tipss">说明：请按照从现在到过去的顺序填写；工作单位和职位尽量用中英文对照，如超过3个，请在其他工作经历中补充说明。</div>
            <el-form :model="formData" ref="formData" :rules="rules" inline class="flexs" label-position="left">
                <el-row>
                    <el-col :span="7" style="margin-right: 47px;">
                        <el-form-item label="工作单位A:" label-width="180px" prop="companyaName" :class="whetherReturnEmpty(formData.companyaName)">
                            <el-input v-model="formData.companyaName" placeholder="请输入" size="medium" :disabled="isEdit" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="6" style="margin-right: 47px;">
                        <el-form-item label="职位：" label-width="100px" prop="companyaPosition" :class="whetherReturnEmpty(formData.companyaPosition)">
                            <el-input v-model="formData.companyaPosition" placeholder="请输入" size="medium" :disabled="isEdit" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="7">
                        <el-form-item label="全职/兼职/实习：" label-width="275px" prop="companyaEmploymentType" :class="whetherReturnEmpty(formData.companyaEmploymentType)">
                            <el-select
                                v-model="formData.companyaEmploymentType"
                                placeholder="请选择"
                                :disabled="isEdit"
                                >
                                <el-option
                                    v-for="item in careerTypeList"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value"
                                    :disabled="isEdit"
                                >
                                </el-option>
                                </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="7">
                        <el-form-item label="开始时间：" label-width="180px" prop="companyaStartDate" :class="whetherReturnEmpty(formData.companyaStartDate)">
                            <el-date-picker
                            v-model="formData.companyaStartDate"
                            type="date"
                            style="width:133px"
                            value-format="yyyy-MM-dd"
                            placeholder="选择日期"
                            :disabled="isEdit"
                            @change="
                            handleStartDateChange(
                            'companyaStartDate',
                            'companyaEndDate'
                            )
                            "
                            >
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col :span="7">
                        <el-form-item label="结束时间：" label-width="180px" prop="companyaEndDate" :class="whetherReturnEmpty(formData.companyaEndDate)">
                            <el-date-picker v-model="formData.companyaEndDate" type="date" style="width:133px" value-format="yyyy-MM-dd" placeholder="选择日期" :disabled="isEdit" :picker-options="getPickerOptions(formData.companyaStartDate)">
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col :span="10">
                        <el-form-item label="工作地点：" label-width="120px" prop="companyaLocation" :class="whetherReturnEmpty(formData.companyaLocation)">
                            <el-input v-model="formData.companyaLocation" placeholder="请输入（国家、省、市）" size="medium" :disabled="isEdit" />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="7" style="margin-right: 47px;">
                        <el-form-item label="公司人数：" label-width="180px" :class="whetherReturnEmpty(formData.companyaSize)">
                            <el-input v-model="formData.companyaSize" placeholder="请输入" size="medium" :disabled="isEdit" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="6" style="margin-right: 47px;">
                        <el-form-item label="公司年收入：" label-width="165px" :class="whetherReturnEmpty(formData.companyaRevenue)">
                            <el-input v-model="formData.companyaRevenue" placeholder="请输入" size="medium" :disabled="isEdit" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="7">
                        <el-form-item label="公司网址：" label-width="117px" :class="whetherReturnEmpty(formData.companyaWebsite)">
                            <el-input v-model="formData.companyaWebsite" placeholder="请输入" size="medium" :disabled="isEdit" />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="7" style="margin-right: 47px;">
                        <el-form-item label="薪酬和奖金：" label-width="180px" :class="whetherReturnEmpty(formData.companyaSalaryBonus)">
                            <el-input v-model="formData.companyaSalaryBonus" placeholder="请输入" size="medium" :disabled="isEdit" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8" style="margin-right: 47px;">
                        <el-form-item label="您的直接汇报对象：" label-width="260px" :class="whetherReturnEmpty(formData.companyaDirectReport)">
                            <el-input v-model="formData.companyaDirectReport" placeholder="请输入" size="medium" :disabled="isEdit" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="多少人向您汇报：" label-width="278px" :class="whetherReturnEmpty(formData.companyaNumReports)">
                            <el-input v-model="formData.companyaNumReports" placeholder="请输入" size="medium" :disabled="isEdit" />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="工作内容及成就：" label-width="158px" prop="companyaResponsibilitiesAchievements" :class="whetherReturnEmpty(formData.companyaResponsibilitiesAchievements)">
                            <el-input
                                v-model="formData.companyaResponsibilitiesAchievements"
                                placeholder="请输入"
                                size="medium"
                                :disabled="isEdit"
                                />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="工作心得：" label-width="103px" prop="companyaWorkReflections" :class="whetherReturnEmpty(formData.companyaWorkReflections)">
                            <el-input v-model="formData.companyaWorkReflections" placeholder="请输入" size="medium" :disabled="isEdit" />
                        </el-form-item>
                    </el-col>
                </el-row>
                <div class="line"></div>
                <el-row>
                    <el-col :span="7" style="margin-right: 47px;">
                        <el-form-item label="工作单位B:" label-width="180px" :class="whetherReturnEmpty(formData.companybName)">
                            <el-input v-model="formData.companybName" placeholder="请输入" size="medium" :disabled="isEdit" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="6" style="margin-right: 47px;">
                        <el-form-item label="职位：" label-width="100px" :class="whetherReturnEmpty(formData.companybPosition)">
                            <el-input v-model="formData.companybPosition" placeholder="请输入" size="medium" :disabled="isEdit" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="7">
                        <el-form-item label="全职/兼职/实习：" label-width="240px" :class="whetherReturnEmpty(formData.companybEmploymentType)">
                            <el-select
                            v-model="formData.companybEmploymentType"
                            placeholder="请选择"
                            :disabled="isEdit"
                            >
                            <el-option
                                v-for="item in careerTypeList"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                                :disabled="isEdit"
                            >
                            </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="7">
                        <el-form-item label="开始时间：" label-width="180px" :class="whetherReturnEmpty(formData.companybStartDate)">
                            <el-date-picker
                                v-model="formData.companybStartDate"
                                type="date"
                                style="width:133px"
                                value-format="yyyy-MM-dd"
                                placeholder="选择日期"
                                :disabled="isEdit"
                                @change="
                                handleStartDateChange(
                                'companybStartDate',
                                'companybEndDate'
                                )
                                "
                            >
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col :span="7">
                        <el-form-item label="结束时间：" label-width="180px" :class="whetherReturnEmpty(formData.companybEndDate)">
                            <el-date-picker
                                v-model="formData.companybEndDate"
                                type="date"
                                style="width:133px"
                                value-format="yyyy-MM-dd"
                                placeholder="选择日期"
                                :disabled="isEdit"
                                :picker-options="getPickerOptions(formData.companybStartDate)"
                            >
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col :span="10">
                        <el-form-item label="工作地点：" label-width="123px" :class="whetherReturnEmpty(formData.companybLocation)">
                            <el-input v-model="formData.companybLocation" placeholder="请输入" size="medium" :disabled="isEdit" />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="7" style="margin-right: 47px;">
                        <el-form-item label="公司人数：" label-width="180px" :class="whetherReturnEmpty(formData.companybSize)">
                            <el-input v-model="formData.companybSize" placeholder="请输入" size="medium" :disabled="isEdit" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="6" style="margin-right: 47px;">
                        <el-form-item label="公司年收入：" label-width="165px" :class="whetherReturnEmpty(formData.companybRevenue)">
                            <el-input v-model="formData.companybRevenue" placeholder="请输入" size="medium" :disabled="isEdit" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="7">
                        <el-form-item label="公司网址：" label-width="117px" :class="whetherReturnEmpty(formData.companybWebsite)">
                            <el-input v-model="formData.companybWebsite" placeholder="请输入" size="medium" :disabled="isEdit" />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="7" style="margin-right: 47px;">
                        <el-form-item label="薪酬和奖金：" label-width="180px" :class="whetherReturnEmpty(formData.companybSalaryBonus)">
                            <el-input v-model="formData.companybSalaryBonus" placeholder="请输入" size="medium" :disabled="isEdit" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8" style="margin-right: 47px;">
                        <el-form-item label="您的直接汇报对象：" label-width="260px" :class="whetherReturnEmpty(formData.companybDirectReport)">
                            <el-input v-model="formData.companybDirectReport" placeholder="请输入" size="medium" :disabled="isEdit" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="多少人向您汇报：" label-width="278px" :class="whetherReturnEmpty(formData.companybNumReports)">
                            <el-input v-model="formData.companybNumReports" placeholder="请输入" size="medium" :disabled="isEdit" />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="工作内容及成就：" label-width="143px">
                            <el-input
                                v-model="formData.companybResponsibilitiesAchievements"
                                placeholder="请输入"
                                size="medium"
                                :disabled="isEdit"
                            />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="工作心得：" label-width="91px">
                            <el-input v-model="formData.companybWorkReflections" placeholder="请输入" size="medium" :disabled="isEdit" />
                        </el-form-item>
                    </el-col>
                </el-row>
                <div class="line"></div>
            <el-row>
            <el-col :span="7" style="margin-right: 47px">
              <el-form-item
                label="工作单位C:"
                label-width="180px"
                :class="whetherReturnEmpty(formData.companycName)"
              >
                <el-input
                  v-model="formData.companycName"
                  placeholder="请输入"
                  size="medium"
                  :disabled="isEdit"
                />
              </el-form-item>
            </el-col>
            <el-col :span="6" style="margin-right: 47px">
              <el-form-item
                label="职位："
                label-width="100px"
                :class="whetherReturnEmpty(formData.companycPosition)"
              >
                <el-input
                  v-model="formData.companycPosition"
                  placeholder="请输入"
                  size="medium"
                  :disabled="isEdit"
                />
              </el-form-item>
            </el-col>
            <el-col :span="7">
              <el-form-item
                label="全职/兼职/实习："
                label-width="240px"
                :class="whetherReturnEmpty(formData.companycEmploymentType)"
              >
              <el-select
                  v-model="formData.companycEmploymentType"
                  placeholder="请选择"
                  :disabled="isEdit"
                >
                  <el-option
                    v-for="item in careerTypeList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                    :disabled="isEdit"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="7">
              <el-form-item
                label="开始时间："
                label-width="180px"
                :class="whetherReturnEmpty(formData.companycStartDate)"
              >
                <el-date-picker
                  v-model="formData.companycStartDate"
                  type="date"
                  style="width: 133px"
                  value-format="yyyy-MM-dd"
                  placeholder="选择日期"
                  :disabled="isEdit"
                  @change="
                handleStartDateChange(
                'companycStartDate',
                'companycEndDate'
                )
                "
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="7">
              <el-form-item
                label="结束时间："
                label-width="180px"
                :class="whetherReturnEmpty(formData.companycEndDate)"
              >
                <el-date-picker
                  v-model="formData.companycEndDate"
                  type="date"
                  style="width: 133px"
                  value-format="yyyy-MM-dd"
                  placeholder="选择日期"
                  :disabled="isEdit"
                  :picker-options="getPickerOptions(formData.companycStartDate)"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="10">
              <el-form-item
                label="工作地点："
                label-width="123px"
                :class="whetherReturnEmpty(formData.companycLocation)"
              >
                <el-input
                  v-model="formData.companycLocation"
                  placeholder="请输入"
                  size="medium"
                  :disabled="isEdit"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="7" style="margin-right: 47px">
              <el-form-item
                label="公司人数："
                label-width="180px"
                :class="whetherReturnEmpty(formData.companycSize)"
              >
                <el-input
                  v-model="formData.companycSize"
                  placeholder="请输入"
                  size="medium"
                  :disabled="isEdit"
                />
              </el-form-item>
            </el-col>
            <el-col :span="6" style="margin-right: 47px">
              <el-form-item
                label="公司年收入："
                label-width="165px"
                :class="whetherReturnEmpty(formData.companycRevenue)"
              >
                <el-input
                  v-model="formData.companycRevenue"
                  placeholder="请输入"
                  size="medium"
                  :disabled="isEdit"
                />
              </el-form-item>
            </el-col>
            <el-col :span="7">
              <el-form-item
                label="公司网址："
                label-width="117px"
                :class="whetherReturnEmpty(formData.companycWebsite)"
              >
                <el-input
                  v-model="formData.companycWebsite"
                  placeholder="请输入"
                  size="medium"
                  :disabled="isEdit"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="7" style="margin-right: 47px">
              <el-form-item
                label="薪酬和奖金："
                label-width="180px"
                :class="whetherReturnEmpty(formData.companycSalaryBonus)"
              >
                <el-input
                  v-model="formData.companycSalaryBonus"
                  placeholder="请输入"
                  size="medium"
                  :disabled="isEdit"
                />
              </el-form-item>
            </el-col>
            <el-col :span="8" style="margin-right: 47px">
              <el-form-item
                label="您的直接汇报对象："
                label-width="260px"
                :class="whetherReturnEmpty(formData.companycDirectReport)"
              >
                <el-input
                  v-model="formData.companycDirectReport"
                  placeholder="请输入"
                  size="medium"
                  :disabled="isEdit"
                />
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item
                label="多少人向您汇报："
                label-width="278px"
                :class="whetherReturnEmpty(formData.companycNumReports)"
              >
                <el-input
                  v-model="formData.companycNumReports"
                  placeholder="请输入"
                  size="medium"
                  :disabled="isEdit"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="工作内容及成就：" label-width="143px">
                <el-input
                  v-model="formData.companycResponsibilitiesAchievements"
                  placeholder="请输入"
                  size="medium"
                  :disabled="isEdit"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="工作心得：" label-width="91px">
                <el-input
                  v-model="formData.companycWorkReflections"
                  placeholder="请输入"
                  size="medium"
                  :disabled="isEdit"
                />
              </el-form-item>
            </el-col>
          </el-row>
            </el-form>
            <h3>3.在校期间发表过的文章或者艺术作品，获得何种专利？参加过哪些学术会议？ 说明：如有需要，请将论文电子版或扫描件随信一同发给我们。</h3>
            <el-table :data="list3" border>
                <el-table-column prop="date" label="时间" align="center">
                    <template slot-scope="scope">
                        <el-date-picker
                               :class="whetherReturnEmpty(scope.row.startTime)"
                                v-model="scope.row.startTime"
                                type="date"
                                placeholder="请选择时间"
                                size="medium"
                                :disabled="isEdit"
                                value-format="yyyy-MM-dd"
                                style="width: 140px;"></el-date-picker>
                    </template>
                </el-table-column>
                <el-table-column prop="articleName" label="文章/艺术品/专利名称" align="center">
                    <template slot-scope="scope">
                        <el-input :class="whetherReturnEmpty(scope.row.articleName)" v-model="scope.row.articleName" placeholder="请输入文章/艺术品/专利名称" :disabled="isEdit"></el-input>
                    </template>
                </el-table-column>
                <el-table-column prop="articleContent" label="内容介绍" align="center">
                    <template slot-scope="scope">
                        <el-input :class="whetherReturnEmpty(scope.row.articleContent)" v-model="scope.row.articleContent" placeholder="请输入内容介绍" :disabled="isEdit"></el-input>
                    </template>
                </el-table-column>
                <el-table-column prop="articleLink" label="期刊/会议名称/刊号/链接" align="center">
                    <template slot-scope="scope">
                        <el-input :class="whetherReturnEmpty(scope.row.articleLink)" v-model="scope.row.articleLink" placeholder="请输入期刊/会议名称/刊号/链接" :disabled="isEdit"></el-input>
                    </template>
                </el-table-column>
                <el-table-column prop="address" label="移除" align="center" width="56">
                    <template slot-scope="scope">
                        <i class="el-icon-remove delClass" v-show="!isEdit" @click="delayRow(scope.row, list3)"></i>
                    </template>
                </el-table-column>
            </el-table>
            <div class="container">
                <el-button class="addBtnClass" round plain @click="addRow(list3)" :disabled="isEdit">+添加一行</el-button>
            </div>
            <h3>4.在校期间组织并参加了何种竞赛及名次。</h3>
            <el-table :data="list4" border>
                <el-table-column prop="date" label="起止时间" width="320" align="center">
                    <template slot-scope="scope">
                        <el-date-picker

                                v-model="scope.row.date"
                                type="daterange"
                                range-separator="至"
                                :disabled="isEdit"
                                start-placeholder="开始日期"
                                end-placeholder="结束日期"
                                value-format="yyyy-MM-dd"
                                style="width:280px"></el-date-picker>
                    </template>
                </el-table-column>
                <el-table-column prop="competitionName" label="竞赛名称" align="center">
                    <template slot-scope="scope">
                        <el-input :class="whetherReturnEmpty(scope.row.competitionName)" v-model="scope.row.competitionName" placeholder="请输入竞赛名称" :disabled="isEdit"></el-input>
                    </template>
                </el-table-column>
                <el-table-column prop="competitionContent" label="内容介绍" align="center">
                    <template slot-scope="scope">
                        <el-input :class="whetherReturnEmpty(scope.row.competitionContent)" v-model="scope.row.competitionContent" placeholder="请输入内容介绍" :disabled="isEdit"></el-input>
                    </template>
                </el-table-column>
                <el-table-column prop="competitionHarvest" label="获得名次/奖项等级" align="center">
                    <template slot-scope="scope">
                        <el-input :class="whetherReturnEmpty(scope.row.competitionHarvest)" v-model="scope.row.competitionHarvest" placeholder="请输入获得名次/奖项等级" :disabled="isEdit"></el-input>
                    </template>
                </el-table-column>
                <el-table-column prop="address" label="移除" align="center" width="56">
                    <template slot-scope="scope">
                        <i class="el-icon-remove delClass" v-show="!isEdit" @click="delayRow(scope.row, list4)"></i>
                    </template>
                </el-table-column>
            </el-table>
            <div class="container">
                <el-button class="addBtnClass" round plain @click="addRow(list4)" :disabled="isEdit">+添加一行</el-button>
            </div>
            <h3>5.曾经获得过的奖励，特别是近三年的校级及以上奖励，如有职业资格证书也写在这部分</h3>
            <el-table :data="list5" border>
                <el-table-column prop="date" label="时间" align="center">
                    <template slot-scope="scope">
                        <el-date-picker
                                v-model="scope.row.startTime"
                                type="date"
                                placeholder="请选择时间"
                                    size="medium"
                                :disabled="isEdit"
                                value-format="yyyy-MM-dd"
                                style="width: 140px;"></el-date-picker>
                    </template>
                </el-table-column>
                <el-table-column prop="awardName" label="奖励" align="center">
                    <template slot-scope="scope">
                        <el-input :class="whetherReturnEmpty(scope.row.awardName)" v-model="scope.row.awardName" placeholder="请输入奖励" :disabled="isEdit"></el-input>
                    </template>
                </el-table-column>
                <el-table-column prop="awardCompany" label="举办单位" align="center">
                    <template slot-scope="scope">
                        <el-input :class="whetherReturnEmpty(scope.row.awardCompany)" v-model="scope.row.awardCompany" placeholder="请输入举办单位" :disabled="isEdit"></el-input>
                    </template>
                </el-table-column>
                <el-table-column prop="awardContent" label="奖励内容描述" align="center">
                    <template slot-scope="scope">
                        <el-input :class="whetherReturnEmpty(scope.row.awardContent)" v-model="scope.row.awardContent" placeholder="请输入奖励内容描述" :disabled="isEdit"></el-input>
                    </template>
                </el-table-column>
                <el-table-column prop="address" label="移除" align="center" width="56">
                    <template slot-scope="scope">
                        <i class="el-icon-remove delClass" v-show="!isEdit" @click="delayRow(scope.row, list5)"></i>
                    </template>
                </el-table-column>
            </el-table>
            <div class="container">
                <el-button class="addBtnClass" round plain @click="addRow(list5)" :disabled="isEdit">+添加一行</el-button>
            </div>
            <h3>6.职业资格证书</h3>
            <el-table :data="list6" border>
                <el-table-column prop="date" label="时间" align="center">
                    <template slot-scope="scope">
                        <el-date-picker
                                v-model="scope.row.startTime"
                                type="date"
                                placeholder="请选择时间"
                                size="medium"
                                value-format="yyyy-MM-dd"
                                style="width: 140px;"
                                :disabled="isEdit"
                            ></el-date-picker>
                    </template>
                </el-table-column>
                <el-table-column prop="certificateName" label="职业资格证名称" align="center">
                    <template slot-scope="scope">
                        <el-input :class="whetherReturnEmpty(scope.row.certificateName)" v-model="scope.row.certificateName" placeholder="职业资格证名称" :disabled="isEdit"></el-input>
                    </template>
                </el-table-column>
                <el-table-column prop="certificateCompany" label="举办单位" align="center">
                    <template slot-scope="scope">
                        <el-input :class="whetherReturnEmpty(scope.row.certificateCompany)" v-model="scope.row.certificateCompany" placeholder="请输入举办单位" :disabled="isEdit"></el-input>
                    </template>
                </el-table-column>
                <el-table-column prop="certificateDescription" label="内容描述" align="center">
                    <template slot-scope="scope">
                        <el-input :class="whetherReturnEmpty(scope.row.certificateDescription)" v-model="scope.row.certificateDescription" placeholder="内容描述" :disabled="isEdit"></el-input>
                    </template>
                </el-table-column>
                <el-table-column prop="address" label="移除" align="center" width="56">
                    <template slot-scope="scope">
                        <i class="el-icon-remove delClass" v-show="!isEdit" @click="delayRow(scope.row, list6)"></i>
                    </template>
                </el-table-column>
            </el-table>
            <div class="container">
                <el-button class="addBtnClass" round plain @click="addRow(list6)" :disabled="isEdit">+添加一行</el-button>
            </div>
            <h3>7.在校期间你参加、发起或组织了哪些校园活动、社会实践、公益活动？</h3>
            <el-table :data="list7" border>
                <el-table-column prop="date" label="起止时间" width="320" align="center">
                    <template slot-scope="scope">
                        <el-date-picker
                                v-model="scope.row.date"
                                type="daterange"
                                range-separator="至"
                                :disabled="isEdit"
                                start-placeholder="开始日期"
                                end-placeholder="结束日期"
                                value-format="yyyy-MM-dd"
                                style="width:280px"></el-date-picker>
                    </template>
                </el-table-column>
                <el-table-column prop="positionName" label="职务名称" align="center">
                    <template slot-scope="scope">
                        <el-input :class="whetherReturnEmpty(scope.row.positionName)" v-model="scope.row.positionName" placeholder="请输入职务名称" :disabled="isEdit"></el-input>
                    </template>
                </el-table-column>
                <el-table-column prop="positionContent" label="具体职责内容" align="center">
                    <template slot-scope="scope">
                        <el-input  :class="whetherReturnEmpty(scope.row.positionContent)" v-model="scope.row.positionContent" placeholder="请输入具体职责内容" :disabled="isEdit"></el-input>
                    </template>
                </el-table-column>
                <el-table-column prop="positionHarvest" label="个人收获" align="center">
                    <template slot-scope="scope">
                        <el-input :class="whetherReturnEmpty(scope.row.positionHarvest)" v-model="scope.row.positionHarvest" placeholder="请输入个人收获" :disabled="isEdit"></el-input>
                    </template>
                </el-table-column>
                <el-table-column prop="address" label="移除" align="center" width="56">
                    <template slot-scope="scope">
                        <i class="el-icon-remove delClass" v-show="!isEdit" @click="delayRow(scope.row, list7)"></i>
                    </template>
                </el-table-column>
            </el-table>
            <div class="container">
                <el-button class="addBtnClass" round plain @click="addRow(list7)" :disabled="isEdit">+添加一行</el-button>
            </div>
            <h3>8.你有哪些兴趣和爱好？在音乐、体育或其他方面有何特长？如果某种爱好或特长曾获得级别证书(比如钢琴9级、小提琴10级等)，请注明。</h3>
            <el-input style="margin-bottom: 20px;" :disabled="isEdit" v-model="formData.hobby" placeholder="请输入"></el-input>
            <h3>9.SKILLS 技能 （编程语言，软件，语言等）</h3>
            <el-table :data="list9" border>
                <el-table-column prop="skillProficiency" label="熟练程度" align="center" width='130'>
                    <template slot-scope="scope">
                        <el-select v-model="scope.row.skillProficiency" placeholder="请选择" :disabled="isEdit">
                            <el-option
                                v-for="item in proficiencyList"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                                :disabled="isEdit"
                            >
                            </el-option>
                        </el-select>
                    </template>
                </el-table-column>
                <el-table-column prop="skillName" label="技能名称" align="center">
                    <template slot-scope="scope">
                        <el-input :class="whetherReturnEmpty(scope.row.skillName)" v-model="scope.row.skillName" placeholder="请输入技能名称" :disabled="isEdit"></el-input>
                    </template>
                </el-table-column>
                <el-table-column prop="address" label="移除" align="center" width="56">
                    <template slot-scope="scope">
                        <i class="el-icon-remove delClass" v-show="!isEdit" @click="delayRow(scope.row, list9)"></i>
                    </template>
                </el-table-column>
            </el-table>
            <div class="container">
                <el-button class="addBtnClass" round plain @click="addRow(list9)" :disabled="isEdit">+添加一行</el-button>
            </div>
        </div>
    </div>
</template>

<script>
import { wfSaiExperienceGetDetailByMaterialId, wfSaiExperienceAdd, wfSaiExperienceUpdate } from '@/assets/api/application';
export default {
    name: "Partfour",
    props: {
      type: {
        type: String,
        default: ''
      },
      finalDraft: {
        type: Number,
        default: 0
      },
      id: {
      type: [String, Number],
      default: ''
    },
    },
    data() {
        return {
            countries: ["中国", "美国", "英国"],
            formData: {
                companybName: '',
                companyaPosition: '',
                companyaEmploymentType: '',
                hobby: '',
                editDto: {}
            },
            list1: [],
            list3: [],
            list4: [],
            list5: [],
            list6: [],
            list7: [],
            list9: [],
            proficiencyList: [{
                value: '非常熟练',
                label: '非常熟练'
            }, {
                value: '一般熟练',
                label: '一般熟练'
            }],
            isEdit: true,
            rules: {
                // companyaName: [{ required: true, message: '请输入公司名称', trigger: 'blur' }],
                // companyaPosition: [{ required: true, message: '请输入职位', trigger: 'blur' }],
                // companyaEmploymentType: [{ required: true, message: '请输入全职/兼职/实习', trigger: 'blur' }],
                // companyaStartDate: [{ required: true, message: '请选择开始时间', trigger: 'blur' }],
                // companyaEndDate: [{ required: true, message: '请选择结束时间', trigger: 'blur' }],
                // companyaLocation: [{ required: true, message: '请输入工作地点', trigger: 'blur' }],
                // companyaResponsibilitiesAchievements: [{ required: true, message: '请输入工作内容及成就', trigger: 'blur' }],
                // companyaWorkReflections: [{ required: true, message: '请输入工作心得', trigger: 'blur' }],
            },
            rowTemplates: {
                list1: {
                    projectName: '',
                    projectContent: '',
                    date: [],
                    editDto: {}

                },
                list3: {
                    articleName: '',
                    articleContent: '',
                    articleLink: '',
                    startTime: '',
                    editDto: {}
                },
                list4: {
                    competitionName: '',
                    competitionContent: '',
                    competitionHarvest: '',
                    date: [],
                    editDto: {}
                },
                list5: {
                    awardName: '',
                    awardCompany: '',
                    awardContent: '',
                    startTime: '',
                    editDto: {}
                },
                list6: {
                    certificateName: '',
                    certificateCompany: '',
                    certificateDescription: '',
                    startTime: '',
                    editDto: {}
                },
                list7: {
                    activityTime: [],
                    positionName: '',
                    positionContent: '',
                    positionHarvest: '',
                    editDto: {}
                },
                list9: {
                    skillProficiency: '',
                    skillName: '',
                    editDto: {}
                }
            },
            careerTypeList: [
            {
                value: '全职',
                label: '全职'
            },
            {
                value: '兼职',
                label: '兼职'
            },
            {
                value: '实习',
                label: '实习'
            }
        ],
        };
    },
    mounted() { },
    created() {
        this.getDetail();
    },
    methods: {
        getPickerOptions(start) {
        return {
            disabledDate: (time) => {
            if (start) {
                return time.getTime() < new Date(start).getTime();
            }
            return false;
            }
        };
        },
        handleStartDateChange(start, end) {
        const startDay = new Date(this.formData[start]).getTime();
        const endDay = new Date(this.formData[end]).getTime();
        if (endDay && startDay > endDay) {
            this.formData[end] = null;
        }
        },
        getDetail() {
            wfSaiExperienceGetDetailByMaterialId({ materialId: this.id }).then(res => {
                if (res.code == '2000') {
                    this.handleResponseData(res.data);
                    this.isNewEdit = false;
                } else {
                    this.isNewEdit = true;
                }
            });
        },
        saveInfo(formName) {
            this.$emit('showLoading');
            // const listsToCheck = [
            //     { list: this.list1, fields: ['projectName', 'projectContent', 'date'], name: '1' },
            //     { list: this.list3, fields: ['articleName', 'articleContent', 'articleLink', 'startTime'], name: '3' },
            //     { list: this.list4, fields: ['competitionName', 'competitionContent', 'competitionHarvest', 'date'], name: '4' },
            //     { list: this.list5, fields: ['awardName', 'awardCompany', 'awardContent', 'startTime'], name: '5' },
            //     { list: this.list6, fields: ['certificateName', 'certificateCompany', 'certificateDescription', 'startTime'], name: '6' },
            //     { list: this.list7, fields: ['positionName', 'positionContent', 'positionHarvest', 'date'], name: '7' },
            //     { list: this.list9, fields: ['skillProficiency', 'skillName'], name: '9' },
            // ];

            // for (const { list, fields, name } of listsToCheck) {
            //     if (list.length === 0) {
            //         this.$message.error(`请完善第${name}部分信息`);
            //         return false;
            //     }
            //     for (const item of list) {
            //         for (const field of fields) {
            //             if (!item[field] || (Array.isArray(item[field]) && item[field].length === 0)) {
            //                 this.$message.error(`请完善第${name}部分信息`);
            //                 return false;
            //             }
            //         }
            //     }
            // }

            const url = this.isNewEdit ? wfSaiExperienceAdd : wfSaiExperienceUpdate;
            this.$refs[formName].validate(async (valid) => {
                if (valid) {
                        // 验证用户信息
                        const userInfo = localStorage.getItem('userInfo');
                        if (!userInfo) {
                            this.$message.error('用户信息无效');
                            return;
                        }
                        const userId = userInfo.id;
                        const data = {
                            materialId: this.id,
                            userId: userId,
                            ...this.formData,
                            list1: this.list1,
                            list3: this.list3,
                            list4: this.list4,
                            list5: this.list5,
                            list6: this.list6,
                            list7: this.list7,
                            list9: this.list9,
                        };
                        console.log(data);
                        // 复制必要字段
                        data.list1.forEach(item => {
                            item.startTime = item.date[0];
                            item.endTime = item.date[1];
                        });
                        data.list4.forEach(item => {
                            item.startTime = item.date[0];
                            item.endTime = item.date[1];
                        });
                        data.list7.forEach(item => {
                            item.startTime = item.date[0];
                            item.endTime = item.date[1];
                        });
                        if (!this.isNewEdit) {
                            data.id = this.formData.id;
                        }
                        console.log(data);
                        url(data).then(res => {
                            if (res.code === '2000') {
                                this.$message.success('保存成功');
                                // this.$emit('nextStep');
                                this.isEdit = true;
                                this.getDetail();
                                setTimeout(() => {
                                    this.$emit('getDetail');
                                }, 2000);
                            } else {
                                this.$message.error(res.msg || '保存失败');
                                this.$emit('hideLoading');
                            }
                        }).catch(error => {
                            console.error('API 请求失败:', error);
                            this.$message.error('保存失败');
                            this.$emit('hideLoading');
                        });
                    } else {
                    this.$message.error('请完善必填信息');
                    this.$emit('hideLoading');
                    return false;
                }
            });
        },
        handleResponseData(data) {
                if (!data) return;

                this.formData = data;
                this.list1 = this.processList(data.list1);
                this.list3 = data.list3 || [];
                this.list4 = this.processList(data.list4);
                this.list5 = data.list5 || [];
                this.list6 = data.list6 || [];
                this.list7 = this.processList(data.list7);
                this.list9 = data.list9 || [];
            },
            processList(list) {
                if (!list || !Array.isArray(list)) return [];

                return list.map(item => {
                    if (item && item.startTime && item.endTime) {
                        this.$set(item, 'date', [item.startTime, item.endTime]);
                    }
                    return item;
                });
            },
        addRow(list) {
            const listName = Object.keys(this.rowTemplates).find(key => this[key] === list);
            if (listName && this.rowTemplates[listName]) {
                list.push({ ...this.rowTemplates[listName] });
            }
        },
        delayRow(row, list) {
            const index = list.indexOf(row);
            if (index > -1) {
                list.splice(index, 1);
            }
        },
        backPage() {
            this.$emit('backPage');
        },
        navigateNext() {
            this.$emit('nextStep');
        },
        goBack() {
            this.$emit('backPage');
        },
        startEdit() {
            this.isEdit = false;
        },
        stopEdit() {
            this.isEdit = true;
        },
        saveAndNavigate() {
            // TODO: 添加保存逻辑
            this.navigateNext();
        },
        validateForm() {
            // TODO: 添加表单验证逻辑
            return true;
        },
        whetherReturnEmpty(value) {
        if (value && value == 1) {
            return 'changeValue';
        }
        }
    },
};
</script>

<style lang='stylus' scoped>
  @import '~css/stylus.styl';

  .title {
    font-weight: bold;
    font-size: 18px;
    color: #333333;
    line-height: 32px;
    margin: 21px 0;
    text-align: left;
    display: flex;
    justify-content: space-between;
  }

  .flexs {
    .el-form-item {
      display: flex;
      margin-bottom: 16px;
      align-items: end;
    }
  }

  .contentBox {
    width: 900px;
    margin: 0 auto;

    h3 {
      font-size: 16px;
      color: #333333;
      margin-bottom: 15px;
      line-height: 20px;
    }
  }

  >>>.el-form-item__content{
      width:100%
  }
  .delClass{
    cursor pointer;
    color: rgba(255, 130, 64, 1);
    font-size: 20px;
  }
  .container:after{
    content: '';
    display: block;
    clear: both;
  }
  .addBtnClass{
    width: 90px !important;
    height: 28px !important;
    background: #FFEFE6;
    border-radius: 17px 17px 17px 17px;
    border: 1px solid #FF8240;
    font-size: 14px !important;
    color: #FF8240;
    margin: 15px 0 18px !important;
    float: right;
    cursor pointer;
    text-align: center;
    line-height: 28px !important;
  }
  .tipss{
    font-size: 12px;
    color: #FF8240;
    margin-bottom: 19px;
    line-height: 18px;
    text-align: left;
    margin-top: 0px;
  }
  .line{
    width: 100%;
    height: 1px;
    background-color: #E5E5E5;
    margin-bottom: 16px;
  }
  .ml-10 {
    margin-left: 10px;
  }
  .btnBox {
    display: flex;
    justify-content: center;
  }

  >>>.el-button {
    width: 92px;
    padding: 0 !important;
    height: 30px;
    line-height: 30px;
    border-radius: 15px;
    font-size: 14px;
    font-weight: 400;
  }

  .saveBtn {
    width: 92px;
    height: 30px;
    background: #FFFFFF;
    border-radius: 15px;
    border: 1px solid #FF8240;
    box-sizing: border-box;
    font-size: 14px;
    color: #FF8240;
    text-align: center;
    line-height: 30px;
    cursor: pointer;
    transition: all 0.3s;
    font-weight: 400;

    &:hover {
      background: #fff5f0;
    }
  }
  .changeValue{
    >>>.el-form-item__label{
      color: #FF8240 !important;
    }
    >>>.el-input__inner{
      border-color: #FF8240 !important;
    }
  }
 >>>.el-input--prefix .el-input__inner{
    padding-right: 10px !important;
  }
  .changeValue{
    >>>.el-form-item__label{
      color: #FF8240 !important;
    }
    >>>.el-input__inner{
      border-color: #FF8240 !important;
      border:1px solid #FF8240 !important;
    }
  }
  </style>