<template>
  <div>
    <!-- 历史记录弹窗 -->
    <el-dialog
      title="材料记录"
      :visible.sync="dialogRecordVisible"
      width="35%"
      center
      :before-close="handleClose"
      :close-on-click-modal="false"
    >
      <div class="historyBox">
        <div
          class="historyListbox"
          v-for="(item, index) in historyList"
          :key="index"
        >
          <p>
            <span>版本{{ item.versionId }}</span>
            <el-button type="text" @click="toDetail(item)">查看详情</el-button>
          </p>
          <p>操作时间：{{ item.wfSaiSchoolVo.updateTime }}</p>
          <p>操作人：{{ item.wfSaiSchoolVo.createName }}</p>
          <!-- <p>新增原因：{{ item.reason }}</p> -->
        </div>
      </div>
      <paging
        :pageall="pageall"
        :pagenum="pagenum"
        @pageclick="pageclick"
      ></paging>
    </el-dialog>
  </div>
</template>

<script>
import paging from 'com/paging.vue';
import { formatDate } from '@utils/smallTools/index.js';
export default {
  components: {
    paging
  },
  props: {
    historyList: Array,
    dialogRecordVisible: Boolean,
    pageall: Number,
    pagenum: Number
  },
  data() {
    return {};
  },
  methods: {
    formatDate,
    toDetail(item) {
      this.$emit('toDetail', item);
    },
    handleClose() {
      this.$emit('handleClose');
    },
    pageclick(val) {
      this.$emit('pageclick');
    }
  }
};
</script>

<style lang="stylus" scoped>
>>>.el-dialog--center .el-dialog__body {
  padding: 0;
}

.historyBox {
  padding: 0 25px;
  max-height: 325px;
  overflow-y: scroll;

  .historyListbox {
    padding: 15px 0;
    border-bottom: 1px solid #EEEEEE;

    p {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 14px;
      color: #333333;
      margin: 0 0 10px;

      .el-button {
        padding: 0 !important;
      }
    }

    p:nth-of-type(1) {
      font-weight: bold;
    }

    .el-button {
      color: rgba(255, 130, 64, 1) !important;
    }
  }
}
</style>