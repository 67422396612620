<template>
  <div class="content-container">
    <el-main>
      <el-breadcrumb separator-class="el-icon-arrow-right" class="bread-bg conTop" :class="{transparent:translate}">
        <el-breadcrumb-item><span @click="toHome" style="cursor: pointer;">{{ title }}</span></el-breadcrumb-item>
        <el-breadcrumb-item v-show="titles != undefined" v-for="(item, index) in titles" :key="index">
          <template v-if="titles.length==2">
            <span v-if="index === 0" @click="toLink(item.path)" style="cursor: pointer;">{{ item.name }}</span>
            <span v-else>{{ item.name }}</span>
          </template>
          <template v-if="titles.length==3">
            <span v-if="index === 0 || index===1" @click="toLink(item.path)" style="cursor: pointer;">{{ item.name }}</span>
            <span v-else>{{ item.name }}</span>
          </template>
          <template v-if="titles.length==1">
            <span>{{ item.name }}</span>
          </template>
        </el-breadcrumb-item>
        <div>
          <el-button type="text" size="mini" v-show="showBtn" @click="cancelList">返回上一页</el-button>
        </div>
      </el-breadcrumb>
    </el-main>
  </div>
</template>

<script>
import throttle from 'lodash/throttle';
export default {
  props: ['title', 'titles', 'showBtn', 'route', 'id', 'translate', 'isBack', 'path', 'path1', 'path2'],
  data() {
    return {};
  },
  mounted() {
    this.handleScrollThrottled = throttle(this.handleScroll, 100);
    window.addEventListener('scroll', this.handleScrollThrottled);
  },
  beforeDestroy() {
    // 移除滚动事件监听器
    window.removeEventListener('scroll', this.handleScrollThrottled);
  },
  methods: {
    // 返回列表路由
    cancelList() {
      if (this.isBack) {
        this.$emit('back');
       } else {
        this.$router.go(-1);
      }
    },
    // 滚动条滚动事件
    handleScroll() {
      const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
      if (scrollTop > 22) {
        document.querySelector('.conTop').classList.add('txtColor');
      } else {
        document.querySelector('.conTop').classList.remove('txtColor');
      }
    },
    toHome() {
      this.$router.push('/');
    },
    toLink(path) {
      this.$router.push(path);
    },
  }
};
</script>

<style lang="stylus" scoped>
@import '~css/stylus.styl';
/deep/ .el-main{
  display: flex
  justify-content: center
}
.content-container .conTop {
  overflow: hidden;
  width: $width;
  height: 39px;
  text-align: left;
  padding: 0 20px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background: #fff;
  box-sizing: border-box;
  margin: 20px 0;
  border-radius: 10px 10px 10px 10px;
  font-size: 12px;
  position: fixed;
  z-index 9;
  box-shadow: 0px 5px 10px 1px rgba(0,0,0,0.16);

}
/deep/ .el-button--text{
  color: #333
}
.content-container .conTop>img {
  margin-right: 6px;
}

.bread-bg {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 44px;
  line-height: 44px;
  background: rgba(232, 247, 255, 1);
  /* background:url(../../assets/ifram-head.png) repeat-x; */
  background-size: 100% 100%;
}

.bread-bg i {
  margin: 0 6px;
}

.bread-bg div {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 15px;
}
.transparent{
  background: rgba(255, 255, 255, 0.2) !important;
  >>>.el-breadcrumb__inner{
    color:#fff !important;
  }
  .el-button{
    color:#fff !important;
  }
  >>>.el-breadcrumb__separator{
    color:#fff !important;
  }
}
>>>.el-breadcrumb .el-breadcrumb__item:last-of-type .el-breadcrumb__separator{
  display: none !important;
}
.txtColor{
  background: #FFFFFF !important;
  >>>.el-breadcrumb__inner{
    color:#606266 !important;
  }
  .el-button{
    color:#606266 !important;
  }
  >>>.el-breadcrumb__separator{
    color:#606266 !important;
  }

}
</style>
