// 下载文件
export const downloadFile = (
  fileUrl,
  name,
  isBlob = false,
  fileType = ".xlsx"
) => {
  // 获取文件blob
  function getBlob(url) {
    return new Promise((resolve) => {
      const xhr = new XMLHttpRequest();
      xhr.open("GET", url, true);
      xhr.responseType = "blob";
      xhr.onload = () => {
        if (xhr.status === 200) {
          resolve(xhr.response);
        }
      };
      xhr.send();
    });
  }

  function saveAs(blob, filename) {
    var link = document.createElement("a");
    link.download = filename;
    link.style.display = "none";
    link.href = window.URL.createObjectURL(blob);
    document.body.appendChild(link);
    link.click();
    URL.revokeObjectURL(link.href); // 释放URL 对象
    document.body.removeChild(link);
  }

  return new Promise((resolve, reject) => {
    try {
      if (isBlob) {
        const blob = fileUrl;
        saveAs(
          blob,
          `${name || new Date().getTime()}${
            name.indexOf(".") > -1 ? "" : fileType
          }`
        );
        resolve({
          code: 2000,
          msg: "成功",
        });
      } else {
        getBlob(fileUrl).then((blob) => {
          saveAs(
            blob,
            `${name || new Date().getTime()}${
              name.indexOf(".") > -1 ? "" : fileType
            }`
          );
          resolve({
            code: 2000,
            msg: "成功",
          });
        });
      }
    } catch (error) {
      reject({
        code: "5000",
        data: error,
        msg: "失败",
      });
    }
  });
};
