import axios from "axios";
import { Message } from "element-ui";
import store from "../store";
import { getToken } from "@/utils/auth";
import { getRandomString } from "@utils/smallTools/index.js";
import Config from "@/settings";
import rsa from "@utils/encryptedFile/rsa.js";
import aes from "@utils/encryptedFile/aes.js";

// import { JYTAPP5 } from '@utils/customConstant/index.js';

const passObj = {};
// 创建axios实例
const service = axios.create({
  baseURL: process.env.VUE_APP_JYKJ_HTTPS, // api 的 base_url
  timeout: Config.timeout, // 请求超时时间
});

// request拦截器
service.interceptors.request.use(
  (config) => {
    if (getToken()) {
      config.headers.JYTAPP4 = getToken(); // 让每个请求携带自定义token 请根据实际情况自行修改
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);
// response 拦截器
service.interceptors.response.use(
  (response) => {
    console.log("接口返回", response.config.url, response);
    const headers = response.headers;

    if (headers.jytresp1 != 1) {
      const code = headers.jytappcode;
      const key = passObj[code].key;
      const iv = passObj[code].iv;
      if (response.data.interceptionData) {
        response.data = aes.decode(response.data.interceptionData, key, iv);
      }
    }
    if (response.data.code == "M5012") {
      // 登录过期
      console.log(store);
      store.dispatch("callSetIshow", true);

      return Promise.reject(response);
    }
    return response.data;
  },
  (error) => {
    // errorStoring(error.response);
    // 兼容blob下载出错json提示
    if (error.toString().indexOf("Error: Network Error") !== -1) {
      Message.error({
        message: "服务器访问失败",
        duration: 5000,
      });
      return Promise.reject(error);
    }
    if (error.toString().indexOf("Error: timeout") !== -1) {
      Message.error({
        message: "请求超时",
        duration: 5000,
      });
      return Promise.reject(error);
    }
    if (
      error.response.data instanceof Blob &&
      error.response.data.type.toLowerCase().indexOf("json") !== -1
    ) {
      const reader = new FileReader();
      reader.readAsText(error.response.data, "utf-8");
      reader.onload = function (e) {
        const errorMsg = JSON.parse(reader.result).message;
        Message.error({
          message: errorMsg,
          duration: 5000,
        });
      };
    }
    return Promise.reject(error);
  }
);

const request = (options, codingFlag = true) => {
  // 加密对数据ase加密
  const code = getRandomString() + new Date().getTime();
  const key = getRandomString();
  const iv = getRandomString();
  const headers = options.headers || {};
  headers.JYTAPP1 = rsa.encode(key); // 对称密钥非对称加密
  headers.JYTAPP2 = rsa.encode(iv); // 对称偏移量非对称加密
  headers.JYTAPP3 = rsa.encode(code); // 对称加密code非对称加密
  headers.JYTAPP4 = localStorage.getItem("token") || ""; // 对称加密code非对称加密
  // headers.JYTAPP5 = JYTAPP5; // 对称加密code非对称加密

  // if (sessionStorage.getItem('doctorId')) {
  //   headers.JYTAPP7 = rsa.encode(sessionStorage.getItem('doctorId')); // 对称加密code
  // };
  // if (sessionStorage.getItem('doctorCode')) {
  //   headers.JYTAPP8 = rsa.encode(sessionStorage.getItem('doctorCode')); // 对称加密code
  // };
  options.headers = headers;
  passObj[code] = {
    key,
    iv,
  };
  // 加密参数
  if (codingFlag) {
    console.log("接口入参：", options.url, options.data);
    options.data = aes.encode(options.data, key, iv)
      ? {
          interceptionData: aes.encode(options.data, key, iv),
        }
      : options.data;
  }
  // 发送请求
  return service({
    ...options,
  });
};
export default request;
