<template>
  <div class="infobigBox">
    <leftNav></leftNav>
    <div class="infoBox" v-loading='loading'>
      <p class="title">
        签名管理<span class="editClass" @click="uploadSign">上传签名</span>
      </p>
      <div class="infopng">
        <div class="signList" v-for="(item, index) in list" :key="index">
          <p class="imgBoxs" @click="delSign(item, index)">
            <img :src="item.signUrl" alt="" />
            <span class="mask">
              <i class="el-icon-delete-solid"></i>
            </span>
          </p>
          <p>{{ item.name }}</p>
        </div>
        <el-empty v-if="list.length == 0" :image="require('../../assets/noData.png')"></el-empty>
      </div>
      <!-- 删除弹窗 -->
      <el-dialog
        title="删除"
        :visible.sync="dialogVisible"
        width="417px"
        center
        :close-on-click-modal="false"
        :show-close="false"
      >
        <span class="tipss">确认删除此签名吗？</span>
        <div class="footBtn">
          <p @click="cancel">取消</p>
          <p @click="sure">确定</p>
        </div>
      </el-dialog>
      <!-- 上传签名弹窗 -->
      <el-dialog
        title="上传签名"
        :visible.sync="upLoaddialogVisible"
        width="417px"
        center
        :close-on-click-modal="false"
        :show-close="false"
      >
        <div class="uploadBox">
          <p class="signName">
            <font style="width: 88px">签名名称：</font>
            <el-input placeholder="请输入签名名称" inline v-model="signName"></el-input>
          </p>
          <p class="signName">
            <font style="width: 88px">签名：</font>
            <el-input placeholder="请输入签名" inline v-model="createName" @input="createSign"></el-input>
          </p>
          <div class="signName borders">
            <!-- <VueSignaturePad
              id="signature"
              width="100%"
              height="200px"
              ref="signaturePad"
              :options='options'
              :default-action="'draw'"
            ></VueSignaturePad> -->
            <!-- <font>上传图片：</font>
            <el-upload
              ref="uploadComponent"
              class="avatar-uploader"
              action=""
              :show-file-list="false"
              :http-request="uploadSectionFile"
              accept=".jpg,.jpeg,.png"
              :limit="1"
              :multiple="false"
            >
              <img v-if="signLogo" :src="signLogo" class="avatar" />
              <div v-else class="avatar-uploader-icon">
                <i class="el-icon-upload"></i>上传图片
              </div>
            </el-upload> -->
            <!-- <span v-if="signLogo" class="reload" @click="handleRemoveAndReset">重新上传</span> -->
            <!-- <div class="operation">
              <span  class="reload" @click="undo">撤销</span>
            </div> -->
          </div>
          <!-- <div class="tips">请在以上区域绘制签名。</div> -->
          <p class="signName">
            <font>预览：</font>
            <span class="imgBox">
              <img :src="signLogo" alt="" />
            </span>
          </p>
        </div>
        <div class="footBtn">
          <el-button @click="upLoadcancel">取消</el-button>
          <el-button :loading="loading" :disabled="loading" @click="upLoadsure">确定</el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import leftNav from "./leftNav";
import { uploadPathFile } from "api/index.js";
import { getCmsSignList, cmsSignAdd, cmsSignDel, getSignImageByName } from "api/application.js";
export default {
  components: {
    leftNav
  },
  data() {
    return {
      form: {},
      show: false,
      dialogVisible: false,
      upLoaddialogVisible: false,
      signName: "",
      signLogo: "",
      list: [],
      delItem: {},
      pointData: [],
      options: {
        penColor: "#000",
        backgroundColor: '#fff',
        onBegin() {
          this.pointData = this.toData() || [];
        }
      },
      loading: false,
      createName: ""
    };
  },
  methods: {
    getList() {
      const data = {
        startDate: "",
        endDate: "",
      };
      getCmsSignList(data).then((res) => {
        if (res.code == 2000) {
          this.list = res.data.list;
          this.list.forEach((item) => {
            item.show = false;
          });
        } else {
          this.$message({
            type: "error",
            message: res.msg,
          });
        }
      });
    },
    uploadSign() {
      this.upLoaddialogVisible = true;
      this.signName = "";
      this.signLogo = "";
    },
    delSign(item, index) {
      this.dialogVisible = true;
      this.delItem = item;
    },
    cancel() {
      // this.undo();
      this.dialogVisible = false;
    },
    sure() {
      cmsSignDel({ id: this.delItem.id }).then((res) => {
        if (res.code == 2000) {
          this.getList();
          this.dialogVisible = false;
          this.delItem = {};
          this.$message({
            type: "success",
            message: "删除成功",
          });
        } else {
          this.$message({
            type: "error",
            message: res.msg,
          });
        }
      });
    },
    upLoadcancel() {
      this.upLoaddialogVisible = false;
      this.signLogo = null;
      this.signName = "";
      // this.undo();
      // this.$refs.uploadComponent.clearFiles();
    },
    upLoadsure() {
      // if (!this.signLogo) {
      //   this.$message({
      //     type: "error",
      //     message: "请上传签名图片",
      //   });
      //   return;
      // }
      if (!this.signName) {
        this.$message({
          type: "error",
          message: "请输入签名名称",
        });
        return;
      }
      this.loading = true;
      // this.$nextTick(() => {
      //   const signatureData = this.$refs.signaturePad.saveSignature();
      //   if (signatureData.isEmpty) {
      //     this.$message({
      //       type: "error",
      //       message: "请签名后再上传",
      //     });
      //     return;
      //   }
      const data = {
        name: this.signName,
        signUrl: this.signLogo,
        userId: localStorage.getItem("userInfo").id,
        userName: localStorage.getItem("userInfo").name,
      };
      cmsSignAdd(data).then((res) => {
        if (res.code == 2000) {
          this.getList();
          this.upLoaddialogVisible = false;
          this.signLogo = null;
          this.signName = "";
          // this.$refs.uploadComponent.clearFiles();
          this.$message({
            type: "success",
            message: "上传成功",
          });
          this.loading = false;
        } else {
          this.$message({
            type: "error",
            message: res.msg,
          });
          this.loading = false;
        }
      // });
      });
    },
    handleAvatarSuccess(res, file) {
      this.signLogo = URL.createObjectURL(file.raw);
    },
    handleRemoveAndReset() {
      this.signLogo = null;
      this.signName = "";
      this.$refs.uploadComponent.clearFiles(); // 清除上传组件的文件列表
    },
    uploadSectionFile(file) {
      const isJPG = file.file.type === "image/jpeg";
      const isPNG = file.file.type === "image/png";
      const isLt2M = file.file.size / 1024 / 1024 < 2;

      if (!isJPG && !isPNG) {
        this.$message.error("上传图片只能是 JPG/JPEG 格式!");
        return;
      }

      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 2MB!");
        return;
      }

      const formData = new FormData();
      formData.append("file", file.file);
      formData.append("path", "usersign");

      uploadPathFile(formData).then((res) => {
        if (res.code == 2000) {
          // this.$message({
          //   type: "success",
          //   message: "上传成功",
          // });
          this.signLogo = res.data;
        } else {
          this.$message({
            type: "error",
            message: res.msg,
          });
        }
      });
    },
    undo() {
      this.$refs.signaturePad.undoSignature();
    },
    rollBack() {
      const data = this.$refs.signaturePad.toData();
      if ((data && data.length) < this.pointData.length) {
        data.push(this.pointData[data.length]);
      }
      this.$refs.signaturePad.fromData(data, { clear: false });
    },
    createSign() {
      this.loading = true;
      getSignImageByName({ name: this.createName }).then((res) => {
        if (res.code == 2000) {
          this.signLogo = res.data;
          this.loading = false;
        } else {
          this.$message({
            type: "error",
            message: res.msg,
          });
          this.loading = false;
        }
      });
    },
  },
  mounted() {
    this.getList();
  },
};
</script>

  <style  lang='stylus' scoped>
  @import '~css/stylus.styl';

  .infobigBox {
    width: 1200px;
    height: 100%;
    display: flex;
    justify-content: space-between;
    background-color: #F5F7FA;
    padding-bottom: 60px;
    margin: 0 auto;
  }

  .infoBox {
    width: 940px;
    background-color: #fff;
    margin: 27px 2% 27px;
    box-sizing: border-box;
    border-radius: 12px 12px 12px 12px;
    padding: 23px 0;

    .title {
      font-size: 20px;
      color: #333333;
      height: 23px;
      position: relative;
      padding-left: 14px;
      font-weight: bold;
      padding: 0 20px 21px 34px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .title::after {
      content: '';
      position: absolute;
      left: 20px;
      top: 2px;
      width: 4px;
      height: 20px;
      display: block;
      background-color: $themeColor;
      border-radius: 2px;
    }

    .infopng {
      border-top: 1px solid #EEEEEE;
      padding: 19px 20px 0;
      margin: 0 auto 19px;
      box-sizing: border-box;

      .signList {
        width: 209px;
        float: left;
        margin-right: 16px;
        margin-bottom: 20px;

        .imgBoxs {
          width: 209px;
          height: 113px;
          border-radius: 14px 14px 14px 14px;
          border: 1px solid #EEEEEE;
          margin-top: 0px;
          position: relative;
          cursor: pointer;
          overflow: hidden;
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            width: 100%;
            height: auto;
          }

          .mask {
            cursor: pointer;
            position: absolute;
            top: 0;
            left: 0;
            background: rgba(0, 0, 0, 0.5);
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 14px 14px 14px 14px;
            color: #FFFFFF;
            font-size: 24px;
            pointer-events: none;
            opacity: 0;
            transition: all 0.3s;
          }
        }
        .imgBoxs:hover .mask {
          opacity: 1;
        }

        p {
          margin-top: 10px;
          font-size: 14px;
          color: #303243;
          text-align: center;
        }
      }

      .signList:nth-of-type(4n) {
        margin-right: 0;
      }
    }

    .infopng::after {
      content: '';
      display: block;
      clear: both;
    }
  }
  .avatar-uploader {
    >>>.el-upload{
        position: relative;
        width: 149px;
        height: 81px;
        border-radius: 6px 6px 6px 6px;
        border: 1px solid #D9DBDE;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;

    }

  }

  .avatar-uploader .el-upload {
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;

  }

  .avatar {
    width: 100%;
    height: auto;
    display: block;
  }
  .editClass {
    width: 92px;
    height: 26px;
    background: #FFFFFF;
    border-radius: 15px 15px 15px 15px;
    border: 1px solid #FF8240;
    font-weight: 400;
    font-size: 14px;
    color: #FF8240;
    text-align: center;
    line-height: 26px;
    float: right;
    cursor: pointer;
  }

  >>>.el-dialog__header {
    padding: 19px 0 0 !important;
    text-align: center;
    font-size: 18px;
    line-height: 20px;
  }

  >>>.el-dialog--center .el-dialog__body {
    padding: 0px !important;
  }

  .tipss {
    font-size: 16px;
    color: #333333;
    line-height: 20px;
    display: block;
    margin: 41px auto;
    text-align: center;
  }

  .footBtn {
    box-sizing: border-box;
    padding: 0 26px 18px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .el-button {
      width: 172px;
      height: 51px;
      text-align: center;
      line-height: 51px;
      font-weight: 400;
      font-size: 16px;
      color: #FF8240;
      border-radius: 90px 90px 90px 90px;
      cursor: pointer;
      padding: 0;
    }

    .el-button:nth-of-type(1) {
      border: 1px solid #FF8240;
      background: #FFFFFF;
    }

    .el-button:nth-of-type(2) {
      background: #FF8240;
      color: #FFFFFF ;
    }
    p {
      width: 172px;
      height: 51px;
      text-align: center;
      line-height: 51px;
      font-weight: 400;
      font-size: 16px;
      color: #FF8240;
      border-radius: 90px 90px 90px 90px;
      cursor: pointer;
      padding: 0;
    }

    p:nth-of-type(1) {
      border: 1px solid #FF8240;
    }

    p:nth-of-type(2) {
      background: #FF8240;
      color: #FFFFFF;
    }
  }
  .uploadBox{
    margin: 22px auto 24px;
    padding: 0 37px;
    box-sizing: border-box;
    .signName{
      display: flex;
      align-items: center;
      margin-bottom: 15px;
      overflow: hidden;
      position: relative;
      font{
        width: 70px;
      }
      .reload{
        font-size: 12px;
        color: #FF8240;
        cursor pointer;
        margin-left: 9px;
        margin-top: 65px;

      }
      // 添加样式以确保签名板显示
      #signature-pad {
        border: 1px solid #ccc;
        border-radius: 4px;
        background-color: #fff;
      }
    }

  }
  .operation{
    position: absolute;
    bottom:10px;
    left:50%;
    transform: translateX(-50%);
    text-align: center;

    .reload{
      font-size: 16px;
      color: #FF8240;
      cursor pointer;
    }
  }
  .avatar-uploader-icon{
    font-size: 13px;
    color: #666666;
    display: flex;
    align-items: center;
    justify-content: center;

    i{
      font-size: 30px;
      color: #FFC8AB;
      margin-right: 11px;
    }
  }
  .tips{
    font-weight: 400;
    font-size: 16px;
    color: #666666;
    margin: 15px 0 12px
    text-align: center;
  }
  .imgBox{
    width: 149px;
    height: 81px;
    border-radius: 6px 6px 6px 6px;
    border: 1px solid #D9DBDE;
    display: flex;
    overflow: hidden;
    img{
        display: block;
        width 100%;

    }

  }
  .el-empty{
    padding:80px 0;
    margin: 0 auto;
  }

</style>
  <style>
#app {
  background-color: #f5f7fa;
}
</style>