<template>
    <div>
      <div class="title">第五部分：申请人家庭成员信息
        <div class="btnBox">
            <!-- <el-button class="saveBtn" plain round @click="goBack">返回上一页</el-button> -->
            <template v-if="isEdit && type!='detail' && finalDraft!=1">
            <el-button type="primary" class="ml-10" round @click="startEdit">开始修改</el-button>
            <!-- <el-button type="primary" class="ml-10" round @click="navigateNext">跳转下一页</el-button> -->
            </template>
            <template  v-else-if="type!='detail' && !isEdit">
            <p class="saveBtn ml-10" v-show="!isEdit" @click="stopEdit">退出修改</p>
            <el-button type="primary" class="ml-10" round @click="saveAndNavigate">确认修改</el-button>
            </template>
        </div>
    </div>
      <div class="contentBox">
        <h3>申请人父亲信息</h3>
        <el-form :model="formData" inline class="flexs" label-position="left">
            <el-row>
                <el-col :span="5" >
                    <el-form-item label="姓名:" label-width="62px" :class="whetherReturnEmpty(formData.editDto.fatherName)">
                        <el-input
                        v-model="formData.fatherName"
                        placeholder="请输入"
                        size="medium"
                        :disabled="isEdit"
                        />
                    </el-form-item>
                </el-col>
                <el-col :span="7" >
                    <el-form-item label="出生日期：" label-width="117px" :class="whetherReturnEmpty(formData.editDto.fatherBirthday)">
                        <el-date-picker
                        v-model="formData.fatherBirthday"
                        type="date"
                        style="width:150px"
                        :disabled="isEdit"
                        ></el-date-picker>
                    </el-form-item>
                </el-col>
                <el-col :span="6" >
                    <el-form-item label="毕业学校:" label-width="117px" :class="whetherReturnEmpty(formData.editDto.fatherGraduationSchool)">
                        <el-input
                        v-model="formData.fatherGraduationSchool"
                        placeholder="请输入"
                        size="medium"
                        :disabled="isEdit"
                        />
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="专业:" label-width="90px" :class="whetherReturnEmpty(formData.editDto.fatherMajor)">
                        <el-input
                        v-model="formData.fatherMajor"
                        placeholder="请输入"
                        size="medium"
                        :disabled="isEdit"
                        />
                    </el-form-item>
                </el-col>

            </el-row>
            <el-row>
                <el-col :span="10" >
                    <el-form-item label="学习起止时间：" label-width="160px" :class="whetherReturnEmpty(formData.editDto.fatherStudyDate)">
                        <el-date-picker
                        v-model="formData.fatherStudyDate"
                        type="daterange"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        style="width:240px"
                        value-format="yyyy-MM-dd"
                        :disabled="isEdit"
                        ></el-date-picker>
                    </el-form-item>
                </el-col>
                <el-col :span="5" >
                    <el-form-item label="学历：" label-width="73px" :class="whetherReturnEmpty(formData.editDto.fatherDegree)">
                        <el-input
                        v-model="formData.fatherDegree"
                        placeholder="请输入"
                        size="medium"
                        :disabled="isEdit"
                        />
                    </el-form-item>
                </el-col>
                <el-col :span="8" >
                    <el-form-item label="现工作单位：" label-width="164px" :class="whetherReturnEmpty(formData.editDto.fatherEmployer)">
                        <el-input
                        v-model="formData.fatherEmployer"
                        placeholder="请输入"
                        size="medium"
                        :disabled="isEdit"
                        />
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="18">
                    <el-form-item label="单位地址:"  label-width="80px" :class="whetherReturnEmpty(formData.editDto.fatherEmployerAddress)">
                    <el-input
                        v-model="formData.fatherEmployerAddress"
                        placeholder="请输入"
                        size="medium"
                        :disabled="isEdit"
                        class="matching-input"

                    />
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="邮编:"  label-width="73px" :class="whetherReturnEmpty(formData.editDto.fatherEmployerPostalCode)">
                    <el-input
                        v-model="formData.fatherEmployerPostalCode"
                        placeholder="请输入"
                        size="medium"
                        :disabled="isEdit"
                    />
                    </el-form-item>
                </el-col>
          </el-row>
          <el-row>
                <el-col :span="7" >
                    <el-form-item label="职务/职位:" label-width="110px" :class="whetherReturnEmpty(formData.editDto.fatherPosition)">
                        <el-input
                        v-model="formData.fatherPosition"
                        placeholder="请输入"
                        size="medium"
                        :disabled="isEdit"
                        />
                    </el-form-item>
                </el-col>
                <el-col :span="5" >
                    <el-form-item label="年收入:" label-width="80px" :class="whetherReturnEmpty(formData.editDto.fatherAnnualIncome)">
                        <el-input
                        v-model="formData.fatherAnnualIncome"
                        placeholder="请输入"
                        size="medium"
                        :disabled="isEdit"
                        />
                    </el-form-item>
                </el-col>
                <el-col :span="6" >
                    <el-form-item label="单位电话:" label-width="117px" :class="whetherReturnEmpty(formData.editDto.fatherEmployerPhone)">
                        <el-input
                        v-model="formData.fatherEmployerPhone"
                        placeholder="请输入"
                        size="medium"
                        :disabled="isEdit"
                        />
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="手机号码:" label-width="117px" :class="whetherReturnEmpty(formData.editDto.fatherMobilePhone)">
                        <el-input
                        v-model="formData.fatherMobilePhone"
                        placeholder="请输入"
                        size="medium"
                        :disabled="isEdit"
                        />
                    </el-form-item>
                </el-col>
            </el-row>
            <h3>申请人母亲信息</h3>
            <el-row>
                <el-col :span="5" >
                    <el-form-item label="姓名:" label-width="54px" :class="whetherReturnEmpty(formData.editDto.motherName)">
                        <el-input
                        v-model="formData.motherName"
                        placeholder="请输入"
                        size="medium"
                        :disabled="isEdit"
                        />
                    </el-form-item>
                </el-col>
                <el-col :span="7" >
                    <el-form-item label="出生日期：" label-width="117px" :class="whetherReturnEmpty(formData.editDto.motherBirthday)">
                        <el-date-picker
                        v-model="formData.motherBirthday"
                        type="date"
                        style="width:150px"
                        :disabled="isEdit"
                        ></el-date-picker>
                    </el-form-item>
                </el-col>
                <el-col :span="6" >
                    <el-form-item label="毕业学校:" label-width="117px" :class="whetherReturnEmpty(formData.editDto.motherGraduationSchool)">
                        <el-input
                        v-model="formData.motherGraduationSchool"
                        placeholder="请输入"
                        size="medium"
                        :disabled="isEdit"
                        />
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="专业:" label-width="90px" :class="whetherReturnEmpty(formData.editDto.motherMajor)">
                        <el-input
                        v-model="formData.motherMajor"
                        placeholder="请输入"
                        size="medium"
                        :disabled="isEdit"
                        />
                    </el-form-item>
                </el-col>

            </el-row>
            <el-row>
                <el-col :span="10" >
                    <el-form-item label="学习起止时间：" label-width="160px" :class="whetherReturnEmpty(formData.editDto.motherStudyDate)">
                        <el-date-picker
                        v-model="formData.motherStudyDate"
                        type="daterange"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        style="width:240px"
                        :disabled="isEdit"
                        value-format="yyyy-MM-dd"
                        ></el-date-picker>
                    </el-form-item>
                </el-col>
                <el-col :span="5" >
                    <el-form-item label="学历：" label-width="73px" :class="whetherReturnEmpty(formData.editDto.motherDegree)">
                        <el-input
                        v-model="formData.motherDegree"
                        placeholder="请输入"
                        size="medium"
                        :disabled="isEdit"
                        />
                    </el-form-item>
                </el-col>
                <el-col :span="8" >
                    <el-form-item label="现工作单位：" label-width="164px" :class="whetherReturnEmpty(formData.editDto.motherEmployer)">
                        <el-input
                        v-model="formData.motherEmployer"
                        placeholder="请输入"
                        size="medium"
                        :disabled="isEdit"
                        />
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="18">
                    <el-form-item label="单位地址:"  label-width="80px" :class="whetherReturnEmpty(formData.editDto.motherEmployerAddress)">
                    <el-input
                        v-model="formData.motherEmployerAddress"
                        placeholder="请输入"
                        size="medium"
                        :disabled="isEdit"
                        class="matching-input"

                    />
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="邮编:"  label-width="73px" :class="whetherReturnEmpty(formData.editDto.motherEmployerPostalCode)">
                    <el-input
                        v-model="formData.motherEmployerPostalCode"
                        placeholder="请输入"
                        size="medium"
                        :disabled="isEdit"
                    />
                    </el-form-item>
                </el-col>
          </el-row>
          <el-row>
                <el-col :span="7" >
                    <el-form-item label="职务/职位:" label-width="110px" :class="whetherReturnEmpty(formData.editDto.motherPosition)">
                        <el-input
                        v-model="formData.motherPosition"
                        placeholder="请输入"
                        size="medium"
                        :disabled="isEdit"
                        />
                    </el-form-item>
                </el-col>
                <el-col :span="5" >
                    <el-form-item label="年收入:" label-width="80px" :class="whetherReturnEmpty(formData.editDto.motherAnnualIncome)">
                        <el-input
                        v-model="formData.motherAnnualIncome"
                        placeholder="请输入"
                        size="medium"
                        :disabled="isEdit"
                        />
                    </el-form-item>
                </el-col>
                <el-col :span="6" >
                    <el-form-item label="单位电话:" label-width="117px" :class="whetherReturnEmpty(formData.editDto.motherEmployerPhone)">
                        <el-input
                        v-model="formData.motherEmployerPhone"
                        placeholder="请输入"
                        size="medium"
                        :disabled="isEdit"
                        />
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="手机号码:" label-width="117px" :class="whetherReturnEmpty(formData.editDto.motherMobilePhone)">
                        <el-input
                        v-model="formData.motherMobilePhone"
                        placeholder="请输入"
                        size="medium"
                        :disabled="isEdit"
                        />
                    </el-form-item>
                </el-col>
            </el-row>
            <h3>申请人配偶信息：（如无，可以不填写）</h3>
            <el-row>
                <el-col :span="5" >
                    <el-form-item label="姓名:" label-width="54px" :class="whetherReturnEmpty(formData.editDto.spouseName)">
                        <el-input
                        v-model="formData.spouseName"
                        placeholder="请输入"
                        size="medium"
                        :disabled="isEdit"
                        />
                    </el-form-item>
                </el-col>
                <el-col :span="7" >
                    <el-form-item label="与申请人关系:" label-width="170px" :class="whetherReturnEmpty(formData.editDto.spouseGraduationSchool)">
                        <el-input
                        v-model="formData.spouseRelationship"
                        placeholder="请输入"
                        size="medium"
                        :disabled="isEdit"
                        />
                    </el-form-item>
                </el-col>
                <el-col :span="7" >
                    <el-form-item label="出生日期：" label-width="117px" :class="whetherReturnEmpty(formData.editDto.spouseBirthday)">
                        <el-date-picker
                        v-model="formData.spouseBirthday"
                        type="date"
                        style="width:150px"
                        :disabled="isEdit"
                        ></el-date-picker>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="18">
                    <el-form-item label="单位地址:"  label-width="80px" :class="whetherReturnEmpty(formData.editDto.spouseEmployerAddress)">
                    <el-input
                        v-model="formData.spouseEmployerAddress"
                        placeholder="请输入"
                        size="medium"
                        :disabled="isEdit"
                        class="matching-input"

                    />
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="邮编:"  label-width="73px" :class="whetherReturnEmpty(formData.editDto.spouseEmployerPostalCode)">
                    <el-input
                        v-model="formData.spouseEmployerPostalCode"
                        placeholder="请输入"
                        size="medium"
                        :disabled="isEdit"
                    />
                    </el-form-item>
                </el-col>
          </el-row>
          <el-row>
                <el-col :span="7" >
                    <el-form-item label="职务/职位:" label-width="110px" :class="whetherReturnEmpty(formData.editDto.spousePosition)">
                        <el-input
                        v-model="formData.spousePosition"
                        placeholder="请输入"
                        size="medium"
                        :disabled="isEdit"
                        />
                    </el-form-item>
                </el-col>
                <el-col :span="5" >
                    <el-form-item label="年收入:" label-width="80px" :class="whetherReturnEmpty(formData.editDto.spouseAnnualIncome)">
                        <el-input
                        v-model="formData.spouseAnnualIncome"
                        placeholder="请输入"
                        size="medium"
                        :disabled="isEdit"
                        />
                    </el-form-item>
                </el-col>
                <el-col :span="6" >
                    <el-form-item label="单位电话:" label-width="117px" :class="whetherReturnEmpty(formData.editDto.spouseEmployerPhone)">
                        <el-input
                        v-model="formData.spouseEmployerPhone"
                        placeholder="请输入"
                        size="medium"
                        :disabled="isEdit"
                        />
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="手机号码:" label-width="117px" :class="whetherReturnEmpty(formData.editDto.spouseMobilePhone)">
                        <el-input
                        v-model="formData.spouseMobilePhone"
                        placeholder="请输入"
                        size="medium"
                        :disabled="isEdit"
                        />
                    </el-form-item>
                </el-col>
            </el-row>
            <h3>申请人子女信息：（如无，可以不填写）</h3>
            <el-table :data="childList" border>
            <el-table-column prop="date" label="姓名"  align="center">
                <template slot-scope="scope">
                    <el-input :class="getClassName(scope.row,scope.row.editDto.childName)" v-model="scope.row.childName" placeholder="请输入" :disabled="isEdit"></el-input>
                </template>
            </el-table-column>
            <el-table-column prop="name" label="性别"  align="center">
                <template slot-scope="scope">
                    <!-- <el-input v-model="scope.row.childGender" placeholder="请输入" :disabled="isEdit"></el-input> -->
                    <el-select :class="getClassName(scope.row,scope.row.editDto.childGender)" v-model="scope.row.childGender" placeholder="请选择" :disabled="isEdit">
                        <el-option label="男" :value="1" />
                        <el-option label="女" :value="0" />
                    </el-select>
                </template>
            </el-table-column>
            <el-table-column prop="address" label="出生日期"  align="center" width="280">
                <template slot-scope="scope">
                    <el-form-item label="" style="margin-bottom: 0px;">
                        <el-date-picker
                        :class="getClassName(scope.row,scope.row.childBirthday)"
                        v-model="scope.row.childBirthday"
                        type="date"
                        :disabled="isEdit"
                        ></el-date-picker>
                    </el-form-item>
                </template>
            </el-table-column>
            <el-table-column prop="address" label="就读年级"  align="center">
                <template slot-scope="scope">
                    <el-input  :class="getClassName(scope.row,scope.row.editDto.childGrade)" v-model="scope.row.childGrade" placeholder="请输入" :disabled="isEdit"></el-input>
                </template>
            </el-table-column>
            <el-table-column prop="address" label="移除" width="50" align="center">
                <template slot-scope="scope">
                    <i class="el-icon-remove delClass" v-if="!isEdit" @click="delayRow(scope.row)"></i>
                </template>
            </el-table-column>
        </el-table>
        <div class="container"><el-button class="addBtnClass" round plain @click="addRow" :disabled="isEdit">+添加一行</el-button></div>
        </el-form>
      </div>
    </div>
  </template>

  <script>
  import { wfSaiFamilyGetDetailByMaterialId, wfSaiFamilyAdd, wfSaiFamilyUpdate } from '@/assets/api/application';
  export default {
    name: "Partfive",
    props: {
      type: {
        type: String,
        default: ''
      },
      finalDraft: {
        type: Number,
        default: 0
      },
      id: {
      type: [String, Number],
      default: ''
    },
    },
    data() {
      return {
        formData: {
            editDto: {}
        },
        childList: [],
        isEdit: true,
        isNewEdit: false,
      };
    },
    mounted() {},
    created() {
        this.getDetail();
    },
    methods: {
        getDetail() {
          this.loading = true;
          wfSaiFamilyGetDetailByMaterialId({ materialId: this.id }).then(res => {
            if (res.code == '2000') {
              this.formData = res.data;
              this.childList = res.data.childList;
              if (this.formData.motherStudyStartDate && this.formData.motherStudyEndDate) {
                this.$set(this.formData, 'motherStudyDate', [this.formData.motherStudyStartDate, this.formData.motherStudyEndDate]);
              }
              if (this.formData.fatherStudyStartDate && this.formData.fatherStudyEndDate) {
                this.$set(this.formData, 'fatherStudyDate', [this.formData.fatherStudyStartDate, this.formData.fatherStudyEndDate]);
              }
              this.isNewEdit = false;
            } else {
              this.isNewEdit = true;
              this.formData = {};
              this.$set(this.formData, 'editDto', {});
              this.childList = [];
            }
          });
      },
    saveAndNavigate() {
        this.$emit('showLoading');
      const url = this.isNewEdit ? wfSaiFamilyAdd : wfSaiFamilyUpdate;
            const data = {
              ...this.formData,
              childList: this.childList,
              materialId: this.id,
              userId: localStorage.getItem('userInfo').id
            };
            if (this.formData.motherStudyDate) {
              data.motherStudyStartDate = this.formData.motherStudyDate[0];
              data.motherStudyEndDate = this.formData.motherStudyDate[1];
            }
            if (this.formData.fatherStudyDate) {
              data.fatherStudyStartDate = this.formData.fatherStudyDate[0];
              data.fatherStudyEndDate = this.formData.fatherStudyDate[1];
            }
            if (this.isNewEdit == false) {
              data.id = this.formData.id;
            };
            url(data).then(res => {
              if (res.code === '2000') {
                this.$message.success('保存成功');
                this.isEdit = true;
                this.getDetail();
                setTimeout(() => {
                  this.$emit('getDetail');
                }, 2000);
                // this.$emit('nextStep');
                this.$emit('hideLoading');
              } else {
                this.$message.error(res.msg || '保存失败');
                this.$emit('hideLoading');
              }
            });
    },
      addRow() {
          this.childList.push({
                childName: '',
                childGender: '',
                childBirthday: '',
                childGrade: '',
                editDto: {}
          });
      },
      delayRow(row) {
        const index = this.childList.indexOf(row);
        this.childList.splice(index, 1);
      },

      goBack() {
          this.$emit('backPage');
      },
      navigateNext() {
          this.$emit('nextStep');
      },
      startEdit() {
          this.isEdit = !this.isEdit;
      },
      stopEdit() {
          this.isEdit = !this.isEdit;
      },
      getClassName(row, rows) {
        if (!row || !row.editDto) return '';
        return this.whetherReturnEmpty(rows);
        },
      whetherReturnEmpty(value) {
      if (value && value == 1) {
        return 'changeValue';
        }
      }

    },
  };
  </script>

  <style  lang='stylus' scoped>
  @import '~css/stylus.styl';

  .title {
    font-weight: bold;
    font-size: 18px;
    color: #333333;
    line-height: 32px;
    margin: 21px 0;
    text-align: left;
    justify-content: space-between;
    display: flex;

  }

  .flexs {
    .el-form-item {
      display: flex;
      margin-bottom: 16px;
      align-items: end;
    }
  }

  .contentBox {
    width: 900px;
    margin: 0 auto;

    h3 {
      font-size: 16px;
      color: #333333;
      margin-bottom: 15px;
      line-height: 20px;
    }
  }

  >>>.el-form-item__content{
      width:100%
  }
  .delClass{
    cursor pointer;
    color: rgba(255, 130, 64, 1);
    font-size: 20px;
  }
  .container:after{
    content: '';
    display: block;
    clear: both;
  }
  .addBtnClass{
    width: 90px !important;
    height: 28px !important;
    background: #FFEFE6;
    border-radius: 17px 17px 17px 17px;
    border: 1px solid #FF8240;
    font-size: 14px !important;
    color: #FF8240;
    margin: 15px 0 18px !important;
    float: right;
    cursor pointer;
    text-align: center;
    line-height: 28px !important;
  }
  .tips{
    font-size: 12px;
    color: #FF8240;
    margin-bottom: 19px;
    line-height: 18px;
  }
  .line{
    width: 100%;
    height: 1px;
    background-color: #E5E5E5;
    margin-bottom: 16px;
  }
  .btnBox{
    display: flex;
    justify-content: center;
  }
  >>>.el-button {
    width: 92px;
    padding: 0 !important;
    height: 30px;
    line-height: 30px;
    border-radius: 15px;
    font-size: 14px;
    font-weight: 400;
  }

  .saveBtn {
    width: 92px;
    height: 30px;
    background: #FFFFFF;
    border-radius: 15px;
    border: 1px solid #FF8240;
    box-sizing: border-box;
    font-size: 14px;
    color: #FF8240;
    text-align: center;
    line-height: 30px;
    cursor: pointer;
    transition: all 0.3s;
    font-weight: 400;

    &:hover {
      background: #fff5f0;
    }
  }
  .changeValue{
    >>>.el-form-item__label{
      color: #FF8240 !important;
    }
    >>>.el-input__inner{
      border-color: #FF8240 !important;
      border: 1px solid #FF8240 !important;
    }
  }
  .ml-10 {
    margin-left: 10px;
  }
  </style>