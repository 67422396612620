<template>
    <div>
      <div class="title">第六部分：申请人推荐人信息
        <div class="btnBox">
            <!-- <el-button class="saveBtn" plain round @click="goBack">返回上一页</el-button> -->
            <template v-if="isEdit && type!='detail' && finalDraft!=1">
            <el-button type="primary" class="ml-10" round @click="startEdit">开始修改</el-button>
            <!-- <el-button type="primary" class="ml-10" round @click="navigateNext">跳转下一页</el-button> -->
            </template>
            <template  v-else-if="type!='detail' && !isEdit">
            <p class="saveBtn ml-10" v-show="!isEdit" @click="stopEdit">退出修改</p>
            <el-button type="primary" class="ml-10" round @click="saveAndNavigate">确认修改</el-button>
            </template>
        </div>
      </div>
      <div class="contentBox">
        <h3>推荐人1</h3>
        <el-form :model="formData" inline class="flexs" label-position="left">
            <el-row>
                <el-col :span="6" >
                    <el-form-item label="推荐人姓名:" label-width="140px" :class="whetherReturnEmpty(formData.editDto.recommender1Name)">
                        <el-input
                        v-model="formData.recommender1Name"
                        placeholder="请输入"
                        size="medium"
                        :disabled="isEdit"
                        />
                    </el-form-item>
                </el-col>
                <el-col :span="6" >
                    <el-form-item label="性别：" label-width="73px" :class="whetherReturnEmpty(formData.editDto.recommender1Gender)">
                        <el-select v-model="formData.recommender1Gender" placeholder="请选择" :disabled="isEdit">
                            <el-option
                            v-for="item in sexList"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                            >
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="6" >
                    <el-form-item label="职务:" label-width="54px" :class="whetherReturnEmpty(formData.editDto.recommender1Position)">
                        <el-input
                        v-model="formData.recommender1Position"
                        placeholder="请输入"
                        size="medium"
                        :disabled="isEdit"
                        />
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="职称:" label-width="54px" :class="whetherReturnEmpty(formData.editDto.recommender1Title)">
                        <el-input
                        v-model="formData.recommender1Title"
                        placeholder="请输入"
                        size="medium"
                        :disabled="isEdit"
                        />
                    </el-form-item>
                </el-col>

            </el-row>
            <el-row>
                <el-col :span="63"  style="margin-right: 80px;">
                    <el-form-item label="单位名称:" label-width="110px" :class="whetherReturnEmpty(formData.editDto.recommender1EmployerName)">
                        <el-input
                        v-model="formData.recommender1EmployerName"
                        placeholder="请输入"
                        size="medium"
                        :disabled="isEdit"
                        />
                    </el-form-item>
                </el-col>
                <el-col :span="7" >
                    <el-form-item label="教授课程:" label-width="110px" :class="whetherReturnEmpty(formData.editDto.recommender1TeachCourse)">
                        <el-input
                        v-model="formData.recommender1TeachCourse"
                        placeholder="请输入"
                        size="medium"
                        :disabled="isEdit"
                        />
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="18">
                    <el-form-item label="单位地址:"  label-width="80px" :class="whetherReturnEmpty(formData.editDto.recommender1EmployerAddress)">
                    <el-input
                        v-model="formData.recommender1EmployerAddress"
                        placeholder="请输入"
                        size="medium"
                        :disabled="isEdit"
                        class="matching-input"

                    />
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="邮编:"  label-width="73px" :class="whetherReturnEmpty(formData.editDto.recommender1EmployerPostalCode)">
                    <el-input
                        v-model="formData.recommender1EmployerPostalCode"
                        placeholder="请输入"
                        size="medium"
                        type="number"
                        :disabled="isEdit"
                    />
                    </el-form-item>
                </el-col>
          </el-row>
            <el-row>
                <el-col :span="6" >
                    <el-form-item label="办公电话:" label-width="110px" :class="whetherReturnEmpty(formData.editDto.recommender1OfficePhone)">
                        <el-input
                        v-model="formData.recommender1OfficePhone"
                        placeholder="请输入"
                        size="medium"
                        :disabled="isEdit"
                        />
                    </el-form-item>
                </el-col>
                <el-col :span="11" style="margin-right: 37px">
                    <el-form-item label="邮箱（如有官方邮箱，请注明)：" label-width="450px" :class="whetherReturnEmpty(formData.editDto.recommender1Email)">
                        <el-input
                        v-model="formData.recommender1Email"
                        placeholder="请输入"
                        size="medium"
                        :disabled="isEdit"
                        />
                    </el-form-item>
                </el-col>
                <el-col :span="6" >
                    <el-form-item label="相识时间：" label-width="126px" :class="whetherReturnEmpty(formData.editDto.recommender1AcquaintanceDate)">
                        <el-date-picker
                        v-model="formData.recommender1AcquaintanceDate"
                        type="date"
                        style="width:130px"
                        :disabled="isEdit"
                        ></el-date-picker>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
            <el-col :span="24">
              <p class="smallTitle">推荐人简介：</p>
              <el-form-item label=""  :class="whetherReturnEmpty(formData.editDto.recommender1Introduction)">
                <el-input
                  v-model="formData.recommender1Introduction"
                  placeholder="如是，注明与哪个国家合办，以及学制，比如4+0, 3+1, 2+2"
                  size="medium"
                  class="matching-input"
                  :disabled="isEdit"
                />
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <p class="smallTitle">1. 你给这位推荐人留下什么印象？请通过1-2个具体事例说明您如何给推荐人留下了深刻的印象，（如学习兴趣，学习能力，钻研能力，独立思考和 解决问题能力，表达能力以及与同学合作的能力等）切忌空泛。（请注意是推荐人对你的印象，而不是你对推荐人的印象）</p>
              <el-form-item label=""  :class="whetherReturnEmpty(formData.editDto.recommender1Impression)">
                <el-input
                  v-model="formData.recommender1Impression"
                  placeholder="请输入"
                  size="medium"
                  class="matching-input"
                  :disabled="isEdit"
                />
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <p class="smallTitle">2. 以这位推荐人的角度来看，他是否了解你其他方面的能力，比如你的各科综合成绩如何, 你的特长, 得过何种荣誉, 克服过哪些困难等（要客观具 体）。 如果有的话，请用1-2个具体事例说明。（请注意适度用词，不要太夸张。）</p>
              <el-form-item label=""  :class="whetherReturnEmpty(formData.editDto.recommender1Abilities)">
                <el-input
                  v-model="formData.recommender1Abilities"
                  placeholder="请输入"
                  size="medium"
                  class="matching-input"
                  :disabled="isEdit"
                />
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <p class="smallTitle">3. 其他需要补充的地方，请详述。（指你认为能突出你的特点，并有利于你所申请专业的地方）</p>
              <el-form-item label=""  :class="whetherReturnEmpty(formData.editDto.recommender1SupplementaryInformation)">
                <el-input
                  v-model="formData.recommender1SupplementaryInformation"
                  placeholder="请输入"
                  size="medium"
                  class="matching-input"
                  :disabled="isEdit"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <h3>推荐人2</h3>
          <el-row>
                <el-col :span="6" >
                    <el-form-item label="推荐人姓名:" label-width="140px" :class="whetherReturnEmpty(formData.editDto.recommender2Name)">
                        <el-input
                        v-model="formData.recommender2Name"
                        placeholder="请输入"
                        size="medium"
                        :disabled="isEdit"
                        />
                    </el-form-item>
                </el-col>
                <el-col :span="6" >
                    <el-form-item label="性别：" label-width="73px" :class="whetherReturnEmpty(formData.editDto.recommender2Gender)">
                        <el-select v-model="formData.recommender2Gender" placeholder="请选择" :disabled="isEdit">
                            <el-option
                            v-for="item in sexList"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                            >
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="6" >
                    <el-form-item label="职务:" label-width="54px" :class="whetherReturnEmpty(formData.editDto.recommender2Position)">
                        <el-input
                        v-model="formData.recommender2Position"
                        placeholder="请输入"
                        size="medium"
                        :disabled="isEdit"
                        />
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="职称:" label-width="54px" :class="whetherReturnEmpty(formData.editDto.recommender2Title)">
                        <el-input
                        v-model="formData.recommender2Title"
                        placeholder="请输入"
                        size="medium"
                        :disabled="isEdit"
                        />
                    </el-form-item>
                </el-col>

            </el-row>
            <el-row>
                <el-col :span="63"  style="margin-right: 80px;">
                    <el-form-item label="单位名称:" label-width="110px" :class="whetherReturnEmpty(formData.editDto.recommender2EmployerName)">
                        <el-input
                        v-model="formData.recommender2EmployerName"
                        placeholder="请输入"
                        size="medium"
                        :disabled="isEdit"
                        />
                    </el-form-item>
                </el-col>
                <el-col :span="7" >
                    <el-form-item label="教授课程:" label-width="110px" :class="whetherReturnEmpty(formData.editDto.recommender2TeachCourse)">
                        <el-input
                        v-model="formData.recommender2TeachCourse"
                        placeholder="请输入"
                        size="medium"
                        :disabled="isEdit"
                        />
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="18">
                    <el-form-item label="单位地址:"  label-width="80px" :class="whetherReturnEmpty(formData.editDto.recommender2EmployerAddress)">
                    <el-input
                        v-model="formData.recommender2EmployerAddress"
                        placeholder="请输入"
                        size="medium"
                        :disabled="isEdit"
                        class="matching-input"

                    />
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="邮编:"  label-width="73px" :class="whetherReturnEmpty(formData.editDto.recommender2EmployerPostalCode)">
                    <el-input
                        v-model="formData.recommender2EmployerPostalCode"
                        placeholder="请输入"
                        size="medium"
                        :disabled="isEdit"
                    />
                    </el-form-item>
                </el-col>
          </el-row>
            <el-row>
                <el-col :span="6" >
                    <el-form-item label="办公电话:" label-width="110px" :class="whetherReturnEmpty(formData.editDto.recommender2OfficePhone)">
                        <el-input
                        v-model="formData.recommender2OfficePhone"
                        placeholder="请输入"
                        size="medium"
                        :disabled="isEdit"
                        />
                    </el-form-item>
                </el-col>
                <el-col :span="11" style="margin-right: 37px">
                    <el-form-item label="邮箱（如有官方邮箱，请注明)：" label-width="450px" :class="whetherReturnEmpty(formData.editDto.recommender2Email)">
                        <el-input
                        v-model="formData.recommender2Email"
                        placeholder="请输入"
                        size="medium"
                        :disabled="isEdit"
                        />
                    </el-form-item>
                </el-col>
                <el-col :span="6" >
                    <el-form-item label="相识时间：" label-width="126px" :class="whetherReturnEmpty(formData.editDto.recommender2AcquaintanceDate)">
                        <el-date-picker
                        v-model="formData.recommender2AcquaintanceDate"
                        type="date"
                        style="width:130px"
                        :disabled="isEdit"
                        ></el-date-picker>
                    </el-form-item>
                </el-col>
            </el-row>

            <el-row>
            <el-col :span="24">
              <p class="smallTitle">推荐人简介：</p>
              <el-form-item label=""  :class="whetherReturnEmpty(formData.editDto.recommender2Introduction)">
                <el-input
                  v-model="formData.recommender2Introduction"
                  placeholder="如是，注明与哪个国家合办，以及学制，比如4+0, 3+1, 2+2"
                  size="medium"
                  class="matching-input"
                  :disabled="isEdit"
                />
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <p class="smallTitle">1. 你给这位推荐人留下什么印象？请通过1-2个具体事例说明您如何给推荐人留下了深刻的印象，（如学习兴趣，学习能力，钻研能力，独立思考和 解决问题能力，表达能力以及与同学合作的能力等）切忌空泛。（请注意是推荐人对你的印象，而不是你对推荐人的印象）</p>
              <el-form-item label=""  :class="whetherReturnEmpty(formData.editDto.recommender2Impression)">
                <el-input
                  v-model="formData.recommender2Impression"
                  placeholder="请输入"
                  size="medium"
                  class="matching-input"
                  :disabled="isEdit"
                />
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <p class="smallTitle">2. 以这位推荐人的角度来看，他是否了解你其他方面的能力，比如你的各科综合成绩如何, 你的特长, 得过何种荣誉, 克服过哪些困难等（要客观具 体）。 如果有的话，请用1-2个具体事例说明。（请注意适度用词，不要太夸张。）</p>
              <el-form-item label=""  :class="whetherReturnEmpty(formData.editDto.recommender2Abilities)" >
                <el-input
                  v-model="formData.recommender2Abilities"
                  placeholder="请输入"
                  size="medium"
                  class="matching-input"
                  :disabled="isEdit"
                />
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <p class="smallTitle">3. 其他需要补充的地方，请详述。（指你认为能突出你的特点，并有利于你所申请专业的地方）</p>
              <el-form-item label=""  :class="whetherReturnEmpty(formData.editDto.recommender2SupplementaryInformation)">
                <el-input
                  v-model="formData.recommender2SupplementaryInformation"
                  placeholder="请输入"
                  size="medium"
                  class="matching-input"
                  :disabled="isEdit"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <h3>推荐人3</h3>
          <el-row>
                <el-col :span="6" >
                    <el-form-item label="推荐人姓名:" label-width="140px" :class="whetherReturnEmpty(formData.editDto.recommender3Name)">
                        <el-input
                        v-model="formData.recommender3Name"
                        placeholder="请输入"
                        size="medium"
                        :disabled="isEdit"
                        />
                    </el-form-item>
                </el-col>
                <el-col :span="6" >
                    <el-form-item label="性别：" label-width="73px" :class="whetherReturnEmpty(formData.editDto.recommender3Gender)">
                        <el-select v-model="formData.recommender3Gender" placeholder="请选择" :disabled="isEdit">
                            <el-option
                            v-for="item in sexList"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                            >
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="6" >
                      <el-form-item label="职务:" label-width="54px" :class="whetherReturnEmpty(formData.editDto.recommender3Position)">
                        <el-input
                        v-model="formData.recommender3Position"
                        placeholder="请输入"
                        size="medium"
                        :disabled="isEdit"
                        />
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="职称:" label-width="54px" :class="whetherReturnEmpty(formData.editDto.recommender3Title)">
                        <el-input
                        v-model="formData.recommender3Title"
                        placeholder="请输入"
                        size="medium"
                        :disabled="isEdit"
                        />
                    </el-form-item>
                </el-col>

            </el-row>
            <el-row>
                <el-col :span="63"  style="margin-right: 80px;">
                    <el-form-item label="单位名称:" label-width="110px" :class="whetherReturnEmpty(formData.editDto.recommender3EmployerName)">
                        <el-input
                        v-model="formData.recommender3EmployerName"
                        placeholder="请输入"
                        size="medium"
                        :disabled="isEdit"
                        />
                    </el-form-item>
                </el-col>
                <el-col :span="7" >
                    <el-form-item label="教授课程:" label-width="110px" :class="whetherReturnEmpty(formData.editDto.recommender3TeachCourse)">
                        <el-input
                        v-model="formData.recommender3TeachCourse"
                        placeholder="请输入"
                        size="medium"
                        :disabled="isEdit"
                        />
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="18">
                    <el-form-item label="单位地址:"  label-width="80px" :class="whetherReturnEmpty(formData.editDto.recommender3EmployerAddress)">
                    <el-input
                        v-model="formData.recommender3EmployerAddress"
                        placeholder="请输入"
                        size="medium"
                        :disabled="isEdit"
                        class="matching-input"

                    />
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="邮编:"  label-width="73px" :class="whetherReturnEmpty(formData.editDto.recommender3EmployerPostalCode)">
                    <el-input
                        v-model="formData.recommender3EmployerPostalCode"
                        placeholder="请输入"
                        size="medium"
                        :disabled="isEdit"
                    />
                    </el-form-item>
                </el-col>
          </el-row>
            <el-row>
                <el-col :span="6" >
                    <el-form-item label="办公电话:" label-width="110px" :class="whetherReturnEmpty(formData.editDto.recommender3OfficePhone)">
                        <el-input
                        v-model="formData.recommender3OfficePhone"
                        placeholder="请输入"
                        size="medium"
                        :disabled="isEdit"
                        />
                    </el-form-item>
                </el-col>
                <el-col :span="11" style="margin-right: 37px">
                    <el-form-item label="邮箱（如有官方邮箱，请注明)：" label-width="450px" :class="whetherReturnEmpty(formData.editDto.recommender3Email)">
                        <el-input
                        v-model="formData.recommender3Email"
                        placeholder="请输入"
                        size="medium"
                        :disabled="isEdit"
                        />
                    </el-form-item>
                </el-col>
                <el-col :span="6" >
                    <el-form-item label="相识时间：" label-width="126px" :class="whetherReturnEmpty(formData.editDto.recommender3AcquaintanceDate)">
                        <el-date-picker
                        v-model="formData.recommender3AcquaintanceDate"
                        type="date"
                        style="width:130px"
                        :disabled="isEdit"
                        ></el-date-picker>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
            <el-col :span="24">
              <p class="smallTitle">推荐人简介：</p>
              <el-form-item label=""  :class="whetherReturnEmpty(formData.editDto.recommender3Introduction)">
                <el-input
                  v-model="formData.recommender3Introduction"
                  placeholder="请输入"
                  size="medium"
                  class="matching-input"
                  :disabled="isEdit"
                />
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <p class="smallTitle">1. 你给这位推荐人留下什么印象？请通过1-2个具体事例说明您如何给推荐人留下了深刻的印象，（如学习兴趣，学习能力，钻研能力，独立思考和 解决问题能力，表达能力以及与同学合作的能力等）切忌空泛。（请注意是推荐人对你的印象，而不是你对推荐人的印象）</p>
              <el-form-item label=""  :class="whetherReturnEmpty(formData.editDto.recommender3Impression)">
                <el-input
                  v-model="formData.recommender3Impression"
                  placeholder="请输入"
                  size="medium"
                  class="matching-input"
                  :disabled="isEdit"
                />
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <p class="smallTitle">2. 以这位推荐人的角度来看，他是否了解你其他方面的能力，比如你的各科综合成绩如何, 你的特长, 得过何种荣誉, 克服过哪些困难等（要客观具 体）。 如果有的话，请用1-2个具体事例说明。（请注意适度用词，不要太夸张。）</p>
              <el-form-item label=""  :class="whetherReturnEmpty(formData.editDto.recommender3Abilities)">
                <el-input
                  v-model="formData.recommender3Abilities"
                  placeholder="请输入"
                  size="medium"
                  class="matching-input"
                  :disabled="isEdit"
                />
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <p class="smallTitle">3. 其他需要补充的地方，请详述。（指你认为能突出你的特点，并有利于你所申请专业的地方）</p>
              <el-form-item label=""  :class="whetherReturnEmpty(formData.editDto.recommender3SupplementaryInformation)">
                <el-input
                  v-model="formData.recommender3SupplementaryInformation"
                  placeholder="请输入"
                  size="medium"
                  class="matching-input"
                  :disabled="isEdit"
                />
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
    </div>
  </template>

  <script>
  import { wfSaiRecommenderAdd, wfSaiRecommenderUpdate, wfSaiRecommenderGetDetailByMaterialId } from '@/assets/api/application';
  export default {
    name: "Partfive",
    props: {
      type: {
        type: String,
        default: ''
      },
      finalDraft: {
        type: Number,
        default: 0
      },
      id: {
      type: [String, Number],
      default: ''
    },
    },
    data() {
      return {
        formData: {
          editDto: {}
        },
        sexList: [
            { label: '男', value: 1 },
            { label: '女', value: 0 },
        ],
        isEdit: true,
        isNewEdit: null,
      };
    },
    mounted() {},
    created() {
      this.getDetail();
    },
    methods: {
      getDetail() {
          this.loading = true;
          wfSaiRecommenderGetDetailByMaterialId({ materialId: this.id }).then(res => {
            if (res.code == '2000') {
              this.formData = res.data;
              this.isNewEdit = false;
            } else {
              this.isNewEdit = true;
              this.formData = {
                ...res.data,
                editDto: {},
              };
            }
          });
      },
      saveAndNavigate() {
        this.$emit('showLoading');
        const url = this.isNewEdit ? wfSaiRecommenderAdd : wfSaiRecommenderUpdate;
            const data = {
              ...this.formData,
              materialId: this.id,
              userId: localStorage.getItem('userInfo').id
            };
            if (this.isNewEdit == false) {
              data.id = this.formData.id;
            };
            url(data).then(res => {
              if (res.code === '2000') {
                this.$message.success('保存成功');
                this.isEdit = true;
                this.getDetail();
                setTimeout(() => {
                  this.$emit('getDetail');
                }, 2000);
                this.$emit('hideLoading');
              } else {
                this.$message.error(res.msg || '保存失败');
                this.$emit('hideLoading');
              }
            });
    },
      saveInfo() {
          this.$emit('nextStep');
      },
      backPage() {
          this.$emit('backPage');
      },
      goBack() {
          this.$emit('backPage');
      },
      navigateNext() {
          this.$emit('nextStep');
      },
      startEdit() {
          this.isEdit = !this.isEdit;
      },
      stopEdit() {
          this.isEdit = !this.isEdit;
      },
      whetherReturnEmpty(value) {
      if (value && value == 1) {
        return 'changeValue';
      }
    }
    },
  };
  </script>

  <style  lang='stylus' scoped>
  @import '~css/stylus.styl';

  .title {
    font-weight: bold;
    font-size: 18px;
    color: #333333;
    line-height: 32px;
    margin: 21px 0;
    text-align: left;
    justify-content: space-between;
    display: flex;
  }

  .flexs {
    .el-form-item {
      display: flex;
      margin-bottom: 16px;
      align-items: end;
    }
  }

  .contentBox {
    width: 900px;
    margin: 0 auto;

    h3 {
      font-size: 16px;
      color: #333333;
      margin-bottom: 15px;
      line-height: 20px;
    }
  }

  >>>.el-form-item__content{
      width:100%
  }

  .delClass{
    cursor pointer;
    color: rgba(255, 130, 64, 1);
    font-size: 20px;
  }
  .container:after{
    content: '';
    display: block;
    clear: both;
  }
  .addBtnClass{
    width: 90px;
    height: 28px;
    background: #FFEFE6;
    border-radius: 17px 17px 17px 17px;
    border: 1px solid #FF8240;
    font-size: 14px;
    color: #FF8240;
    margin: 15px 0 18px;
    float: right;
    cursor pointer;
    text-align: center;
    line-height: 28px
  }
  .tips{
    font-size: 12px;
    color: #FF8240;
    margin-bottom: 19px;
    line-height: 18px;
  }
  .line{
    width: 100%;
    height: 1px;
    background-color: #E5E5E5;
    margin-bottom: 16px;
  }
  .smallTitle{
    font-weight: 400;
    font-size: 14px;
    color: #333333;
    line-height: 18px;
    margin-bottom: 10px
  }
  .btnBox{
    display: flex;
    justify-content: center;
  }
  >>>.el-button {
    width: 92px;
    padding: 0 !important;
    height: 30px;
    line-height: 30px;
    border-radius: 15px;
    font-size: 14px;
    font-weight: 400;
  }

  .saveBtn {
    width: 92px;
    height: 30px;
    background: #FFFFFF;
    border-radius: 15px;
    border: 1px solid #FF8240;
    box-sizing: border-box;
    font-size: 14px;
    color: #FF8240;
    text-align: center;
    line-height: 30px;
    cursor: pointer;
    transition: all 0.3s;
    font-weight: 400;

    &:hover {
      background: #fff5f0;
    }
  }
  .changeValue{
    >>>.el-form-item__label{
      color: #FF8240 !important;
    }
    >>>.el-input__inner{
      border-color: #FF8240 !important;
    }
  }
  .ml-10 {
    margin-left: 10px;
  }
  </style>