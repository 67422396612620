<template>
  <div class="professionalDetails"  @contextmenu.prevent @copy.prevent  @cut.prevent  @paste.prevent style=" user-select: none;">
    <headerTitle title="首页" :titles=titles :showBtn="true" :translate="true"></headerTitle>
    <div class="flex-center-column">
      <!--  -->
      <boxHead :schoolId="schoolId" :Id="Id" :isContrast="isContrast" :dataInfos="dataInfo" :isChoose="true"></boxHead>
      <div class="detailBox">
        <!--  -->
        <div class="banner-box-left" id="box-left">
          <div v-for="(item,index) in titleList" :key="index" @click="handleSelectMenu(index)" :class="{activeCss:index==isShow}">{{ item.name }}</div>
        </div>
        <div class="banner-box">
          <!-- <el-menu text-color="#333333" active-text-color="$themeColor" :default-active="activeMenu" mode="horizontal" @select="handleSelect">
            <el-menu-item index="1" v-show="setMenu.ZYJS.isShow==1">{{setMenu.ZYJS.showName}}</el-menu-item>
            <el-menu-item index="2" v-show="setMenu.BJJS.isShow==1">{{ setMenu.BJJS.showName }}</el-menu-item>
            <el-menu-item index="3" v-show="setMenu.ZYXGXX.isShow==1">{{ setMenu.ZYXGXX.showName }}</el-menu-item>
            <el-menu-item index="4" v-show="setMenu.KC.isShow==1">{{ setMenu.KC.showName }}</el-menu-item>
          </el-menu> -->
          <div id="content-0" style="">
            <p class="banner-box-title" style="padding-top:20px">
              <span></span>
              学院与项目介绍
            </p>
            <span >
              <p class="links">学院名称：<span><font v-if="dataInfo.collegeNameCn">{{ dataInfo.collegeNameCn }} | </font>{{ dataInfo.collegeNameEn }}</span></p>
              <p class="links" v-if="dataInfo.collegeIntroduction">学院介绍：{{ dataInfo.collegeIntroduction }}{{ dataInfo.collegeIntroductionEn }}</p>
              <p class="links">项目名称：<span @click="toLink(dataInfo.homepage)"><font v-if="dataInfo.programNameCn">{{ dataInfo.programNameCn }} | </font>{{ dataInfo.programNameEn }}</span></p>
              <p >项目介绍：
                <span class="linkClass" v-html="dataInfo.introdutionCn" ></span>
                <span class="linkClass" v-html="dataInfo.introductionEn" ></span></p>
              <!-- <p><span style="margin-right: 20px;" v-for="(item,index) in dataInfo.majors" :key="index">{{ item.name }}</span></p> -->
            </span>
          </div>
          <!-- <div id="content-2"  v-if="dataInfo.introdutionCn">
            <p class="banner-box-title">
              <span></span>
              简介
            </p>

          </div> -->
          <!-- <div id="content-2"  v-if="dataInfo.backgroundRequire">
            <p class="banner-box-title">
              <span></span>
              背景要求
            </p>
            <span>
              {{ dataInfo.backgroundRequire }}
            </span>
          </div> -->
          <div id="content-1">
            <p class="banner-box-title">
              <span></span>
              项目基础信息
            </p>
            <div class="descriptionBox float">
              <p v-if="dataInfo.homepage"><span class="fontBlod">项目主页链接：</span><span @click="toLink(dataInfo.homepage)">{{ dataInfo.homepage }}</span></p>
              <p v-if="dataInfo.programCataglog"><span class="fontBlod">项目细分领域：</span>{{ dataInfo.programCataglog }}</p>
              <p v-if="dataInfo.lengthOfStudyCn"><span class="fontBlod">项目学习时长：</span>{{ dataInfo.lengthOfStudyCn }}</p>
              <p v-if="dataInfo.lengthOfStudy"><span class="fontBlod"></span>{{ dataInfo.lengthOfStudy }}</p>
              <!-- <p v-if="dataInfo.majors && dataInfo.majors.length!=0"><span class="fontBlod">该项目所属专业：</span><font style="margin-right: 10px;" v-for="(item,index) in dataInfo.majors" :key="index">{{ item.name }}</font></p>
              <p v-if="dataInfo.department"><span class="fontBlod">该项目所属系：</span>{{ dataInfo.department }}</p> -->
              <p v-if="dataInfo.subdivision"><span class="fontBlod">细分方向：</span>{{ dataInfo.subdivision }}</p>
              <p v-if="dataInfo.subdivisionEn"><span class="fontBlod"></span>{{ dataInfo.subdivisionEn }}</p>
            </div>
            <!-- <descriptions :descriptionsInfo="dataInfo"></descriptions> -->
          </div>
          <div>
            <!-- <p class="banner-box-title" v-if="dataInfo.curriculum">
              <span></span>
              课程
            </p> -->
            <p v-if="dataInfo.curriculumCn"><span class="fontBlod">课程信息:</span>{{ dataInfo.curriculumCn }}</p>
            <p v-if="dataInfo.curriculum"><span class="fontBlod"></span>{{ dataInfo.curriculum }}</p>
            <p v-if="dataInfo.curriculumUrl"><span class="fontBlod">课程信息链接：</span><span class="link" @click="toLink(dataInfo.curriculumUrl)">{{ dataInfo.curriculumUrl }}</span></p>
            <!-- :header-cell-style="rowClass"  -->
            <!-- <el-table v-if="dataInfo.curriculum && dataInfo.curriculum.length>0" stripe   :data="dataInfo.curriculum" style="width: 100%" border >
              <el-table-column label="课程名称 （Curriculum）" align="center">
                <template slot-scope="scope">
                  <span>{{ scope.row.cn }}</span>
                  <span v-if="scope.row.en">（{{ scope.row.en }}）</span>
                 </template>
              </el-table-column>
              <el-table-column label="课程信息" align="center" prop="courseInformation"></el-table-column>
            </el-table> -->
          </div>
          <div class="studyBox" >
            <div>
              <p v-if="dataInfo.researchAreaCn"><span class="fontBlod">研究领域：</span>{{ dataInfo.researchAreaCn }}</p>
              <p v-if="dataInfo.researchArea"><span class="fontBlod"></span>{{ dataInfo.researchArea }}</p>
              <p v-if="dataInfo.researchLink"><span class="fontBlod">研究信息链接：</span><span @click="toLink(dataInfo.researchLink)">{{ dataInfo.researchLink }}</span></p>
              <p v-if="dataInfo.researchDirectionCn"><span class="fontBlod">研究方向：</span>{{ dataInfo.researchDirectionCn }}</p>
              <p v-if="dataInfo.researchDirection"><span class="fontBlod">研究方向：</span>{{ dataInfo.researchDirection }}</p>
            </div>
            <div>
              <p v-if="dataInfo.researchTopicCn"><span class="fontBlod">研究主题：</span>{{ dataInfo.researchTopicCn }}</p>
              <p v-if="dataInfo.researchTopic"><span class="fontBlod">研究主题：</span>{{ dataInfo.researchTopic }}</p>
              <p v-if="dataInfo.researchGroupCn"><span class="fontBlod">研究中心/小组：</span>{{ dataInfo.researchGroupCn }}</p>
              <p v-if="dataInfo.researchGroup"><span class="fontBlod"></span>{{ dataInfo.researchGroup }}</p>
              <!-- <p v-if="dataInfo.researchGroupCn"><span class="fontBlod">研究中心：</span>{{ dataInfo.researchCenterCn }}</p>
              <p v-if="dataInfo.researchGroup"><span class="fontBlod">研究中心（英文）：</span>{{ dataInfo.researchCenter }}</p> -->
            </div>
          </div>
          <div id="content-2"  >
            <p class="banner-box-title">
              <span></span>
              项目申请要求
            </p>
            <div class="descriptionBox">
              <p v-if="dataInfo.applicationRequirementsRemarksCn"><span class="fontBlod">项目申请条件：</span><font @click="toLink(dataInfo.applicationRequirementsLink)">{{ dataInfo.applicationRequirementsRemarksCn }}</font></p>
              <p v-if="dataInfo.applicationRequirementsRemarks"><span class="fontBlod"></span><font @click="toLink(dataInfo.applicationRequirementsLink)">{{ dataInfo.applicationRequirementsRemarks }}</font></p>
              <p v-if="dataInfo.applicationRequirementsLink"><span class="fontBlod">项目申请资格链接：</span><span class="link" @click="toLink(dataInfo.applicationRequirementsLink)">{{ dataInfo.applicationRequirementsLink }}</span></p>
              <p v-if="dataInfo.gpaCn"><span class="fontBlod">GPA：</span>{{ dataInfo.gpaCn }}</p>
              <p v-if="dataInfo.gpaCn"><span class="fontBlod"></span>{{ dataInfo.gpa }}</p>
              <p v-if="dataInfo.researchLink"><span class="fontBlod">GPA要求链接：</span><span >{{dataInfo.gpaLink }}</span></p>
              <p v-if="dataInfo.languageRequireCn"><span class="fontBlod">语言要求：</span>{{ dataInfo.languageRequireCn }}</p>
              <p v-if="dataInfo.languageRequire"><span class="fontBlod"></span>{{ dataInfo.languageRequire }}</p>
              <p v-if="dataInfo.lettersOfRecommendationCn"><span class="fontBlod">推荐信数量要求：</span>{{ dataInfo.lettersOfRecommendationCn }}</p>
              <p v-if="dataInfo.lettersOfRecommendation"><span class="fontBlod"></span>{{ dataInfo.lettersOfRecommendation }}</p>
              <p v-if="dataInfo.psNum">Ps：需要{{ dataInfo.psNum }}篇 具体要求：{{ dataInfo.ps }}</p>
              <p v-if="dataInfo.sopNum">Sop：需要{{dataInfo.sopNum}}篇 具体要求：{{ dataInfo.sop }}</p>
              <p v-if="dataInfo.essayNum">Essay：需要{{ dataInfo.essayNum }}篇 具体要求：{{ dataInfo.essay }}</p>
              <!-- <p v-if="dataInfo.gpa"><span @click="toLink(dataInfo.gpaLink)">GPA：</span>要求总分达到：{{dataInfo.gpa}}分;<font v-if="dataInfo.gpaAverageScore">GPA官网建议的平均分数{{ dataInfo.gpaAverageScore }}分</font></p> -->
              <p v-if="dataInfo.toeflRequire"><span @click="toLink(dataInfo.toeflLink)">托福：</span><font v-if="dataInfo.toeflRequire!=1">{{matching(dataInfo.toeflRequire)}}总分达到{{dataInfo.toeflTotal}}分，单项最低分达到{{dataInfo.toeflLow}}分，具体需求：{{ dataInfo.toefl }};托福官网建议的平均分数{{ dataInfo.toeflAverageScore }}分</font><font v-else>不要求</font></p>
              <p v-if="dataInfo.greRequire"><span @click="toLink(dataInfo.greLink)">GRE：</span><font v-if="dataInfo.greRequire!=1">{{matching(dataInfo.greRequire)}}总分达到{{dataInfo.greTotal}}分，GRE Verbal达到{{dataInfo.greVerbal}}分，GRE  mathematics达到{{dataInfo.greMaths}}分，GRE  writing达到{{dataInfo.greWriting}}分，具体需求：{{dataInfo.gre}};GRE官网建议的平均分数{{ dataInfo.greAverageScore }}分</font><font v-else>不要求</font></p>
              <p v-if="dataInfo.duolingoRequire"><span @click="toLink(dataInfo.duolingoLink)">多领国：</span><font v-if="dataInfo.duolingoRequire!=1">{{matching(dataInfo.duolingoRequire)}}总分达到{{dataInfo.duolingoTotal}}分，单项最低分达到{{dataInfo.duolingoLow}}分，具体需求：{{dataInfo.duolingo}}；多邻国建议的平均分数{{ dataInfo.duolingoAverageScore }}分</font><font v-else>不要求</font></p>
              <p v-if="dataInfo.gmatRequire"><span @click="toLink(dataInfo.gmatLink)">GMAT：</span><font v-if="dataInfo.gmatRequire!=1">{{matching(dataInfo.gmatRequire)}}总分达到{{dataInfo.gmatTotal}}分，单项最低分达到{{dataInfo.gmatLow}}分，具体需求：{{dataInfo.gmat}}；GMAT官网建议的平均分数{{ dataInfo.gmatAverageScore }}分</font><font v-else>不要求</font></p>
              <p v-if="dataInfo.lsatRequire"><span @click="toLink(dataInfo.lsatLink)">LSAT：</span><font v-if="dataInfo.lsatRequire!=1">{{matching(dataInfo.lsatRequire)}}总分达到{{dataInfo.lsatTotal}}分，单项最低分达到{{dataInfo.lsatLow}}分，具体需求：{{dataInfo.lsat}}；LAST官网建议的平均分数{{ dataInfo.lsatAverageScore }}分</font><font v-else>不要求</font></p>
              <p v-if="dataInfo.pteRequire"><span @click="toLink(dataInfo.pteLink)">PTE： </span><font v-if="dataInfo.pteRequire!=1">{{matching(dataInfo.pteRequire)}}总分达到{{dataInfo.pteTotal}}分，单项最低分达到{{dataInfo.pteLow}}分，具体需求：{{dataInfo.pte}}；PTE官网建议的平均分数{{ dataInfo.pteAverageScore }}分</font><font v-else>不要求</font></p>
              <p v-if="dataInfo.mactRequire"><span @click="toLink(dataInfo.mcatLink)">MCAT：</span><font v-if="dataInfo.mcatRequire!=1">{{matching(dataInfo.mcatRequire)}}总分达到{{dataInfo.mcatTotal}}分，单项最低分达到{{dataInfo.mcatLow}}分，具体需求：{{dataInfo.mcat}}；MCAT官网建议的平均分数{{ dataInfo.mcatAverageScore }}分</font><font v-else>不要求</font></p>
              <p v-if="dataInfo.prerequisiteCoursesCn"><span class="fontBlod">本科背景要求：</span>{{ dataInfo.prerequisiteCoursesCn }}</p>
              <p v-if="dataInfo.prerequisiteCourses"><span class="fontBlod"></span>{{ dataInfo.prerequisiteCourses }}</p>
              <p v-if="dataInfo.prerequisiteCourses"><span class="fontBlod">本科背景要求链接：</span><span class="link" @click="toLink(dataInfo.curriculumUrl)">{{ dataInfo.curriculumUrl }}</span></p>
              <p v-if="dataInfo.workingExperience"><span class="fontBlod">工作要求： </span>{{ dataInfo.workingExperience }}</p>
              <p v-if="dataInfo.workingExperienceEn"><span class="fontBlod"></span>{{ dataInfo.workingExperienceEn }}</p>
              <p v-if="dataInfo.interview"><span class="fontBlod">面/笔试要求： </span>{{ dataInfo.interview }}</p>
              <p v-if="dataInfo.interviewEn"><span class="fontBlod"> </span>{{ dataInfo.interviewEn }}</p>
              <!-- <p v-if="dataInfo.scoreSubmissionDeadline"><span class="fontBlod">送分截止时间（英文）：</span>{{ dataInfo.scoreSubmissionDeadline }}</p> -->
              <!-- <p v-if="dataInfo.admissionInfoUrl"><span class="fontBlod">该项目申请要求的entrypoint链接：</span> <span @click="toLink(dataInfo.admissionInfoUrl)">{{ dataInfo.admissionInfoUrl }}</span></p> -->
              <p v-if="dataInfo.sampleReels"><span class="fontBlod">作品集：</span>{{ dataInfo.sampleReels }}</p>
              <p v-if="dataInfo.sampleReelsEn"><span class="fontBlod"></span>{{ dataInfo.sampleReelsEn }}</p>
              <p v-if="dataInfo.documentCn"><span class="fontBlod">文书要求：</span>{{dataInfo.documentCn }}</p>
              <p v-if="dataInfo.document"><span class="fontBlod"></span>{{dataInfo.document }}</p>
              <p v-if="dataInfo.documentLink"><span class="fontBlod">文书要求链接：</span><span >{{dataInfo.documentLink }}</span></p>
            </div>
          </div>
            <div class="applicationBox" id="content-3">
              <p class="banner-box-title">
              <span></span>
              项目流程与材料
            </p>
            <div>
              <p v-if="dataInfo.projectOnlineApplicationUrl"><span class="fontBlod">项目网申入口网址：</span><span @click="toLink(dataInfo.projectOnlineApplicationUrl)">{{ dataInfo.projectOnlineApplicationUrl }}</span></p>
              <p v-if="dataInfo.applicationDeadlineFallCn"><span class="fontBlod">申请截止日</span>：{{dataInfo.applicationDeadlineFallCn}}</p>
              <p v-if="dataInfo.applicationDeadlineFall"><span class="fontBlod"></span>{{dataInfo.applicationDeadlineFall}}</p>
              <p v-if="dataInfo.scoreSubmissionDeadlineCn"><span class="fontBlod">送分截止时间：</span>等同于项目申请日</p>
              <p v-if="dataInfo.scoreCodeCn"><span class="fontBlod">送分代码：</span>{{ dataInfo.scoreCodeCn }}</p>
              <p v-if="dataInfo.scoreCode"><span class="fontBlod"></span>{{ dataInfo.scoreCode }}</p>
              <p v-if="dataInfo.scoreCertificationCn"><span class="fontBlod">成绩认证：</span>{{ dataInfo.scoreCertificationCn }}</p>
              <p v-if="dataInfo.scoreCertification"><span class="fontBlod"></span>{{ dataInfo.scoreCertification }}</p>
              <p v-if="dataInfo.materialsListCn"><span class="fontBlod">材料清单：</span>{{ dataInfo.materialsListCn }}</p>
              <p v-if="dataInfo.materialsList"><span class="fontBlod"> </span>{{ dataInfo.materialsList }}</p>
              <p v-if="dataInfo.applicationFeeCn"><span class="fontBlod">申请费：</span>{{ dataInfo.applicationFeeCn }}</p>
              <p v-if="dataInfo.applicationFee"><span class="fontBlod"></span>{{ dataInfo.applicationFee }}</p>
              <p v-if="dataInfo.projectContactEmail"><span class="fontBlod">项目联系邮箱地址：</span>{{ dataInfo.projectContactEmail }}</p>
              <p v-if="dataInfo.materialDeadlineCn"><span class="fontBlod">材料截止时间：</span>{{ dataInfo.materialDeadlineCn }}</p>
              <p v-if="dataInfo.materialDeadline"><span class="fontBlod"></span>{{ dataInfo.materialDeadline }}</p>
              <p v-if="dataInfo.multipleMajorsRequireCn"><span class="fontBlod">可否申请多个专业：</span>{{ dataInfo.multipleMajorsRequireCn }}</p>
            <p v-if="dataInfo.multipleMajorsRequire"><span class="fontBlod"></span>{{ dataInfo.multipleMajorsRequire }}</p>

            </div>
          </div>
          <p class="banner-box-title" id="content-4">
              <span></span>
              就业与财务
            </p>
            <div class="applicationBox" style="margin-top: 0;">
              <p v-if="dataInfo.averageEmploymentRateCn"><span class="fontBlod">平均就业率：</span>{{ dataInfo.averageEmploymentRateCn }}</p>
              <p v-if="dataInfo.averageEmploymentRate"><span class="fontBlod"></span>{{ dataInfo.averageEmploymentRate }}</p>
              <p v-if="dataInfo.averageSalaryCn"><span class="fontBlod">平均薪酬：</span>{{ dataInfo.averageSalaryCn }}</p>
              <p v-if="dataInfo.averageSalary"><span class="fontBlod"></span>{{ dataInfo.averageSalary }}</p>
              <p v-if="dataInfo.employmentDirectionCn"><span class="fontBlod">就业方向：</span>{{ dataInfo.employmentDirectionCn }}</p>
              <p v-if="dataInfo.employmentDirection"><span class="fontBlod"></span>{{ dataInfo.employmentDirection }}</p>
              <p v-if="dataInfo.tuitionCn"><span class="fontBlod">学费：</span>{{ dataInfo.tuitionCn }}</p>
              <p v-if="dataInfo.tuition"><span class="fontBlod"></span>{{ dataInfo.tuition }}</p>
              <p v-if="dataInfo.projectOnlineApplicationUrl"><span class="fontBlod">学费链接：</span><span class="link" @click="toLink(dataInfo.tuitionLink)">{{ dataInfo.tuitionLink }}</span></p>
              <p v-if="dataInfo.scholarshipCn"><span class="fontBlod">奖学金：</span>{{ dataInfo.scholarshipCn }}</p>
              <p v-if="dataInfo.scholarship"><span class="fontBlod">奖学金：</span>{{ dataInfo.scholarship }}</p>
            </div>
            <!-- <descriptions :descriptionsInfo="dataInfo"></descriptions> -->
            <div class="boxArrow">
            <!-- <el-button class="boxArrow-btn" @click="toPrev"><i class="el-icon-arrow-left"></i>向前</el-button>
            <el-button class="boxArrow-btn" @click="toNext">向后<i class="el-icon-arrow-right"></i></el-button> -->
          </div>
        </div>
        <!--  -->
        <!-- <div class="box-right"  v-show="setMenu.AL.isShow==1">
          <div>
            <p class="banner-box-title" style="padding-top: 20px">
              <span></span>
              案例分析
            </p>
            <p>{{ caseData.caseAnalysis || '本专业暂无成功案例。该专业暂无收录之案例' }}</p>
            <el-button class="case" @click="toDetail" round>查看案例</el-button>
          </div>
          <div >
            <p class="banner-box-title">
              <span></span>
              院校背景分析
            </p>
            <collegeBackground :chartData="caseData.schoolAnalysis"></collegeBackground>
          </div>
          <div >
            <p class="banner-box-title">
              <span></span>
              专业背景分析
            </p>
            <majorBackground :chartData="caseData.majorAnalysis"></majorBackground>
          </div>
          <div style="width:100%">
            <p class="banner-box-title">
              <span></span>
              分数分析
            </p>
            <score :chartData="caseData.scoreAnalysis"></score>
          </div>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import headerTitle from '@/components/title.vue';
import boxHead from './components/boxHead.vue';
// import collegeBackground from './components/collegeBackground.vue';
// import majorBackground from './components/majorBackground.vue';
// import score from './components/score.vue';
// import descriptions from './components/descriptions.vue';
import { getcmsProgram, analysisCaseInfo } from 'api/libraryList.js';
import { getmenuList } from 'api/menu.js';
import { Loading } from 'element-ui';

export default {
  name: 'ProfessionalDetails',
  components: { headerTitle, boxHead },
  // collegeBackground, majorBackground, score, descriptions
  data() {
    return {
      schoolId: '',
      Id: '',
      activeMenu: '1', // 默认激活的菜单项
      dataInfo: {},
      caseData: {
        scoreAnalysis: [],
        majorAnalysis: [],
        schoolAnalysis: [],
      },
      setMenu: {
        ZYJS: { isShow: 0 },
        BJJS: { isShow: 0 },
        ZYXGXX: { isShow: 0 },
        KC: { isShow: 0 },
        AL: { isShow: 1 },
      },
      isContrast: true,
      titleList: [
        { name: '学院与项目介绍' },
        { name: '项目基础信息' },
        { name: '项目申请要求' },
        { name: '项目流程与材料' },
        { name: '就业与财务' }
      ],
      isShow: 0,
      loadingInstance: null,
      titles: []
    };
  },
  created() {
    this.getMenu();
    this.schoolId = this.$route.query.schoolId;
    this.Id = this.$route.query.id;
    this.getMajorDetail();
    window.addEventListener('scroll', this.handleScrollThrottled);
  },
  methods: {
    initTitles() {
      this.titles = [
        {
          name: "学院列表",
          path: `/professionalList?schoolId=${this.$route.query.schoolId}`
        },
        {
          name: "项目列表",
          path: `/projectList?schoolId=${this.$route.query.schoolId}&id=${this.dataInfo.collegeId}`
        },
        {
          name: "项目详情",
          path: ""
        }
      ];
    },
    linkedText(inner) {
      // 使用正则表达式匹配链接
      if (inner != null && inner != '') {
        const urlRegex = /(https?:\/\/[^\s]+)/g;
        // 使用替换函数将链接转换为 <a> 标签
        return inner.replace(urlRegex, (url) => {
          return `<a href="${url}" target="_blank" class="linke">${url}</a>`;
        });
      }
    },
    rowClass({ row, column, rowIndex, columnIndex }) {
        if (rowIndex === 0 && columnIndex === 0) {
          this.$nextTick(() => {
            if (document.getElementsByClassName(column.id).length !== 0) {
              document.getElementsByClassName(column.id)[0].setAttribute('rowSpan', 2);
              return false;
            }
          });
        }
        if (rowIndex === 1 && (columnIndex === 0 || columnIndex === 1)) {
          return { display: 'none' };
        }
      },
    getMajorDetail() {
      this.showLoading();
      getcmsProgram({
        id: this.$route.query.id
      }).then(res => {
        if (res.code == '2000') {
          this.dataInfo = res.data;
          this.initTitles();
          this.hideLoading();
        } else {
          this.$message({
            type: 'error',
            message: res.msg
          });
          this.hideLoading();
        }
      });
    },
    toDetail() {
      this.$router.push({
        path: '/caseDetails',
      });
    },
    toLink(val) {
      if (val !== '' && val !== null) {
        window.open(val);
      }
    },
    handleSelect(index) {
      const element = document.getElementById(`content-${index}`);
      if (element) {
        const scrollTopValue = element.documentElement.scrollTop + 100;
        console.log(scrollTopValue);
        // element.scrollIntoView({ behavior: 'smooth' });
        window.scrollTo({
          top: scrollTopValue,
          behavior: 'smooth' // 平滑滚动
        });
      }
    },
    analysisCaseInfo() {
      analysisCaseInfo({
        majorId: this.$route.query.id
      }).then(res => {
        if (res.code == '2000') {
          this.caseData = res.data;
        } else {
          this.$message({
            type: 'error',
            message: res.msg
          });
        }
      });
    },
    getMenu() {
      const data = {
        code: 'ZY'
      };
      getmenuList(data).then(res => {
            if (res.code == 2000) {
                // this.$store.commit('setMenu',res.data)
                this.setMenu = res.data;
            } else {
                this.$message({
                    type: 'error',
                    message: res.msg
                });
            }
        }).catch((err) => {
            this.$message({
                type: 'error',
                message: err.msg
            });
        });
    },
    matching(val) {
      return val == 0 ? '要求' : val == 1 ? '不要求' : val == 2 ? '建议' : val == 3 ? '可选择' : '';
    },
    matchTxt(val) {
      return val == 0 ? '学期' : val == 1 ? '学分' : val == 2 ? '学年' : '';
    },
    handleSelectMenu(index) {
      this.isShow = index;
      // window.scrollTo({
      //   top: 0,
      //   behavior: 'smooth' // 添加平滑滚动效果
      // });
      const element = document.getElementById(`content-${index}`);
      if (element) {
        const scrollTopValue = element.offsetTop - 90;
        // element.scrollIntoView({ behavior: 'smooth' });
        window.scrollTo({
          top: scrollTopValue,
          behavior: 'smooth' // 平滑滚动
        });
      }
    },
    toPrev() {
        if (this.isShow == 0) {
          this.isShow = this.titleList.length - 1;
        } else {
          this.isShow = this.isShow - 1;
        }
        window.scrollTo({
          top: 0,
          behavior: 'smooth' // 添加平滑滚动效果
        });
    },
    toNext() {
      if (this.isShow == this.titleList.length - 1) {
        this.isShow = 0;
      } else {
        this.isShow = this.isShow + 1;
      }
      window.scrollTo({
        top: 0,
        behavior: 'smooth' // 添加平滑滚动效果
      });
    },
    handleScroll() {
        const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
        console.log(scrollTop);
        const scrollTop2 = document.getElementById('box-left').offsetTop;
        console.log(scrollTop, scrollTop2);
        if (scrollTop > scrollTop2) {
          document.querySelector('.banner-box-left').classList.add('boxLeftact');
        } else {
          document.querySelector('.banner-box-left').classList.remove('boxLeftact');
        }
      },
      showLoading() {
        this.loadingInstance = Loading.service({
          background: 'rgba(255, 255, 255, 0.8)', // 背景颜色，可以自定义遮罩层的颜色
          customClass: 'fontSize'
        });
      },
      hideLoading() {
        if (this.loadingInstance) {
          this.loadingInstance.close(); // 关闭加载动画
        }
    },
  },
  beforeDestroy() {
    // 移除滚动事件监听器
    window.removeEventListener('scroll', this.handleScroll);
  },
  mounted() {
    // this.analysisCaseInfo();
    window.addEventListener('scroll', this.handleScroll);
  }
};
</script>
<style  lang='stylus' scoped>
@import '~css/stylus.styl';

.professionalDetails {
  width: 100%;
  background: #F5F7FA url('../../assets/professionalList.png') no-repeat;
  background-size: contain;
  padding-bottom: $padding-bottom;

  .detailBox {
    width: $width;
    line-height: 26px;
  }
  .detailBox::before {
    content: '';
    display: block;
    clear: both;
  }
  .banner-box-left{
    width: 170px;
    background: #FFFFFF;
    border-radius: 12px 12px 12px 12px;
    padding: 20px 0 16px;
    box-sizing: border-box;
    height: 286px;
    float: left;

    div{
      padding-left: 17px;
      position: relative;
      margin-bottom: 10px;
      height: 40px;
      line-height: 40px;
      font-size: 15px;
      color: #666666;
      cursor: pointer;
    }
    div:after{
      content: '';
      position: absolute;
      left: 0;
      width: 4px;
      height: 40px;
      background: #CCCCCC;
    }
  }
  .banner-box {
    height: 100%;
    line-height: 26px;
    padding-bottom: 28px;
    box-sizing: border-box;
    width: 1015px;
    background: #fff;
    border-radius: 12px 12px 12px 12px;
    padding: 0 20px 120px;
    float: right;

    .banner-box-item {
      padding-bottom: 20px;

      p {
        font-size: 20px;
      }
    }
  }

  .banner-box-title {
    font-weight: bold;
    font-size: 20px;
    color: #333333;
    line-height: 32px;
    padding: 30px 0 20px;
    display: flex;
    margin-bottom: 0px !important;
    align-items: center;

    &>span {
      line-height: 32px;
      display: inline-block;
      color: $themeColor;
      width: 4px;
      height: 20px;
      background: $themeColor;
      border-radius: 90px 90px 90px 90px;
      margin-right: 8px;
    }
  }

  .box-right {
    border-radius: 12px 12px 12px 12px;
    width: 403px;
    background: #FFFFFF;
    padding: 0 20px 20px;
    box-sizing: border-box;
  }

  .case {
    width: 364px;
    height: 40px;
    background: $themeColor;
    color: #fff;
    margin-top: 20px;
  }
}

/deep/ .el-menu-item {
  font-size: 18px;
  padding: 0;
  margin: 0 50px;
}

/deep/ .el-menu--horizontal>.el-menu-item.is-active {
  border-bottom: 4px solid #409EFF;
}
.links{
  margin-bottom:10px;
}
.links>span{
  font-weight: 400;
  font-size: 14px;
  color: #409EFF;
  text-decoration: underline;
  cursor: pointer;
}
.descriptionBox{
  p{
    font-size: 14px;
    color: #333333;
    line-height: 26px;
    margin-bottom: 15px;
    span{
      color: #409EFF;
      text-decoration: underline;
      cursor: pointer;
    }
  }
}
.float>p{

}
.float:after{
  content: "";
  display: block;
  clear: both;
}
.tipBox{
  margin: 20px 0 0;
  .titles{
    font-size: 14px;
    color: $themeColor;
    line-height: 26px;
    text-decoration-line: underline;
    font-weight: bold;
    cursor: pointer;
  }
  p{
    span{
      display: inline-block;
      margin-right: 60px;
      font-size: 14px;
      color: #333333;
    }
  }
  p:nth-of-type(2){
    font-size: 12px;
    color: #333333;
    margin: 15px 0 15px;
   }
}
.applicationBox{
}
.applicationBox{
  div{
    p{
      font-weight: 400;
      font-size: 14px;
      color: #333333;
      line-height: 26px;
      margin-bottom: 15px;
      margin-right: 39px;
    }
  }
  > p{
    font-weight: 400;
    font-size: 14px;
    color: #333333;
    line-height: 26px;
    margin-bottom: 15px;
    font{
      cursor: pointer;
      text-decoration-line: underline;
      color: #409EFF;
    }

  }
}
.studyBox{
  margin-top: 20px;
  div{
    p{
      font-size: 14px;
      color: #333333;
      line-height: 26px;
      margin-bottom: 15px;
    }
  }
  div:nth-last-of-type(1){
   p{
    margin-bottom: 0;
   }
  }
}
.fontBlod{
  font-weight: bold;
  color: #333333 !important;
  text-decoration: none !important;
  margin-right: 0px !important;
}
.link{
  color: #409eff;
    text-decoration: underline;
    cursor: pointer;
}
>>>.linke{
    color: #409eff !important;
    text-decoration: underline;
}
.activeCss{
  color:$themeColor !important;
}
.activeCss:after{
  background:$themeColor !important;
}
.boxArrow{
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}
.boxArrow-btn{
  width: 70px;
  height: 35px;
  background: #FFFFFF;
  border-radius: 6px 6px 6px 6px;
  border: 1px solid #FF8240;
  padding: 0 !important;
  font-size: 14px;
  color: #FF8240;
  i{
    font-size: 12px;

  }
}
.boxLeftact{
  position: fixed;
  top: 120px;

}
</style>