var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"infobigBox"},[_c('leftNav'),_c('div',{staticClass:"infoBox"},[_c('div',{staticClass:"title"},[_vm._v(" 选校择校详情 "),_c('div',{staticClass:"btnBox"},[_c('el-button',{attrs:{"round":"","plain":"","type":"primary","size":"small"},on:{"click":_vm.returnTo}},[_vm._v(" 返回上一页 ")]),(_vm.pageType != 'add' && _vm.historyList.length)?_c('el-button',{attrs:{"round":"","plain":"","type":"primary","size":"small","loading":_vm.addLoading},on:{"click":_vm.showDialog}},[_vm._v("历史记录")]):_vm._e(),(_vm.form.isSignatory != 1 && _vm.pageType == 'details')?_c('el-button',{attrs:{"round":"","type":"primary","size":"small"},on:{"click":function($event){return _vm.regInfo(true)}}},[_vm._v(_vm._s(_vm.addLoading ? '请稍后...' : '发送给客户签字'))]):_vm._e(),(_vm.pageType != 'details')?_c('el-button',{attrs:{"loading":_vm.addLoading,"round":"","type":"primary","size":"small"},on:{"click":function($event){return _vm.regInfo(false)}}},[_vm._v(_vm._s(_vm.addLoading ? '请稍后...' : '保存'))]):_vm._e()],1)]),_c('div',{staticClass:"infopng"},[_c('p',{staticClass:"smallTitle"},[_vm._v("材料基本信息")]),_c('div',{staticClass:"searchBox"},[_c('el-form',{ref:"form",attrs:{"inline":true,"model":_vm.form,"disabled":_vm.pageType == 'details',"label-width":"120px"}},[_c('el-form-item',{attrs:{"label":"合同名称:","label-width":"90px"}},[_c('el-select',{staticStyle:{"width":"160px"},attrs:{"value-key":"id","disabled":_vm.pageType != 'add',"size":"medium"},on:{"change":(val) => {
                  _vm.contractChange(val);
                }},model:{value:(_vm.form.contractName),callback:function ($$v) {_vm.$set(_vm.form, "contractName", $$v)},expression:"form.contractName"}},_vm._l((_vm.contractList),function(item){return _c('el-option',{key:item.id,attrs:{"label":item.contractName,"value":item}})}),1)],1),_c('el-form-item',{attrs:{"label":"是否定稿:","label-width":"90px"}},[(_vm.form.isFinalized && _vm.pageType == 'details')?_c('p',{class:_vm.getStatusClass(_vm.form.isFinalized),staticStyle:{"width":"100px"}},[_vm._v(" "+_vm._s(_vm.form.isFinalized == 1 ? '已定稿' : '未定稿')+" ")]):_c('el-select',{staticStyle:{"width":"120px"},attrs:{"size":"medium"},model:{value:(_vm.form.isFinalized),callback:function ($$v) {_vm.$set(_vm.form, "isFinalized", $$v)},expression:"form.isFinalized"}},_vm._l((_vm.statusList),function(item){return _c('el-option',{key:item.code,attrs:{"label":item.name,"value":item.code}})}),1)],1),_c('el-form-item',{attrs:{"label":"客户是否已签字:","label-width":"115px"}},[_c('span',{class:_vm.getStatusClass(_vm.form.isSignatory)},[_vm._v(_vm._s(_vm.form.isSignatory == 1 ? '已签字' : '未签字'))])])],1)],1),_c('p',{staticClass:"line"}),_c('p',{staticClass:"smallTitle small"},[_vm._v("选校择校表")]),_c('el-table',{attrs:{"data":_vm.form.selectionDtos,"border":""}},[_c('el-table-column',{attrs:{"label":"学校名称（英文）","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-select',{attrs:{"disabled":_vm.pageType == 'details',"filterable":"","remote":"","placeholder":"请输入学校名称","remote-method":(name) => {
                  _vm.schoolRemoteMethod(name);
                },"loading":_vm.schoolLoading},on:{"change":(id) => {
                  _vm.schoolChange(id, scope.$index);
                }},model:{value:(scope.row.schoolId),callback:function ($$v) {_vm.$set(scope.row, "schoolId", $$v)},expression:"scope.row.schoolId"}},_vm._l((_vm.schoolList),function(item){return _c('el-option',{key:item.id,attrs:{"label":item.universityNameEn,"value":item.id}})}),1)]}}])}),_c('el-table-column',{attrs:{"prop":"address","label":"项目名称（英文）","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(scope.row.schoolId)?_c('el-select',{directives:[{name:"el-select-loadmore",rawName:"v-el-select-loadmore",value:(_vm.loadmore),expression:"loadmore"}],attrs:{"disabled":_vm.pageType == 'details',"filterable":"","remote":"","placeholder":"请输入项目名称","remote-method":(name) => {
                  _vm.projectRemoteMethod(name, scope.row.schoolId);
                },"loading":_vm.projectLoading},on:{"change":(id) => {
                  _vm.projectChange(id, scope.$index);
                }},model:{value:(scope.row.projectId),callback:function ($$v) {_vm.$set(scope.row, "projectId", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"scope.row.projectId"}},_vm._l((_vm.projectList),function(item){return _c('el-option',{key:item.id,attrs:{"label":item.programNameEn,"value":item.id}})}),1):_vm._e()]}}])}),(_vm.pageType != 'details')?_c('el-table-column',{attrs:{"prop":"operation","label":"操作","align":"center","width":"80"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.handleDelete(0, '确认删除该条数据吗？', scope.$index)}}},[_vm._v("移除")])]}}],null,false,2147445953)}):_vm._e()],1),(_vm.pageType != 'details')?_c('p',{staticClass:"addBtn",on:{"click":_vm.addTable}},[_vm._v("+")]):_vm._e()],1)]),_c('el-dialog',{attrs:{"title":_vm.titile,"visible":_vm.dialogVisible,"width":"417px","center":"","close-on-click-modal":false,"show-close":false},on:{"update:visible":function($event){_vm.dialogVisible=$event}}},[_c('span',{staticClass:"tips"},[_vm._v(_vm._s(_vm.tipTxt))]),_c('div',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{staticStyle:{"width":"38%","color":"#ff8240","border":"1px solid #ff8240"},attrs:{"plain":"","round":""},on:{"click":_vm.cancel}},[_vm._v("取消")]),_c('el-button',{staticStyle:{"width":"38%","color":"#fff","background":"#ff8240"},attrs:{"loading":_vm.addLoading,"round":""},on:{"click":_vm.sure}},[_vm._v(_vm._s(_vm.addLoading ? '请稍候...' : '确定'))])],1)]),_c('historyDialog',{attrs:{"historyList":_vm.historyList,"pagenum":_vm.pagenum,"pageall":_vm.pageall,"dialogRecordVisible":_vm.dialogRecordVisible},on:{"pageclick":_vm.pageDialogClick,"handleClose":_vm.showDialog,"toDetail":_vm.toDetail}}),_c('historyDialogDetail',{attrs:{"dialogDetailVisible":_vm.dialogDetailVisible,"historyInfo":_vm.historyInfo},on:{"handleClose":_vm.closeDialog}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }