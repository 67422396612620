<template>
  <div class="infobigBox">
    <leftNav></leftNav>
    <div class="infoBox">
      <p class="title">
        CRM档案
      </p>
      <div class="infopng">
        <el-row class="infoRight">
          <el-col :span="6" class="infoLeft">
            <p>客户名称：<span>{{form.name}}</span></p>
          </el-col>
          <el-col :span="6" class="infoLeft">
            <p>意向阶段：<span>{{form.intentionStage}}</span></p>
          </el-col>
          <el-col :span="6" class="infoLeft">
            <p>意向国家/地区：<span>{{form.intentionCountry}}</span></p>
          </el-col>
          <el-col :span="6" class="infoLeft">
            <p>计划入学季：<span>{{form.planStudentDate}}</span></p>
          </el-col>
        </el-row>
        <el-row class="infoRight">
          <el-col :span="6" class="infoLeft">
            <p>在读院校：<span>{{form.school}}</span></p>
          </el-col>
          <el-col :span="6" class="infoLeft">
            <p>在读年级：<span>{{form.grade}}</span></p>
          </el-col>
          <el-col :span="6" class="infoLeft">
            <p>在读专业：<span>{{form.speciality}}</span></p>
          </el-col>
          <el-col :span="6" class="infoLeft">
            <p>电子邮件：<span>{{form.email}} </span></p>
          </el-col>
        </el-row>

        <el-row class="infoRight" v-if="form.languageScore!=null && form.languageScore.length>0">
          <el-col :span="24" class="infoLeft fr">
            <p>语言成绩：</p>
          </el-col>
          <el-col :span="24" class="infoLeft fr">
            <el-table
              :data="form.languageScore"
              style="width: 100%;margin-top: 10px;">
              <el-table-column
                prop="name"
                label="语言成绩类型"
                align="center"
                >
              </el-table-column>
              <el-table-column
                prop="totalScore"
                label="Total points（总分）"
                align="center"
                width="168px"
                >
              </el-table-column>
              <el-table-column label="细项" align="center">
                <el-table-column
                  prop="listenScore"
                  align="center"
                  label="Listening（听）">
                </el-table-column>
                <el-table-column
                  prop="speakScore"
                  align="center"
                  label="Speaking（说）">
                </el-table-column>
                <el-table-column
                  prop="readScore"
                  align="center"
                  label="Reading（读）">
                </el-table-column>
                <el-table-column
                  prop="writeScore"
                  align="center"
                  label="Writing（写）">
                </el-table-column>
              </el-table-column>
            </el-table>
          </el-col>
        </el-row>
        <el-row class="infoRight" v-if="form.gradeScore!=null && form.gradeScore.length>0">
          <el-col :span="24" class="infoLeft fr">
            <p>标准学术考试成绩：</p>
          </el-col>
          <el-col :span="24" class="infoLeft fr">
            <el-table
              :data="form.gradeScore"
              style="width: 100%;margin-top: 10px;"
              border
              >
              <el-table-column
                prop="name"
                label="语言成绩类型"
                align="center"
                >
              </el-table-column>
              <el-table-column
                prop="totalScore"
                label="Total points（总分）"
                align="center"
                width="168px"

                >
              </el-table-column>
              <el-table-column
              :show-header="false"
                prop="listenScore"
                align="center"
                label="细项1">
              </el-table-column>
              <el-table-column
                prop="speakScore"
                align="center"
                :show-header="false"
                label="细项2">
              </el-table-column>
              <el-table-column
                prop="readScore"
                align="center"
                :show-header="false"
                label="细项3">
              </el-table-column>
            </el-table>
          </el-col>
        </el-row>
        <el-row class="infoRight">
          <el-col :span="6" class="infoLeft">
            <p>手机号：<span>{{form.phone}} </span></p>
          </el-col>
          <el-col :span="6" class="infoLeft">
            <p>微信号：<span>{{form.wxNumber}} </span></p>
          </el-col>
        </el-row>
        <el-row class="infoRight">
          <el-col :span="24" class="infoLeft fr">
            <p>客户简介：<span>{{form.content}} </span></p>
          </el-col>
        </el-row>
        <!-- <el-row class="infoRight">
          <el-col :span="24" class="infoLeft">
            <p>当前负责老师：<span>{{form.teacherName}} </span></p>
          </el-col>
        </el-row> -->
      </div>

    </div>
  </div>
</template>

<script>
import leftNav from "./leftNav";
import { getcustomInfodetail } from '@/assets/api/index';
export default {
  components: {
    leftNav,
  },
  data() {
    return {
      form: {},
      tableData: []

    };
  },
  methods: {
    getcustomInfodetail() {
      const data = {
        token: localStorage.getItem('userInfo').cmsToken
      };

      getcustomInfodetail(data).then(res => {
        if (res.code == '2000') {
          this.form = res.data;
          if (this.form.languageScore && this.form.gradeScore != null) {
            this.form.languageScore = JSON.parse(this.form.languageScore);
          }
          if (this.form.gradeScore && this.form.gradeScore != null) {
            this.form.gradeScore = JSON.parse(this.form.gradeScore);
          }
          console.log(this.form.languageScore);
          console.log(this.form.gradeScore);
        } else {
          this.$message({
            type: 'error',
            message: res.msg
          });
        }
      });
    }
  },
  mounted() {
    this.getcustomInfodetail();
  },
};
</script>

<style lang='stylus' scoped>
    @import '~css/stylus.styl';

    .infobigBox {
      width: 1200px;
      height: 100%;
      display: flex;
      justify-content: space-between;
      background-color: #F5F7FA;
      padding-bottom: 60px;
      margin: 0 auto;
    }

    .infoBox {
      width: 940px;
      background-color: #fff;
      margin: 27px 2% 27px;
      box-sizing: border-box;
      border-radius: 12px 12px 12px 12px;
      padding: 23px 0;

      .title {
        font-size: 20px;
        color: #333333;
        height: 23px;
        position: relative;
        padding-left: 14px;
        font-weight: bold;
        padding: 0 20px 21px 34px;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      .title::after {
        content: '';
        position: absolute;
        left: 20px;
        top: 2px;
        width: 4px;
        height: 20px;
        display: block;
        background-color: $themeColor;
        border-radius: 2px;
      }
    }

    .infopng {
        border-top: 1px solid #EEEEEE;
        padding: 19px 20px 0;
        margin: 0 auto 19px;
        box-sizing: border-box;
    }
    .infoRight{
        margin-bottom: 15px;
        .infoLeft{
            font-size: 14px;
            color: #333333;
            font-weight: 400;
            line-height: 20px;
            span{
                color: #666666;

            }
        }
    }
    .fr>p>span{
        float right;
        width 815px;
    }

  </style>
<style>
#app {
  background-color: #f5f7fa;
}
</style>