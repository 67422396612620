<template>
  <div class="footer flex-justify-center"  @contextmenu.prevent @copy.prevent  @cut.prevent  @paste.prevent style=" user-select: none;">
    <div class="flexBetweenCenter">
      <div class="footer-box flexBetween flexColumn">
        <div>
          <p>关于我们</p>
          <span>致力于成为留学顾问的好朋友，为留学顾问提供优质的工具服务</span>
        </div>
        <div>
          <p>粤ICP备2024020323号</p>
          <span>2024太原市超哥留学有限公司</span>
        </div>
      </div>
      <div class="footer-box flexBetween flexColumn">
        <div>
          <p>公司地址</p>
          <span>山西省太原市万柏林区</span>
        </div>
        <div>
          <p>Q&A</p>
          <span>用户服务与隐私协议</span>
        </div>
      </div>
      <div class="qrCode flex">
        <div>
          <img src="@/assets/code.png" alt="" />
          <p>客服微信</p>
        </div>
        <div>
          <img src="@/assets/code.png" alt="" />
          <p>公众号</p>
        </div>
        <div>
          <img src="@/assets/code.png" alt="" />
          <p>小程序</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Footer',
  components: {},
  data() {
    return {};
  },
  methods: {},
  mounted() {}
};
</script>
<style  lang='stylus' scoped>
@import '~css/stylus.styl';

.footer {
  background: url('../assets/footerBg.png') no-repeat;
  background-size: cover;
  background-position:center;
  color: #fff;
  height: 321px;
  position: relative;
  margin-top: -40px
  z-index:6

  &>div {
    width: $width;
    padding: 100px 0 60px;
  }

  .footer-box {
    height: 100%;

    p {
      font-weight: bold;
      font-size: 20px;
      margin-bottom: 15px;
    }
  }

  .qrCode {
    div {
      margin-right: 40px;
    }

    &>div:last-child {
      margin: 0;
    }
    p{
      text-align: center
    }
    img {
      width: 80px;
      height: 80px;
      margin-bottom: 10px
    }
  }
}
</style>