<template>
  <div>
    <div class="title">
      学生申请信息表丨第一部分：申请人详细情况
      <div class="btnBox">
        <el-button
          v-if="isEdit && type!='detail' && finalDraft!=1"
          type="primary"
          round
          @click="toEdit"
          >点击修改</el-button
        >
        <!-- <el-button
          v-if="isEdit"
          type="primary"
          style="margin-left: 10px"
          round
          @click="Jump"
          >跳转下一页</el-button
        > -->
        <p
          class="saveBtn"
          v-if="isEdit==false"
          @click="exitEdit"
          style="margin-right: 10px"
        >
          退出修改
        </p>
        <el-button
          type="primary"
          v-if="isEdit==false"
          round
          @click="saveInfo('formData')"
          >确认修改</el-button
        >
      </div>
    </div>
    <div class="contentBox">
      <el-form
        :model="formData"
        :rules="rules"
        ref="formData"
        inline
        class="flexs"
        label-position="left"
      >
        <h3>基本信息</h3>
        <el-row>
          <el-col :span="6">
            <el-form-item
              :class="whetherReturnEmpty(formData.editDto.name)"
              label="姓名:"
              label-width="74px"
              prop="name"
            >
              <el-input
                v-model="formData.name"
                placeholder="请输入"
                size="medium"
                :disabled="isEdit"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              label="是否有曾用名（以户口本为准）:"
              :class="whetherReturnEmpty(formData.editDto.hasPreviousName)"
              label-width="400px"
              prop="hasPreviousName"
            >
              <el-select
                v-model="formData.hasPreviousName"
                placeholder="请选择"
                size="medium"
                width="80px"
                :disabled="isEdit"
              >
                <el-option label="是" :value="1" />
                <el-option label="否" :value="0" />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item
              :class="whetherReturnEmpty(formData.editDto.gender)"
              label="性别:"
              label-width="52px"
              prop="gender"
            >
              <el-select
                v-model="formData.gender"
                placeholder="请选择"
                size="medium"
                :disabled="isEdit"
              >
                <el-option label="男" :value="1" />
                <el-option label="女" :value="0" />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="5">
            <el-form-item
              :class="whetherReturnEmpty(formData.editDto.nationality)"
              label="国籍:"
              label-width="60px"
              prop="nationality"
            >
              <el-select
                v-model="formData.nationality"
                placeholder="请选择"
                size="medium"
                :disabled="isEdit"
              >
                <el-option
                 v-for="item in nationalityList"
                 :key="item.id"
                  :label="item.name"
                  :value="item.name"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item
              :class="whetherReturnEmpty(formData.editDto.maritalStatus)"
              label="婚姻状况:"
              label-width="110px"
              prop="maritalStatus"
            >
              <el-select
                v-model="formData.maritalStatus"
                placeholder="请选择"
                size="medium"
                :disabled="isEdit"
              >
                <el-option label="未婚" :value="0" />
                <el-option label="已婚" :value="1" />
                <el-option label="离异" :value="2" />
                <el-option label="丧偶" :value="3" />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item
              :class="whetherReturnEmpty(formData.editDto.birthday)"
              label="出生日期:"
              label-width="110px"
              prop="birthday"
            >
              <el-date-picker
                v-model="formData.birthday"
                type="date"
                placeholder="选择日期"
                size="medium"
                value-format="yyyy-MM-dd"
                style="width: 140px"
                :disabled="isEdit"
              ></el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item
              :class="whetherReturnEmpty(formData.editDto.birthplace)"
              label="出生地:"
              label-width="122px"
              prop="birthplace"
            >
              <el-input
                v-model="formData.birthplace"
                placeholder="请输入"
                size="medium"
                :disabled="isEdit"
              />
            </el-form-item>
          </el-col>
          <el-col :span="9">
            <el-form-item
              :class="whetherReturnEmpty(formData.editDto.idNumber)"
              label="身份证号:"
              label-width="122px"
              prop="idNumber"
            >
              <el-input
                v-model="formData.idNumber"
                placeholder="请输入"
                size="medium"
                :disabled="isEdit"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="18">
            <el-form-item
              :class="whetherReturnEmpty(formData.editDto.homeAddress)"
              label="家庭地址:"
              label-width="80px"
              prop="homeAddress"
            >
              <el-input
                v-model="formData.homeAddress"
                placeholder="请输入"
                size="medium"
                class="matching-input"
                :disabled="isEdit"
              />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item
              :class="whetherReturnEmpty(formData.editDto.homePostalCode)"
              label="邮政编码:"
              label-width="110px"
              prop="homePostalCode"
            >
              <el-input
                v-model="formData.homePostalCode"
                placeholder="请输入"
                size="medium"
                :disabled="isEdit"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="18">
            <el-form-item
              :class="whetherReturnEmpty(formData.editDto.mailAddress)"
              label="邮寄地址:"
              label-width="100px"
              prop="mailAddress"
            >
              <el-input
                v-model="formData.mailAddress"
                placeholder="录取通知书的地址"
                size="medium"
                class="matching-input"
                :disabled="isEdit"
              />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item
              :class="whetherReturnEmpty(formData.editDto.mailPostalCode)"
              label="邮政编码:"
              label-width="110px"
              prop="mailPostalCode"
            >
              <el-input
                v-model="formData.mailPostalCode"
                placeholder="请输入"
                size="medium"
                :disabled="isEdit"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <!-- <h3>护照信息</h3>
        <el-row>
          <el-col :span="6">
            <el-form-item :class="whetherReturnEmpty(formData.editDto.passportNumber)" label="护照号:" label-width="90px" prop="passportNumber" >
              <el-input v-model="formData.passportNumber" placeholder="请输入" size="medium" :disabled="isEdit" />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item :class="whetherReturnEmpty(formData.editDto.passportIssuePlace)" label="签发地:" label-width="90px" prop="passportIssuePlace" >
              <el-input v-model="formData.passportIssuePlace" placeholder="请输入" size="medium" :disabled="isEdit" />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item :class="whetherReturnEmpty(formData.editDto.passportIssueDate)" label="签发日期:" label-width="110px" prop="passportIssueDate" >
              <el-date-picker
v-model="formData.passportIssueDate"
type="date"
placeholder="选择日期"
size="medium"
                value-format="yyyy-MM-dd"
style="width: 140px;"
:disabled="isEdit"></el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item :class="whetherReturnEmpty(formData.editDto.passportExpiryDate)" label="有效日期:" label-width="110px" prop="passportExpiryDate" >
              <el-date-picker
v-model="formData.passportExpiryDate"
type="date"
placeholder="选择日期"
size="medium"
                value-format="yyyy-MM-dd"
style="width: 140px;"
:disabled="isEdit"></el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="6">
            <el-form-item :class="whetherReturnEmpty(formData.editDto.visaRefusalHistory)" label="有无拒签史:" label-width="140px" prop="visaRefusalHistory" >
              <el-select v-model="formData.visaRefusalHistory" placeholder="请选择" size="medium" :disabled="isEdit">
                <el-option label="有" :value="1" />
                <el-option label="无" :value="0" />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item :class="whetherReturnEmpty(formData.editDto.visaRefusalCountry)" label="国家:" label-width="90px" prop="visaRefusalCountry" >
              <el-select v-model="formData.visaRefusalCountry" placeholder="请选择" size="medium" :disabled="isEdit">
                <el-option v-for="country in countries" :key="country" :label="country" :value="country" />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item :class="whetherReturnEmpty(formData.editDto.visaRefusalCount)" label="次数:" label-width="110px" prop="visaRefusalCount" >
              <el-input v-model="formData.visaRefusalCount" placeholder="请输入" size="medium" :disabled="isEdit" />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item :class="whetherReturnEmpty(formData.editDto.visaRefusalType)" label="类型:" label-width="110px" prop="visaRefusalType" >
              <el-select v-model="formData.visaRefusalType" placeholder="请选择" size="medium" :disabled="isEdit">
                <el-option label="拒签" value="rejection" />
                <el-option label="其他" value="other" />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="6">
            <el-form-item :class="whetherReturnEmpty(formData.editDto.entryHistory)" label="有无入境史:" label-width="140px" prop="entryHistory" >
              <el-select v-model="formData.entryHistory" placeholder="请选择" size="medium" :disabled="isEdit">
                <el-option label="有" :value="1" />
                <el-option label="无" :value="0" />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item :class="whetherReturnEmpty(formData.editDto.entryCountry)" label="国家:" label-width="90px" prop="entryCountry" >
              <el-select v-model="formData.entryCountry" placeholder="请选择" size="medium" :disabled="isEdit">
                <el-option v-for="country in countries" :key="country" :label="country" :value="country" />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item :class="whetherReturnEmpty(formData.editDto.entryCount)" label="次数:" label-width="110px" prop="entryCount" >
              <el-input v-model="formData.entryCount" placeholder="请输入" size="medium" :disabled="isEdit" />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item :class="whetherReturnEmpty(formData.editDto.entryType)" label="类型:" label-width="110px" prop="entryType" >
              <el-select v-model="formData.entryType" placeholder="请选择" size="medium" :disabled="isEdit">
                <el-option label="短期访问" value="shortVisit" />
                <el-option label="长期居住" value="longStay" />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="10">
            <el-form-item :class="whetherReturnEmpty(formData.editDto.firstPassport)" label="是否为第一本护照:" label-width="200px" prop="firstPassport" >
              <el-select v-model="formData.firstPassport" placeholder="请选择" size="medium" :disabled="isEdit">
                <el-option label="是" :value="1" />
                <el-option label="否" :value="0" />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item :class="whetherReturnEmpty(formData.editDto.personalEmail)" label="个人邮箱:" label-width="110px" prop="personalEmail" >
              <el-input v-model="formData.personalEmail" placeholder="请输入" size="medium" :disabled="isEdit" />
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item :class="whetherReturnEmpty(formData.editDto.officialEmail)" label="官方邮箱:" label-width="110px" prop="officialEmail" >
              <el-input v-model="formData.officialEmail" placeholder="请输入" size="medium" :disabled="isEdit" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="6">
            <el-form-item :class="whetherReturnEmpty(formData.editDto.hasVisa)" label="是否有签证:" label-width="200px" prop="hasVisa" >
              <el-select v-model="formData.hasVisa" placeholder="请选择" size="medium" :disabled="isEdit">
                <el-option label="有" :value="1" />
                <el-option label="无" :value="0" />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item :class="whetherReturnEmpty(formData.editDto.visaNumber)" label="签证号码:" label-width="110px" prop="visaNumber" >
              <el-input v-model="formData.visaNumber" placeholder="请输入" size="medium" :disabled="isEdit" />
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item :class="whetherReturnEmpty(formData.editDto.visaPeriod)" label="签证颁发及过期时间:" label-width="220px" prop="visaPeriod" >
              <el-input v-model="formData.visaPeriod" placeholder="请输入" size="medium" :disabled="isEdit" />
            </el-form-item>
          </el-col>
        </el-row> -->
        <h3>信用卡信息</h3>
        <el-row>
          <el-col :span="6">
            <el-form-item
              :class="whetherReturnEmpty(formData.editDto.creditCardNumber)"
              label="卡号:"
              label-width="52px"
              prop="creditCardNumber"
            >
              <el-input
                v-model="formData.creditCardNumber"
                placeholder="请输入"
                size="medium"
                :disabled="isEdit"
              />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item
              :class="whetherReturnEmpty(formData.editDto.creditCardName)"
              label="持卡人姓名:"
              label-width="140px"
              prop="creditCardName"
            >
              <el-input
                v-model="formData.creditCardName"
                placeholder="请输入"
                size="medium"
                :disabled="isEdit"
              />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item
              :class="whetherReturnEmpty(formData.editDto.creditCardExpiryDate)"
              label="到期日:"
              label-width="84px"
              prop="creditCardExpiryDate"
            >
              <el-date-picker
                v-model="formData.creditCardExpiryDate"
                type="date"
                placeholder="选择日期"
                size="medium"

                style="width: 140px"
                :disabled="isEdit"
              ></el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item
              :class="
                whetherReturnEmpty(formData.editDto.creditCardSecurityCode)
              "
              label="安全码:"
              label-width="90px"
              prop="creditCardSecurityCode"
            >
              <el-input
                v-model="formData.creditCardSecurityCode"
                placeholder="请输入"
                size="medium"
                :disabled="isEdit"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="7">
            <el-form-item
              :class="whetherReturnEmpty(formData.editDto.personalPhoneNumber)"
              label="个人手机号:"
              label-width="150px"
              prop="personalPhoneNumber"
            >
              <el-input
                v-model="formData.personalPhoneNumber"
                placeholder="请输入"
                size="medium"
                :disabled="isEdit"
              />
            </el-form-item>
          </el-col>
          <el-col :span="9">
            <el-form-item
              :class="whetherReturnEmpty(formData.editDto.homePhoneNumber)"
              label="家庭固定电话(包括区号):"
              label-width="323px"
              prop="homePhoneNumber"
            >
              <el-input
                v-model="formData.homePhoneNumber"
                placeholder="请输入"
                size="medium"
                :disabled="isEdit"
              />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
  </div>
</template>
<script>
import {
  getDetailByMaterialId,
  wfSaiApplicantAdd,
  wfSaiApplicantUpdate
} from '@/assets/api/application';
import { dicListByCode } from 'api/index.js';
export default {
  name: 'Partone',
  props: {
    id: {
      type: [String, Number],
      default: ''
    },
    type: {
      type: String,
      default: ''
    },
    finalDraft: {
      type: Number,
      default: 0
    }
  },
  data() {
    const validatePhone = (rule, value, callback) => {
      if (!value) {
        callback(new Error('请输入手机号码'));
      } else if (!/^1[3-9]\d{9}$/.test(value)) {
        callback(new Error('请输入正确的手机号码'));
      } else {
        callback();
      }
    };

    const validateEmail = (rule, value, callback) => {
      if (value && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)) {
        callback(new Error('请输入正确的邮箱格式'));
      } else {
        callback();
      }
    };

    return {
      formData: {
        editDto: {}
      },
      isEdit: true,
      rules: {
        name: [
          { required: true, message: '请输入姓名', trigger: 'blur' },
          { min: 2, max: 20, message: '长度在 2 到 20 个字符', trigger: 'blur' }
        ],
        idNumber: [
          { required: true, message: '请输入身份证号', trigger: 'blur' },
          {
            pattern: /^[1-9]\d{5}(18|19|20)\d{2}(0[1-9]|1[0-2])(0[1-9]|[12]\d|3[01])\d{3}(\d|X)$/,
            message: '请输入正确的身份证号',
            trigger: 'blur'
          }
        ],
        personalPhoneNumber: [
          { required: true, validator: validatePhone, trigger: 'blur' }
        ],
        mailAddress: [
          { required: true, message: '请输入邮寄地址', trigger: 'blur' }
        ],
        personalEmail: [{ validator: validateEmail, required: true, trigger: 'blur', message: '请检查邮箱地址', }],
      },
      isNewEdit: null,
      nationalityList: [],
    };
  },
  mounted() {},
  methods: {
    Jump() {
      this.$emit('nextStep');
    },
    saveInfo(formName) {
      this.$emit('showLoading');
      const url = this.isNewEdit ? wfSaiApplicantAdd : wfSaiApplicantUpdate;
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          try {
            const data = {
              ...this.formData,
              materialId: this.id,
              userId: localStorage.getItem('userInfo').id,
              partion: 1 // 1 基础信息  2护照信息
            };
            if (this.isNewEdit == false) {
              data.id = this.formData.id;
            }
            url(data).then((res) => {
              if (res.code === '2000') {
                this.$message.success('保存成功');
                this.isEdit = true;
                this.getDetail();
                setTimeout(() => {
                  this.$emit('getDetail');
                }, 2000);
                this.$emit('hideLoading');
                // this.$emit('nextStep');
              } else {
                this.$message.error(res.msg || '保存失败');
                this.$emit('hideLoading');
              }
            });
          } catch (error) {
            this.$message.error('保存失败');
            this.$emit('hideLoading');
          }
        } else {
          this.$message.error('请完善必填信息');
          this.$emit('hideLoading');
          return false;
        }
      });
    },
    toEdit() {
      this.isEdit = false;
    },
    exitEdit() {
      this.isEdit = true;
    },
    getDetail() {
      try {
        this.loading = true;
        getDetailByMaterialId({ materialId: this.id }).then(
          (res) => {
            if (res.code == '2000') {
              this.formData = res.data;
              this.isNewEdit = false;
            } else {
              this.isNewEdit = true;
              this.formData = {
                ...res.data,
              };
            }
            this.loading = false;
          }
        );
      } catch (error) {
        console.error('获取详情失败:', error);
        this.$message.error('获取详情失败');
      } finally {
        this.loading = false;
      }
    },
    whetherReturnEmpty(value) {
      if (value !== undefined && value !== null && value === 1) {
        return 'changeValue';
      }
      return '';
    },
    getCodeList() {
                dicListByCode({ code: 'GJLB' })
                .then(res => {
                    if (res.code == '2000') {
                        this.nationalityList = res.data;
                    } else {
                    this.$message({
                        type: 'error',
                        message: res.msg
                    });
                    }
                })
                .catch(error => {
                    console.error('An error occurred:', error);
                    this.$message({
                    type: 'error',
                    message: '请求过程中发生错误，请稍后再试。'
                    });
                });
            },
  },
  created() {
    this.getDetail();
    this.getCodeList();
  }
};
</script>

<style lang='stylus' scoped>
@import '~css/stylus.styl';

.title {
  font-weight: bold;
  font-size: 18px;
  color: #333333;
  line-height: 32px;
  margin: 21px 0;
  text-align: left;
  display: flex;
  justify-content: space-between;

}

.flexs {
  .el-form-item {
    display: flex;
    margin-bottom: 16px;
    align-items: end;
  }
}

.contentBox {
  width: 900px;
  margin: 0 auto;
  background: #fff;

  h3 {
    font-size: 16px;
    color: #333;
    margin: 20px 0 15px;
    padding-left: 10px;
    border-left: 4px solid #FF8240;
  }
}

>>>.el-form-item__content {
  width: 100%;
}

.btnBox {
  display: flex;
  justify-content: center;
}

>>>.el-button {
  width: 92px;
  padding: 0 !important;
  height: 30px;
  line-height: 30px;
  border-radius: 15px;
  font-size: 14px;
  font-weight: 400;
}

.saveBtn {
  width: 92px;
  height: 30px;
  background: #FFFFFF;
  border-radius: 15px;
  border: 1px solid #FF8240;
  box-sizing: border-box;
  font-size: 14px;
  color: #FF8240;
  text-align: center;
  line-height: 30px;
  cursor: pointer;
  transition: all 0.3s;
  font-weight: 400;

  &:hover {
    background: #fff5f0;
  }
}

.changeValue {
  >>>.el-form-item__label {
    color: #FF8240 !important;
  }

  >>>.el-input__inner {
    border-color: #FF8240 !important;
  }
}
</style>