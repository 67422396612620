<template>
    <div>
      <el-dialog
        title="消息提醒"
        :visible.sync="dialogVisible"
        width="900px"
        :close-on-click-modal="false"
        v-loading="loading"
        :append-to-body="true"

      >
        <div class="message-content">
            <el-table  :data="tableData">
                <el-table-column prop="date" label="" align="center" width="60">
                    <template slot-scope="scope">
                        <i class="el-icon-message-solid" :class="[scope.row.isRead==0?'activeimg':'img']"></i>
                    </template>
                </el-table-column>
                <el-table-column prop="date" label="序号" align="center" width="60">
                    <template slot-scope="scope">
                        {{ (pagenum - 1) * pageSize + scope.$index + 1 }}
                    </template>
                </el-table-column>
                <el-table-column prop="content" width="440" label="提醒内容"></el-table-column>
                <el-table-column prop="createTime" label="提醒时间" width="200"></el-table-column>
                <el-table-column prop="address" label="操作" >
                    <template slot-scope="scope">
                        <el-button type="text" @click="handleClick(scope.row)">查看</el-button>
                    </template>
                </el-table-column>

            </el-table>
            <paging  :pageall="pageall" :pagenum="pagenum" @pageclick="pageclick" style="margin-top: 20px"></paging>
         </div>
      </el-dialog>

    </div>
  </template>

  <script>
import paging from '@/components/paging.vue';
import { messageRecord, readTask } from '@/assets/api';
  export default {
      name: 'Message',
      components: {
        paging
      },
      data() {
          return {
              dialogVisible: false,
              loading: false,
              tableData: [],
              pageall: 0,
              pagenum: 1,
              pageSize: 6
          };
      },
      methods: {
        openDialog() {
            this.dialogVisible = !this.dialogVisible;
            this.getList();
        },
        pageclick(val) {
            this.pagenum = val;
            this.getList();
        },
        getList() {
            messageRecord({
                pageNum: this.pagenum,
                pageSize: this.pageSize,
                userId: localStorage.getItem('userInfo').id,
                msgType: 3
            }).then(res => {
                if (res.code == 2000) {
                    this.tableData = res.data.list;
                    this.pageall = res.data.total;
                } else {
                    this.$message({
                        type: 'error',
                        message: res.msg
                    });
                }
            });
        },
        formatTimestamp(timestamp) {
            const date = new Date(timestamp);
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0'); // 月份从0开始，需要加1
            const day = String(date.getDate()).padStart(2, '0');
            const hours = String(date.getHours()).padStart(2, '0');
            const minutes = String(date.getMinutes()).padStart(2, '0');
            return `${year}年${month}月${day}日 ${hours}:${minutes}`;
        },
        handleClick(row) {
            this.dialogVisible = !this.dialogVisible;
            this.$router.push({
                path: '/applicationFormdetail',
                query: { id: row.taskId, type: "edit" }
            });
            readTask({
                id: row.id
            }).then(res => {
                console.log(res);
            });
        }
    },
      mounted() {},
  };
  </script>

  <style lang='stylus' scoped>
  @import '~css/stylus.styl';

  >>>.el-dialog__body{
      padding: 0
  }
  >>>.el-dialog__header{
    border-bottom: 1px solid #EEEEEE;
  }
  >>>.el-dialog{
    padding-bottom:20px;
  }
.message-content{
    padding: 0 20px;
    box-sizing: border-box;
    .el-table{
        margin-bottom: 31px;

    }
    >>>.el-table__header th, .el-table__header tr{
        background: #FFFFFF !important;
    }
}
.activeimg{
    color: rgba(255, 119, 119, 1);
    font-size: 18px;
}
.img{
    color: rgba(127, 156, 184, 1);
    font-size: 18px;
}
  </style>