<template>
  <div class="MatchingBox"  @contextmenu.prevent @copy.prevent  @cut.prevent  @paste.prevent style=" user-select: none;">
    <div class="topban">
      <div class="topban_left">
        <p>
          <img src="~@/assets/share/iconleft.png" alt="" />院校匹配<img
            src="~@/assets/share/iconright.png"
            alt=""
          />
        </p>
        <span>精准院校匹配：解锁你的理想大学之路</span>
      </div>
    </div>
    <div class="match_content">
      <div class="collegeMatching flex">
          <div class="flexBetweenCenter matching-box">
            <el-form  :model="ruleForm" :rules="rules" ref="ruleForm" label-width="130px" class=" ruleform" label-position="left">
               <div class="flex ruleform">
                  <el-form-item label="我的学校:"  label-width="75px">
                     <!-- <el-input size="medium" v-model="ruleForm.schoolName"></el-input> -->
                     <el-select
                        v-model="ruleForm.schoolName"
                        filterable
                        remote
                        :remote-method="remoteSearch"
                        placeholder="请输入关键词"
                        >
                        <el-option
                        v-for="item in collegeList"
                        :key="item.id"
                        :label="item.name"
                        :value="item.name"
                        ></el-option>
                     </el-select>
                  </el-form-item>
                  <el-form-item label="留学意向国家：" prop="areaId">
                     <el-select class="input-text" size="medium" @change="changeArea" v-model="ruleForm.areaId" placeholder="请选择">
                        <el-option v-for="item in areaList" :key="item.value" :label="item.name" :value="item.id"></el-option>
                     </el-select>
                  </el-form-item>
                  <el-form-item label="GPA：" prop="gpa" label-width="55px" style="width: 250px">
                     <el-input class="input-text matching-input" size="medium" v-model.trim="ruleForm.gpa" placeholder="请输入">
                        <template slot="append"><span class="finger" @click="toCount">计算服务</span></template>
                     </el-input>
                  </el-form-item>
                  <el-form-item label="意向专业方向：" prop="majorTxt" label-width="130px" >
                     <el-input class="input-text" @focus="selectMajor" size="medium" v-model.trim="ruleForm.majorTxt" placeholder="请选择"></el-input>
                  </el-form-item>
               </div>
               <div class="flex ruleform">
               <el-form-item label="托福：" prop="tf" label-width="60px"  v-if="ruleForm.city==='美国'">
                     <el-input size="medium" style="width: 100px;margin-right: 10px" v-model="ruleForm.tf" placeholder="总分"></el-input>
                     <el-input size="medium" style="width: 110px;" v-model="ruleForm.tf" placeholder="单项最低分"></el-input>
                  </el-form-item>
                  <el-form-item label="托福：" prop="tf" label-width="60px" v-if="ruleForm.city==='美国'">
                     <el-input size="medium" style="width: 100px;margin-right: 10px"  v-model="ruleForm.tf" placeholder="总分"></el-input>
                     <el-input size="medium"  style="width: 110px;" v-model="ruleForm.tf" placeholder="单项最低分"></el-input>
                  </el-form-item>

               </div>
            </el-form>
          </div>
          <div class="mate-btn  finger" @click="onMate('ruleForm')">
            开始匹配<i class="el-icon-position" style="margin-left: 5px;font-size: 13px;"></i>
          </div>
      </div>
      <div class="requireBox">
         <div v-if="isPanelVisible" class="panel">
         <div class="requireContent">
            <p>
               <span><font style="width:72px">GRE总分：</font><el-input  v-model="ruleForm.greTotal"  placeholder="请输入" inline></el-input></span>
               <span><font style="width:89px">GRE Verbal：</font><el-input  v-model="ruleForm.greVerbal"  placeholder="请输入" inline></el-input></span>
               <span><font style="width:140px">GRE mathematics：</font><el-input  v-model="ruleForm.greMaths"  placeholder="请输入" inline></el-input></span>
               <span><font style="width:100px">GRE writing：</font><el-input  v-model="ruleForm.greWriting"  placeholder="请输入" inline></el-input></span>
            </p>
            <!-- <p>
               <span><font style="width:110px">GRE：</font><el-input  v-model="ruleForm.greMaths"  placeholder="请输入" inline></el-input></span>
            </p> -->
         </div>
         <div class="requireContent">
            <p>
               <span><font style="width:82px">雅思总分：</font><el-input v-model="ruleForm.ieltsTotal" placeholder="请输入" inline></el-input></span>
               <span><font style="width:120px">雅思单项最低分：</font><el-input v-model="ruleForm.ieltsLow" placeholder="请输入" inline></el-input></span>
            </p>
            <!-- <p>
               <span><font style="width:110px">雅思：</font><el-input  v-model="ruleForm.greMaths"  placeholder="请输入" inline></el-input></span>
            </p> -->
         </div>
         <div class="requireContent" >
            <p>
               <span><font style="width:82px">托福总分：</font><el-input v-model="ruleForm.toeflTotal" placeholder="请输入" inline></el-input></span>
               <span><font style="width:120px">托福单项最低分：</font><el-input v-model="ruleForm.toeflLow" placeholder="请输入" inline></el-input></span>
            </p>
            <!-- <p>
               <span><font style="width:110px">托福：</font><el-input  v-model="ruleForm.greMaths"  placeholder="请输入" inline></el-input></span>
            </p> -->
         </div>
         <div class="requireContent">
            <p>
               <span><font style="width:85px">多邻国总分：</font><el-input v-model="ruleForm.duolingoTotal" placeholder="请输入" inline></el-input></span>
               <span><font style="width:130px">多邻国单项最低分：</font><el-input v-model="ruleForm.duolingoLow" placeholder="请输入" inline></el-input></span>
            </p>
            <!-- <p>
               <span><font style="width:130px">多邻国：</font><el-input  v-model="ruleForm.greMaths"  placeholder="请输入" inline></el-input></span>
            </p> -->
         </div>
         <div class="requireContent">
            <p>
               <span><font style="width:85px">GMAT总分：</font><el-input v-model="ruleForm.gmatTotal" placeholder="请输入" inline></el-input></span>
               <span><font style="width:130px">GMAT单项最低分：</font><el-input v-model="ruleForm.gmatLow" placeholder="请输入" inline></el-input></span>
            </p>
            <!-- <p>
               <span><font style="width:130px">GMAT：</font><el-input  v-model="ruleForm.greMaths"  placeholder="请输入" inline></el-input></span>
            </p> -->
         </div>
         <div class="requireContent">
            <p>
               <span><font style="width:82px">LSAT总分：</font><el-input v-model="ruleForm.lsatTotal" placeholder="请输入" inline></el-input></span>
               <span><font style="width:120px">LSAT单项最低分：</font><el-input v-model="ruleForm.lsatLow" placeholder="请输入" inline></el-input></span>
            </p>
            <!-- <p>
               <span><font style="width:120px">LSAT：</font><el-input  v-model="ruleForm.greMaths"  placeholder="请输入" inline></el-input></span>
            </p> -->
         </div>
         </div>
         <el-button class="btns" type="text" @click="togglePanel"> {{ isPanelVisible ? '收起' : '展开' }} <i :class="{'activeimg':!isPanelVisible}" class="el-icon-d-arrow-right rotates"></i></el-button>
      </div>
    </div>
    <div class="schoolBox">
      <div class="school_top">
         <p><span></span>学校列表</p>
         <span @click="changeExhibit">{{ changeTxt }} <img :src="changeIcon" alt=""></span>
      </div>
      <div class="school_list">
         <div class="list_top">
            <p class="toggle" :class="{'active':index==activeIndexes}" @click="toggleChange(index)" v-for="(item,index) in toggleList" :key="index">
              <span>{{ item.name }}</span>
            </p>
            <el-button type="primary" round size="mini" class="map" @click="toMap">查看地图</el-button>
         </div>
         <div class="scoollist_box" v-show="changeTxt==='列表'">
            <div class="school_list_item" v-for="(item, index) in schoolList" :key="index"  @click="toProfessionalList(item)">
               <img :src=item.logo alt="">
               <div class="schoolcontent">
                  <p class="schoolname">
                     <span>{{ item.universityNameCn }}</span>
                  </p>
                  <p>{{ item.universityNameEn }}</p>
                  <p>{{ item.area }}</p>
                  <div class="rankbox">
                     <p>
                        <span class="ranking">QS排名：<font>{{ item.qsRank }}名</font></span>
                        <span class="ranking">U.S.news排名：<font>{{ item.usnewsRank }}名</font></span>
                     </p>
                     <p>
                        <span class="ranking">The排名：<font>{{ item.theRank }}名</font></span>
                        <span class="ranking">Rank排名：<font>{{ item.ruankeRank }}名</font></span>
                     </p>
            </div>
                  <!-- <p>专业申请临近截止日期：{{ item.deadline }}</p> -->
               </div>
            </div>
            <el-empty v-if="schoolList.length == 0" description="暂无数据"></el-empty>
         </div>
         <div class="scoollist_box" v-show="changeTxt==='图文'">
            <el-table :data="schoolList" @row-click="toProfessionalList">
               <el-table-column label="序号" type="index" align="center"></el-table-column>
               <el-table-column label="中文学校名称" prop="universityNameCn" align="center"></el-table-column>
               <el-table-column label="英文学校名称" prop="universityNameEn" align="center"></el-table-column>
               <el-table-column label="地区" prop="area" align="center"></el-table-column>
               <el-table-column label="QS排名" prop="qsRank" align="center"></el-table-column>
               <el-table-column label="U.S.news排名" prop="usnewsRank" align="center"></el-table-column>
               <el-table-column label="The排名" prop="theRank" align="center"></el-table-column>
               <el-table-column label="ARWU排名" prop="ruankeRank" align="center"></el-table-column>
               <!-- <el-table-column label="专业申请临近截止时间" prop="deadline" align="center"></el-table-column> -->
            </el-table>
            <el-empty v-if="schoolList.length == 0" description="暂无数据"></el-empty>
         </div>
         <paging
        :pageall="pageall"
        :pagenum="pagenum"
        @pageclick="pageclick"
        style="margin-top: 20px;"
      ></paging>
      </div>
    </div>
    <majorDialog ref="majorDialog" @sendShowList="sendShowList" @sendDataList="sendDataList"></majorDialog>
    <GPADialog ref='GPADialog'></GPADialog>
  </div>
</template>

<script>
import { dicListByCode, cmsUniversity } from 'api/index.js';
import majorDialog from '../homePage/components/majorDialog';
import paging from "@/components/paging.vue";
import GPADialog from '../../components/GPAcalculation.vue';
import { getBkSchool } from 'api/caseSharing.js';
export default {
  name: "CollegeMatching",
  components: {
   majorDialog,
   paging,
   GPADialog
  },
  data() {
    return {
      input: '',
      value: '',
      pageall: 0,
      pagenum: 1,
      areaList: [],
      changeTxt: '列表',
      schoolList: [

      ],
      toggleList: [
         {
            name: '可以申请',
            value: '0'
         },
         {
            name: 'GPA不足',
            value: '1'
         },
         // {
         //    name: '不在清单',
         //    value: '3'
         // }
      ],
      activeIndexes: 0,
      ruleForm: {
        areaId: this.$route.query.searchObj?.areaId || '',
        ieltsTotal: this.$route.query.searchObj?.ielts || '',
        schoolName: this.$route.query.searchObj?.schoolName || '',
        toeflTotal: this.$route.query.searchObj?.toeflTotal || '',
        gpa: this.$route.query.searchObj?.gpa || '',
        gpaLess: 0,
        majorTxt: this.$route.query.searchObj?.majorTxt || []
      },
      rules: {

         areaId: [
            { required: true, message: '请选择留学意向国家', trigger: 'change' }
            ],
            gpa: [
               { required: true, message: '请输入GPA', trigger: 'blur' },
            ],
            majorTxt: [
               { required: true, message: '请选择意向专业方向', trigger: 'change' }
               ],
      },
      isPanelVisible: false,
      areaName: "",
      majorList: [],
      collegeList: []
    };
  },
  methods: {
   sendDataList(data) {
      this.majorList = data;
    },
    sendShowList(data) {
      this.ruleForm.majorTxt = data;
    },
   getList() {
      const data = {
          pageNum: this.pagenum,
         pageSize: 10,
         ...this.ruleForm

      };
      delete data.majorTxt;
      data.majorList = this.majorList;
      cmsUniversity(data).then(res => {
         if (res.code == '2000') {
            this.schoolList = res.data.list;
            this.pageall = res.data.total;
         } else {
            this.$message({
              type: 'error',
              message: res.msg
            });
            }
      }).catch(error => {
        console.error('An error occurred:', error);
       });
   },
   selectMajor() {
      this.$refs.majorDialog.openDialog();
    },
    onMate(formName) {
      this.$refs[formName].validate((valid) => {
          if (valid) {
            this.getList();
          } else {
            console.log('error submit!!');
            return false;
          }
        });
    },
    toCount() {
      this.$refs.GPADialog.openDialog();
    },
    changeExhibit() {
      this.changeTxt = this.changeTxt === '列表' ? '图文' : '列表';
    },
    toggleChange(index) {
      this.activeIndexes = index === this.activeIndexes ? [] : [index];
      this.ruleForm.gpaLess = this.activeIndexes == 1 ? 1 : 0;

      this.onMate('ruleForm');
    },
    pageclick(num) {
      this.pagenum = num;
    },
    togglePanel() {
      this.isPanelVisible = !this.isPanelVisible;
    },
    getArea() {
      dicListByCode({ code: 'ZXDQ' })
          .then(res => {
            if (res.code == '2000') {
                this.areaList = res.data;
                if (this.$route.query.searchObj) {
                  let chooseArea = [];
                  chooseArea = this.areaList.filter(item => item.id == this.$route.query.searchObj.areaId);
                  console.log(this.areaList);
                  this.areaName = chooseArea[0].name;
               }
            } else {
              this.$message({
                type: 'error',
                message: res.msg
              });
            }
          })
          .catch(error => {
            console.error('An error occurred:', error);
            this.$message({
              type: 'error',
              message: '请求过程中发生错误，请稍后再试。'
            });
          });
    },
    changeArea(item) {
      let chooseArea = [];
      chooseArea = this.areaList.filter(item => item.id == this.ruleForm.areaId);
      this.areaName = chooseArea[0].name;
    },
    toProfessionalList(val) {
      this.$router.push({
        path: '/professionalList',
        query: {
          schoolId: val.id
        }
      });
    },
    toMap() {
      if (this.schoolList.length === 0) {
        this.$message({
          type: 'warning',
          message: '请先进行匹配筛选'
        });
        return;
      }
      const chooseSchool = [];
      this.schoolList.forEach(item => {
       chooseSchool.push(item.id);
      });
      this.$router.push({ path: '/map', query: { chooseSchool: chooseSchool }});
    },
    remoteSearch(queryString) {
      if (queryString !== '') {
        const data = {
          ifPage: false,
          pageSize: 99999,
          pageNum: 1,
          name: queryString,
        };
        getBkSchool(data).then(res => {
        if (res.code == '2000') {
          this.collegeList = res.data.list || [];
        } else {
          this.$message({
            type: 'error',
            message: res.msg
          });
        }
      });
      } else {
        this.collegeList = []; // 当搜索字符串为空时，清空选项列表
      }
    }
  },
  mounted() {
   this.getArea();
   this.majorList = this.$route.query.chooseList || [];
   if (this.$route.query.searchObj) {
      this.getList();
   }
   },

  computed: {
    changeIcon() {
      return this.changeTxt === '列表' ? require('@/assets/list.png') : require('@/assets/picture.png');
    },

  },
};
</script>
<style  lang='stylus' scoped>
@import '~css/stylus.styl';
.MatchingBox {
  width: 100%;
  background-color: #f5f7fa;
  padding-bottom: 84px;
}
.topban {
   width: 100%;
   height: 140px;
   background: url('~@/assets/match_ban.png') no-repeat;
   background-size: 100% 100%;
   position: relative;
   z-index:1;
 }

 .topban_left {
   position: absolute;
   top: 50%;
   left: 50%;
   transform: translate(-50%, -50%);

   p {
     font-size: 20px;
     color: #fff;
     display: flex;
     align-items: center;
     justify-content: center;

     img {
       width: 40px;
       height: auto;
       display: block;
       margin-right: 12px;
     }

     img:nth-of-type(2) {
       margin-left: 12px;
     }
   }

   span {
     display: block;
     font-size: 14px;
     color: #FFFFFF;
     text-stroke: 1px rgba(0, 0, 0, 0);
     height: 19px;
     margin-top: 6px;

   }
 }

 .collegeMatching {
   margin: -31px auto 0;
   background: #FFFFFF;

   border-radius: 12px 12px 0 0;
   position:relative;
   z-index:2;
   width:1200px;
   .bottom-pad {
     margin-bottom: 10px;
   }

   .mate-btn {
      font-size: 14px;
      color: #FFFFFF;
      background: $themeColor;
      border-radius: 6px 6px 6px 6px;
      width: 110px;
      height: 35px;
      line-height: 35px;
      text-align: center;
      margin: 22px 20px 0 0;
   }

   &>img {
     width: 71px;
     height: 83px;
     margin: 13px 105px 0 39px;
   }

   .matching-box {
     flex: 1;
     flex-wrap: wrap;
     padding: 20px 0 20px 30px;
     margin-right: 30px;

     .matching-title {
       width: 80px;
     }

     .matching-titles {
       width: 108px;
     }
   }
 }
 >>>.el-input-group__append {
   background: #fff;
   color: $themeColor;
   padding: 0 11px;
 }

 .matching-input >>>.el-input__inner {

 }

 >>>.el-input__inner:focus {
   outline: none;
   border-color: #DCDFE6;
 }
 >>>.el-input__inner{
   padding: 0 11px !important;
 }
 .schoolBox{
   width: 1200px;
   margin: 20px auto;
   padding:20px;
   background: #FFFFFF;
   box-sizing: border-box;
   border-radius: 12px 12px 12px 12px;
   .school_top{
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;
      align-items: center;
      p{
         font-weight: bold;
         font-size: 20px;
         color: #333333;
         height: 26px;
         line-height: 26px;
         display: flex;
         align-items: center;
         >span{
            width: 4px;
            height: 20px;
            background: $themeColor;
            border-radius: 90px 90px 90px 90px;
            margin-right: 10px;
            display: inline-block;
         }
      }
      span{
         font-size: 16px;
         color: #333333;
         display: flex;
         align-items: center;
         cursor:pointer;
         img{
            width:18px;
            display: inline-block;
            margin-left:10px
         }
      }

   }
 }
 .school_list{
      .list_top{
         display: flex;
         align-items: center;
         justify-content:center;
         position:relative;
         .toggle{
            cursor:pointer;
            padding: 10px 62px;
            border: 1px solid #EEEEEE;
            font-size: 16px;
            position:relative;
            overflow: hidden;
            margin-left:-7px

         }
         .toggle:nth-of-type(3)>span{
            transform: skew(20deg);
            transform-origin: top left;
            display: inline-block;
         }
         .toggle:nth-of-type(1){
            border-radius: 20px 0px 0px 20px;
            border-right: none;
            background: linear-gradient(-67deg, transparent 13px, #fff 0) top right;
         }

          .toggle:nth-of-type(3){
            transform: skew(-20deg);
            border-left: 1px solid #EEEEEE;
            border-right: 1px solid #EEEEEE;
          }

          .toggle:nth-of-type(2){
            border-radius: 0px 20px 20px 0px;
            background:linear-gradient(110deg, transparent 13px, #fff 0) top right;
            border-left:none
         }
          .toggle:nth-of-type(1).active{
            background: linear-gradient(-71deg, transparent 13px, #FFEFE6 0) top right;
            color: $themeColor;
         }
         .toggle:nth-of-type(3).active{
            background:#FFEFE6;
            color: $themeColor;
         }
         .toggle:nth-of-type(2).active{
            background: linear-gradient(-70deg,#FFEFE6 177px, transparent  0) left top;
            color: $themeColor;
         }
      }
}
.scoollist_box{
   width:100%;
   margin: 20px auto 0;
   .school_list_item{
      display: flex;
      width: calc(((100% - 42px) / 3));
      margin-right: 21px;
      background: linear-gradient( 180deg, #FDFAF6 0%, #FFFFFF 100%);
      border-radius: 8px 8px 8px 8px;
      transition: all 0.6s;
      box-sizing: border-box;
      float: left;
      padding: 15px;
      margin-bottom: 21px;
      cursor: pointer;
      img{
         width :50px;
         height: 50px;
         display: block;
         margin-right: 15px;
      }
      .schoolcontent{
         width:100%;

         .schoolname{
            margin-bottom: 8px;
            height: 24px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            transition: all 1s;
            span:nth-of-type(1){
               font-weight: bold;
               font-size: 18px;
               color: #333333;
            }
            span:nth-of-type(2){
               font-size: 14px;
               color: #333333;
               font{
                  color: #FF772E;
               }
            }
         }
         p{
            font-size: 14px;
            color: #333333;
            margin-bottom: 8px;
            height: 19px;
            line-height: 19px;
         }
         &>p:nth-last-of-type(2){
            margin-bottom: 0;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 280px;
            margin-bottom: 9px;
         }
      }
   }
   .school_list_item:nth-of-type(3n){
      margin-right: 0;
   }
   .school_list_item:hover{
      background: linear-gradient( 180deg, #FDFAF6 0%, #FFFFFF 100%);
      box-shadow: 0px 5px 30px 1px rgba(0,0,0,0.1);
      .schoolname>span:nth-of-type(1){
         color: $themeColor;
      }
   }
}
.scoollist_box::after{
   content: '';
   display: block;
   clear: both;
}
>>>.el-table__body tr .el-table__cell{
   padding: 14px 0 !important;
}
.ruleform{
   justify-content: space-between;
   width: 100%;
   .el-form-item{
      margin-bottom: 0;
      margin-right:18px;
   }

}
.rankbox{
   margin-top: 9px;
   p{
     display: flex;
     justify-content: space-between;
     margin-bottom: 9px;
   }
   .ranking{
      font{
         color: #FF772E
      }
   }
 }
 .requireBox{
   width:1200px;
   margin: 0 auto;
   padding: 20px;
   border-top:1px solid #EEEEEE;
   background-color: #ffffff;
   box-sizing: border-box;
   border-radius: 0 0 12px 12px;
   .requireContent{
      padding: 14px 17px;
      box-sizing: border-box;
      width 100%;
      background: #FAFBFC;
      border-radius: 12px 12px 12px 12px;
      float: left;
      >p{
         width:100%;
         display: flex;
         align-items: center;

         font{
            display: inline-block;
            width: 56px;
         }
         .el-input{
            width: 150px;
         }
         span{
            display: flex;
            align-items: center;
            margin-right: 28px;
         }
         span:last-of-type{
            margin-right: 0;
         }
      }

      >p:nth-of-type(2){
         display: block;

         width: 100%;
         margin-top: 15px;
         .el-input{
            width: 100%;
         }
      }

   }
   .requireContent:nth-of-type(2n){
      float: right;
   }
 }
 .requireBox::after{
   content: '';
   display: block;
   clear: both;
 }
 .activeimg{
   transform: rotate(90deg) !important;
 }
 .rotates{
   transform: rotate(-90deg);
 }
 .btns{
   margin:0 auto;
   font-size: 14px;
   color: #333333;
   padding: 0px;
   display: block;
   padding-top: 16px;
 }

 .map{
   position: absolute;
   right: 0;

 }
</style>