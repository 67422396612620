<template>
  <div class="infobigBox">
    <leftNav></leftNav>
    <div class="infoBox">
      <p class="title">选校择校</p>
      <div class="infopng">
        <div class="searchBox">
          <el-form
            :inline="true"
            :model="searchObj"
            ref="form"
            label-position="left"
          >
            <el-form-item label="合同名称：">
              <el-input
                clearable
                v-model.trim="searchObj.contractName"
                style="width: 150px"
                size="medium"
                placeholder="请输入合同名称"
              ></el-input>
            </el-form-item>
            <el-form-item label="客户名称：">
              <el-input
                clearable
                v-model.trim="searchObj.customerName"
                style="width: 150px"
                size="medium"
                placeholder="请输入客户名称"
              ></el-input>
            </el-form-item>
            <el-form-item label="客户手机号：">
              <el-input
                clearable
                v-model.trim="searchObj.customerPhone"
                style="width: 160px"
                size="medium"
                placeholder="请输入客户手机号"
              ></el-input>
            </el-form-item>
            <el-form-item label="是否定稿：">
              <el-select
                clearable
                v-model="searchObj.isFinalized"
                style="width: 120px"
                size="medium"
              >
                <el-option
                  v-for="item in statusList"
                  :key="item.code"
                  :label="item.name"
                  :value="item.code"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button
                round
                size="medium"
                plain
                type="primary"
                @click="pageclick(1)"
                >查询</el-button
              >
              <el-button
                size="medium"
                round
                @click="handleDetail('add')"
                type="primary"
                >新增</el-button
              >
            </el-form-item>
          </el-form>
        </div>
        <el-table :data="tableData" v-loading="loading">
          <el-table-column
            type="index"
            label="序号"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="contractName"
            label="合同名称"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="customerName"
            label="客户名称"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="customerPhone"
            label="手机号"
            align="center"
          ></el-table-column>
          <el-table-column prop="isFinalized" label="是否定稿" align="center">
            <template slot-scope="scope">
              <span :class="getStatusClass(scope.row.isFinalized)">{{
                scope.row.isFinalized === 1 ? '已定稿' : '未定稿'
              }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="updateTime" label="操作时间"></el-table-column>
          <el-table-column
            prop="operation"
            label="操作"
            align="center"
            width="200"
          >
            <template slot-scope="scope">
              <el-button
                type="text"
                @click="handleDetail('details', scope.row)"
              >
                查看详情
              </el-button>
              <el-button
                :disabled="scope.row.isFinalized == 1"
                type="text"
                @click="handleDetail('update', scope.row)"
                >编辑</el-button
              >
              <el-button type="text" @click="handleDown(scope.row)">
                下载
              </el-button>
              <el-button type="text" @click="handleDelete(scope.row)">
                删除
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <paging
          :pageall="pageall"
          :pagenum="pagenum"
          @pageclick="pageclick"
          style="margin-top: 20px"
        ></paging>
      </div>
    </div>
    <el-dialog
      :title="titile"
      :visible.sync="dialogVisible"
      width="417px"
      center
      :close-on-click-modal="false"
      :show-close="false"
    >
      <span class="tips">{{ tipTxt }}</span>
      <div class="footBtn">
        <p @click="cancel">取消</p>
        <p @click="sure">确定</p>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { downloadFile } from '@/assets/js/index.js';
import leftNav from './leftNav';
import paging from '@/components/paging.vue';
import { getSchoolList, toDelete } from 'api/chooseSchool.js';
import { sendLog } from 'api/index.js';

export default {
  components: {
    leftNav,
    paging
  },
  data() {
    return {
      searchObj: {},
      tableData: [],
      dialogVisible: false,
      tipTxt: '',
      titile: '',
      statusList: [
        {
          code: 1,
          name: '已定稿'
        },
        {
          code: 0,
          name: '未定稿'
        }
      ],
      pageall: 0,
      pagenum: 1,
      loading: false,
      deletId: '',
      dowLoadInfo: ''
    };
  },
  methods: {
    pageclick(val) {
      this.pagenum = val;
      this.getList();
    },
    getList() {
      this.loading = true;
      const params = {
        createId: localStorage.getItem('userInfo').id,
        ifPage: true,
        pageSize: 10,
        pageNum: this.pagenum,
        ...this.searchObj
      };
      getSchoolList(params)
        .then((res) => {
          this.loading = false;
          if (res.code == 2000) {
            this.tableData = res.data.list;
            this.pageall = res.data.total;
          } else {
            this.$message({
              type: 'error',
              message: res.msg
            });
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    getStatusClass(score) {
      return score === 1 ? 'statusA ' : 'statusB';
    },
    // 删除按钮事件
    handleDelete(row) {
      this.deletId = row.id;
      this.dialogVisible = true;
      this.tipTxt = '确定要删除该条数据吗？';
      this.titile = '提示';
      this.num = 0;
    },
    handleDown(row) {
      this.dowLoadInfo = row;
      this.dialogVisible = true;
      this.tipTxt = '确认下载文件吗？';
      this.titile = '下载';
      this.num = 1;
    },
    cancel() {
      this.dialogVisible = false;
    },
    deleteItem() {
      toDelete({ id: this.deletId })
        .then((res) => {
          if (res.code == 2000) {
            this.dialogVisible = false;
            this.pageclick(1);
            this.$message({
              type: 'success',
              message: res.msg
            });
          } else {
            this.$message({
              type: 'error',
              message: res.msg
            });
          }
        })
        .catch((err) => {
          this.$message({
            type: 'error',
            message: err.msg
          });
        });
    },
    downLoadFileClick(url, name) {
      this.dialogVisible = false;
      const materialExcel = this.dowLoadInfo.materialExcelJson;
      const materialPdf = this.dowLoadInfo.materialPdfJson;
      downloadFile(materialExcel.url, materialExcel.name);
      downloadFile(materialPdf.url, materialPdf.name, false, '.pdf');
      sendLog({
        logMethod: "/move/sys/log/downloadLog",
        pageModeName: "学生选校择校表",
        logOperation: "下载文件",
        logType: "7",
        modular: "5",
        fileName: materialPdf.name,
        pageModeUrl: "/chooseSchool",
        fileUrl: materialPdf.url
      }).then((res) => {
      });
    },
    sure() {
      switch (this.num) {
        case 0:
          this.deleteItem();
          break;
        case 1:
          this.downLoadFileClick();
          break;
      }
    },
    handleDetail(pageType, row) {
      this.$router.push({
        path: '/chooseSchoolDetail',
        query: {
          pageType,
          id: (row && row.id) || ''
        }
      });
    }
  },
  mounted() {
    this.pageclick(1);
  }
};
</script>

<style lang="stylus" scoped>
@import '~css/stylus.styl';

>>>.el-dialog__header {
  padding: 19px 0 0 !important;
  text-align: center;
  font-size: 18px;
  line-height: 20px;
}

>>>.el-dialog--center .el-dialog__body {
  padding: 0px !important;
}

.infobigBox {
  width: 1200px;
  height: 100%;
  display: flex;
  justify-content: space-between;
  background-color: #F5F7FA;
  padding-bottom: 60px;
  margin: 0 auto;
}

.infoBox {
  width: 940px;
  background-color: #fff;
  margin: 27px 2% 27px;
  box-sizing: border-box;
  border-radius: 12px 12px 12px 12px;
  padding: 23px 0;

  .title {
    font-size: 20px;
    color: #333333;
    height: 23px;
    position: relative;
    padding-left: 14px;
    font-weight: bold;
    padding: 0 20px 21px 34px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .title::after {
    content: '';
    position: absolute;
    left: 20px;
    top: 2px;
    width: 4px;
    height: 20px;
    display: block;
    background-color: $themeColor;
    border-radius: 2px;
  }
}

.infopng {
  border-top: 1px solid #EEEEEE;
  padding: 19px 20px 0;
  margin: 0 auto 19px;
  box-sizing: border-box;
}

.statusA {
  color: rgba(29, 184, 40, 1);
}

.statusB {
  color: rgba(255, 130, 64, 1);
}

.tips {
  font-size: 16px;
  color: #333333;
  line-height: 20px;
  display: block;
  margin: 41px auto;
  text-align: center;
}

.footBtn {
  box-sizing: border-box;
  padding: 0 26px 18px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  p {
    width: 172px;
    height: 51px;
    text-align: center;
    line-height: 51px;
    font-weight: 400;
    font-size: 16px;
    color: #FF8240;
    border-radius: 90px 90px 90px 90px;
    cursor: pointer;
  }

  p:nth-of-type(1) {
    border: 1px solid #FF8240;
  }

  p:nth-of-type(2) {
    background: #FF8240;
    color: #FFFFFF;
  }
}

.el-button--small.is-round {
  padding: 6px 27px;
  font-size: 14px;
}

.searchBox {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.el-form-item {
  margin-bottom: 10px;
}
</style>
<style>
#app {
  background-color: #f5f7fa;
}
</style>
