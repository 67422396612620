<template>
  <div class="leftmenu">
    <div class="userbox">
      <img :src="userInfo.headLogo" alt="">
      <p>用户：{{ userInfo.name }}</p>
    </div>
    <el-menu :default-active="activeIndex" class="el-menu-vertical-demo" @select="handleSelect">
      <div v-for="(item, index) in pageList" :key="index">
        <el-menu-item :index="item.url" @click="routerTo(item)" v-if="!item.children || !item.children.length">
          <i><img :src="item.isActive ? item.iconAct : item.icon" alt=""></i>
          <span slot="title">{{ item.name }}</span>
        </el-menu-item>
        <el-submenu :index="`${index}-submenu`" v-else>
          <template slot="title">
            <i><img :src="item.isActive ? item.iconAct : item.icon" alt=""></i>{{ item.name }}</template>
          <el-menu-item
v-for="(items, indexs) in item.children"
:key="indexs"
:index="items.url"
            @click="routerTo(items)">
            <span
style="font-size: 15px; font-weight: 400; padding-left: 35px; height: 50px; line-height: 50px;"
              slot="title">{{
                items.name }}</span>
          </el-menu-item>
        </el-submenu>
      </div>
    </el-menu>
  </div>
</template>

<script>
export default {
  name: "LeftNav",
  data() {
    const pageList = [
      { name: "个人资料", url: "/personalData", icon: require('@/assets/lefticon1.png'), iconAct: require('@/assets/lefticon1_hover.png'), isActive: false },
      { name: '我的收藏', url: '/myCollection', icon: require('@/assets/lefticon3.png'), iconAct: require('@/assets/lefticon3_hover.png'), isActive: false },
      { name: '我的订单', url: '/myOrder', icon: require('@/assets/lefticon4.png'), iconAct: require('@/assets/lefticon4_hover.png'), isActive: false },
      { name: '签名管理', url: '/mySignature', icon: require('@/assets/lefticon6.png'), iconAct: require('@/assets/lefticon6_hover.png'), isActive: false },
      { name: '留学进度', url: '/Studyabroad', icon: require('@/assets/lefticon7.png'), iconAct: require('@/assets/lefticon7_hover.png'), isActive: false },
    ];
  const userInfo = localStorage.getItem('userInfo') || {};
  if (userInfo.userType == 0) {
    pageList.push({
      name: '我的档案',
      url: '/',
      icon: require('@/assets/lefticon5.png'),
      iconAct: require('@/assets/lefticon5_hover.png'),
      isActive: false,
      children: [
        { name: 'CRM档案', url: '/crmArchives' },
        {
          name: '申请材料管理',
          url: '/applicationForm',
          children: [
            { name: '申请材料管理详情', url: '/applicationFormdetail' },
          ]
        },
      ]
    });
  } else if (userInfo.userType == 1) {
    pageList.push({
      name: '选校择校',
      url: '/chooseSchool',
      icon: require('@/assets/lefticon7.png'),
      iconAct: require('@/assets/lefticon7_hover.png'),
      isActive: false,
      childrens: [
        {
          name: '选校择校详情',
          url: '/chooseSchoolDetail',
        },
      ]
    });
  }
    return { pageList, activeIndex: '', userInfo };
  },
  created() { },
  mounted() {
    this.updateActiveIndex();
    this.userInfo = localStorage.getItem('userInfo') || {};
  },
  watch: {
    $route(to, from) {
      this.updateActiveIndex();
    }
  },
  methods: {
    getMenuPath(currentPath) {
      // 遍历查找对应的菜单路径
      const findMenuPath = (items, path) => {
        for (const item of items) {
          // 检查当前路径或其详情页
          if (item.url === path ||
              (item.childrens && item.childrens.some(child => child.url === path))) {
            return item.url; // 返回父级菜单路由
          }

          if (item.children) {
            for (const child of item.children) {
              if (child.url === path) {
                return path;
              }
              if (child.children) {
                for (const grandChild of child.children) {
                  if (grandChild.url === path) {
                    return child.url; // 返回父级菜单的路由
                  }
                }
              }
            }
          }
        }
        return path;
      };
      return findMenuPath(this.pageList, currentPath);
    },

    handleSelect(index, indexPath) {
      this.activeIndex = this.getMenuPath(index);
      this.updatePageListActive(index);
    },

    routerTo(item) {
      this.$router
        .push({
          path: item.url
        })
        .then(() => { })
        .catch(() => { });
    },

    updateActiveIndex() {
      const currentPath = this.$route.path;
      this.activeIndex = this.getMenuPath(currentPath);
      this.updatePageListActive(currentPath);
    },

    updatePageListActive(path) {
      // 重置所有菜单项的选中状态
      const resetActiveStatus = (items) => {
        items.forEach(item => {
          item.isActive = false;
          if (item.children) {
            resetActiveStatus(item.children);
          }
        });
      };

      // 检查路径是否匹配，并设置选中状态
      const checkPath = (items, currentPath) => {
        let hasActive = false;

        for (const item of items) {
          // 检查当前项的 URL 是否匹配，或者是否匹配其详情页
          if (item.url === currentPath ||
              (item.childrens && item.childrens.some(child => child.url === currentPath))) {
            item.isActive = true;
            hasActive = true;
          }

          // 检查是否是子路径
          if (item.children) {
            const childHasActive = checkPath(item.children, currentPath);
            if (childHasActive) {
              item.isActive = true;
              hasActive = true;
            }

            // 检查孙子路径
            for (const child of item.children) {
              if (child.children) {
                const grandChildActive = child.children.some(grandChild => grandChild.url === currentPath);
                if (grandChildActive) {
                  item.isActive = true;
                  child.isActive = true;
                  hasActive = true;
                }
              }
            }
          }

          // 特殊处理：如果当前路径以某个菜单的 URL 开头（处理动态路由）
          if (item.url !== '/' && currentPath.startsWith(item.url + '/')) {
            item.isActive = true;
            hasActive = true;
          }
        }
        return hasActive;
      };

      // 执行更新
      resetActiveStatus(this.pageList);
      checkPath(this.pageList, path);
    },
  }
};
</script>

<style lang='stylus'>
@import '~css/stylus.styl';
.el-main{
  background-color: #F5F7FA !important;
}
.leftmenu {
  width: 230px;
  height: 100%;
  background: #fff;
  border-radius: 12px 12px 12px 12px;
  margin: 27px 0;
  padding: 20px 0 0;
  text-align: center;
  min-height: 645px;
}

.el-menu {
  border-right: none;
}

.el-menu-item, .el-submenu__title {
  border-right: none;
  height: 60px !important;
  line-height: 60px !important;
  font-size: 16px !important;
  color: #999999 !important;
  font-weight: bold !important;
  display: flex;
  justify-content: center !important;
  align-items: center;

  i {
    img {
      width: 22px;
      height: 22px;
      margin-right: 10px;
      vertical-align: -5px;
    }
  }
}

.el-menu-item:focus, .el-menu-item:hover {
  background: linear-gradient(270deg, #FFFFFF 0%, #FFEFE6 100%);
}

.el-menu-item.is-active {
  background: linear-gradient(270deg, #FFFFFF 0%, #FFEFE6 100%);
  color: $themeColor !important;
}

.el-menu-item {
  justify-content: flex-start;
  padding-left: 42px !important;
}
.is-active .el-submenu__title{
  color: $themeColor;
  background: linear-gradient(270deg, #FFFFFF 0%, #FFEFE6 100%);
}
.el-submenu__title {
  justify-content: flex-start;
  padding-left: 42px !important;
}

.userbox {
  margin-bottom: 20px;
  p {
    font-size: 14px;
    color: #666666;
    margin-top: 10px;
  }
  img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }
}
</style>