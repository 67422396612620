<template>
  <div>
    <el-dialog
      :visible.sync="show"
      width="60%"
      :show-close="false"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <!-- title slot -->
      <div slot="title">
        <div class="headerBigBox">
          <div class="headerText">添加至选校择校</div>
          <div class="headerButtonBox">
            <!--  -->
            <div class="headerButton" @click="cancelClick">
              <i class="el-icon-close"></i>
            </div>
          </div>
        </div>
      </div>
      <div class="dialog_content">
        <!-- search -->
        <div class="searchBigBox">
          <p>搜索：</p>
          <el-input
            style="width: 200px"
            size="medium"
            type="text"
            v-model="contractName"
            @input="onInput"
            placeholder="请输入合同名称"
            clearable
          ></el-input>
        </div>
        <!-- content -->
        <div class="contentBigBox">
          <el-table
            :row-key="getRowKey"
            :data="dataList"
            stripe
            style="
              width: 100%;
              max-height: calc(100vh - 260px);
              overflow-y: auto;
            "
            @selection-change="handleSelectionChange"
          >
            <el-table-column type="selection" reserve-selection>
            </el-table-column>

            <el-table-column
              type="index"
              label="序号"
              width="55"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="contractName"
              label="合同名称"
              align="center"
              width="220"
            >
            </el-table-column>
            <el-table-column
              prop="customerName"
              label="客户名称"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="customerPhone"
              label="手机号"
              align="center"
              min-width="200"
            >
            </el-table-column>
          </el-table>
          <paging
            :pageall="pageall"
            :pagenum="pagenum"
            @pageclick="pageclick"
            style="margin-top: 20px"
          ></paging>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button
          @click="cancelClick()"
          style="width: 25%; border-radius: 25px"
          round
          plain
        >
          取消
        </el-button>
        <el-button
          type="primary"
          @click="addSchool()"
          round
          :loading="loading"
          style="
            width: 25%;
            border-radius: 25px;
            background: #ff8240;
            border-color: #ff8240;
          "
        >
          确定
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import paging from '@/components/paging.vue';
import { batchInsertSaiSchool, batchContracts } from 'api/chooseSchool.js';
import { debounce } from 'lodash';
export default {
  name: 'ChooseSchoolDialog',
  props: {
    show: Boolean,
    dataInfo: Object,
    id: String,
    dataInfos: Object
  },
  components: {
    paging
  },
  data() {
    return {
      pageall: 0,
      pagenum: 1,
      searhObj: '',
      dataList: [],
      multipleSelection: [],
      loading: false,
      contractName: ''
    };
  },
  methods: {
    pageclick(val) {
      this.pagenum = val;
      this.getList();
    },
    getList() {
      const userInfo = localStorage.getItem('userInfo') || {};
      console.log(userInfo);
      if (userInfo.teacherId == null) {
        return;
      }
      const data = {
        validContract: 1,
        ifPage: false,
        pageSize: 999,
        pageNum: 1,
        teacherId: userInfo.teacherId,
        contractName: this.contractName
      };
      batchContracts(data).then((res) => {
        if (res.code == '2000') {
          // this.pageall = res.data.total || 0;
          this.dataList = res.data.list || [];
        } else {
          this.$message({
            type: 'error',
            message: res.msg
          });
        }
      });
    },
    addSchool() {
      if (this.multipleSelection.length == 0) {
        this.$message({
          type: 'error',
          message: '请选择客户'
        });
        return;
      }
      console.log(this.dataInfos);
      const data = {
        schoolId: this.dataInfo.id,
        schoolName: this.dataInfo.universityNameEn,
        projectId: Number(this.id),
        projectName: this.dataInfos.programNameEn
      };
      // 便利this.multipleSelection,将contractNumber放在contractNumberList数组中并用逗号隔开
      const contractNumberList = this.multipleSelection.map((item) => {
        return item.contractNumber;
      });
      data.contractNumberList = contractNumberList;
      console.log(data);
      this.loading = true;
      batchInsertSaiSchool(data).then((res) => {
        if (res.code == '2000') {
          this.$message({
            type: 'success',
            message: '添加成功'
          });
          this.loading = false;
          this.$emit('update:show', false);
        } else {
          this.loading = false;
          this.$message({
            type: 'error',
            message: res.msg
          });
        }
      });
    },
    onInput() {
      this.pagenum = 1;
      debounce(this.getList, 500)();
      // this.getList();
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    getRowKey(row) {
      return row.id;
    },
    cancelClick() {
      this.$emit('update:show', false);
    }
  },
  created() {
    this.getList();
  }
};
</script>
<style lang="stylus" scoped>
.headerBigBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: bold;
  font-size: 18px;
  color: #666666;

  .headerButton {
    cursor: pointer;
  }
}

>>> .el-dialog {
  margin-top: 5vh !important;
}

>>>.el-dialog__body {
  padding: 0;
}

.searchBigBox {
  display: flex;
  margin-bottom: 15px;
  align-items: center;
}

.dialog_content {
  padding: 16px 20px;
  border-top: 1px solid #eee;
}
</style>