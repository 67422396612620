<template>
  <div class="big_box"  @contextmenu.prevent @copy.prevent  @cut.prevent  @paste.prevent style=" user-select: none;">
    <div class="topban">
      <div class="topban_left">
        <p>
          <img src="~@/assets/share/iconleft.png" alt="" />超哥资讯<img
            src="~@/assets/share/iconright.png"
            alt=""
          />
        </p>
        <span>全球超哥资讯最新动态</span>
      </div>
    </div>
    <div class="searchBox" v-show="setMenu.cgzx_one.isShow==1">
        <div class="project">
        <p>主类：</p>
        <el-radio-group
          v-model="mainClassId"
          @change="areasChange"
        >
          <el-radio-button
            v-for="(item, index) in ZXZLList"
            :key="index"
            :label="item.id"
            >{{ item.name }}</el-radio-button
          >
        </el-radio-group>
      </div>
      <div class="project">
        <p>地区：</p>
        <el-radio-group
          v-model="areaId"
          @change="areasChange"
        >
          <el-radio-button
            v-for="(item, index) in ZXDQList"
            :key="index"
            :label="item.id"
            >{{ item.name }}</el-radio-button
          >
        </el-radio-group>
      </div>
      <!-- <div class="project">
        <p>排名：</p>
        <el-radio-group
          v-model="rank"
          @change="areasChange"
        >
          <el-radio-button
            v-for="(item, index) in ZXPMList"
            :key="index"
            :label="item.name"
            >{{ item.name }}</el-radio-button
          >
        </el-radio-group>
      </div> -->
      <div class="project">
        <p>子类：</p>
        <el-radio-group
          v-model="sonClassId"
          @change="areasChange"
        >
          <el-radio-button
            v-for="(item, index) in ZXSLList"
            :key="index"
            :label="item.id"
            >{{ item.name }}</el-radio-button
          >
        </el-radio-group>
      </div>
      <div class="schoolbox">
        <p>查看更多具体学校：</p>
        <div v-if="isPanelVisible" class="panel">
          <el-checkbox-group v-model="checkUniversity" @change="areasChange" size="medium">
                <el-checkbox-button v-for="school in schoolList" :label="school.id" :key="school.id">{{school.universityNameCn}}</el-checkbox-button>
              </el-checkbox-group>
          </div>
        <el-button round @click="togglePanel"> {{ isPanelVisible ? '收起' : '展开' }} <img :class="{'activeimg':!isPanelVisible}" src="@/assets/icon_arrow.png" alt=""></el-button>
      </div>
    </div>

    <div class="sharebox" v-show="setMenu.cgzx_two.isShow==1">
      <div class="sharetop">
          <p>
            <span>共查询到 <font>{{ pageall }}</font>条数据</span>
            <span>已选择<font>{{ choosetotal }}</font>条数据</span>
          </p>
        <el-button type="primary" @click="share">分享<i class="el-icon-share"></i></el-button>
    </div>
      <div class="sharelist">
        <el-checkbox-group
            v-model="checkedCities"
            @change="handleCheckedCitiesChange"
            >
            <el-checkbox v-for="item in infoList" :label="item" :key="item.id" class="flexs  shareBox">
              <div style="display:flex;"  @click="toDetail(item)">
              <img class="imgs" :src="item.picture" alt="">
                <div class="shareTxt">
                  <p>{{item.title}}<span class="tips">{{item.sonClass}}</span></p>
                  <p v-html="item.content"></p>
                  <p>
                    <span><i class="el-icon-office-building"></i>{{item.universityName}}</span>
                    <span><i class="el-icon-date"></i>{{item.createTime.split(' ')[0]}}</span>
                  </p>
                </div>
              </div>
            </el-checkbox>
          </el-checkbox-group>
       </div>
       <el-empty v-if="infoList.length == 0" description="暂无数据"></el-empty>
       <paging
        :pageall="pageall"
        :pagenum="pagenum"
        @pageclick="pageclick"
        style="margin-top: 20px;"
      ></paging>
    </div>
    <el-dialog title="预览" width="500px" :visible.sync="dialogFormVisible" :close-on-click-modal="false">
      <el-form :model="form">
        <el-form-item label="自定义主标题" :label-width="formLabelWidth">
          <el-input v-model="form.maintitle" placeholder="主标题（例如：超哥留学的新闻快讯）"></el-input>
        </el-form-item>
        <el-form-item label="自定义副标题" :label-width="formLabelWidth">
          <el-input v-model="form.subtitle" placeholder="副标题"></el-input>
        </el-form-item>
        <el-form-item label="自定义结尾" :label-width="formLabelWidth">
          <el-input v-model="form.end" placeholder="自定义分享内容结尾"></el-input>
        </el-form-item>
      </el-form>
      <div class="sharepic" ref="screenshotArea">
          <p class="shareTitle" v-show="form.maintitle!=''">{{ form.maintitle }}</p>
          <p class="subTitle" v-show="form.subtitle!=''">{{ form.subtitle }}</p>
          <p class="line"></p>
          <div class="tipBox">
            <div class="tiplist" v-for="(item,index) in checkedCities" :key="index">
              <p class="tiptitle">{{ item.title }}</p>
              <span class="tipcontent">{{ item.content }}</span>
              <p class="tipname">{{ item.name }} {{ item.time }}</p>
            </div>
          </div>
          <p class="endtitle"  v-show="form.end!=''">{{ form.end }}</p>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="sharePic">生成图片并下载</el-button>
      </div>
    </el-dialog>
    <el-dialog title="预览" custom-class="previewDialog" width="800px" :visible.sync="previewVisible" :close-on-click-modal="false">
      <div class="showBox">
        <!-- <img :src="showDetail.picture" alt="" style="width: 100%; height: auto;"> -->
        <p class="tiptitle">{{ showDetail.title }}</p>
        <p class="tipname"><span>{{ showDetail.universityName }}</span> <span>{{ showDetail.createTime }}</span></p>
        <span class="tipcontent" v-html="showDetail.content"></span>
        <!-- <i class="el-icon-close close"  @click="previewVisible = false"></i> -->
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getInfoList } from 'api/information.js';
import { dicListByCode } from 'api/index.js';
import { getCollegeList } from 'api/libraryList.js';
import { getmenuList } from 'api/menu.js';
import html2canvas from 'html2canvas';
import paging from "@/components/paging.vue";

export default {
  components: {
    paging,
  },
  data() {
    return {
      mainList: [],
      ismain: 0,
      areas: 0,
      activeNames: 1,
      checkUniversity: [],
      choosetotal: 0,
      checkedCities: [],
      infoList: [],
      dialogFormVisible: false,
      form: {
        maintitle: "机构新闻快讯",
        subtitle: "",
        end: "",
      },
      pageall: 0,
      pagenum: 1,
      isPanelVisible: false,
      formLabelWidth: '120',
      dicCodelist: ['ZXZL', 'ZXDQ', 'ZXSL', 'ZXPM'], // ZXZL 主类  ZXDQ地区  ZXSL子类
      ZXZLList: [],
      ZXSLList: [],
      ZXDQList: [],
      ZXPMList: [],
      schoolList: [],
      areaId: 0,
      sonClassId: 0,
      mainClassId: 0,
      hasRequested: false,
      rank: '不限',
      setMenu: {
        cgzx_one: {
          isShow: 1
        },
        cgzx_two: {
          isShow: 1
        },
      },
      previewVisible: false,
      showDetail: {}
    };
  },
  created() {
    this.getMenu();
  },
  mounted() {
    this.getCodeList();
    this.getSchool();
  },
  methods: {
    getMenu() {
      const data = {
        code: 'CGZX'
      };
      getmenuList(data).then(res => {
            if (res.code == 2000) {
                // this.$store.commit('setMenu',res.data)
                this.setMenu = res.data;
                console.log(this.setMenu);
            } else {
                this.$message({
                    type: 'error',
                    message: res.msg
                });
            }
        }).catch((err) => {
            this.$message({
                type: 'error',
                message: err.msg
            });
        });
    },
    sendRequest() {
      if (!this.hasRequested) {
        this.getList();
      }
  },
    getList() {
      const data = {
        ifPage: true,
        pageSize: 10,
        pageNum: this.pagenum,
        areaId: this.areaId || '',
        sonClassId: this.sonClassId || '',
        mainClassId: this.mainClassId || '',
        checkUniversity: this.checkUniversity || '',
      };
      if (this.areaId == 0) {
        data.areaId = '';
      }
      if (this.sonClassId == 0) {
        data.sonClassId = '';
      }
      if (this.mainClassId == 0) {
        data.mainClassId = '';
      }

      if (this.rank == '不限') {
        data.qsRankStart = '';
        data.qsRankEnd = '';
        data.rank = '';
      } else {
        const parts = this.rank.split('-');
        data.qsRankStart = parts[0];
        data.qsRankEnd = parts[1];
      }

      getInfoList(data).then(res => {
        if (res.code == '2000') {
          this.pageall = res.data.total || 0;
          this.infoList = res.data.list || [];
          this.infoList.forEach(value => {
            this.checkedCities.map((item, index) => {
              if (item.id == value.id) {
                this.$set(this.checkedCities, index, value);
              }
            });
          });
        } else {
          this.$message({
            type: 'error',
            message: res.msg
          });
        }
      });
    },
    changeColl() {},
    share() {
      if (this.checkedCities.length == 0) {
        this.$message({
          type: "warning",
          message: "请至少选择一条新闻！",
        });
      } else {
        this.form.subtitle = '';
        this.form.end = '';
        this.dialogFormVisible = true;
      }
    },
    handleCheckedCitiesChange() {
      this.choosetotal = this.checkedCities.length || 0;
    },
    toDetail(item) {
      this.showDetail = item;
      this.previewVisible = true;
      console.log(this.showDetail);
    },
    sharePic() {
      html2canvas(this.$refs.screenshotArea).then((canvas) => {
        // 将canvas转换为DataURL，这里以png格式为例
        const imgUrl = canvas.toDataURL('image/png');

        // 创建一个可下载的a标签
        const link = document.createElement('a');
        link.href = imgUrl;
        link.download = '新闻.png';
        link.click();
      });
    },
    togglePanel() {
      this.isPanelVisible = !this.isPanelVisible;
    },
    pageclick(num) {
      this.pagenum = num;
      this.getList();
    },
    getCodeList() {
        this.ZXDQList.push({ name: '不限', id: 0 });
        this.ZXSLList.push({ name: '不限', id: 0 });
        this.ZXPMList.push({ name: '不限', id: 0 });
        this.dicCodelist.forEach((item) => {
            dicListByCode({ code: item }).then(res => {
                if (res.code == '2000') {
                    this[item + 'List'].unshift(...res.data);
                    if (item == 'ZXZL') {
                      this.mainClassId = this[item + 'List'][0].id;
                      this.getList();
                    }
                } else {
                    this.$message({
                        type: 'error',
                        message: res.msg
                    });
                }
            }).catch(error => {
                console.error('An error occurred:', error);
                this.$message({
                    type: 'error',
                    message: '请求过程中发生错误，请稍后再试。'
                });
            });
        });
    },
    getSchool() {
      const data = {
        ifPage: true,
        pageSize: 1000,
        pageNum: this.pagenum,
        areaId: this.areaId
      };
      if (this.rank == '不限') {
        data.qsRankStart = '';
        data.qsRankEnd = '';
        data.rank = '';
      } else {
        const parts = this.rank.split('-');
        data.qsRankStart = parts[0];
        data.qsRankEnd = parts[1];
      }
      getCollegeList(data).then(res => {
        if (res.code == '2000') {
          this.schoolList = res.data.list || [];
        } else {
          this.$message({
            type: 'error',
            message: res.msg
          });
        }
      });
    },
    areasChange() {
      this.pagenum = 1;
      this.getList();
      this.getSchool();
    },
  },
};
</script>

<style lang="stylus" scoped>
@import '~css/stylus.styl';
p{
    margin: 0
}
.project {
    display: flex;
    align-items: center;
    margin-bottom: 23px;
    p{
      font-size: 14px;
      color: #333333;
    }
    .el-radio-button{
      margin-right: 10px;
      border: none;
    }
    >>>.el-radio-button__inner{
      background: #EEEEEE;
      height: 26px;
      border-radius: 6px 6px 6px 6px;
      color: #999999;
      background-color: #eee;
      font-size: 14px;
      padding: 4px 8px;
      line-height: 17px;
      border: none;
    }
    >>>.el-radio-button__orig-radio:checked+.el-radio-button__inner{
      color: $themeColor;
      box-shadow: none;
      background: #FFEFE6;
    }
}
.sharetop {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 21px 0 16px;
    box-sizing: border-box;
    width:1160px
    border-bottom: 1px solid #EEEEEE;
    margin:0 auto 20px;
    p{
      span{
        font-size: 14px;
        color: #333333;
        height: 19px;
        font{
          display: inline-block;
          margin: 0 10px;
        }
      }
      span:nth-of-type(1){
        margin-right: 40px;
      }
    }
    .el-button{
      width: 100px;
      height: 30px;
      background: $themeColor;
      border-radius: 15px 15px 15px 15px;
      line-height: 30px;
      padding: 0;
      font-size: 14px;
      color: #FFFFFF;
      i{
        margin-left:5px;
      }
    }

}
.sharelist{
    display: flex;
    align-items: center;
    margin: 0 auto;
    width 1159px;
    .imgs{
        width: 190px;
        height:127px;
        margin-right:15px;
        border-radius: 12px 12px 12px 12px;
    }
    .shareTxt{
      width: 82%;
      p{
        white-space: normal;
        .tips{
          height: 24px;
          line-height: 24px;
          padding: 0 7px;
          margin-right:0px;
          border-radius: 4px 4px 4px 4px;
          border: 1px solid #22C582;
          font-size: 14px;
          color: #22C582;
          margin-left: 10px;
          font-weight: 400;
          display: inline-block;

        }
      }
      p:nth-of-type(1){
        height: 24px;
        font-weight: bold;
        font-size: 18px;
        color: #333333;
        margin-bottom: 10px;
        display: flex;
        align-items: center;
     }
     p:nth-of-type(2){
      font-weight: 400;
      font-size: 14px;
      color: #666666;
      line-height: 20px;
      margin-bottom: 12px;
      word-break: break-all;
      display: -webkit-box; /* 旧版Safari和Chrome */
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3; /* 显示三行 */
      overflow: hidden; /* 隐藏超出的文本 */
      text-overflow: ellipsis; /* 显示省略号 */
     }
     p:nth-of-type(3){
      span:nth-of-type(1){
        margin-right: 144px;
      }
      span{
        font-size: 14px;
        color: #666666;
        line-height: 20px;
        height:20px;
        i{
          margin-right: 6px;
        }
      }
     }
}
}
.flexs{
  display: flex;
  align-items: center;
  width: 100%;
  padding: 20px;
  border: 1px solid #eee;
  border-radius: 5px;
  margin-bottom: 10px;
  box-sizing: border-box;
  transition: all 0.6s;
  >>>.el-checkbox{
    width: 100%;
    display: flex;
    align-items: center;
  }
  >>>.el-checkbox__label{
    width: 100%;
    display: flex;
    align-items: center;
  }
}
>>>.el-checkbox-group{
  width: 100%;
}
>>>.el-checkbox__input.is-checked+.el-checkbox__label{
  color: #606266
}
.sharebox{
  width 1200px;
  background: #FFFFFF;
  border-radius: 12px 12px 12px 12px;
  margin: 20px auto;
  padding-bottom:20px;
}
.big_box{
  background: #F5F7FA;
  padding-bottom:84px;
  position:relative;
}
>>>.el-dialog__body{
  padding: 0px 20px
}
.el-form-item{
  margin-bottom: 0px;
}
.sharepic{
  padding: 20px;
  margin: 20px 0 0;
  background: url(../../assets/share.png) repeat;
  background-size: cover;
  .shareTitle{
    font-size: 20px;
    color: #333333;
    font-weight: bold;
    text-align: center
  }
  .subTitle{
    font-size: 14px;
    color: #333333;
    text-align: center;
    margin: 10px 0;
  }
  .line{
    height: 1px;
    background: #eee;
    margin: 10px 0;
  }
  .endtitle{
    font-size: 14px;
    color: #333333;
    text-align: left;
    margin:10px 0;
  }
}
.tiplist{
  padding-bottom: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid #eee;
  .tiptitle{
    font-weight: bold;
    font-size: 16px;
    color: #333333;
    margin-bottom: 10px;
  }
  .tipcontent{
    display: block;
    font-size: 14px;
    line-height: 24px;
    color: #333;
  }
  .tipname{
    display:block;
    font-size: 14px;
    color: #eee;
    line-height: 20px;
  }

}
.dialog-footer{
  text-align: right !important;
}
.searchBox{
  width: 1200px;
  box-sizing: border-box;
  background: #FFFFFF;
  border-radius: 12px 12px 12px 12px;
  padding: 26px 30px;
  margin: -33px auto 0;
  position:relative
  z-index:2;
}
.topban {
  width: 100%;
  height: 140px;
  background: url('~@/assets/info_ban.png') no-repeat;
  background-size: 100% 100%;
  position: relative;
  z-index:1;
}

.topban_left {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  p {
    font-size: 20px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 40px;
      height: auto;
      display: block;
      margin-right: 12px;
    }

    img:nth-of-type(2) {
      margin-left: 12px;
    }
  }

  span {
    display: block;
    font-size: 14px;
    color: #FFFFFF;
    text-align: center;
    height: 19px;
    margin-top: 6px;
  }
}
.schoolbox{
  .el-button{
    width: 140px;
    height: 30px;
    background: #FFFFFF;
    border-radius: 20px 20px 20px 20px;
    border: 1px solid $themeColor;
    padding: 0;
    margin: 0 auto;
    font-size: 14px;
    color: $themeColor;
    display: block;
    transition: all 0.5s;
  }
  span{
    img{
      transition: all 0.3s;
      width : 9px;
      display: inline-block;
      margin-left:7px
    }
  }
  .activeimg{
    transform: rotate(180deg);
  }
}
.panel {
  padding: 20px 0;
  .el-checkbox-button{
    margin: 0 10px 20px 0
  }
  >>>.el-checkbox-button--medium .el-checkbox-button__inner{
    height: 26px;
    background: #EEEEEE;
    border-radius: 6px 6px 6px 6px;
    font-size: 14px;
    color: #999999;
    padding: 4px 8px;
    line-height: inherit;
    border: none;
    box-shadow: none;
  }
  >>>.el-checkbox-button.is-checked .el-checkbox-button__inner{
    background: #FFEFE6;
    color: $themeColor;
  }
}
>>>.el-dialog {

  margin-top:8vh !important;
}

>>>.el-dialog .el-dialog__body {
  flex: 1;
  overflow: auto;
}
.shareBox:hover{
  box-shadow: 0px 5px 30px 1px rgba(0,0,0,0.1);
  .shareTxt>p:nth-of-type(1){
    color: #FF8240;
  }
}
>>>.el-checkbox__inner{
  border-radius: 50% !important;
  width:25px !important;
  height: 25px !important;

}
>>>.el-checkbox__inner:after{
  height: 14px;
  left: 10px;
  top: 3px;
}
>>>.el-radio-button:first-child .el-radio-button__inner{
  border-left: none !important;

}
.showBox{
  padding:20px 0 67px;

  img{
    border-radius: 12px 12px 12px 12px;
  }
  .tiptitle{
    font-weight: bold;
    font-size: 20px;
    color: #333333;
    margin: 0 0 10px 0;
  }
  .tipname{
    display: flex;
    justify-content: space-between;
    font-weight: 400;
    font-size: 14px;
    color: #333333;
    margin-bottom: 10px;
  }
  .tipcontent{
    font-weight: 400;
    font-size: 14px;
    color: #333333;
    line-height: 22px;
    max-height: 300px;
    overflow-y:scroll;
    display: block;

  }
}
>>>.previewDialog{
  position: relative;
  .el-dialog__header{
    padding:22px 20px 15px;
    border-bottom: 1px solid #EEEEEE;
  }
}
.close{
  width: 28px;
  height : 28px;
  color: #fff;
  background:transparent;
  border-radius: 50%;
  border: 1px solid #fff;
  cursor: pointer;
  position: absolute;
  bottom: -43px;
  text-align: center;
  line-height: 28px;
  font-size: 18px;
  left:50%;
  transform: translateX(-50%);
}
</style>
